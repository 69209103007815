import { PosLogModuleEnum, PosLogOperationEnum } from '@/dataModel/dict/enum';
import { IStore } from '@/dataModel/mdata/store/interface';
import { ISettingUser } from '@/dataModel/system/settingUser';
import { IPosLog, IQueryLogs } from '@/dataModel/system/systermLog/interface';
import request from '@/utils/request';
import { IBackEndLog } from '@/dataModel/system/systermLog/interface';

const { post } = request;

const log = {
  /**
   *
   * 分页查询后台/前台操作日志
   * @param params
   * @returns
   */
  query: (
    params: {
      filter: {
        creatorName: string;
        day?: string;
        endTime?: string;
        moduleId: string;
        operationDesc: string;
        operationId: string;
        sourceType: string;
        startTime?: string;
        store: string;
      };
      mark: string;
    } & IPageParams
  ) => post<IPageData<IQueryLogs>>('/api/pcweb/system/log/queryLogs', params),

  /**
   *
   * 分页查询POS操作日志
   * @param params
   * @returns
   */
  queryPosLog: (
    params: {
      filter: {
        endTime: string;
        startTime: string;
        cashiers: ISettingUser['uuid'][];
        storeIds: IStore['id'][];
        module: PosLogModuleEnum;
        operation: PosLogOperationEnum;
        posId: string;
      };
    } & IPageParams
  ) => post<IPageData<IPosLog>>('/api/pcweb/system/pos/log/query', params),

  /**
   *
   * 后端操作日志
   * @param params
   * @returns
   */
  backEndLog: (params: {
    filter: {
      endTime: string;
      startTime: string;
      source: string;
      module: string;
      operationDesc: string;
      operationName: string;
      operator: string;
    };
  }) => post<IPageData<IBackEndLog>>('/api/pcweb/system/backstage/log/query', params)
};

export default log;
