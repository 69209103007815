import request from '@/utils/request';
import { IAllotQueryGoods, IAllotStoreList, IAllotGoods, ICreatorList } from '@/dataModel/wrh/allot/interface';
const { post } = request;

// 获取门店数据列表

const WrhAllot = {
  // 获取已分货数据
  queryPoolData: (params?: {
    filter: {
      invLack: boolean;
      sortIds?: string[];
      supplierUuids?: string[];
      wrhs?: string[];
      keyword?: string;
    };
    orderBy?: {
      direction: 'desc' | 'asc';
      field: string;
    };
    page: number;
    pageSize: number;
  }) => {
    return post<IPageData<any>>('/api/pcweb/business/wrh/allot/pool/query', params);
  },
  // 生成配货单，返货任务唯一标识
  generateAllocBill: (params?: {
    /**勾选的商品 */
    uuids: string[];
    /**是否全量生成配货单 true是 false否 */
    isAll: boolean;
  }) => {
    return post('/api/pcweb/business/wrh/allot/pool/generateAllocBill', params);
  },
  // 获取任务信息
  getTaskInfo: (params?: { uuid: string }) => {
    return post('/api/pcweb/business/wrh/allot/pool/getTaskInfo', params);
  },
  // 获取库存不足商品记录数量
  getInvLackPoolsCount: (params?: { storeId: string }) => {
    return post('/api/pcweb/business/wrh/allot/pool/getInvLackPoolsCount', params);
  },
  // 获取门店数据列表
  /**
   * 分页查
   */
  query: (
    params: {
      filter: {
        sortIds?: string[];
        suppliers?: string[];
        wrhs?: string[];
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => post<IPageData<IAllotQueryGoods>>('/api/pcweb/business/wrh/allot/pre/queryGoods', params),
  //添加商品
  addGoods: (params?: { uuids: string[] }) => post<any>('/api/pcweb/business/wrh/allot/pre/addGoods', params),
  // 清空未分货商品
  clearGoods: () => post<{ traceId: string }>('/api/pcweb/business/wrh/allot/pre/clearGoods'),
  goodsList: (
    params?: {
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => post<IPageData<IAllotGoods>>('/api/pcweb/business/wrh/allot/pre/goodsList', params),
  /** 删除商品 */

  //获取门店分货数据列表
  storeAllotList: (params?: { uuid: string; orderBy?: IPageParamsOrderBy }) =>
    post<IAllotStoreList[]>('/api/pcweb/business/wrh/allot/pre/storeAllotList', params),
  /** 保存门店分货数据 */
  saveStoreAllotData: (params?: { allotUuid: string; qty?: number; storeId?: string; storeUuid?: string }) =>
    post<IAllotStoreList[]>('/api/pcweb/business/wrh/allot/pre/saveStoreAllotData', params),
  /** 完成分货 */
  autoAllot: () => post<{ traceId: string }>('/api/pcweb/business/wrh/allot/pre/autoAllot'),
  getStoreList: (params?: { orderBy?: IPageParamsOrderBy; uuid: string }) => {
    return post('/api/pcweb/business/wrh/allot/pool/getStoreList', params);
  },
  /** 自动分货 */
  finishAllot: () => post<{ traceId: string }>('/api/pcweb/business/wrh/allot/pre/finishAllot'),
  // 删除已分数据
  removeGoods: (params?: { uuid: string }) => {
    return post('/api/pcweb/business/wrh/allot/pre/removeGoods', params);
  },
  // 删除已分数据
  removePoolGoods: (params?: { uuid: string }) => {
    return post('/api/pcweb/business/wrh/allot/pool/remove', params);
  },
  // 保存门店分货数据
  saveStoreData: (params?: { storeDataList: any[]; poolUuid: string }) => {
    return post('/api/pcweb/business/wrh/allot/pool/saveStoreData', params);
  },
  // 库存不足商品，重新分货
  reAllotForLackInv: () => {
    return post('/api/pcweb/business/wrh/allot/pool/reAllotForLackInv');
  },
  // 查询提交人
  getAllCreatorList: () => post<ICreatorList[]>('/api/pcweb/business/wrh/allot/pool/getAllCreatorList')
};

export default WrhAllot;
