import { PurchaseOrderStatusEnum } from '@/dataModel/dict/enum';
import {
  IPurchaseOrder,
  IPurchaseOrderDetail,
  IPurchaseOrderQueryFilter,
  IPurchaseOrderReferInv,
  IPurchaseOrderReferSupplier,
  ISupplierAgreementDetail
} from '@/dataModel/purchase/order/interface';
import request from '@/utils/request';
/**
 * 请求：获取参考信息
 */
export interface IPurchaseOrderReferenceRequest {
  /**
   * 商品uuid
   * @example 00456789123
   */
  goods: string;

  /**
   * 商品单位
   * @example 箱
   */
  goodsMunit: string;

  /**
   * 含量
   * @example 1*12
   */
  goodsQpcStr: string;

  /**
   * 租户ID
   * @example TENANT1234
   */
  pid?: string;

  /**
   * 门店ID
   * @example 99998888
   */
  storeId?: string;

  /**
   * 用户ID
   * @example USER5678
   */
  wid?: string;

  /**
   * 仓库标识
   * @example 05774343854
   */
  wrh: string;
}

/**
 * 请求：获取参考信息
 */
export interface IPurchaseOrderReferenceRequest {
  /**
   * 商品uuid
   * @example 00456789123
   */
  goods: string;

  /**
   * 商品单位
   * @example 箱
   */
  goodsMunit: string;

  /**
   * 含量
   * @example 1*12
   */
  goodsQpcStr: string;

  /**
   * 租户ID
   * @example TENANT1234
   */
  pid?: string;

  /**
   * 门店ID
   * @example 99998888
   */
  storeId?: string;

  /**
   * 用户ID
   * @example USER5678
   */
  wid?: string;

  /**
   * 仓库标识
   * @example 05774343854
   */
  wrh: string;
}

export default {
  /** 分页查询 */
  query: (params: { filter: IPurchaseOrderQueryFilter } & IPageParams) =>
    request.post<IPageData<IPurchaseOrder>>('/api/pcweb/purchase/order/query', params),
  /** 详情 - 商品信息 */
  getDetails: (uuid: string) => request.post<IPurchaseOrderDetail[]>('/api/pcweb/purchase/order/getDetails', { uuid }),

  /**获取所以明细-用于采购进货单 */
  getDetailsForIn: (params: { id?: string; uuid?: string }) =>
    request.post<IPurchaseOrderDetail[]>('/api/pcweb/purchase/order/getDetailsForIn', params),
  /** 详情 - 表头 */
  get: (params: { uuid?: string; id?: string }) =>
    request.post<IPurchaseOrder>('/api/pcweb/purchase/order/get', params),
  /** 分页查询商品 */
  queryDetails: (params: { bill: string; filter?: { keyword?: string } } & IPageParams) =>
    request.post<IPageData<IPurchaseOrderDetail>>('/api/pcweb/purchase/order/queryDetails', params),
  /** 保存 修改 */
  save: (params: any) => request.post('/api/pcweb/purchase/order/save', params),

  /** 批量删除 */
  removeBatch: (params: { uuids: string[] }) => request.post<any>('/api/pcweb/purchase/order/removeBatch', params),

  /** 批量提交订单 */
  submitBatch: (params: { uuids: string[]; status: PurchaseOrderStatusEnum }) =>
    request.post<any>('/api/pcweb/purchase/order/submitBatch', params),

  /** 提交采购订单 */
  submit: (params: { uuid: string; status: PurchaseOrderStatusEnum; version: number }) =>
    request.post<any>('/api/pcweb/purchase/order/submit', params),

  /** 删除采购单 */
  remove: (params: { uuid: string }) => request.post<any>('/api/pcweb/purchase/order/remove', params),
  /** 库存信息 */
  getInfoInv: (params: IPurchaseOrderReferenceRequest) =>
    request.post<IPurchaseOrderReferInv>('/api/pcweb/purchase/order/getInfoInv', params),
  getInfoSupplier: (params: IPurchaseOrderReferenceRequest) =>
    request.post<IPurchaseOrderReferSupplier[]>('/api/pcweb/purchase/order/getInfoSupplier', params),
  /** 根据导入任务获取商品 */
  getGoodsByIoTask: (params: { taskUuid: string }) =>
    request.post<ISupplierAgreementDetail[]>('/api/pcweb/purchase/order/getGoodsByIoTask', params),
  /** 能否越库/收货 */
  checkCrossAndReceive: (params: { uuid: string }) =>
    request.post<{ isCross: boolean; isReceive: boolean; receiveReason: string; crossReason: string }>(
      '/api/pcweb/purchase/order/checkCrossAndReceive',
      params
    )
};
