import { IFreightTemplate, IFreightTCustomerList } from '@/dataModel/oMall/freight/interface';
import request from '@/utils/request';

const freight = {
  /** 获取所有运费模版列表 */
  getAll: () => request.post<IFreightTemplate[]>('/api/pcweb/mdata/freight/template/getAll'),
  /** 根据模版获取引用该模版的所有客户 */
  getCustomerList: (params: { uuid: string }) =>
    request.post<IFreightTCustomerList[]>('/api/pcweb/mdata/freight/template/getCustomerList', params),
  /** 修改行号接口 */
  modifyLine: (params: {
    templateLineList: {
      uuid: string;
      line: number;
    }[];
  }) => request.post<any>('/api/pcweb/mdata/freight/template/modifyLine', params),
  /** 删除模版 */
  remove: (params: { uuid: string }) => request.post<any>('/api/pcweb/mdata/freight/template/remove', params),
  /** 保存运费模版 */
  save: (params: IFreightTemplate) => request.post<any>('/api/pcweb/mdata/freight/template/save', params)
};
export default freight;
