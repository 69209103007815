import request from '@/utils/request';
import {
  IAllPrePayCardList,
  IPrePayDetailBasicInfo,
  IPrePayManagement,
  IPrePayManagementDetailList
} from '@/dataModel/member/prePayManagement/interface';
import { UseCountTypeEnum } from '@/dataModel/dict/enum';
const { post } = request;

const prePayManagement = {
  /**
   * 分页查询礼品卡管理列表/未出售/使用中/已过期
   */
  query: (
    params: {
      filter: {
        batchNo?: string;
        beginId?: string;
        beginValidateDate?: string;
        cardTypeId?: string;
        endId?: string;
        endValidateDate?: string;
        id?: string;
        keyWord?: string;
        maximumBalance?: string;
        maximumFaceAmount?: string;
        maximumSaleAmount?: string;
        minimumBalance?: string;
        minimumFaceAmount?: string;
        minimumSaleAmount?: string;
        orderBys?: any;
        saleChannel?: string;
        salerId?: string;
        saleStoreId?: string;
        useCountType: UseCountTypeEnum;
      };
      status;
    } & IPageParams & {
        storeId?: string;
      }
  ) => post<IPageData<IPrePayManagement>>('/api/pcweb/member/prepay/queryPrePayCard', params, {}, 'card'),

  /**
   * 获取批次
   */
  getAllBatchNo: () => post<string[]>('/api/pcweb/member/card/entity/getAllBatchNo', {}),

  /**
   * 礼品卡管理详情
   */
  get: (params: { uuid }) =>
    post<IPrePayDetailBasicInfo>('/api/pcweb/member/prepay/getPrePayCardDetail', params, {}, 'card'),

  /**
   * 礼品卡管理详情列表
   */
  getDetailList: (
    params: {
      filter: {
        orderBys: any;
      };
      uuid;
    } & IPageParams
  ) => post<IPageData<IPrePayManagementDetailList>>('/api/pcweb/member/prepay/queryPrePayCardFlow', params, {}, 'card'),
  /**
   * 删除礼品卡
   */
  batchRemovePrePayCard: (params: { uuids: string[] }) =>
    post('/api/pcweb/member/prepay/batchRemovePrePayCard', params, {}, 'card'),
  /**
   * 作废礼品卡
   */
  abortedPrePayCard: (params: { cards: { uuid: string; version: string }[] }) =>
    post('/api/pcweb/member/prepay/batchInvalid', params, {}, 'card'),

  /**
   *  查询全量发售礼品卡时带出的卡列表
   */
  queryAllPrePayCardList: (params: {
    filter: {
      batchNo?: string;
      beginId?: string;
      beginValidateDate?: string;
      cardTypeId?: string;
      endId?: string;
      endValidateDate?: string;
      id?: string;
      keyWord?: string;
      maximumBalance?: string;
      maximumFaceAmount?: string;
      maximumSaleAmount?: string;
      minimumBalance?: string;
      minimumFaceAmount?: string;
      minimumSaleAmount?: string;
      orderBys?: any;
      saleChannel?: string;
      salerId?: string;
      saleStoreId?: string;
      useCountType: UseCountTypeEnum;
    };
  }) => post<IAllPrePayCardList>('/api/pcweb/member/prepay/selectPrePayCard', params, {}, 'card'),
  /**
   * 批量发售
   */
  salePrePayCardBatch: (params: {
    buyerMobile: string;
    buyerName: string;
    realSaleAmount: string;
    sales: {
      amount: number;
      faceAmount: number;
      ids?: string[];
      subTotal: number;
    };
    totalPrice: number;
    storeId?: string;
  }) => post('/api/pcweb/member/prepay/salePrePayCardBatch', params, {}, 'card'),
  /**
   * 批量制卡自动生成批次号
   */
  autoBatchNoGenerate: () =>
    post<{ batchNo: string; traceId: string }>('/api/pcweb/member/prepay/autoBatchNoGenerate', {}, {}, 'card'),

  createPrePayCardBatch: (params: {
    batchNo: string;
    cardType: string;
    cardTypeId: string;
    cardTypeName: string;
    createQty: number;
    faceAmount: number;
    saleAmount: number;
    startCardNo: number;
    validateDate: string;
    version: number;
  }) =>
    post<{ errorUrl: string | null; failedCount: number; successCount: number }>(
      '/api/pcweb/member/prepay/createPrePayCardBatch',
      params,
      {},
      'card'
    )
};

export default prePayManagement;
