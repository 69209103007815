import request from '@/utils/request';
import {
  IReportStoreDaily,
  IReportStoreDailyTotal,
  IReportStoreDailyGeneralSort,
  IReportStoreDailyMediumSort,
  IReportStoreDailySubSort,
  IReportStoreDailyGoods
} from '@/dataModel/finance/reportDailyStore/interface';
const { post } = request;

export default {
  /**查询进销存日报合计 原接口-废弃 */
  queryTotalByStore: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        goodsId?: string; //商品编码
        storeId?: string; //门店编码
        goodsBarcode?: string; //商品条码
        goodsName?: string; //商品名称
        sortId?: string; //商品分类
        searchKey?: string; //模糊查询
      };
    } & IPageParams
  ) => post<IReportStoreDailyTotal>('/api/pcweb/finance/report/daily/store/queryTotalByStore', params),
  /**按门店查询进销存日报 */
  queryByStore: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        storeId?: string; //门店Id
      };
    } & IPageParams
  ) => post<IPageData<IReportStoreDaily>>('/api/pcweb/finance/report/daily/store/queryByStore', params),
  /**按门店查询进销存日报合计 */
  queryByStoreTotal: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        storeId?: string; //门店Id
      };
    } & IPageParams
  ) => post<IReportStoreDailyTotal>('/api/pcweb/finance/report/daily/store/queryByStoreTotal', params),
  /**按门店查询进销存日报 */
  queryByStoreNew: (
    params: {
      filter?: {
        createEndTime: string; //结束时间
        createStartTime: string; //开始时间
        wrhIdList?: Array<[]>; //门店Id
        isStore?: number | boolean; //门店端默认1 总部端默认为0,
        isSummary?: boolean; // 是否统计
      };
    } & IPageParams
  ) => post<IPageData<IReportStoreDaily>>('/api/pcweb/finance/report/daily/store/queryByStoreNew', params),
  /**按门店查询进销存日报合计 */
  getTotalByStoreNew: (
    params: {
      filter?: {
        createEndTime: string; //结束时间
        createStartTime: string; //开始时间
        wrhIdList?: Array<[]>; //门店Id
        isStore?: number | boolean; //门店端默认1 总部端默认为0,
        isSummary?: boolean; // 是否统计
      };
    } & IPageParams
  ) => post<IReportStoreDailyTotal>('/api/pcweb/finance/report/daily/store/getTotalByStore', params),
  /** 按类查询 */
  queryBySort: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        sortIdList?: Array<[]>; //类别集合
        sortType?: string; //类别维度
        wrhType: number; //仓库类型
        isSummary?: boolean; //是否统计
      };
    } & IPageParams
  ) => post<IPageData<IReportStoreDailyGeneralSort>>('/api/pcweb/finance/report/daily/store/queryBySort', params),
  /** 按类查询合计 */
  getTotalBySort: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        sortId?: string; //商品分类
        storeId?: string; //门店编码
      };
    } & IPageParams
  ) => post<IReportStoreDailyTotal>('/api/pcweb/finance/report/daily/store/getTotalBySort', params),
  /** 按商品分类大类查询 */
  queryByGeneralSort: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        sortId?: string; //商品分类
        storeId?: string; //门店编码
      };
    } & IPageParams
  ) =>
    post<IPageData<IReportStoreDailyGeneralSort>>('/api/pcweb/finance/report/daily/store/queryByGeneralSort', params),
  /** 按商品分类大类查询合计 */
  queryByGeneralSortTotal: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        sortId?: string; //商品分类
        storeId?: string; //门店编码
      };
    } & IPageParams
  ) => post<IReportStoreDailyTotal>('/api/pcweb/finance/report/daily/store/queryByGeneralSortTotal', params),
  /**按商品分类中类查询 */
  queryByMediumSort: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        sortId?: string; //商品分类
        storeId?: string; //门店编码
      };
    } & IPageParams
  ) => post<IPageData<IReportStoreDailyMediumSort>>('/api/pcweb/finance/report/daily/store/queryByMediumSort', params),
  /**按商品分类中类查询合计 */
  queryByMediumSortTotal: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        sortId?: string; //商品分类
        storeId?: string; //门店编码
      };
    } & IPageParams
  ) => post<IReportStoreDailyTotal>('/api/pcweb/finance/report/daily/store/queryByMediumSortTotal', params),
  /**按商品分类小类查询 */
  queryBySubSort: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        sortId?: string; //商品分类
        storeId?: string; //门店编码
      };
    } & IPageParams
  ) => post<IPageData<IReportStoreDailySubSort>>('/api/pcweb/finance/report/daily/store/queryBySubSort', params),
  /**按商品分类小类查询合计 */
  queryBySubSortTotal: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        sortId?: string; //商品分类
        storeId?: string; //门店编码
      };
    } & IPageParams
  ) => post<IPageData<IReportStoreDailyTotal>>('/api/pcweb/finance/report/daily/store/queryBySubSortTotal', params),
  /**按商品查询 */
  queryByGoodsId: (
    params: {
      createEndTime?: string; //结束时间
      createStartTime?: string; //开始时间
      sortId?: string; //商品分类
      goodsId?: string; //商品编码
      storeId?: string; //门店编码
      goodsBarcode?: string; //商品条码
      goodsName?: string; //商品名称
      searchKey?: string; //模糊查询
    } & IPageParams
  ) => post<IPageData<IReportStoreDaily>>('/api/pcweb/finance/report/daily/store/queryByGoodsId', params),
  /**按商品查询合计 */
  queryByGoodsIdTotal: (
    params: {
      createEndTime?: string; //结束时间
      createStartTime?: string; //开始时间
      sortId?: string; //商品分类
      goodsId?: string; //商品编码
      storeId?: string; //门店编码
      goodsBarcode?: string; //商品条码
      goodsName?: string; //商品名称
      searchKey?: string; //模糊查询
    } & IPageParams
  ) => post<IReportStoreDaily>('/api/pcweb/finance/report/daily/store/queryByGoodsIdTotal', params),
  /* 按单品查询列表 */
  queryByGoods: (
    params: {
      filter?: {
        createEndTime: string; // 结束时间
        createStartTime: string; // 开始时间
        sortIdList?: Array<[]>; // 门店列表
        wrhIdList?: Array<[]>; // 分类列表
        isStore?: boolean | number; // 是总部端还是门店
        searchKey?: string; // 商品编码/商品名称/商品条码
        isSummary?: boolean; // 是否统计 门店端默认1 总部端默认为0,
      };
    } & IPageParams
  ) => post<IPageData<IReportStoreDaily>>('/api/pcweb/finance/report/daily/store/queryByGoods', params),
  /* 按单品 获得所有的值 */
  getTotalByGoods: (
    params: {
      filter?: {
        createEndTime: string; // 结束时间
        createStartTime: string; // 开始时间
        sortIdList?: Array<[]>; // 门店列表
        isStore?: boolean | number; // 是总部端还是门店
        searchKey?: string; // 商品编码/商品名称/商品条码
        isSummary?: boolean; // 是否统计
      };
    } & IPageParams
  ) => post<IReportStoreDaily>('/api/pcweb/finance/report/daily/store/getTotalByGoods', params)
};
