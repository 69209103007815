import request from '@/utils/request';
import { IHolderActivity } from '@/dataModel/equipment/holder/interface';
import { HolderActivityStateEnum } from '@/dataModel/equipment/holder/enum';

export default {
  /**
   * 分页查询手持设备
   */
  query: (
    params?: {
      filter?: {
        deviceSn?: string; // 设备 SN 号
        status?: HolderActivityStateEnum; // 激活状态
        storeId?: string; // 门店 Id
      };
    } & IPageParams
  ) => request.post<IPageData<IHolderActivity>>('/api/pcweb/equipment/holder/query', params),

  /**
   * 查询详情
   */
  get: (params: { uuid: string }) => request.post<IHolderActivity>('/api/pcweb/equipment/holder/get', params),

  /**
   * 删除手持设备授权码
   * @param params
   * @returns
   */
  delete: (params: { uuid: string }) => request.post('/api/pcweb/equipment/holder/delete', params),

  /**
   * 更新备注
   */
  updateRemark: (params: { uuid: string; remark: string }) =>
    request.post('/api/pcweb/equipment/holder/updateRemark', params),

  /**
   * 移除激活
   */
  removeActive: (params: { uuid: string }) => request.post('/api/pcweb/equipment/holder/removeActive', params)
};
