import {
  IAdjustPrice,
  ICheckRecordDetail,
  IDetailCount,
  IPriceCalcPlanGoods,
  IPriceScheme,
  IQueryFormPlan,
  IRecordQueryData,
  IResultCount,
  ITrialGoods,
  ITrialRecord,
  ITrialResult
} from '@/dataModel/retail/priceCalcPlan/interface';
import request from '@/utils/request';
const { post } = request;

export default {
  /**查询方案 */
  query: (params?: {
    filter?: {
      /**商品名称、编码、识别码模糊查询 */
      searchKey?: string;
      /**分类编码 */
      sortIdEq?: string;
    };
  }) => post<IPageData<IPriceScheme>>('/api/pcweb/retail/priceCalc/plan/query', params),
  /**新增方案 */
  createScheme: (params?: {
    /**分类 */
    includedSortList: string[];
    /**方案名称 */
    name: string;
    /**开启状态 */
    opened: boolean;
    /**生效价格 */
    priceType: string;
  }) => post('/api/pcweb/retail/priceCalc/plan/create', params),
  /**修改价格方案 */
  modify: (params: { includedSortList: string[]; name: string; opened: boolean; priceType: string }) =>
    post('/api/pcweb/retail/priceCalc/plan/modify', params),
  /**删除价格方案 */
  remove: (params: { uuid: string }) => post('/api/pcweb/retail/priceCalc/plan/remove', params),
  /**查询价格方案详情 */
  get: (uuid: string) => post<IPriceScheme>('/api/pcweb/retail/priceCalc/plan/get', { uuid }),
  /**查询商品详情 */
  getDetail: (
    params: {
      filter: {
        goodsPositioningEq?: string;
        priceCalcPlanUuidEq: string;
        searchKey?: string;
        sortIdEq?: string;
        munitUuidIn?: string[];
      };
    } & IPageParams
  ) => post<IPageData<IPriceCalcPlanGoods>>('/api/pcweb/retail/priceCalc/plan/getDetail', params),
  /**批量设置商品方案配置 */
  modifyDetail: (params: {
    details: {
      cardinalPriceTypeForAdd: string;
      goodsPositioning: string;
      munitUuid: string;
      price: number;
      priceAdd: number;
      priceAddMode: string;
      ratio: number;
    }[];
    uuid: string; //方案uuid
  }) => post<any>('/api/pcweb/retail/priceCalc/plan/modifyDetail', params),
  /**修改价格方案配置 */
  modifySettings: (params: {
    settingVo: {
      defaultCardinalPriceTypeForAdd: string; //默认加价基数
      defaultGoodsPositioning: string; //默认商品定位
      defaultPriceAddMode: string; //默认加价方式
      embellish: boolean; //是否美化
      marketResearchPriceValidDay: number; //市调价格有效期
      price: number; //固定价
      priceAdd: number; //加固定价格
      ratio: number; //加固定比例
      /**试算价格提醒规则 */
      trialCalcWarnRule: {
        /** 超过市调价格的*/
        outMarketResearchPriceRange: {
          higher: number;
          lower: number;
        };
        /**超过昨日价格的 */
        outYesterdayPriceRange: {
          higher: number;
          lower: number;
        };
      };
    };
    uuid;
  }) => post<any>('/api/pcweb/retail/priceCalc/plan/modifySettings', params),
  /** 分页查询市调记录 */
  recordQuery: (
    params: {
      filter?: {
        /** 天数筛选 '3' | '7' | '15' | '30' */
        day: '3' | '7' | '15' | '30';
        /** 商品分类ID */
        sortId: string;
      };
    } & IPageParams
  ) => post<IPageData<IRecordQueryData>>('/api/pcweb/retail/market/record/query', params),
  /**获取商品定位数量 */
  getDetailCount: (uuid: string) => post<IDetailCount>('/api/pcweb/retail/priceCalc/plan/getDetailCount', { uuid }),
  /**开始试算 */
  trialCreate: (params: { adjustType: string; allStore: boolean; plan: string; storeIdList?: string[] }) =>
    post<any>('/api/pcweb/retail/priceCalc/trial/create', params),
  /**获取价格试算记录详情 */
  getRecord: (uuid: string) => post<ITrialRecord>('/api/pcweb/retail/priceCalc/trial/getRecord', { uuid }),
  /**中断试算 */
  interrupt: (uuid: string) => post<any>('/api/pcweb/retail/priceCalc/trial/interrupt', { uuid }),
  /**分页查询价格试算记录 */
  queryRecord: (
    params: {
      filter?: {
        storeIdIn: string[]; //门店
        trialBeginTimeStart: string; //开始时间
        trialEndTimeEnd: string; //结束时间
      };
    } & IPageParams
  ) => post<IPageData<ITrialRecord>>('/api/pcweb/retail/priceCalc/trial/queryRecord', params),
  /**分页查询价格试算商品明细 */
  queryRecordDetail: (
    params: {
      filter?: {
        adjusted: boolean; //是否调价
        goodsPositioningEq: string; //商品定位
        searchKey: string; //商品名称、编码、识别码模糊查询
        sortIdEq: string; //分类编码等于
        storeIdEq: string; //门店编码等于
        trailEq: string; //试算记录uuid
      };
    } & IPageParams
  ) => post<IPageData<ITrialGoods>>('/api/pcweb/retail/priceCalc/trial/queryRecordDetail', params),
  /**获取价格试算结果详情 */
  getResult: (uuid: string) => post<ITrialResult>('/api/pcweb/retail/priceCalc/trial/getResult', { uuid }),
  /**分页查询价格试算结果商品列表 */
  queryResultDetail: (
    params: {
      filter?: {
        embellish?: boolean; //是否启用美化
        goodsPositioningEq?: string; //商品定位
        onlyWarn?: boolean; //是否异常价格
        searchKey?: string; //商品名称、编码、识别码模糊查询
        sortIdEq?: string; //分类编码等于
        storeIdEq?: string; //门店编码等于
        trailEq: string; //试算记录uuid
        adjusted?: boolean; //是否调价
      };
    } & IPageParams
  ) => post<IPageData<ITrialGoods>>('/api/pcweb/retail/priceCalc/trial/queryResultDetail', params),
  modifyResult: (params: {
    modifyList: {
      calcPrice: number;
      detailUuid: string;
      embellish: boolean;
    }[];
    trail: string;
  }) => post<any>('/api/pcweb/retail/priceCalc/trial/modifyResult', params),
  /**查询试算状态 */
  getState: (uuid: string) => post<any>('/api/pcweb/retail/priceCalc/trial/getState', { uuid }),
  /**总部-生成调价单 */
  createAdjustPrice: (params: { detailList?: string[]; isAll: boolean; trial: string; embellish: boolean }) =>
    post<any>('/api/pcweb/retail/priceCalc/trial/create/adjustPrice', params),
  /**查询调价单生成状态 */
  getAdjustPriceTask: (uuid: string) => post<any>('/api/pcweb/retail/priceCalc/trial/getAdjustPriceTask', { uuid }),
  /**总部-分页查询调价单 */
  queryAdjustPrice: (
    params: {
      filter?: {
        storeId: string;
      };
      trial: string;
    } & IPageParams
  ) => post<IPageData<IAdjustPrice>>('/api/pcweb/retail/priceCalc/trial/queryAdjustPrice', params),
  /**分页查询策略分析报表 */
  queryFormsPlan: (
    params: {
      filter?: {
        endDate?: string;
        startDate?: string;
        goodsPositioningList?: string[];
        searchKey?: string;
        sortIdList?: string[];
        storeIdList?: string[];
      };
      priceCalcPlanUuid: string;
    } & IPageParams
  ) => post<IPageData<IQueryFormPlan>>('/api/pcweb/retail/priceCalc/plan/forms/query', params),
  /**查询策略分析报表统计 */
  queryFormsPlanCount: (params: {
    filter?: {
      endDate?: string;
      startDate?: string;
      goodsPositioningList?: string[];
      searchKey?: string;
      sortIdList?: string[];
      storeIdList?: string[];
    };
    priceCalcPlanUuid: string;
  }) => post<any>('/api/pcweb/retail/priceCalc/plan/forms/queryCount', params),
  /** 获取试算结果商品数量*/
  getResultCount: (uuid: string) => post<IResultCount>('/api/pcweb/retail/priceCalc/trial/getResultCount', { uuid }),
  /**根据试算标识获取进行中的调价单任务 */
  getDoingAdjustPriceTask: (trail: string) =>
    post<any>('/api/pcweb/retail/priceCalc/trial/getDoingAdjustPriceTask', { trail }),

  /**生成门店改价单 */
  createAdjustPriceStore: (params: { detailList?: string[]; isAll: boolean; trial: string; embellish: boolean }) =>
    post<any>('/api/pcweb/business/price/calcTrial/create/adjustPrice', params),
  /** 查询门店端改价单列表*/
  queryAdjustPriceStore: (
    params: {
      trial: string;
    } & IPageParams
  ) => post<IPageData<IAdjustPrice>>('/api/pcweb/business/price/calcTrial/queryAdjustPrice', params),
  /**总部-校验试算记录商品是否存在于调价单中 */
  checkRecordDetail: (params: { detailList?: string[]; isAll: boolean; trial: string }) =>
    post<ICheckRecordDetail>('/api/pcweb/retail/priceCalc/trial/checkRecordDetail', params),
  /**门店-校验试算记录商品是否存在于调价单中 */
  checkRecordDetailStore: (params: { detailList?: string[]; isAll: boolean; trial: string }) =>
    post<ICheckRecordDetail>('/api/pcweb/business/price/calcTrial/checkRecordDetail', params)
};
