import request from '@/utils/request';
import { IEquipmentPos, IGetCount, IQueryMonitor, IGet } from '@/dataModel/equipment/cashRegister/interface';

const { post } = request;
export default {
  /**
   * 删除收银机
   * @param params
   * @returns
   */
  delete: (params: { uuid: string }) => post('/api/pcweb/equipment/pos/delete', params, {}, 'pos'),
  /**
   * 查询收银机详情
   * @param params
   * @returns
   */
  get: (params: { id: string; uuid: string }) => post<IGet>('/api/pcweb/equipment/pos/get', params, {}, 'pos'),
  /**
   * 查询收银机统计信息
   * @returns
   */
  getCount: () => post<IGetCount>('/api/pcweb/equipment/pos/getCount', {}, {}, 'pos'),
  /**
   * 分页查询收银机
   * @param params
   * @returns
   */
  query: (
    params: {
      filter: {
        deviceSn?: string;
        deviceType?: string;
        id?: string;
        onlineStatus?: string;
        status?: string;
        storeId?: string;
        store?: string;
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => post<IPageData<IEquipmentPos>>('/api/pcweb/equipment/pos/query', params, {}, 'pos'),
  /**
   * 移除激活
   * @param params
   * @returns
   */
  removeActive: (params: { uuid: string }) => post('/api/pcweb/equipment/pos/removeActive', params, {}, 'pos'),
  /**
   * 修改备注
   * @param params
   * @returns
   */
  updateRemark: (params: { uuid: string; remark: string }) =>
    post('/api/pcweb/equipment/pos/updateRemark', params, {}, 'pos'),
  /**
   * 查询在用版本列表-下拉框用
   */
  getVersionList: () => post<string[]>('/api/pcweb/equipment/pos/getVersionList', {}, {}, 'pos'),
  /**
   * 查询收银机监控信息
   */
  queryMonitor: (params: {
    filter?: {
      /**
       * 收银机软件版本号
       */
      appVersion?: string;
      /**
       * 收银机编号
       */
      id?: string;
      /**
       * 监控状态
       */
      monitorStatus?: string;
      /**
       * 所属门店id
       */
      storeId?: string;
    };
  }) => post<IQueryMonitor[]>('/api/pcweb/equipment/pos/queryMonitor', params, {}, 'pos'),
  queryByStoreUuid: (
    params: {
      filter?: {
        deviceSn?: string;
        deviceType?: string;
        id?: string;
        onlineStatus?: string;
        status?: string;
        storeId?: string;
      };
    } & IPageParams
  ) => post<any>('/api/pcweb/equipment/pos/queryByStoreUuid', params, {}, 'pos')
};
