import {
  IQueryProductParametersItems,
  IQueryReplenishSchemaItems,
  IQuerySortRulesData,
  ISortRules
} from '@/dataModel/business/replenish/interface';
import { ReplenishSchemaGoodsStatusEnum, ReplenishSchemaStatusEnum } from '@/dataModel/dict/enum';
import request from '@/utils/request';
const { post } = request;

export default {
  /** 批量设置商品参数 */
  batchSetUpGoods: (params: {
    goodsIds: string[];
    info: {
      faceAccount?: number;
      highestStock?: number;
      lowestStock?: number;
      orderCycle?: number;
      safeStock?: number;
    };
    replenish: string;
  }) => post<{ failedCount: string; successCount: number }>('/api/pcweb/business/replenish/batchSetUpGoods', params),

  /** 批量设置分类规则 */
  batchSetUpSortRules: (params: { formulaType: string; replenish: string; ruleType: string; sortIds: string[] }) =>
    post<{ failedCount: string; successCount: number }>('/api/pcweb/business/replenish/batchSetUpSortRules', params),

  /** 批量启用/停用商品参数 */
  batchStartOrStopGoods: (params: { goodsIds: string[]; replenish: string; status: string }) =>
    post<{ failedCount: string; successCount: number }>('/api/pcweb/business/replenish/batchStartOrStopGoods', params),

  /** 批量启用/停用分类规则 */
  batchStartOrStopSortRules: (params: { replenish: string; sortIds: string[]; status: ReplenishSchemaStatusEnum }) =>
    post<{ failedCount: string; successCount: number }>(
      '/api/pcweb/business/replenish/batchStartOrStopSortRules',
      params
    ),

  /** 删除门店补货方案 */
  deleteReplenishSchema: (params: { uuid: string }) =>
    post('/api/pcweb/business/replenish/deleteReplenishSchema', params),

  /** 分页查询门店补货商品参数 */
  queryGoods: (
    params: {
      filter: {
        goodsEffective?: string;
        goodsSearchKey: string;
        status: ReplenishSchemaGoodsStatusEnum;
        replenish?: string;
        sortIds?: string[];
      };
      sortIds: string[];
      uuid: string;
    } & IPageParams
  ) => post<IPageData<IQueryProductParametersItems>>('/api/pcweb/business/replenish/queryGoods', params),

  /** 分页查询门店补货方案 */
  queryReplenishSchema: (
    params: {
      filter: {
        storeSearchKey: string;
      };
    } & IPageParams
  ) => post<IPageData<IQueryReplenishSchemaItems>>('/api/pcweb/business/replenish/queryReplenishSchema', params),

  /** 获得补货方案详情 */
  getReplenishSchema: (params: { uuid: string }) =>
    post<IQueryReplenishSchemaItems>('/api/pcweb/business/replenish/getReplenishSchema', params),

  /** 查询分类规则列表 */
  querySortRules: (params: { uuid: string }) =>
    post<IQuerySortRulesData>('/api/pcweb/business/replenish/querySortRules', params),

  /** 复制门店补货方案 */
  repeatReplenishSchema: (params: { uuid: string; sid: string }) =>
    post<{ traceId: string; uuid: string; version: number }>(
      '/api/pcweb/business/replenish/repeatReplenishSchema',
      params
    ),

  /** 保存门店补货方案 */
  saveReplenishSchema: (params: { sid: string }) =>
    post<{ traceId: string; uuid: string; version: number }>(
      '/api/pcweb/business/replenish/saveReplenishSchema',
      params
    ),

  /** 启用/停用门店补货方案 */
  startOrStopReplenishSchema: (params: { uuid: string; status: string }) =>
    post<{ traceId: string; version: number }>('/api/pcweb/business/replenish/startOrStopReplenishSchema', params),

  /** 查询分类集合 */
  getSorts: (params: { filter: { rule: boolean }; replenish: string }) =>
    post<ISortRules[]>('/api/pcweb/business/replenish/getSorts', params)
};
