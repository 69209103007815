import {
  IBatchDataModify,
  IBatchRoleModify,
  IMoveOrg,
  ISettingUser,
  ISettingUserDetail
} from '@/dataModel/system/settingUser';
import request from '@/utils/request';

const { post } = request;

const settingUser = {
  /**
   * 获取员工资料
   */
  getUser: (params: { pid?: string; uuid: string; wid?: string }) =>
    post<ISettingUserDetail>('/api/pcweb/system/setting/user/getUser', params),

  /**
   * 获取当前员工资料
   */
  queryCurUser: (params: { pid?: string; storeId?: string; wid?: string; wmId?: string }, opt?: { escapeCheck?: boolean }) =>
    post<ISettingUser>('/api/pcweb/system/setting/user/getUserInfo', params, opt),
  /**
   * 查询员工列表
   */
  query: (
    params: {
      filter?: {
        dataPermissionUuid?: string;
        organizationUuid?: string;
        rolePermissionUuid?: string;
        userInfo?: string;
      };
    } & IPageParams
  ) => post<IPageData<ISettingUser>>('/api/pcweb/system/setting/user/query', params),
  /**
   * 删除
   * @param params
   * @returns
   */
  remove: (params: { uuid: string; id: string }) => post<any>('/api/pcweb/system/setting/user/remove', params),

  /**
   * 创建
   */
  create: (params: {
    id: string;
    isAdmin: number;
    mobile: number;
    name: string;
    organizationId: string;
    organizationUuid: string;
    dataUuids: string[];
    roleUuids: string[];
    remark?: string;
    loginHqOrganization?: boolean; //是否登录总部组织
    loginStoreOrganization?: boolean; //是否登录门店组织
    stores?: string[]; //门店
  }) => post<{ traceId: string; uuid: string }>('/api/pcweb/system/setting/user/create', params),

  /** 获取可登录总部的用户 */
  getHq: () => post<ISettingUser[]>('/api/pcweb/system/setting/user/getHq'),
  getDataPackageLegal:() => post<any>('/api/pcweb/home/newDataPackageLegal'),
  /**
   * 修改
   */
  modify: (params: {
    id: string;
    uuid: string;
    isAdmin: number;
    mobile: number;
    name: string;
    organizationId: string;
    organizationUuid: string;
    dataUuids: string[];
    roleUuids: string[];
    remark?: string;
    version: number;
  }) => post<{ traceId: string }>('/api/pcweb/system/setting/user/modify', params),
  /**
   * 批量设置角色权限
   */
  batchUserRoleModify: (params: IBatchRoleModify) =>
    post<{ traceId: string }>('/api/pcweb/system/setting/user/batchUserRoleModify', params),
  /**
   * 批量设置数据权限
   */
  batchUserDataModify: (params: IBatchDataModify) =>
    post<{ traceId: string }>('/api/pcweb/system/setting/user/batchUserDataModify', params),
  /**
   * 员工移动到组织
   */
  userMoveOrg: (params: IMoveOrg) => post<{ traceId: string }>('/api/pcweb/system/setting/user/userMoveOrg', params),
  /**
   * 转移默认采购员
   */
  modifyPurchaser: (params: { uuid: string }) =>
    post<{
      traceId: string;
      uuid: string;
    }>('/api/pcweb/system/setting/user/modifyPurchaser', params),
  /**
   * 员工绑定分类
   */
  purchaserBindSort: (params: { sortIds: string[]; uuid: string }) =>
    post<{ traceId: string; uuid: string }>('/api/pcweb/system/setting/user/purchaserBindSort', params),
  /**
   * 获取角色为采购员的员工
   */
  getPurchaseUser: () => post<ISettingUser[]>('/api/pcweb/system/setting/user/getPurchaseUser', null),
  /**
   * 查询员工列表
   */
  getAll: (params: any) => post<ISettingUser[]>('/api/pcweb/system/setting/user/getAll', params)
};

export default settingUser;
