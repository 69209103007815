import { IGuiderReport, IGuiderReportDetail } from '@/dataModel/finance/guiderReport/interface';
import request from '@/utils/request';
const { post } = request;

export default {
  /**导购员业绩汇总 */
  queryGuiderReport: (
    params: {
      filter?: {
        endTime?: string; //结束时间
        guider?: string; //导购员uuid
        startTime?: string; //开始日期
        store?: string; //门店uuid
      };
    } & IPageParams
  ) => post<IPageData<IGuiderReport>>('/api/pcweb/finance/guider/report/queryGuiderReport', params),
  /**导购员业绩汇总统计 */
  queryGuiderReportCount: (params: {
    filter?: {
      endTime?: string; //结束时间
      guider?: string; //导购员uuid
      startTime?: string; //开始日期
      store?: string; //门店uuid
    };
  }) => post<any>('/api/pcweb/finance/guider/report/queryGuiderReportCount', params),
  /**导购员销售明细 */
  queryGuiderReportDetail: (
    params: {
      filter?: {
        endTime?: string; //结束时间
        guider?: string; //导购员uuid
        startTime?: string; //开始日期
        store?: string; //门店uuid
      };
    } & IPageParams
  ) => post<IPageData<IGuiderReportDetail>>('/api/pcweb/finance/guider/report/queryGuiderReportDetail', params),
  /**导购员销售明细统计 */
  queryGuiderReportDetailCount: (params: {
    filter?: {
      endTime?: string; //结束时间
      guider?: string; //导购员uuid
      startTime?: string; //开始日期
      store?: string; //门店uuid
    };
  }) => post<any>('/api/pcweb/finance/guider/report/queryGuiderReportDetailCount', params)
};
