import {
  IGoodsAccountList,
  ISaleAccountList,
  IBusinessFundsByCashier,
  IFlowAccountList,
  IBusinessFundsByFlowFilter,
  IBusinessFundsByFlow,
  IRechargeList,
  ISellCardList,
  IMemberList,
  ISaleTotalByDay,
  INormalPayTypes,
  IAllPaysOfTenant,
  ICollectionFlowFilter,
  ICollectionFlowTotal,
  ICollectionFlow
} from '@/dataModel/business/turnoverManageAccountQuery/interface';
import { IPaymentMethodGetAllPays } from '@/dataModel/retail/paymentMethod/interface';
import request from '@/utils/request';
const { post } = request;

const TurnoverManageAccountQuery = {
  /**
   *
   * @param params 分页查询销售对账
   * @returns
   */
  querySaleAccount: (
    params: {
      filter: {
        cashierName: string;
        currencyId: string;
        isDiff: string;
        occurDayBegin: string;
        occurDayEnd: string;
        posId: string;
      };
    } & IPageParams
  ) => post<IPageData<ISaleAccountList>>('/api/pcweb/business/turnovermanage/accountquery/querySaleAccount', params),

  /**
   *
   * @param params 分页查询商品对账
   * @returns
   */
  queryGoodsAccount: (
    params: {
      filter: {
        cashierName: string;
        goodsKeyWord: string;
        occurDayBegin: string;
        occurDayEnd: string;
      };
    } & IPageParams
  ) => post<IPageData<IGoodsAccountList>>('/api/pcweb/business/turnovermanage/accountquery/queryGoodsAccount', params),
  /**商品对账统计 */
  getGoodsAccount: (params: {
    filter: {
      cashierName: string;
      goodsKeyWord: string;
      occurDayBegin: string;
      occurDayEnd: string;
    };
  }) => post<any>('/api/pcweb/business/turnovermanage/accountquery/getGoodsAccount', params),
  /**
   *按收银员查询
   */
  queryBusinessFundsByCashier: (
    params: {
      filter?: {
        /**
         * 收银员编码
         */
        cashierId: string;
        /**
         * 付款方式编码
         */
        currencyIds: string[];
        /**
         * 是否差异
         */
        isDiff: boolean;
        /**
         * 营业日期开始
         */
        startDate: string;
        /**
         * 营业日期结束
         */
        endDate: string;
        /**
         * 门店id
         */
        storeId: string;
      };
    } & IPageParams
  ) => post<IPageData<IBusinessFundsByCashier>>('/api/pcweb/finance/revenue/cashier', params),

  /**
   * 按收银员查询合计
   */

  queryBusinessFundsTotalByCashier: (
    params: {
      filter?: {
        /**
         * 收银员编码
         */
        cashierId: string;
        /**
         * 付款方式编码
         */
        currencyIds: string[];
        /**
         * 是否差异
         */
        isDiff: boolean;
        /**
         * 营业日期开始
         */
        startDate: string;
        /**
         * 营业日期结束
         */
        endDate: string;
        /**
         * 门店id
         */
        storeId: string;
      };
    } & IPageParams
  ) => post<IPageData<IBusinessFundsByCashier>>('/api/pcweb/finance/revenue/cashierTotal', params),

  /**
   *
   * @param params 分页查询pos机对账
   * @returns
   */
  queryPosAccount: (
    params: {
      filter: {
        cashierName: string; //收银员名称
        currencyId: string; //付款方式编码
        isDiff: string; //是否差异
        occurDayBegin: string; //营业日期开始
        occurDayEnd: string; //营业日期结束
        posId: string; //pos机编号
      };
    } & IPageParams
  ) => post<IPageData<ISaleAccountList>>('/api/pcweb/business/turnovermanage/accountquery/queryPosAccount', params),
  /**按流水查询 */
  queryBusinessFundsByFlow: (
    params: {
      filter?: {
        billClass?: string; //交易类型
        cashierId?: string; //收银员Id
        endTime?: string; //结束时间
        flowId?: string; //流水号
        posId?: string; //pos机号
        startTime?: string; //开始时间
      };
    } & IPageParams
  ) =>
    post<IPageData<IFlowAccountList>>(
      '/api/pcweb/business/turnovermanage/accountquery/queryBusinessFundsByFlow',
      params
    ),
  /**按流水查询统计 */
  queryBusinessFundsByFlowStat: (params: {
    filter?: {
      billClass?: string; //交易类型
      cashierId?: string; //收银员Id
      endTime?: string; //结束时间
      flowId?: string; //流水号
      posId?: string; //pos机号
      startTime?: string; //开始时间
    };
  }) => post<any>('/api/pcweb/business/turnovermanage/accountquery/queryBusinessFundsByFlowStat', params),

  /** 获取门店端支付方式 */
  getStorePaymentsList: () =>
    post<IPaymentMethodGetAllPays[]>('/api/pcweb/retail/currency/schema/getAllPaysOfStore', {}, {}, 'pos'),

  /** 销售流水记录查询 */
  getSell: (params: { filter?: IBusinessFundsByFlowFilter } & IPageParams) =>
    post<IPageData<IBusinessFundsByFlow>>(
      '/api/pcweb/business/turnovermanage/accountquery/queryBusinessFundsByFlow',
      params
    ),
  /** 销售流水记录统计查询 */
  getSellCount: (params: { filter?: IBusinessFundsByFlowFilter } & IPageParams) =>
    post<IPageData<{ total: number }>>(
      '/api/pcweb/business/turnovermanage/accountquery/queryBusinessFundsByFlowStat',
      params
    ),

  /** 按充值流水查询 */
  getRechargeList: (params: { filter?: IBusinessFundsByFlowFilter } & IPageParams) =>
    post<IPageData<IRechargeList>>('/api/pcweb/business/turnovermanage/accountquery/queryRechargeFlow', params),

  /** 按充值流水统计查询 */
  getRechargeCount: (params: { filter?: IBusinessFundsByFlowFilter } & IPageParams) =>
    post<IPageData<{ total: number }>>(
      '/api/pcweb/business/turnovermanage/accountquery/queryRechargeFlowCount',
      params
    ),

  /** 按充值流水查询 */
  getSellCardList: (params: { filter?: IBusinessFundsByFlowFilter } & IPageParams) =>
    post<IPageData<ISellCardList>>('/api/pcweb/business/turnovermanage/accountquery/querySaleCardFlow', params),

  /** 按充值流水统计查询 */
  getSellCardCount: (params: { filter?: IBusinessFundsByFlowFilter } & IPageParams) =>
    post<IPageData<{ total: number }>>(
      '/api/pcweb/business/turnovermanage/accountquery/querySaleCardFlowCount',
      params
    ),

  /** 会员付费开卡查询 */
  getMemberList: (
    params: {
      filter: {
        beginDate?: string;
        endDate?: string;
        memberInfo?: string;
        orderNo?: string;
        paymentMethodIds?: string[];
        storeId?: string;
      };
    } & IPageParams
  ) => post<IPageData<IMemberList>>('/api/pcweb/member/info/queryMemberCardFlow', params),

  /** 会员付费开卡查询 */
  getMemberCount: (
    params: {
      filter: {
        beginDate?: string;
        endDate?: string;
        memberInfo?: string;
        orderNo?: string;
        paymentMethodId?: string;
        storeId?: string;
      };
    } & IPageParams
  ) => post<IPageData<{ paymentTotal: number }>>('/api/pcweb/member/info/queryMemberCardFlowTotal', params),

  /**营业收入 */
  queryNormalSaleTotalByDay: (
    params: {
      filter?: {
        currencyId?: string;
        endDate?: string;
        startDate?: string;
      };
    } & IPageParams
  ) => post<IPageData<ISaleTotalByDay>>('/api/pcweb/finance/revenue/bill', params),
  /**营业收入统计 */
  querySaleTotalByDaySum: (params: {
    filter?: {
      currencyId?: string;
      endDate?: string;
      startDate?: string;
    } & IPageParams;
  }) => post('/api/pcweb/finance/revenue/billTotal', params),

  /** 收款流水查询 */
  getCollection: (params: { filter?: ICollectionFlowFilter } & IPageParams) =>
    post<IPageData<ICollectionFlow>>('/api/pcweb/finance/revenue/flow', params),
  /** 收款流水统计查询 */
  getCollectionCount: (params: { filter?: ICollectionFlowFilter } & IPageParams) =>
    post<ICollectionFlowTotal>('/api/pcweb/finance/revenue/flowTotal', params),

  /** 获得门店/总部--Tab(营业款(按门店)、营业收入，收款流水) 支付方式 */
  getNormalSalePayTypes: () =>
    post<INormalPayTypes[]>('/api/pcweb/business/turnovermanage/accountquery/getNormalSalePayTypes'),

  /** 获得门店/总部--Tab(销售) 支付方式 */
  getSalePayTypes: () => post<INormalPayTypes[]>('/api/pcweb/business/turnovermanage/accountquery/getSalePayTypes'),

  /** 获取门店/总部--Tab(礼品卡发售) 支付方式 */
  getAllPaysOfCard: () => post<IAllPaysOfTenant[]>('/api/pcweb/retail/currency/schema/getAllPaysOfCard', {}, {}, 'pos'),

  /** 获取门店/总部--Tab(收银) 支付方式 */
  getAllPaysOfTenant: () =>
    post<IAllPaysOfTenant[]>('/api/pcweb/retail/currency/schema/getAllPaysOfTenant', {}, {}, 'pos'),

  /** 获取门店/总部--Tab(充值) 支付方式 */
  getRechargePayTypes: () =>
    post<INormalPayTypes[]>('/api/pcweb/business/turnovermanage/accountquery/getRechargePayTypes'),

  /** 获取门店/总部--Tab(会员付费开卡) 支付方式 */
  getCardPayTypes: () => post<INormalPayTypes[]>('/api/pcweb/business/turnovermanage/accountquery/getCardPayTypes')
};

export default TurnoverManageAccountQuery;
