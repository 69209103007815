import { InvPriceAdjustStatusEnum } from '@/dataModel/dict/enum';
import {
  IFinanceStoreAdjustPrice,
  IFinanceStoreAdjustPriceLog,
  IFinanceWrhAdjustPrice,
  IFinanceAdjustPriceDetail,
  IFinanceAdjustPriceGoods,
  IFinanceWrhAdjustPriceLog,
  WebBaseResponseBaseSaveResult
} from '@/dataModel/finance/adjustPrice/interface';
import request from '@/utils/request';

const { post } = request;

const adjustPrice = {
  /** 获取门店库存成本单头 */
  getStore: (params: { id: string; uuid: string }) =>
    post<IFinanceStoreAdjustPrice>('/api/pcweb/finance/adjustprice/getStore', params),
  /** 门店库存成本单据日志 */
  getStoreLog: (params: { bill: string }) =>
    post<IFinanceStoreAdjustPriceLog[]>('/api/pcweb/finance/adjustprice/getStoreLog', params),
  /** 获取仓库库存成本单头 */
  getWrh: (params: { id: string; uuid: string }) =>
    post<IFinanceWrhAdjustPrice>('/api/pcweb/finance/adjustprice/getWrh', params),
  /** 仓库库存成本单据日志 */
  getWrhLog: (params: { bill: string }) =>
    post<IFinanceWrhAdjustPriceLog[]>('/api/pcweb/finance/adjustprice/getWrhLog', params),
  /** 分页查询门店库存成本 */
  queryStore: (
    params: {
      filter: {
        createEndTime?: string;
        createStartTime?: string;
        id?: string;
        reason?: string;
        status?: InvPriceAdjustStatusEnum;
        stores: string[];
      };
    } & IPageParams
  ) => post<IPageData<IFinanceStoreAdjustPrice>>('/api/pcweb/finance/adjustprice/queryStore', params),
  /** 分页查询门店库存成本明细 */
  queryStoreDetail: (
    params: {
      bill: string;
      filter?: {
        goodsSearchKey: string;
      };
    } & IPageParams
  ) => post<IPageData<IFinanceAdjustPriceDetail>>('/api/pcweb/finance/adjustprice/queryStoreDetail', params),
  /** 分页查询门店库存成本调整添加商品 */
  queryStoreGoods: (
    params: {
      filter: {
        goodsSearchKey: string;
        sortId: string;
      };
      storeId: string;
    } & IPageParams
  ) => post<IPageData<IFinanceAdjustPriceGoods>>('/api/pcweb/finance/adjustprice/queryStoreGoods', params),
  /** 分页查询门店库存成本调整添加商品 */
  queryGoodsForFastAdjust: (
    params: {
      filter: {
        goodsSearchKey: string;
      };
    } & IPageParams
  ) => post<IPageData<IFinanceAdjustPriceGoods>>('/api/pcweb/finance/adjustprice/queryGoodsForFastAdjust', params),
  /** 分页查询仓库库存成本 */
  queryWrh: (
    params: {
      filter: {
        createEndTime?: string;
        createStartTime?: string;
        id?: string;
        reason?: string;
        status?: InvPriceAdjustStatusEnum;
        wrhs: string[];
      };
    } & IPageParams
  ) => post<IPageData<IFinanceWrhAdjustPrice>>('/api/pcweb/finance/adjustprice/queryWrh', params),
  /** 分页查询仓库库存成本明细 */
  queryWrhDetail: (
    params: {
      bill: string;
      filter?: {
        goodsSearchKey: string;
      };
    } & IPageParams
  ) => post<IPageData<IFinanceAdjustPriceDetail>>('/api/pcweb/finance/adjustprice/queryWrhDetail', params),
  /** 分页查询仓库库存成本调整添加商品 */
  queryWrhGoods: (
    params: {
      filter: {
        goodsSearchKey: string;
        sortId: string;
      };
      wrh: string;
    } & IPageParams
  ) => post<IPageData<IFinanceAdjustPriceGoods>>('/api/pcweb/finance/adjustprice/queryWrhGoods', params),
  /** 删除门店库存成本价调整单-列表 */
  removeStore: (params: { uuid: string }) =>
    post<{ traceId: string }>('/api/pcweb/finance/adjustprice/removeStore', params),
  /** 删除仓库库存成本价调整单-列表 */
  removeWrh: (params: { uuid: string }) =>
    post<{ traceId: string }>('/api/pcweb/finance/adjustprice/removeWrh', params),
  /** 保存门店库存成本调整单 */
  saveStore: (params: {
    details?: {
      /** 商品唯一标识 传goods */
      goods: string;
      /**调整后门店成本价(元) */
      invPriceNew: any;
      reason: string;
    }[];
    info?: { reason: string; remark: string; store: string };
    targetStatus?: InvPriceAdjustStatusEnum;
    uuid: string;
    version: number;
  }) => post<{ traceId: string; uuid: string; version: number }>('/api/pcweb/finance/adjustprice/saveStore', params),
  saveWrh: (params: {
    details?: {
      /** 商品唯一标识 传goods */
      goods: string;
      /**调整后门店成本价(元) */
      invPriceNew: number;
      reason: string;
    }[];
    info?: { reason: string; remark: string; store: string };
    targetStatus: InvPriceAdjustStatusEnum;
    uuid?: string;
    version: number;
  }) => post<{ traceId: string; uuid: string; version: number }>('/api/pcweb/finance/adjustprice/saveWrh', params),
  /** 完成调整-门店库存成本价列表页 */
  submitStore: (params: { uuid: string; version: number }) =>
    post<string>('/api/pcweb/finance/adjustprice/submitStore', params),
  /** 完成调整-仓库库存成本价列表页 */
  submitWrh: (params: { uuid: string; version: number }) =>
    post<string>('/api/pcweb/finance/adjustprice/submitWrh', params),
  /** 查询导入仓库库存成本明细 */
  getIoWrhDetail: (params: { ioUuid: string }) =>
    post<IFinanceAdjustPriceDetail[]>('/api/pcweb/finance/adjustprice/getIoWrhDetail', params),
  /** 查询导入门店库存成本明细 */
  getIoStoreDetail: (params: { ioUuid: string }) =>
    post<IFinanceAdjustPriceDetail[]>('/api/pcweb/finance/adjustprice/getIoStoreDetail', params),
  /** 门店快速成本调整 */
  fastAdjustSubmit: (params: WebBaseResponseBaseSaveResult) =>
    post<{ traceId: string; uuid: string; version: number }>('/api/pcweb/finance/adjustprice/fastAdjustSubmit', params),
  /** 查询门店快速成本调整提交结果 */
  // /api/pcweb/finance/adjustprice/getFastSubmitResult
  getFastSubmitResult: () =>
    post<{ endTime: string; isSure: 0 | 1; startTime: string; status: 'doing' | 'done' }>(
      '/api/pcweb/finance/adjustprice/getFastSubmitResult'
    )
};

export default adjustPrice;
