import request from '@/utils/request';
import {
  IPoserOrderBill,
  IPosOrderFilter,
  IPoserOrderDetail,
  IQueryDetail
} from '@/dataModel/retail/posOrder/interface';
const { post } = request;

const posOrder = {
  /**分页pos订单-单据 */
  getPosOrderQuery: (params: { filter: IPosOrderFilter } & IPageParams) =>
    post<IPageData<IPoserOrderBill>>('/api/pcweb/business/order/query', params),
  /**获取pos订单详情 */
  posOrderDetail: (params: { uuid: string }) => post<IPoserOrderDetail>('/api/pcweb/business/order/get', params),
  // 查询商品优惠明细
  queryGoodsFavours: (params: { order: string; orderDetailUuid: string }) =>
    post<{ favourName: string; favourTotal: number; favourBillId: string }[]>(
      '/api/pcweb/business/order/queryGoodsFavours',
      params
    ),
  /**分页查询商品明细 */
  queryOrderGoods: (params: { filter?: { goodsKeyWord?: string }; order: string } & IPageParams) =>
    post<IPageData<IQueryDetail>>('/api/pcweb/business/order/queryOrderGoods', params),
  // 获得导购员列表
  queryGetGuterList: () => post<{ id?: string; name: string }[]>('/api/pcweb/business/guider/getAll')
};

export default posOrder;
