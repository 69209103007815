import { ISupplierByStore } from '@/dataModel/finance/supplierDifference/interface';
import request from '@/utils/request';
const { post } = request;

const supplierDifference = {
  /**
   *  按门店
   */
  subdiffByStore: (
    params: {
      filter?: {
        dept?: string[];
        goodsKeyword?: string;
        id?: string;
        orgs?: string[];
        occurDateEnd?: string;
        subdiffEndDate?: string;
        subdiffStartDate?: string;
        suppliers?: string[];
      };
    } & IPageParams
  ) => post<IPageData<ISupplierByStore>>('/api/pcweb/finance/supplier/subdiff/report/byStore', params),
  /**
   *  按门店合计
   */
  subdiffByStoreSum: (
    params: {
      filter?: {
        dept?: string[];
        goodsKeyword?: string;
        id?: string;
        orgs?: string[];
        occurDateEnd?: string;
        subdiffEndDate?: string;
        subdiffStartDate?: string;
        suppliers?: string[];
      };
    } & IPageParams
  ) => post<ISupplierByStore>('/api/pcweb/finance/supplier/subdiff/report/byStoreSum', params),
  /**
   *  按门店
   */
  subdiffBySupplier: (
    params: {
      filter?: {
        dept?: string[];
        goodsKeyword?: string;
        id?: string;
        orgs?: string[];
        occurDateEnd?: string;
        subdiffEndDate?: string;
        subdiffStartDate?: string;
        suppliers?: string[];
      };
    } & IPageParams
  ) => post<IPageData<ISupplierByStore>>('/api/pcweb/finance/supplier/subdiff/report/bySupplier', params),
  /**
   *  按供应商合计
   */
  subdiffBySupplierSum: (
    params: {
      filter?: {
        dept?: string[];
        goodsKeyword?: string;
        id?: string;
        orgs?: string[];
        occurDateEnd?: string;
        subdiffEndDate?: string;
        subdiffStartDate?: string;
        suppliers?: string[];
      };
    } & IPageParams
  ) => post<ISupplierByStore>('/api/pcweb/finance/supplier/subdiff/report/bySupplierSum', params),
  /**
   *  按门店
   */
  subdiffDetail: (
    params: {
      filter?: {
        dept?: string[];
        goodsKeyword?: string;
        id?: string;
        orgs?: string[];
        occurDateEnd?: string;
        subdiffEndDate?: string;
        subdiffStartDate?: string;
        suppliers?: string[];
      };
    } & IPageParams
  ) => post<IPageData<ISupplierByStore>>('/api/pcweb/finance/supplier/subdiff/report/detail', params),
  /**
   *  按供应商合计
   */
  subdiffDetailSum: (
    params: {
      filter?: {
        dept?: string[];
        goodsKeyword?: string;
        id?: string;
        orgs?: string[];
        occurDateEnd?: string;
        subdiffEndDate?: string;
        subdiffStartDate?: string;
        suppliers?: string[];
      };
    } & IPageParams
  ) => post<ISupplierByStore>('/api/pcweb/finance/supplier/subdiff/report/detailSum', params)
};
export default supplierDifference;
