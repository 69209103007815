import { PurchaseOrderStatusEnum } from '@/dataModel/dict/enum';
import { IStore } from '@/dataModel/mdata/store/interface';
import { ISupplier } from '@/dataModel/mdata/supplier/interface';
import { ILadingBatchResult, ILadingGoods, ILadingStore, ILadingSupplier } from '@/dataModel/purchase/lading/interface';
import request from '@/utils/request';
const { post } = request;

const lading = {
  /**获取供应商列表 */
  getSupplierList: () => post<ILadingSupplier[]>('/api/pcweb/purchase/lading/getSupplierList'),
  /**分页查询商品 */
  query: (
    params: {
      filter?: {
        // endTime: string;
        keyword?: string;
        sortId?: string;
        storeIdList?: string[];
        supplierIdList?: string[];
      };
    } & IPageParams
  ) =>
    post<{
      /**总金额 */
      total: number;
      /**商品数量 */
      goodsCount: number;
      goodsResult: IPageData<ILadingGoods>;
    }>('/api/pcweb/purchase/lading/query', params),
  /**获取门店列表 */
  getStoreList: (params: { goodsId: string }) =>
    post<ILadingStore[]>('/api/pcweb/purchase/lading/getStoreList', params),
  /**分页查询门店（用于添加门店） */
  queryStore: (
    params: {
      filter?: {
        searchKey: string;
      };
      goodsId: string;
    } & IPageParams
  ) => post<IPageData<IStore>>('/api/pcweb/purchase/lading/queryStore', params),
  /**添加商品 */
  addGoods: (params: { goodsIds: string[] }) => post('/api/pcweb/purchase/lading/addGoods', params),
  /**添加门店 */
  addStores: (params: { goodsId: string; storeIdList: string[] }) =>
    post('/api/pcweb/purchase/lading/addStores', params),
  /**修改参考天数 */
  modifyReferDays: (params: { days: number }) => post('/api/pcweb/purchase/lading/modifyReferDays', params),
  /**批量移除商品 */
  removeGoodsBatch: (params: { goodsIds: string[] }) => post('/api/pcweb/purchase/lading/removeGoodsBatch', params),
  /**删除门店 */
  removeStore: (params: { goodsId: string; storeUuid: string }) =>
    post('/api/pcweb/purchase/lading/removeStore', params),
  /**查询商品 */
  queryGoods: (
    params: {
      filter?: {
        keyword?: string;
        sortId: string;
      };
    } & IPageParams
  ) => post<IPageData<ILadingGoods>>('/api/pcweb/purchase/lading/queryGoods', params),
  /**保存门店行数据 */
  saveStoreData: (params: { goodsId: string; purQty: number; store: string }) =>
    post('/api/pcweb/purchase/lading/saveStoreData', params),
  /**保存商品单行数据 */
  saveLine: (params: { goodsId: string; goodsMunitUuid: string; inPrice: number; supplierId: string }) =>
    post('/api/pcweb/purchase/lading/saveLine', params),
  /** 分页查询门店（用于提交提单任务） */
  queryStoreForLading: (
    params: {
      filter?: {
        searchKey?: string;
      };
    } & IPageParams
  ) => post<IPageData<IStore>>('/api/pcweb/purchase/lading/queryStoreForLading', params),
  /**提单弹框 获取门店 */
  getSortListForLading: () => post<IStore[]>('/api/pcweb/purchase/lading/getSortListForLading'),
  /**分页查询供应商（用于提交提单任务） */
  querySupplierForLading: (
    params: {
      filter?: {
        searchKey?: string;
      };
    } & IPageParams
  ) => post<IPageData<ISupplier>>('/api/pcweb/purchase/lading/querySupplierForLading', params),
  /** 提单*/
  subLading: (params: {
    sortIdList: string[];
    storeIdList: string[];
    supplierIdList: string[];
    targetStatus: PurchaseOrderStatusEnum;
  }) => post<{ uuid: string }>('/api/pcweb/purchase/lading/subLading', params),
  /**获取批次任务信息 */
  getBatch: (params: { uuid: string }) => post<ILadingBatchResult>('/api/pcweb/purchase/lading/getBatch', params),
  /**获取参考天数 */
  getReferDays: () => post('/api/pcweb/purchase/lading/getReferDays'),
  /**
查询是否有今晚24点结束的采购计划单 */
  getIsTodayFinishPlan: () => post<{ isTodayFinishPlan: boolean }>('/api/pcweb/purchase/plan/getIsTodayFinishPlan'),
  /** 获取错误行数，用于提单之前校验*/
  getErrorLineCount: () => post<number>('/api/pcweb/purchase/lading/getErrorLineCount'),

  /** 保存单行数据批量 */
  saveLineBatch: (params: {
    lines: {
      goodsId: string;
      goodsMunitUuid: string;
      inPrice: number;
      supplierId: string;
    }[];
  }) => post('/api/pcweb/purchase/lading/saveLineBatch', params)
};

export default lading;
