import request from '@/utils/request';
import { UseCountTypeEnum } from '@/dataModel/dict/enum';
import {
  ICardBalance,
  ICardBalanceTotal,
  IGiftCard,
  IGiftCardTotal,
  IStoreCard,
  IStoreCardTotal
} from '@/dataModel/finance/cardPurchase';

export default {
  /** 查询礼品卡汇总 */
  queryGiftCard: (
    params: {
      filter?: {
        /** 开始日期 */
        startDate?: string;
        /** 结束日期 */
        endDate?: string;
        /** 使用次数 */
        useCountType?: UseCountTypeEnum;
        /** 卡类型编码/名称 */
        cardType?: string;
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => request.post<IPageData<IGiftCard>>(`/api/pcweb/member/drpt/queryAll`, params, {}, 'card'),

  /** 查询门店卡流水汇总 */
  queryStoreCard: (
    params: {
      filter?: {
        /** 开始日期 */
        startDate?: string;
        /** 结束日期 */
        endDate?: string;
        /** 门店uuid */
        store?: string;
        stores?: string[];
        /** 使用次数 */
        useCountType?: UseCountTypeEnum;
        /** 卡类型编码/名称 */
        cardType?: string;
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => request.post<IPageData<IStoreCard>>(`/api/pcweb/member/drpt/query`, params, {}, 'card'),

  /** 查询礼品卡汇总总计 */
  queryGiftCardTotal: (params: {
    filter?: {
      /** 开始日期 */
      startDate?: string;
      /** 结束日期 */
      endDate?: string;
      /** 使用次数 */
      useCountType?: UseCountTypeEnum;
      /** 卡类型编码/名称 */
      cardType?: string;
    };
    orderBy?: IPageParamsOrderBy;
  }) => request.post<IGiftCardTotal>('/api/pcweb/member/drpt/queryAllSum', params, {}, 'card'),

  /** 查询门店卡流水汇总总计 */
  queryStoreCardTotal: (params: {
    filter?: {
      /** 开始日期 */
      startDate?: string;
      /** 结束日期 */
      endDate?: string;
      /** 门店uuid */
      store?: string;
      /** 使用次数 */
      useCountType?: UseCountTypeEnum;
      /** 卡类型编码/名称 */
      cardType?: string;
    };
    orderBy?: IPageParamsOrderBy;
  }) => request.post<IStoreCardTotal>('/api/pcweb/member/drpt/querySum', params, {}, 'card'),

  /** 查询卡余额变动 */
  queryCardBalance: (
    params: {
      filter?: {
        /** 开始日期 */
        startDate?: string;
        /** 结束日期 */
        endDate?: string;
        /** 使用次数 */
        useCountType?: UseCountTypeEnum;
        /** 卡类型编码/名称 */
        cardType?: string;
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => request.post<IPageData<ICardBalance>>(`/api/pcweb/member/drpt/queryBalance`, params, {}, 'card'),

  /** 查询卡余额变动 汇总 */
  queryCardBalanceTotal: (params: {
    filter?: {
      /** 开始日期 */
      startDate?: string;
      /** 结束日期 */
      endDate?: string;
      /** 使用次数 */
      useCountType?: UseCountTypeEnum;
      /** 卡类型编码/名称 */
      cardType?: string;
    };
    orderBy?: IPageParamsOrderBy;
  }) => request.post<ICardBalanceTotal>('/api/pcweb/member/drpt/queryBalanceSum', params, {}, 'card')
};
