import request from '@/utils/request';
import { IConsumeSummary } from '@/dataModel/member/consumeSummary/interface';
const { post } = request;

const consumeSummary = {
  /**总部会员消费汇总 */
  queryHeadMemberConsumeSummary: (
    params: {
      filter?: {
        beginDate?: string; //开始日期
        channel?: string; //渠道
        endDate?: string; //结束日期
        storeSearchKey?: string; //门店
        memberSearchKey?: string; //会员
      };
    } & IPageParams
  ) => post<IPageData<IConsumeSummary>>('/api/pcweb/member/info/queryHeadMemberConsumeSummary', params),
  /** 总部会员消费汇总统计*/
  queryHeadMemberConsumeSummaryTotal: (params: {
    filter?: {
      beginDate?: string; //开始日期
      channel?: string; //渠道
      endDate?: string; //结束日期
      storeSearchKey?: string; //门店
      memberSearchKey?: string; //会员
    };
  }) => post<any>('/api/pcweb/member/info/queryHeadMemberConsumeSummaryTotal', params),
  /**门店会员消费汇总 */
  queryStoreMemberConsumeSummary: (
    params: {
      filter?: {
        beginDate?: string; //开始日期
        channel?: string; //渠道
        endDate?: string; //结束日期
        memberSearchKey?: string; //门店
      };
    } & IPageParams
  ) => post<IPageData<IConsumeSummary>>('/api/pcweb/member/info/queryStoreMemberConsumeSummary', params),
  /** 门店会员消费汇总统计*/
  queryStoreMemberConsumeSummaryTotal: (params: {
    filter?: {
      beginDate?: string; //开始日期
      channel?: string; //渠道
      endDate?: string; //结束日期
      memberSearchKey?: string; //门店
    };
  }) => post<any>('/api/pcweb/member/info/queryStoreMemberConsumeSummaryTotal', params)
};

export default consumeSummary;
