import request from '@/utils/request';
import {
  IMemberMoneyFlowTotal,
  IMemberMoneyFlow,
  IMemberMoneyStore,
  IMemberMoneyFlowFilter,
  IMemberMoneyStoreFilter,
  IMemberStoreWithHeads
} from '@/dataModel/member/memberMoneyFlow/interface';
const { post } = request;

const memberStoredSummary = {
  /**储值资金流向查询 */
  queryStoredFlowDirection: (
    params: {
      filter?: IMemberMoneyFlowFilter;
    } & IPageParams
  ) => post<IPageData<IMemberMoneyFlow>>('/api/pcweb/member/info/queryStoredFlowDirection', params),
  /**储值资金流向查询合计 */
  queryStoredFlowDirectionSum: (params: { filter?: IMemberMoneyFlowFilter }) =>
    post<IMemberMoneyFlowTotal[]>('/api/pcweb/member/info/queryStoredFlowDirectionSum', params),
  /**储值资金流向门店 */
  queryStoredFlowDirectionHead: (params: { filter?: IMemberMoneyStoreFilter }) =>
    post<IMemberMoneyStore[]>('/api/pcweb/member/info/queryStoredFlowDirectionHead', params),
  /**
   * 查询包含虚拟总部的门店列表
   */
  queryStoreWithHeads: () =>
    request.post<IMemberStoreWithHeads[]>('/api/pcweb/member/info/queryStoredFlowDirectionCondition')
};

export default memberStoredSummary;
