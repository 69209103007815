import { BarcodePrintStatusEnum, PromotionTypeEnum } from '@/dataModel/dict/enum';
import { IBarcodePrint, IBarcodePrintDetailGoods, IBarcodePrintGoods } from '@/dataModel/retail/barcodePrint/interface';
import request from '@/utils/request';
const { post } = request;

const barcodePrint = {
  /**
   * 门店分页查询待打印任务/历史打印任务
   * @param params
   * @returns
   */
  queryBill: (
    params: {
      isPrinted: boolean;
      filter?: {
        id?: string;
        printDateBegin?: string;
        printDateEnd?: string;
        searchKey?: string;
      };
    } & IPageParams
  ) => post<IPageData<IBarcodePrint>>('/api/pcweb/retail/barcodeprint/queryBill', params),

  /**
   * 分页查询特殊条码打印商品明细
   * @returns
   */
  queryDetail: (
    params: {
      bill: string;
      filter?: {
        goodsKeyWord: string;
      };
    } & IPageParams
  ) => post<IPageData<IBarcodePrintDetailGoods>>('/api/pcweb/retail/barcodeprint/queryDetail', params),

  /**
   * 分页查询特殊条码打印添加商品
   * @returns
   */
  queryGoods: (params: { filter: { goodsSortId: string; searchKey: string } } & IPageParams) =>
    post<IPageData<IBarcodePrintGoods>>('/api/pcweb/retail/barcodeprint/queryGoods', params),

  /**
   * 保存
   * @param params
   * @returns
   */
  save: (params: {
    details: {
      barcode: string;
      goodsId: string;
      goodsMunitUuid: string;
      printCount: number;
      promotePrice: number;
      validDate: string;
    }[];
    targetStatus: BarcodePrintStatusEnum;
    uuid?: string;
    version: number;
  }) => post<{ uuid: string; version: number }>('/api/pcweb/retail/barcodeprint/save', params),

  /**
   * 特殊条码生成规则接口
   * @param params
   * @returns
   */
  createPromotion: (params: {
    barcodeType: PromotionTypeEnum;
    goodsMunitIdent: string;
    promotePrice: number;
    validDate: string;
  }) => post<{ barcode: string }>('/api/pcweb/retail/barcodeprint/createPromotion', params),

  /**
   *打印--列表页
   * @param params
   * @returns
   */
  print: (params: { uuid: string; version: number }) => post<any>('/api/pcweb/retail/barcodeprint/print', params),

  /**
   * 批量删除单据
   * @param params
   * @returns
   */
  batchRemoveBills: (params: { uuids: string[] }) =>
    post<any>('/api/pcweb/retail/barcodeprint/batchRemoveBills', params),

  /**
   * 根据导入任务获取商品
   * @param params
   * @returns
   */
  getGoodsByIoTask: (params: { taskUuid: string }) =>
    post<IBarcodePrintDetailGoods[]>('/api/pcweb/retail/barcodeprint/getGoodsByIoTask', params)
};

export default barcodePrint;
