import {
  BillTypeEnum,
  CheckFlowBillClassEnum,
  CheckFlowStatusEnum,
  CheckTaskBillClassEnum,
  CheckTaskGoodsScopeEnum,
  CheckTaskRuleOutOfScopeEnum,
  CheckTaskRuleUnCheckQtyEnum,
  CheckTaskSearchTypeEnum,
  CheckTaskSourceClassEnum,
  CheckTaskStatusEnum,
  CheckTaskWorkStatusEnum
} from '@/dataModel/dict/enum';
import {
  ICheckTaskSelectModalGoods,
  IInvCheckTask,
  IInvCheckTaskFLow,
  IInvCheckTaskGoods,
  IInvPreOrder,
  IInvPreCheckFlow
} from '@/dataModel/inv/checkTask/interface';
import request from '@/utils/request';
const { post } = request;

const checkTask = {
  /**
   * 分页盘点记录
   */
  query: (
    params: {
      filter?: {
        billId?: string;
        creatorName?: string;
        endTime?: string;
        sourceClass?: string;
        billClass?: string;
        startTime?: string;
        status?: CheckTaskStatusEnum;
        wrhId?: string;
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => post<IPageData<IInvCheckTask>>('/api/pcweb/inv/check/task/query', params),

  /**
   * 完成
   */
  finished: (params: { billId: string; version: number }) => post<any>('/api/pcweb/inv/check/task/finished', params),

  /**
   * 作废
   */
  aborted: (params: { billId: string; version: number }) => post<any>('/api/pcweb/inv/check/task/aborted', params),

  /**
   * 分页查询商品
   */
  queryGoods: (
    params: {
      filter: {
        existGoodsIds?: string[];
        existSortIds?: string[];
        notExistGoodsIds?: string[];
        notExistSortIds?: string[];
        searchKey?: string;
        sortId?: string;
        isWrh?: boolean;
      };
    } & IPageParams
  ) => post<IPageData<ICheckTaskSelectModalGoods>>('/api/pcweb/inv/check/task/queryGoods', params),

  /**
   * 新增
   */
  create: (params: {
    isShowSnapshot: number;
    billClass?: CheckTaskBillClassEnum;
    endTime?: string;
    existGoodsIds?: string[];
    existSortIds?: string[];
    goodsScope?: CheckTaskGoodsScopeEnum;
    notExistGoodsIds?: any[];
    notExistSortIds?: any[];
    remark: string;
    ruleOutOfScope?: CheckTaskRuleOutOfScopeEnum;
    ruleUnCheckQty?: CheckTaskRuleUnCheckQtyEnum;
    sourceClass?: CheckTaskSourceClassEnum;
    startTime?: string;
    wrhIds?: string[];
    name?: string;
    isRemoveOutStatusGoods?: boolean;
    nuid?: string;
  }) => post<any>('/api/pcweb/inv/check/task/create', params),

  /**
   * 获得
   */
  get: (params: { uuid?: string; id?: string }) => post<IInvCheckTask>('/api/pcweb/inv/check/task/get', params),

  /**
   * 通过盘点任务查询预盘单列表
   */
  getPreCheckByTask: (
    params: {
      uuid?: string;
      filter?: {
        billInfo?: string;
        cargoArea?: string;
        lastModifierId?: string;
        checkTaskId?: string;
        checkTask?: string;
      };
    } & IPageParams
  ) => post<IPageData<IInvPreOrder>>('/api/pcweb/inv/check/pre/query', params),
  /**
   * 分页盘点商品明细
   */
  queryDetail: (
    params: {
      billId: string;
      filter: {
        searchKey?: string;
        searchType?: CheckTaskSearchTypeEnum[];
        sortId?: string;
        checked?: boolean;
        isPrepareCheck?: boolean;
        sortIds?: string[];
      };
      isRandom?: boolean;
      flowUuid?: string;
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => post<IPageData<IInvCheckTaskGoods>>('/api/pcweb/inv/check/task/queryDetail', params),

  /**
   * 分页查询流水单
   */
  queryFlow: (
    params: {
      filter: {
        checkTask?: string;
        goodsId?: string;
        status?: CheckFlowStatusEnum;
      };
    } & IPageParams
  ) => post<IPageData<IInvCheckTaskFLow>>('/api/pcweb/inv/check/flow/query', params),

  /**
   *盘点流水保存/保存并提交
   */
  save: (params: {
    isSubmit: boolean;
    remark?: string;
    preCheck?: string;
    version?: number;
    uuid?: string;
    checkTask: string;
    billClass: CheckFlowBillClassEnum | CheckTaskWorkStatusEnum;
    detailList: {
      goodsId?: string;
      qty?: number;
      batchList?: { batchNo?: string; qty?: number; validDate?: string }[];
    }[];
  }) => post<{ traceId: string; uuid: string; version: number }>('/api/pcweb/inv/check/flow/save', params),

  /**
   *开始盘点
   */
  start: (params: { billId: string; version: number }) =>
    post<{
      bills: { billId: string; billType: { id: BillTypeEnum; name: string }; status: { id: string; name: string } }[];
      traceId: string;
    }>('/api/pcweb/inv/check/task/start', params),

  /**
   *流水单头
   */
  getBasicInfo: (params: { uuid: string }) => post<IInvCheckTaskFLow>('/api/pcweb/inv/check/flow/getBasicInfo', params),
  /**
   * 查询预盘单单头
   */
  getPreCheck: (params: { uuid: string; id?: string }) =>
    post<IInvPreCheckFlow>('/api/pcweb/inv/check/pre/get', params),
  /**
   *分页查询流水单商品明细
   */
  queryDetails: (
    params: {
      filter: {
        billUuid: string;
        goodsInfo?: string;
        sortId?: string;
      };
    } & IPageParams
  ) => post<IPageData<IInvCheckTaskGoods>>('/api/pcweb/inv/check/flow/queryDetails', params),
  /**
   *查询预盘单明细
   */
  queryPreCheckDetails: (
    params: {
      bill: string;
      filter?: {
        keyword?: string;
      };
    } & IPageParams
  ) => post<IPageData<IInvCheckTaskGoods>>('/api/pcweb/inv/check/pre/queryDetails', params),
  /**
   * 盘点流水作废
   */
  flowAbort: (params: { uuid: string }) => post<any>('/api/pcweb/inv/check/flow/abort', params),

  /**
   * 查询详情-编辑用
   */
  getDetail: (params: { uuid: string }) => post<any>('/api/pcweb/inv/check/flow/getDetail', params),

  /**
   * 提交-列表页
   */
  submit: (params: { uuid: string }) => post<any>('/api/pcweb/inv/check/flow/submit', params),

  /**
   * 是否存在未提交流水单,true:存在
   */
  checkUnSubmitFlow: (params: { taskUuid: string }) => post<any>('/api/pcweb/inv/check/flow/checkUnSubmitFlow', params),

  /**
   * 设为复盘&取消
   */
  set: (params: { check: boolean; uuids: string[] }) => post<any>('/api/pcweb/inv/check/task/set', params),

  /**
   * 删除流水
   */
  remove: (params: { uuid: string }) => post<any>('/api/pcweb/inv/check/flow/remove', params),

  /**
   * 盘点商品明细统计
   */
  getSum: (params: { billId: string }) =>
    post<{
      lessSum: number;
      normalSum: number;
      eqSum: number;
      moreSum: number;
      prepareCheckSum: number;
      checkSum: number;
    }>('/api/pcweb/inv/check/task/getSum', params),
  /**
   * 盘点商品明细统计
   */
  getFinishResult: (params: { id: string }) => post<any>('/api/pcweb/inv/check/task/getFinishResult', params),

  /**
   * 分页盘点商品批号明细
   */
  queryDetailBatch: (params: { billId: string; goodsId: string; flowUuid: string } & IPageParams) =>
    post<IPageData<IInvCheckTaskGoods>>('/api/pcweb/inv/check/task/queryDetailBatch', params),

  /**
   * 一键设为复盘&取消
   */
  setAll: (params: {
    billId: string;
    check: boolean;
    filter: {
      searchKey?: string;
      searchType?: CheckTaskSearchTypeEnum[];
      sortId?: string;
    };
  }) => post<{ failedCount: number; successCount: number }>('/api/pcweb/inv/check/task/setAll', params),

  /**
   * 获取当前任务单状态&取消
   */
  getBillStatus: (params: { uuid?: string; id?: string }) =>
    post<IInvCheckTask>('/api/pcweb/inv/check/task/getBillStatus', params),
  /**
   * 添加商品（通过分类，或商品）
   * @param params
   * @returns
   */
  addGoods: (params: { goodsList?: string[]; nuid?: string; sortIds?: string[] }) =>
    post<{ traceId: string }>('/api/pcweb/inv/check/task/addGoods', params),
  /**
   * 获取商品池商品数量
   * @param params
   * @returns
   */
  getGoodsCount: (params: { nuid?: string }) => post<number>('/api/pcweb/inv/check/task/getGoodsCount', params),
  /**
   * 添加商品（通过分类，或商品）
   * @param params
   * @returns
   */
  queryGoodsAdded: (
    params: {
      filter?: { searchKey?: string; sortIds?: string[] };
      nuid?: string;
    } & IPageParams
  ) => post<IPageData<ICheckTaskSelectModalGoods>>('/api/pcweb/inv/check/task/queryGoodsAdded', params),
  /**
   * 清空商品池
   * @param params
   * @returns
   */
  clearGoods: (params: { nuid?: string }) => post<number>('/api/pcweb/inv/check/task/clearGoods', params),
  /**
   * 清空商品池
   * @param params
   * @returns
   */
  removeGoods: (params: { nuid?: string; goods?: string }) =>
    post<number>('/api/pcweb/inv/check/task/removeGoods', params),
  /**
   * 复制商品到新的盘点任务中
   * @param params
   * @returns
   */
  copyGoodsToNewTask: (params: { nuid?: string; sourceBill?: string }) =>
    post<{
      poolGoodsCount?: number;
      sourceGoodsCount?: number;
    }>('/api/pcweb/inv/check/task/copyGoodsToNewTask', params)
};
export default checkTask;
