import request from '@/utils/request';
import {
  IGiftCardBySaler,
  IGiftCardBySalerTotal,
  IGiftCardByStore,
  IGiftCardByStoreTotal
} from '@/dataModel/finance/giftCard';

export default {
  /** 按售卡业务员 */
  queryGiftCardBySaler: (
    params: {
      filter?: {
        /** 开始日期 */
        startDate?: string;
        /** 结束日期 */
        endDate?: string;
        /** 购卡人id/客户id */
        buyer?: string;
        /** 售卡人id */
        saler?: string;
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => request.post<IPageData<IGiftCardBySaler>>(`/api/pcweb/member/drpt/queryBySaler`, params, {}, 'card'),

  /** 按售卡业务员总计 */
  queryGiftCardBySalerTotal: (params: {
    filter?: {
      /** 开始日期 */
      startDate?: string;
      /** 结束日期 */
      endDate?: string;
      /** 购卡人id/客户id */
      buyer?: string;
      /** 售卡人id */
      saler?: string;
    };
    orderBy?: IPageParamsOrderBy;
  }) => request.post<IGiftCardBySalerTotal>('/api/pcweb/member/drpt/queryBySalerSum', params, {}, 'card'),

  /** 按门店 */
  queryGiftCardByStore: (
    params: {
      filter?: {
        /** 开始日期 */
        startDate?: string;
        /** 结束日期 */
        endDate?: string;
        /** 门店uuid */
        store?: string;
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => request.post<IPageData<IGiftCardByStore>>(`/api/pcweb/member/drpt/queryByStore`, params, {}, 'card'),

  /** 按门店总计 */
  queryGiftCardByStoreTotal: (params: {
    filter?: {
      /** 开始日期 */
      startDate?: string;
      /** 结束日期 */
      endDate?: string;
      /** 门店uuid */
      store?: string;
    };
    orderBy?: IPageParamsOrderBy;
  }) => request.post<IGiftCardByStoreTotal>('/api/pcweb/member/drpt/queryByStoreSum', params, {}, 'card')
};
