import request from '@/utils/request';
import { ICustomer } from '@/dataModel/mdata/customer/interface';
import { CustomerStatusEnum, CustomerOrderTypeEnum } from '@/dataModel/dict/enum';
import { IOmallPriceLevelGoods } from '@/dataModel/mdata/omallPriceLevel/interface';
const { post } = request;

export type ApiCustomerQueryParams = IPageParams & {
  filter?: {
    contactInfo?: string;
    endTime?: string;
    salesmanInfo?: string;
    searchKey?: string;
    startTime?: string;
    order?: CustomerOrderTypeEnum;
    status?: CustomerStatusEnum;
  };
};

export type ApiCustomerGetParams = {
  uuid?: ICustomer['uuid'];
  id?: ICustomer['id'];
};

export type ApiCustomerModifyStatusParams = {
  infoList: Pick<ICustomer, 'uuid' | 'version'>[];
  status: CustomerStatusEnum;
};

export type ApiCustomerCreateParams = Pick<
  ICustomer,
  | 'bankAccount'
  | 'contactor'
  | 'email'
  | 'isSettleByNow'
  | 'isSettleByPeriod'
  | 'locationAddress'
  | 'locationCity'
  | 'locationCityName'
  | 'locationCounty'
  | 'locationCountyName'
  | 'locationProvince'
  | 'locationProvinceName'
  | 'name'
  | 'id'
  | 'phone'
  | 'salesman'
  | 'salesmanId'
  | 'salesmanName'
  | 'verifyDate'
  | 'verifyDays'
>;

export type ApiCustomerModifyParams = ApiCustomerCreateParams & Pick<ICustomer, 'uuid' | 'version'>;

const customer = {
  /** 分页查询客户档案列表 */
  query: (params?: ApiCustomerQueryParams) => post<IPageData<ICustomer>>('/api/pcweb/mdata/customer/query', params),

  /** 查询客户详情 */
  get: (params: ApiCustomerGetParams) => post<ICustomer>('/api/pcweb/mdata/customer/get', params),

  /** 修改状态 */
  modifyStatus: (params: ApiCustomerModifyStatusParams) => post<any>('/api/pcweb/mdata/customer/modifyStatus', params),

  /** 新增客户 */
  create: (params: ApiCustomerCreateParams) =>
    post<{ uuid: ICustomer['uuid'] }>('/api/pcweb/mdata/customer/create', params),

  /** 修改客户 */
  modify: (params: ApiCustomerModifyParams) =>
    post<{ version: ICustomer['version'] }>('/api/pcweb/mdata/customer/modify', params),
  /** 修改客户 */
  getOmallWXACode: (params: { salesmanId: string }) => post<any>('/api/pcweb/mdata/customer/getOmallWXACode', params),
  /** 分页查询客户批发价格目录 */
  queryGoods: (
    params: {
      goodsKeyword?: string;
      uuid: string;
    } & IPageParams
  ) => post<IPageData<IOmallPriceLevelGoods>>('/api/pcweb/mdata/customer/queryGoods', params)
};

export default customer;
