import { JoinPaymentStatusEnum, JoinPayTypeEnum } from '@/dataModel/dict/enum';
import { IJoinPayQueryItems } from '@/dataModel/finance/joinPay/interface';
import request from '@/utils/request';

const { post } = request;

export default {
  /** 分页查询表单 */
  query: (
    params: {
      filter: {
        createDateBegin: string;
        createDateEnd: string;
        id: string;
        occurDateBegin: string;
        occurDateEnd: string;
        reason: string;
        settleNo: number;
        status: string;
        storeSearchKey: string;
      };
    } & IPageParams
  ) => post<IPageData<IJoinPayQueryItems>>('/api/pcweb/finance/join/pay/queryBill', params),

  /** 查详情 */
  get: (params: { uuid: string }) => post<IJoinPayQueryItems>('/api/pcweb/finance/join/pay/get', params),

  /** 保存 */
  save: (params: {
    amt: number;
    bankAccountId: string;
    occurDate: string;
    payType: JoinPayTypeEnum;
    reason: string;
    remark: string;
    status: JoinPaymentStatusEnum;
    uuid?: string;
    version: number;
  }) => post<{ traceId: string; uuid: string; version: number }>('/api/pcweb/finance/join/pay/save', params),

  /** 批量作废单据 */
  batchAbortBills: (params: { uuids: string[] }) =>
    post<{ failedCount: number; successCount: number }>('/api/pcweb/finance/join/pay/batchAbortBills', params),

  /** 批量审核单据 */
  batchApplyBills: (params: { uuids: string[] }) =>
    post<{ failedCount: number; successCount: number }>('/api/pcweb/finance/join/pay/batchApplyBills', params),

  /** 批量删除单据 */
  batchRemoveBills: (params: { uuids: string[] }) =>
    post<{ failedCount: number; successCount: number }>('/api/pcweb/finance/join/pay/batchRemoveBills', params),

  /** 提交 */
  submit: (params: { uuid: string; version: number }) =>
    post<{ traceId: string }>('/api/pcweb/finance/join/pay/submit', params)
};
