import {
  IBusinessWrhLoss,
  IBusinessWrhLossBasicInfo,
  IBusinessWrhLossDetails,
  IWrhLossGoods,
  IWrhLossEditDetail
} from '@/dataModel/business/wrhLoss/interface';
import { WrhLossStatusEnum } from '@/dataModel/dict/enum';
import request from '@/utils/request';
const { post } = request;

const WrhLoss = {
  /**
   * 分页查
   */
  query: (
    params: {
      filter: {
        createStartTime?: string;
        createEndTime?: string;
        id?: string;
        reason?: string;
        status?: WrhLossStatusEnum;
        wrhId?: string;
        goodsInfo?: string;
      };
    } & IPageParams
  ) => post<IPageData<IBusinessWrhLoss>>('/api/pcweb/wrh/loss/query', params),
  /**
   * 列表提交报损单
   */
  submit: (params: { uuid: string; version: number }) =>
    post<{ traceId: string; version: number }>('/api/pcweb/wrh/loss/submit', params),
  /**
   * 删除
   */
  remove: (params: { uuid: string }) => post<{ data: string }>('/api/pcweb/wrh/loss/remove', params),
  /**
   * 获取表头信息
   */
  getBasicInfo: (params: { id: string }) => post<IBusinessWrhLossBasicInfo>('/api/pcweb/wrh/loss/getBasicInfo', params),
  /**
   * 查询订单商品明细列表
   */
  queryDetails: (
    params: {
      filter: {
        billId: string;
        goodsInfo: string;
      };
    } & IPageParams
  ) => post<IPageData<IBusinessWrhLossDetails>>('/api/pcweb/wrh/loss/queryDetails', params),
  /**
   * 查询详情-编辑/审核用
   */
  getDetail: (params: { id: string }) => post<IWrhLossEditDetail>('/api/pcweb/wrh/loss/getDetail', params),
  /**
   * 保存/提交
   */
  save: (params: {
    detailList: {
      munitUuid: string;
      planQty: number;
      reason: string;
    }[];
    imageList: string[];
    isSubmit: boolean;
    wrhId: string;
    reason: string;
    uuid?: string;
    version?: number;
  }) => post<{ traceId: string; uuid: string; version: number }>('/api/pcweb/wrh/loss/save', params),
  /**
   * 审核同意
   */
  approve: (params: {
    uuid: string;
    version: number;
    approveRemark: string;
    detailList: { approveQty: number; uuid: string }[];
  }) => post<{ traceId: string; version: string }>('/api/pcweb/wrh/loss/approve', params),
  /**
   * 审核拒绝
   */
  reject: (params: { uuid: string; version: number; approveRemark: string }) =>
    post<{ traceId: string; version: string }>('/api/pcweb/wrh/loss/reject', params),
  /**
   * 查询商品列表-添加商品时用
   */
  queryGoods: (params: { filter: { goodsInfo: string; sortId: string }; wrhId: string } & IPageParams) =>
    post<IPageData<IWrhLossGoods>>('/api/pcweb/wrh/loss/queryGoods', params),

  /**
   * 导入报损
   */
  getGoodsByIoTask: (params: { taskUuid: string }) =>
    post<IBusinessWrhLossDetails[]>('/api/pcweb/wrh/loss/getGoodsByIoTask', params)
};

export default WrhLoss;
