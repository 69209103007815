import request from '@/utils/request';
import { IMdataTemplateOrder } from '@/dataModel/mdata/template/interface';
const { post } = request;

const templateRequire = {
  /**
   * 查询可订货列表
   * @param params
   * @returns
   */
  create: (params: { goodsIds: Array<string>; name: string; remark: string }) =>
    post<any>('/api/pcweb/mdata/template/require/create', params),
  /**
   * 查询订货模版
   * @param params
   * @returns
   */
  get: () => post<IMdataTemplateOrder[]>('/api/pcweb/mdata/template/require/getAll')
};

export default templateRequire;
