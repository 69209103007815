import { ReasonTypeEnum } from '@/dataModel/dict/enum';
import { IReason } from '@/dataModel/mdata/reason/interface';
import request from '@/utils/request';

const { post } = request;

const reason = {
  /**
   * 新建原因
   */
  create: (params: { businessType: string; name: string }) =>
    post<{ traceId: string; uuid: string }>('/api/pcweb/mdata/reason/create', params),

  /**
   * 查询原因列表
   */
  query: (
    params: {
      filter: {
        businessType?: string;
      };
    } & IPageParams
  ) => post<IPageData<IReason>>('/api/pcweb/mdata/reason/query', params),

  /**
   * 根据业务类型获取
   */
  getByBusinessType: (params: { businessType: ReasonTypeEnum }) =>
    post<IReason[]>('/api/pcweb/mdata/reason/getByBusinessType', params),

  /**
   * 查看详情
   */
  get: (params: { uuid: string }) => post<IReason>('/api/pcweb/mdata/reason/get', params),

  /**
   * 修改
   */
  modify: (params: { businessType: string; name: string; uuid: string; version: number }) =>
    post<{ traceId: string; version: number }>('/api/pcweb/mdata/reason/modify', params),

  /**
   * 删除
   */
  remove: (params: { uuid: string }) => post<{ traceId: string }>('/api/pcweb/mdata/reason/remove', params),

  /**
   * 批量删除
   */
  removeBatch: (params: { uuids: string[] }) =>
    post<{ failedCount: number; successCount: number }>('/api/pcweb/mdata/reason/removeBatch', params),

  /**
   * 批量导入
   */
  createBatch: (params: { batch: [{ businessType: string; name: string }] }) =>
    post<{ failedCount: number; successCount: number }>('/api/pcweb/mdata/reason/createBatch', params)
};

export default reason;
