import { IRuleBillItem } from '@/dataModel/system/ruleBill/interface';
import request from '@/utils/request';
const { post } = request;

const ruleBill = {
  /**
   * 分页查询
   */
  query: (params: { filter: { searchKey: string } } & IPageParams) =>
    post<IPageData<IRuleBillItem>>('/api/pcweb/system/rule/bill/query', params),

  /**
   * 修改
   */
  modify: (params: { flowLength: number; billType: string; prefix: string }) =>
    post<any>('/api/pcweb/system/rule/bill/modify', params),

  /**
   * 重置
   */
  reset: (params: { billType: string }) => post<any>('/api/pcweb/system/rule/bill/reset', params),

  /**
   * 检测前缀是否可以使用
   */
  check: (params: { billType: string; prefix: string }) => post<any>('/api/pcweb/system/rule/bill/check', params)
};
export default ruleBill;
