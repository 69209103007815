import {
  IReturnNotify,
  IReturnNotifyDetailInfo,
  IReturnNotifyGoods,
  IReturnNotifyGoodsDetail,
  IReturnNotifyStoreInfo,
  IReturnNotifyStoreReturnInfo
} from '@/dataModel/business/returnNotify/interface';
import { ReturnNotifyLimitTypeEnum, StoreReturnNotifyStatusEnum } from '@/dataModel/dict/enum';
import request from '@/utils/request';
const { post } = request;

export default {
  /**
   * 查询退货通知单
   */
  query: (params: {
    filter: {
      id?: string;
      storeInfo?: string;
      goodsInfo?: string;
      createStartTime?: string;
      createEndTime?: string;
      statusList?: StoreReturnNotifyStatusEnum[];
    };
  }) => post<IPageData<IReturnNotify>>('/api/pcweb/business/store/return/notify/query', params),
  /**
   * 删除退货通知单
   */
  remove: (params: { id: string; page?: number; pageSize?: number }) =>
    post<{ data: string }>('/api/pcweb/business/store/return/notify/remove', params),
  /**
   * 截止退货通知单
   */
  end: (params: { id: string; version: number; page?: number; pageSize?: number }) =>
    post<{ data: string }>('/api/pcweb/business/store/return/notify/end', params),
  /**
   * 保存/提交
   */
  save: (params: {
    detailList: {
      limitQty: number;
      limitType: string;
      munitUuid: string;
      reason: string;
    }[];
    endDate: string;
    isSubmit: boolean;
    reason: string;
    storeIdList: string[];
    storeScope: string;
    uuid: string;
    version: string;
  }) =>
    post<{ traceId: string; uuid: string; version: number }>('/api/pcweb/business/store/return/notify/save', params),
  /**
   * 获取退货通知详情信息
   */
  getBaseInfo: (params: { id: string }) =>
    post<IReturnNotifyDetailInfo>('/api/pcweb/business/store/return/notify/getBaseInfo', params),
  /**
   * 获取通知详情列表
   */
  getDetail: (params: { id: string }) =>
    post<{
      createInfo: IOperatorInfo;
      lastModifyInfo: IOperatorInfo;
      endDate: string;
      goodsList: any[];
      id: string;
      reason: string;
      remark: string;
      status: StoreReturnNotifyStatusEnum;
      storeList: {
        store: string;
        storeId: string;
        storeName: string;
      }[];
      storeScope: { id: ReturnNotifyLimitTypeEnum; name: string };
      tenantId: string;
      uuid: string;
      version: number;
    }>('/api/pcweb/business/store/return/notify/getDetail', params),
  /** 根据订单标识查询门店范围列表 */
  getStoreScopeList: (params: { uuid: string; page: number; pageSize: number }) =>
    post<{ store: string; storeId: string; storeName: string }[]>(
      '/api/pcweb/business/store/return/notify/getStoreScopeList',
      params
    ),
  /**
   *商品详情
   */
  queryGoodsDetail: (
    params: {
      filter: {
        id: string;
        goodsInfo: string;
      };
    } & IPageParams
  ) => post<IPageData<IReturnNotifyGoodsDetail>>('/api/pcweb/business/store/return/notify/queryGoodsDetail', params),
  /**
   * 分页查询门店信息-未退货
   */
  queryStoreInfo: (
    params: {
      filter: {
        billId: string;
        isReturn: boolean;
      };
    } & IPageParams
  ) => post<IPageData<IReturnNotifyStoreInfo>>('/api/pcweb/business/store/return/notify/queryStoreInfo', params),

  /** 查询门店退货单据信息 */
  queryStoreReturnInfo: (params: { id: string; page: number; pageSize: number }) =>
    post<IPageData<IReturnNotifyStoreReturnInfo>>(
      '/api/pcweb/business/store/return/notify/queryStoreReturnInfo',
      params
    ),
  /**
   * 查询商品列表
   */
  queryGoods: (
    params: {
      filter: {
        goodsInfo: string;
        sortId: string;
      };
    } & IPageParams
  ) => post<IPageData<IReturnNotifyGoods>>('/api/pcweb/business/store/return/notify/queryGoods', params)
};
