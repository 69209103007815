import { IJointGoodsSale, IJointGoodsSaleCount } from '@/dataModel/retail/jointGoodsSale';
import request from '@/utils/request';
const { post } = request;

const jointGoodsSale = {
  /**查询 */
  query: (
    params?: {
      filter?: {
        endDate: string; //结束日期
        startDate: string; //开始日期
        storeId: string; //门店
        keyWord: string; //商品信息
      };
    } & IPageParams
  ) => post<IPageData<IJointGoodsSale>>('/api/pcweb/retail/sale/supplier/query', params),
  /**统计 */
  count: (params?: {
    filter?: {
      endDate: string; //结束日期
      startDate: string; //开始日期
      storeId: string; //门店
      keyWord: string; //商品信息
    };
  }) => post<IJointGoodsSaleCount>('/api/pcweb/retail/sale/supplier/count', params)
};

export default jointGoodsSale;
