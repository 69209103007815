import {
  IReportPrepaymentSummary,
  IReportPrepaymentDetail,
  IReportPrepaymentSummaryCount,
  IReportPrepaymentDetailCount
} from '@/dataModel/finance/reportPrepayment/interface';
import request from '@/utils/request';
const { post } = request;

export default {
  /**预付款报表汇总 */
  queryReport: (
    params: {
      filter?: {
        endDate: string; //结束日期
        organizationId: string; //部门
        prepayId: string; //预付款号
        purchaseOrderId: string; //订单号
        startDate: string; //开始日期
        storeId: string; //门店
        supplierId: string; //供应商
      };
    } & IPageParams
  ) => post<IPageData<IReportPrepaymentSummary>>('/api/pcweb/finance/supplier/prepay/queryReport', params),
  getReportCount: (params: {
    filter?: {
      endDate: string; //结束日期
      organizationId: string; //部门
      prepayId: string; //预付款号
      purchaseOrderId: string; //订单号
      startDate: string; //开始日期
      storeId: string; //门店
      supplierId: string; //供应商
    };
  }) => post<IReportPrepaymentSummaryCount>('/api/pcweb/finance/supplier/prepay/getReportCount', params),

  /**预付款报表明细 */
  queryDetailReport: (
    params: {
      filter?: {
        endDate: string; //结束日期
        organizationId: string; //部门
        prepayId: string; //预付款号
        purchaseOrderId: string; //订单号
        startDate: string; //开始日期
        storeId: string; //门店
        supplierId: string; //供应商
      };
    } & IPageParams
  ) => post<IPageData<IReportPrepaymentDetail>>('/api/pcweb/finance/supplier/prepay/queryDetailReport', params),
  getDetailReportCount: (params: {
    filter?: {
      endDate: string; //结束日期
      organizationId: string; //部门
      prepayId: string; //预付款号
      purchaseOrderId: string; //订单号
      startDate: string; //开始日期
      storeId: string; //门店
      supplierId: string; //供应商
    };
  }) => post<IReportPrepaymentDetailCount>('/api/pcweb/finance/supplier/prepay/getDetailReportCount', params)
};
