import request from '@/utils/request';
import { IQueryList, IPickingDetailGoods, IPickingHead } from '@/dataModel/wrh/pickOrder/interface';
import { IWrhPickingTaskQuery, IWrhPickingTask } from '@/dataModel/wrh/pickOrderSearch/interface';

const { post } = request;
export interface IQueryParam {
  // 创建时间截止时间
  endTime?: string;
  // 单号
  id?: string;
  // 线路标识
  line?: string;
  // 分拣员标识
  picker?: string;
  // 拣货方式（weight-称重分拣、sandard-标品拣货）
  pickingType?: string;
  // 来源（manual-手动创建(pickingWave-拣货波次）
  source?: string;
  // 创建时间开始时间
  startTime?: string;
  //状态（init-保存(submitted-已提交)
  status?: string;
  // 门店标识
  store?: string;
  // 拣货波次单号
  waveId?: string;
  // 仓库标识
  wrh?: string;
}

const PickOrder = {
  /* 分页查询列表 */
  queryList: (
    params: {
      filter?: IQueryParam;
    } & IPageParams
  ) => post<IPageData<IQueryList>>('/api/pcweb/picking/query', params),

  //查询明细
  queryDetail: (
    params: {
      filter?: {
        searchKey?: string;
      };
      // 单据标识
      bill?: string;
    } & IPageParams
  ) => post<IPageData<IPickingDetailGoods>>('/api/pcweb/picking/queryDetail', params),

  // 完成
  orderFinsh: (params: { uuid?: string; version?: string; goodsList?: Array<[]> }) =>
    post('/api/pcweb/picking/pickedFinish', params),

  /**批量拣货完成 */
  batchOrderFinsh: (params: {
    items: {
      uuid: string;
      version: string;
    }[];
  }) =>
    post<{
      fail?: number;
      success?: number;
    }>('/api/pcweb/picking/batchPickedFinish', params),

  // 查询单头
  queryOrderHead: (parmas: {
    uuid?: string;
    /* 门店ID */
    storeId?: string;
    /* 编码 */
    id?: string;
  }) => post<IPickingHead>('/api/pcweb/picking/get', parmas),

  // 提交/作废
  modifyOrder: (parmas: {
    /*目标状态  */
    targetStatus?: string;
    uuid?: string;
    /* 当前版本号 */
    version?: string;
  }) => post('/api/pcweb/picking/modifyStatus', parmas),

  //  保存/保存并提交/保存并完成
  saveOrder: (parms: {
    /* 新增唯一标识（新增单据使用） */
    nuid?: string;
    /* 分拣员编码 */
    pickerId?: string;
    /* 拣货方式（weight-称重分拣、sandard-标品拣货） */
    pickingType?: string;
    /* 拣货商品 */
    saveList?: [];
    /* 门店编码 */
    storeId?: string;
    /* 目标状态 */
    targetStatus?: string;
    /* 单据标识,修改时不为空 */
    uuid?: string;
    /*版本号,修改时不为空 */
    version?: string;
    /* 仓库编码 */
    wrhId?: string;
  }) => post('/api/pcweb/picking/save', parms),

  // 删除订单
  removeOrder: (param: { uuid?: string }) => post('/api/pcweb/picking/remove', param),
  // 批量提交
  submitAll: (param: {
    items: Array<
      [
        {
          uuid?: string;
          version?: string;
        }
      ]
    >;
  }) =>
    post<{
      fail?: number;
      success?: number;
    }>('/api/pcweb/picking/batchSubmit', param),

  // 拣货单查询 报表
  queryOrderList: (params: { filter?: IWrhPickingTaskQuery & IPageParams }) =>
    post<IPageData<IWrhPickingTask>>('/api/pcweb/business/picking/report/query', params)
  // 获得未完成拣货单数量
  // /api/pcweb/picking/getUncloseBillCount
};

export default PickOrder;
