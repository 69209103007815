import request from '@/utils/request';
import {
  IOrderQueryReference,
  IOrderQueryReferenceFilter,
  IOrderQueryReferenceSum
} from '@/dataModel/purchase/orderQueryReference/interface';
const { post } = request;

const orderQueryReference = {
  /**废弃 */
  queryReference: (
    params: {
      filter: IOrderQueryReferenceFilter;
    } & IPageParams
  ) => post<IPageData<IOrderQueryReference>>('/api/pcweb/purchase/query/queryReference', params),
  /**采购订货参考-按商品 */
  queryReferenceByGoods: (
    params: {
      filter: IOrderQueryReferenceFilter;
    } & IPageParams
  ) => post<IPageData<IOrderQueryReference>>('/api/pcweb/purchase/query/queryByGoods', params),
  /**采购订货参考-按商品合计 */
  queryReferenceByGoodsSum: (
    params: {
      filter: IOrderQueryReferenceFilter;
    } & IPageParams
  ) => post<IOrderQueryReferenceSum>('/api/pcweb/purchase/query/queryByGoodsSum', params)
};

export default orderQueryReference;
