import request from '@/utils/request';
import {
  IReportSummary,
  IReportSummaryTotal,
  IReportSummaryGeneralSort,
  IReportSummaryMediumSort,
  IReportSummarySubSort,
  IReportSummaryGoods
} from '@/dataModel/finance/reportSummary/interface';
const { post } = request;

export default {
  /**查询进销存汇总报合计 */
  queryTotalByStore: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        goodsId?: string; //商品编码
        storeId?: string; //仓库编码
        goodsBarcode?: string; //商品条码
        goodsName?: string; //商品名称
        sortId?: string; //商品分类
        searchKey?: string; //模糊查询
      };
    } & IPageParams
  ) => post<IReportSummaryTotal>('/api/pcweb/finance/report/summary/wrh/queryTotalByStore', params),
  /**按仓库查询进销存汇总报 */
  queryByStore: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        storeId?: string; //仓库Id
      };
    } & IPageParams
  ) => post<IPageData<IReportSummary>>('/api/pcweb/finance/report/summary/wrh/queryByStore', params),
  /**按仓库查询进销存汇总报合计 */
  queryByStoreTotal: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        storeId?: string; //仓库Id
      };
    } & IPageParams
  ) => post<IReportSummaryTotal>('/api/pcweb/finance/report/summary/wrh/queryByStoreTotal', params),
  /**按仓库查询进销存汇总报 2.0版本 */
  queryByStoreNew: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        isStore?: boolean; //是否为门店
        isSummary: boolean; // 是否统计
        wrhIdList: Array<[]>; //门店或仓库编码
      };
    } & IPageParams
  ) => post<IPageData<IReportSummary>>('/api/pcweb/finance/report/summary/wrh/queryByStoreNew', params),
  /**按仓库查询进销存汇总报合计 2.0版本 */
  getTotalByStoreNew: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        isStore?: boolean; //是否为门店
        isSummary: boolean; // 是否统计
        wrhIdList: Array<[]>; //门店或仓库编码
      };
    } & IPageParams
  ) => post<IReportSummary[]>('/api/pcweb/finance/report/summary/wrh/getTotalByStore', params),
  /**按商品分类大类查询 */
  queryByGeneralSort: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        storeId?: string; //仓库编码
        sortId?: string; //商品分类
      };
    } & IPageParams
  ) => post<IPageData<IReportSummaryGeneralSort>>('/api/pcweb/finance/report/summary/wrh/queryByGeneralSort', params),
  /**按商品分类大类查询合计 */
  // queryByGeneralSortTotal: (
  //   params: {
  //     filter?: {
  //       createEndTime?: string; //结束时间
  //       createStartTime?: string; //开始时间
  //       storeId?: string; //仓库编码
  //       sortId?: string; //商品分类
  //     };
  //   } & IPageParams
  // ) => post<IReportSummaryTotal>('/api/pcweb/finance/report/summary/wrh/queryByGeneralSortTotal', params),
  /**按商品分类中类查询 */
  queryByMediumSort: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        storeId?: string; //仓库编码
        sortId?: string; //商品分类
      };
    } & IPageParams
  ) => post<IPageData<IReportSummaryMediumSort>>('/api/pcweb/finance/report/summary/wrh/queryByMediumSort', params),
  /**按商品分类中类查询合计 */
  queryByMediumSortTotal: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        storeId?: string; //仓库编码
        sortId?: string; //商品分类
      };
    } & IPageParams
  ) => post<IReportSummaryTotal>('/api/pcweb/finance/report/summary/wrh/queryByMediumSortTotal', params),
  /**按商品分类小类查询 */
  queryBySubSort: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        storeId?: string; //仓库编码
        sortId?: string; //商品分类
      };
    } & IPageParams
  ) => post<IPageData<IReportSummarySubSort>>('/api/pcweb/finance/report/summary/wrh/queryBySubSort', params),
  /**按商品分类小类查询合计 */
  queryBySubSortTotal: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        storeId?: string; //仓库编码
        sortId?: string; //商品分类
      };
    } & IPageParams
  ) => post<IReportSummaryTotal>('/api/pcweb/finance/report/summary/wrh/queryBySubSortTotal', params),
  /**按商品查询 */
  queryByGoodsId: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        goodsId?: string; //商品编码
        storeId?: string; //仓库编码
        goodsBarcode?: string; //商品条码
        goodsName?: string; //商品名称
        sortId?: string; //商品分类
        searchKey?: string; //模糊查询
      };
    } & IPageParams
  ) => post<IPageData<IReportSummaryGoods>>('/api/pcweb/finance/report/summary/wrh/queryByGoodsId', params),
  /**按商品查询合计 */
  queryByGoodsIdTotal: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        goodsId?: string; //商品编码
        storeId?: string; //仓库编码
        goodsBarcode?: string; //商品条码
        goodsName?: string; //商品名称
        sortId?: string; //商品分类
        searchKey?: string; //模糊查询
      };
    } & IPageParams
  ) => post<IReportSummaryTotal>('/api/pcweb/finance/report/summary/wrh/queryByGoodsIdTotal', params),
  /***
   *
   *  进销存2.0接口
   *
   *  */
  /* 按分类查询 */
  queryBySort: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        isStore?: boolean; //是否为门店
        isSummary?: boolean; //是否统计
        sortIdList?: Array<[]>; //类别集合
        sortType?: Array<[]>; // 类别维度
        wrhIdList?: Array<[]>; // 门店或仓库编码
      };
    } & IPageParams
  ) => post<IPageData<IReportSummaryTotal>>('/api/pcweb/finance/report/summary/wrh/queryBySort', params),
  /* 按分类查询合计 */
  queryByGeneralSortTotal: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        isStore?: boolean; //是否为门店
        isSummary?: boolean; //是否统计
        sortIdList?: Array<[]>; //类别集合
        sortType?: Array<[]>; // 类别维度
        wrhIdList?: Array<[]>; // 门店或仓库编码
      };
    } & IPageParams
  ) => post<IPageData<IReportSummaryTotal>>('/api/pcweb/finance/report/summary/wrh/queryByGeneralSortTotal', params),
  /* 按照单品查询 */
  queryByGoods: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        isStore?: boolean; //是否为门店
        isSummary?: boolean; //是否统计
        sortIdList?: Array<[]>; //类别集合
        searchKey?: string; //商品编码/商品名称/商品条码
        wrhIdList?: Array<[]>; // 门店或仓库编码
      };
    } & IPageParams
  ) => post<IPageData<IReportSummaryTotal>>('/api/pcweb/finance/report/summary/wrh/queryByGoods', params),
  /* 按照单品查询合计 */
  getTotalByGoods: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        isStore?: boolean; //是否为门店
        isSummary?: boolean; //是否统计
        sortIdList?: Array<[]>; //类别集合
        searchKey?: string; //商品编码/商品名称/商品条码
        wrhIdList?: Array<[]>; // 门店或仓库编码
      };
    } & IPageParams
  ) => post<IReportSummaryTotal>('/api/pcweb/finance/report/summary/wrh/getTotalByGoods', params)
};
