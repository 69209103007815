import request from '@/utils/request';
import {
  IPurchaseQuery,
  IPurchaseGet,
  IPurchaseGetBatchInfo,
  IPurchaseSaveDetails,
  IPurchaseGetDetails
} from '@/dataModel/purchase/back/interface';
const { post } = request;
const purchaseBack = {
  /**
   * 分页查询退货单
   */
  query: (
    params: {
      filter: {
        endDate?: string;
        goodsKeyword?: string;
        id?: string;
        purchaser?: string;
        settleNo?: string;
        startDate?: string;
        status?: string;
        supplier?: string;
        warehouse?: string;
      };
    } & IPageParams
  ) => post<IPageData<IPurchaseQuery>>('/api/pcweb/purchase/back/query', params),
  /**
   * 分页查询退货单明细
   */
  queryDetails: (
    params: {
      filter?: { keyword?: string };
      bill: string;
    } & IPageParams
  ) => post<IPageData<any>>('/api/pcweb/purchase/back/queryDetails', params),
  /**
   * 获取单头信息
   */
  get: (params: {
    // id?: string;
    uuid: string;
  }) => post<IPurchaseGet>('/api/pcweb/purchase/back/get', params),
  /**
   * 获取版本信息  批次弹框接口
   */
  getBatchInfo: (params: { goods: string }) =>
    post<IPurchaseGetBatchInfo[]>('/api/pcweb/purchase/back/getBatchInfo', params),
  /**
   * 保存采购退货单
   */
  save: (params: {
    details?: IPurchaseSaveDetails[];
    uuid?: string;
    version?: number;
    targetStatus?: string;
    info?: {
      backDate: string;
      supplierId: string;
      warehouse: string;
      purchaseInId?: string;
      purchaserId?: string;
      reason?: string;
      remark?: string;
    };
  }) => post<any>('/api/pcweb/purchase/back/save', params),
  /**
   * 删除退货单
   */
  remove: (params: { uuid: string }) => post('/api/pcweb/purchase/back/remove', params),
  /**
   * 获取明细
   */
  getDetails: (params: { uuid: string }) => post<IPurchaseGetDetails[]>('/api/pcweb/purchase/back/getDetails', params),
  /**
   *  批量删除
   */
  removeBatch: (params: { uuids: string[] }) => post<any>('/api/pcweb/purchase/back/removeBatch', params)
};
export default purchaseBack;
