import request from '@/utils/request';
import { IInvUse, IInvUseGoods, IInvUseGoodsDetail } from '@/dataModel/inv/use/interface';
import { StoreUseStatusEnum } from '@/dataModel/dict/enum';
import { IInvUseGoodsList } from '@/pages/HeadquartersBackend/Inv/Use/Edit';
const { post } = request;

const use = {
  /**
   * 分页查询领用单
   */
  query: (
    params: {
      filter: {
        createdEnd?: string;
        createdStart?: string;
        id?: string;
        receive?: string;
        receiveDateEnd?: string;
        receiveDateStart?: string;
        settleNo?: number;
        status?: StoreUseStatusEnum;
        store?: string;
      };
    } & IPageParams
  ) => post<IPageData<IInvUse>>('/api/pcweb/inv/use/query', params),
  /**
   * 同意
   */
  agree: (params: { details: { goods: string; qty: number; qtyStr: string }[]; uuid: string; version: number }) =>
    post('/api/pcweb/inv/use/agree', params),
  /**
   * 分页查询商品
   */
  queryGoods: (
    params: {
      filter: {
        searchKey?: string;
        sortId?: string;
      };
      receiveStoreId: string;
    } & IPageParams
  ) => post<IPageData<IInvUseGoods>>('/api/pcweb/inv/use/queryGoods', params),
  /**
   * 保存/保存并提交/提交并同意
   */
  create: (params: {
    reason: string;
    receive: string;
    receiveDate: string;
    receiveStore: string;
    remark: string;
    status: StoreUseStatusEnum;
    saves: {
      goods: string;
      goodsMunitUuid: string;
      qty: string;
      qtyStr: string;
      remark: string;
    }[];
  }) => post('/api/pcweb/inv/use/create', params),
  /**查看领用单头 */
  get: (params: { uuid: string }) => post<IInvUse>('/api/pcweb/inv/use/get', params),
  /**
   * 分页查询领用单明细
   */
  queryDetail: (
    params: {
      filter: {
        bill: string;
      };
    } & IPageParams
  ) => post<IPageData<IInvUseGoodsDetail>>('/api/pcweb/inv/use/queryDetail', params),
  /**
   * 修改/修改并提交/修改并同意
   */
  modify: (params: {
    reason: string;
    receive: string;
    receiveDate: string;
    receiveStore: string;
    remark: string;
    status: StoreUseStatusEnum;
    saves: {
      goods: string;
      goodsMunitUuid: string;
      qty: string;
      qtyStr: string;
      remark: string;
    }[];
    uuid: string;
    version: number;
  }) => post('/api/pcweb/inv/use/modify', params),
  /**
   *  提交
   */
  submit: (params: { uuid: string; version: number; status: StoreUseStatusEnum }) =>
    post('/api/pcweb/inv/use/submit', params),
  /**
   * 删除
   */
  remove: (params: { uuid: string }) => post('/api/pcweb/inv/use/remove', params),
  /**
   * 拒绝
   */
  reject: (params: { reason: string; uuid: string; version: number }) => post('/api/pcweb/inv/use/reject', params),
  /**
   * 批量同意
   */
  batchAgree: (params: { storeUseAgrees: { uuid: string; version: number }[] }) =>
    post<any>('/api/pcweb/inv/use/batchAgree', params),
  /**获取导入任务明细 */
  getGoodsByIoTask: (params: { receiveStoreId: string; taskUuid: string }) =>
    post<IInvUseGoodsList[]>('/api/pcweb/inv/use/getGoodsByIoTask', params)
};

export default use;
