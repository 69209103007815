import {
  PromoteActivityCreationResult,
  PromoteActivityFinishedRequest,
  PromoteActivityFinishedResult,
  PromoteActivityGetRequest,
  PromoteActivityItem,
  PromoteActivityQueryFilter,
  PromoteActivityRemovesRequest,
  PromoteActivityRemovesResult,
  ILimitActiveInfo,
  IqueryGoods
} from '@/dataModel/finance/storePromoteSales/interface';
import request from '@/utils/request';
const { post } = request;

export default {
  /** /api/pcweb/promtion/local/activity/create
创建 */
  create: (params: PromoteActivityItem) =>
    post<PromoteActivityCreationResult>('/api/pcweb/promtion/local/activity/create', params),
  /** /api/pcweb/promtion/local/activity/finished
批量终止 */
  finished: (params: PromoteActivityFinishedRequest) =>
    post<PromoteActivityFinishedResult>('/api/pcweb/promtion/local/activity/finished', params),

  /** /api/pcweb/promtion/local/activity/get
    获得 */
  get: (params: PromoteActivityGetRequest) =>
    post<PromoteActivityItem>('/api/pcweb/promtion/local/activity/get', params),

  /** /api/pcweb/promtion/local/activity/modify
    修改 */
  modify: (params: PromoteActivityItem) =>
    post<PromoteActivityFinishedResult>('/api/pcweb/promtion/local/activity/modify', params),

  /** /api/pcweb/promtion/local/activity/query
    分页查询促销活动 */
  query: (params?: { filter: PromoteActivityQueryFilter }) =>
    post<IPageData<PromoteActivityItem>>('/api/pcweb/promtion/local/activity/query', params),

  /** /api/pcweb/promtion/local/activity/removes
    批量删除 */
  removes: (params: PromoteActivityRemovesRequest) =>
    post<PromoteActivityRemovesResult>('/api/pcweb/promtion/local/activity/remove', params),
  /** 获取会员信息 api/pcweb/member/info/queryMemberCardTemplate */
  queryMemberCardTemplate: () => post('/api/pcweb/member/info/getMemberLevels'),
  /** 查询TAB /api/pcweb/promtion/local/activity/tab */
  queryTabCount: (params?: { filter: PromoteActivityQueryFilter }) =>
    post<{
      allCount?: number;
      doingCount?: number;
      finishedCount?: number;
      initCount?: number;
    }>('/api/pcweb/promtion/local/activity/tab', params),
  /** 限时折扣活动接口 */

  /**限时折扣重合商品检查  */
  limitActiveGoodsCheck: (param: { bill?: string }) =>
    post<{ promoteActivityItems: [] }>('/api/pcweb/promtion/local/activity/timelimit/check', param),
  /* 限时折扣商品明细新增or修改 */
  createLimitActive: (param: {
    // 0(折扣)
    discount?: string;
    // 优惠类型
    discountType?: string;
    // (固定减价)
    fixedPrice?: string;
    // 取整类型
    ignoreChangeType?: string;
    // 0(活动限购)
    limitQty?: number;
    // 0(会员限购)
    memberLimitQty?: number;
    // 0(促销价)
    promotionPrice?: number;
    skus?: Array<[]>;
    // 是否新增
    add?: boolean;
  }) =>
    post<{ bill?: string; traceId?: string; billId?: string; succed: number; fail: number }>(
      '/api/pcweb/promtion/local/activity/timelimit/create',
      param
    ),

  /* 设置查询结果 */
  modifyQueryResult: (
    params: {
      filter?: {
        // 单据标识
        bill?: string;
        // 未设置商品
        noSetting?: boolean;
        // 商品名称
        searchKey?: string;
        sortIds?: Array<[]>;
      };
      orderBy?: {
        // 排序方向，升序：asc，降序desc
        direction?: string;
        field?: string;
      };
      // 折扣
      discount?: number;

      discountType?: string;
      // 减价
      fixedPrice?: number;
      // 类型
      ignoreChangeType?: string;
      limitQty?: number;
      memberLimitQty?: number;
      // 促销价
      promotionPrice?: number;
      storeId?: string;
    } & IPageParams
  ) => post<IPageData<ILimitActiveInfo>>('/api/pcweb/promtion/local/activity/timelimit/modifyQuery', params),
  /* 分页查询限时折扣商品明细 */
  queryDetail: (
    param: {
      filter?: {
        billId?: string;
        // 未设置商品
        noSetting?: boolean;
        // 商品名称
        searchKey?: string;
        sortIds?: Array<[]>;
      };
      // 单据标识
      bill?: string;
      orderBy?: {
        // 排序方向，升序：asc，降序desc
        direction?: string;
        field?: string;
      };
    } & IPageParams
  ) => post<IPageData<ILimitActiveInfo>>('/api/pcweb/promtion/local/activity/timelimit/query', param),

  /* 限时折扣商品明细删除 */
  removeGoods: (params: { bill?: string; uuids?: Array<[]> | string }) =>
    post<{ succed: number; fail: number }>('/api/pcweb/promtion/local/activity/timelimit/remove', params),
  // 查询商品接口
  queryGoodsSku: (
    params: {
      filter?: {
        /* 商品名称/编码/条码 */
        searchKey?: string;
        /*"分类id  */
        sortId?: string;
        /* 门店编码 */
        storeIds?: Array<[]>;
        /* 商品状态 */
        status?: string;
        /*单据标识  */
        bill?: string;
      };
    } & IPageParams
  ) => post<IPageData<IqueryGoods>>('/api/pcweb/promtion/local/activity/querySku', params),
  // 活动直接导入数据的时候先查询一个uuid 默认打入
  getDefaultUuid: (params: { promoteClass: string }) =>
    post<{ bill: string; billId: string }>('/api/pcweb/promtion/local/activity/timelimit/createBill', params),
  getCopyBill: (params: { bill: string }) =>
    post<{ bill: string; billId: string }>('/api/pcweb/promtion/local/activity/timelimit/copy', params)
};
