import { RequireQueryOrdersDimensionEnum, RequireOrderStatusEnum } from '@/dataModel/dict/enum';
import { ISort } from '@/dataModel/mdata/sort/interface';
import { IByStore, IBySort, IByGoods, IDetail } from '@/dataModel/business/storeQueryOrder/interface';
import request from '@/utils/request';
const { post } = request;
const queryOrder = {
  /** 门店订货查询-合计查询接口 */
  queryOrdersCount: (params: {
    dimension: RequireQueryOrdersDimensionEnum;
    filter?: {
      billId?: string;
      endDate?: string;
      sortIds?: ISort['id'];
      startDate?: string;
      status?: RequireOrderStatusEnum;
      stores?: string[];
    };
  }) =>
    post<{ goodsCount: number; qty: number; distTotal: number }>(
      '/api/pcweb/business/require/queryOrdersCount',
      params
    ),

  /**总部商品-门店订货查询-门店 */
  queryOrdersByStore: (
    params: {
      filter?: {
        endDate?: string;
        startDate?: string;
        stores?: string[];
      };
    } & IPageParams
  ) => post<IPageData<IByStore>>('/api/pcweb/business/require/queryOrdersByStore', params),
  /**总部商品-门店订货查询-分类 */
  queryOrdersBySort: (
    params: {
      filter?: {
        endDate?: string;
        startDate?: string;
        sortIds?: string[];
      };
    } & IPageParams
  ) => post<IPageData<IBySort>>('/api/pcweb/business/require/queryOrdersBySort', params),
  /**总部商品-门店订货查询-商品 */
  queryOrdersByGoods: (
    params: {
      filter?: {
        endDate?: string;
        startDate?: string;
        searchKey?: string;
      };
    } & IPageParams
  ) => post<IPageData<IByGoods>>('/api/pcweb/business/require/queryOrdersByGoods', params),
  /**总部商品-门店订货查询-订货明细 */
  queryOrdersDetail: (
    params: {
      filter?: {
        billId?: string;
        endDate?: string;
        startDate?: string;
        stores?: string[];
        status?: RequireOrderStatusEnum;
      };
    } & IPageParams
  ) => post<IPageData<IDetail>>('/api/pcweb/business/require/queryOrdersDetail', params)
};

export default queryOrder;
