import request from '@/utils/request';
const { post } = request;

/**
 * 单据操作日志
 */
const customerExamine = {
  /** 查单据操作日志分页 */
  query: (
    params?: {
      isInit: boolean;
      filter?: {
        contactorKeyword?: string;
        keyword?: string;
        salesman?: string;
        status?: string;
      };
    } & IPageParams
  ) => post<IPageData<any>>('/api/pcweb/omall/customer/apply/query', params),
  /** 查单据操作日志分页 */
  //     {
  //     bankAccount: string;
  //     bosId: string;
  //     isSettleByNow: boolean;
  //     isSettleByPeriod: boolean;
  //     levelUuid: string;
  //     name: string;
  //     pid: string;
  //     salesman: string;
  //     storeId: string;
  //     transFee: string;
  //     transFeeTemp: string;
  //     uuid: string;
  //     verifyDate: string;
  //     verifyDays: string;
  //     version: number;
  //     wid: string;
  //   }
  approve: (params?: any) => post<any>('/api/pcweb/omall/customer/apply/approve', params),
  refuse: (params?: any) => post<any>('/api/pcweb/omall/customer/apply/refuse', params)
};

export default customerExamine;
