import request from '@/utils/request';
import { IMemberInfo, IQueryFilter, IMemberDetail, IQueryDetailFilter } from '@/dataModel/member/memberInfo/interface';
const { post, get } = request;

const memberInfo = {
  /**
   * 查询会员列表信息
   * @param params
   * @returns
   */
  query: (
    params: {
      filter?: IQueryFilter;
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => post<IPageData<IMemberInfo>>('/api/pcweb/member/info/query', params),
  /**
   * 查询会员详情
   * @param params
   * @returns
   */
  getDetail: (params: { uuid: string }) => post<IMemberDetail>('/api/pcweb/member/info/getDetail', params),
  /**
   * 冻结
   * @param params
   * @returns
   */
  freeze: (params: { uuid: string; remark: string }) => post<{ data: string }>('/api/pcweb/member/info/freeze', params),
  /**
   * 解冻
   * @param params
   * @returns
   */
  unFreeze: (params: { uuid: string; remark: string }) =>
    post<{ data: string }>('/api/pcweb/member/info/unfreeze', params),
  /**
  /**
   * 充值
   * @param params
   * @returns
   */
  deposit: (params: { uuid: string }) => post<{ data: any }>('/api/pcweb/member/info/deposit', params),
  /**
   * 查询交易流水
   * @param params
   * @returns
   */
  queryTrade: (
    params: {
      filter?: IQueryDetailFilter;
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => post<any>('/api/pcweb/member/info/queryTrade', params),
  /**
   * 查询储值明细
   * @param params
   * @returns
   */
  queryBalance: (
    params: {
      filter?: IQueryDetailFilter;
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => post<any>('/api/pcweb/member/info/queryBalance', params),
  /**
   * 查询积分明细
   * @param params
   * @returns
   */
  queryPoint: (
    params: {
      filter?: IQueryDetailFilter;
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => post<any>('/api/pcweb/member/info/queryPoint', params),
  /**
   * 查询优惠券明细
   * @param params
   * @returns
   */
  queryCoupon: (
    params: {
      filter?: {
        memberUuid: string;
        status: string;
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => post<any>('/api/pcweb/member/info/queryCoupon', params)
};
export default memberInfo;
