import request from '@/utils/request';
import { IMemberStoredSummary } from '@/dataModel/member/memberStoredSummary/interface';
const { post } = request;

const memberStoredSummary = {
  /**储值汇总 */
  queryMemberStoredSummary: (
    params: {
      filter?: {
        beginDate?: string; //开始时间
        endDate?: string; //结束时间
      };
    } & IPageParams
  ) => post<IPageData<IMemberStoredSummary>>('/api/pcweb/member/info/queryMemberStoredSummary', params),
  /**储值汇总合计 */
  queryMemberStoredSummaryTotal: (params: {
    filter?: {
      beginDate?: string; //开始时间
      endDate?: string; //结束时间
    };
  }) => post<any>('/api/pcweb/member/info/queryMemberStoredSummaryTotal', params)
};

export default memberStoredSummary;
