import { ChannelEnum, OnlineGoodsSearchTypeEnum } from '@/dataModel/dict/enum';
import {
  IMdataOnlineGoods,
  IMdataOnlineGoodsCategory,
  IMdataOnlineGoodsDetail,
  IMdataOnlineGoodsOnlineElemeCatPropertyResult,
  IMdataOnlineGoodsOnlineJdCatPropertyResult,
  IMdataOnlineGoodsOnlineMtCatPropertyResult,
  IMdataOnlineGoodsOnlineSpuGetResult,
  IMdataOnlineGoodsOnlineSpuModifyDto,
  IMdataOnlineGoodsQueryFilter,
  IMdatagetPlatGoods,
  IOnlineGoodsTipsCount,
  IMdataOnlineGoodsOnlinePlatformSpec,
  ISuggestMtPlatformCatalog
} from '@/dataModel/mdata/onlineGoods/interface';
import request from '@/utils/request';

const { post } = request;

const onlineGoods = {
  /**分页查询线上商品 */
  query: (
    params: {
      filter: IMdataOnlineGoodsQueryFilter;
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => post<IPageData<IMdataOnlineGoods>>('/api/pcweb/mdata/online/goods/query', params, {}, 'onlineGoods'),

  /**查询线上商品同步异常数量 必填信息缺失数量 */
  queryOnlineGoodsCount: (
    params: {
      filter: IMdataOnlineGoodsQueryFilter;
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => post<IOnlineGoodsTipsCount>('/api/pcweb/mdata/online/goods/getAbnormalAndRequiredCount', params, {}, 'onlineGoods'),

  /**添加商品按钮 -> 查询商品 */
  queryGoods: (
    params: {
      filter: {
        allChannel?: boolean;
        onlineChannels?: ChannelEnum[];
        onlineGroupId?: string;
        searchKey?: string;
        searchType?: OnlineGoodsSearchTypeEnum;
        storeIds?: string[];
        sortId?: string;
        /**平台key */
        channel?: ChannelEnum;
      };
    } & IPageParams
  ) => post<IPageData<IMdataOnlineGoods>>('/api/pcweb/mdata/online/goods/queryGoods', params, {}, 'onlineGoods'),
  /** 发布门店商品到线上平台 */
  pushStoreGoods: (params: {
    goodsIds: string[];
    goodsMunitUuidList: string[];
    onlineChannel: ChannelEnum;
    toStoreIdList: string[];
  }) => post<{ traceId: string }>('/api/pcweb/mdata/online/goods/pushStoreGoods', params, {}, 'onlineGoods'),

  /** 获取 */
  get: (params: { uuid: string }) => post<IMdataOnlineGoodsDetail>('/api/pcweb/mdata/online/goods/get', params, {}, 'onlineGoods'),

  /** 根据渠道类型获取线上商品类目 */
  getCategory: (params: { channel: ChannelEnum }) =>
    post<IMdataOnlineGoodsCategory[]>('/api/pcweb/mdata/online/goods/getCategory', params, {}, 'onlineGoods'),

  /** 获取饿了么商品类目规格 */
  getElemeCatProperty: (params: {
    /** 三级类目编码 */
    cat3Id: string;
    /** 门店编码 */
    sid: string;
  }) =>
    post<IMdataOnlineGoodsOnlineElemeCatPropertyResult[]>('/api/pcweb/mdata/online/goods/getElemeCatProperty', params, {}, 'onlineGoods'),

  /** 获取京东商品类目规格 */
  getJdCatProperty: (params: {
    /** 三级类目编码 */
    cat3Id: string;
  }) => post<IMdataOnlineGoodsOnlineJdCatPropertyResult[]>('/api/pcweb/mdata/online/goods/getJdCatProperty', params, {}, 'onlineGoods'),

  /** 获取美图商品类目规格 */
  getMtCatProperty: (params: {
    /** 三级类目编码 */
    cat3Id: string;
  }) => post<IMdataOnlineGoodsOnlineMtCatPropertyResult[]>('/api/pcweb/mdata/online/goods/getMtCatProperty', params, {}, 'onlineGoods'),

  /** 获取京东 饿了么 美团商品类目规格 */
  getPlatformCatProperty: (params: {
    /** 平台类型 */
    platform: ChannelEnum;
    /** 三级类目编码 */
    platformCatalogId: string;
  }) => post<IMdataOnlineGoodsOnlinePlatformSpec[]>('/api/pcweb/v2/mdata/online/attributes/get', params, {}, 'onlineGoods'),

  /** 获取美团推荐类目 */
  getSuggestMtPlatformCatalog: (params: {
    /** 商品名称 */
    name: string;
  }) => post<ISuggestMtPlatformCatalog>('/api/pcweb/v2/mdata/online/catalog/suggest', params, {}, 'onlineGoods'),

  /** 获得多规格信息 */
  getSpu: (params: { uuid: string }) =>
    post<IMdataOnlineGoodsOnlineSpuGetResult>('/api/pcweb/mdata/online/goods/getSpu', params, {}, 'onlineGoods'),

  /** 修改 */
  modify: (params: {
    goodsName: string;
    logoUrls: string[];
    marketPrice?: number;
    onlineGroupId: string;
    platCategoryId?: string;
    onlineRetailPrice: number;
    stock: number;
    /** 是否同步图片到商品档案 */
    syncLogo2Data?: boolean;
    /** 是否同步图片到饿了么 */
    syncLogo2Eleme?: boolean;
    /** 是否同步图片到京东 */
    syncLogo2Jd?: boolean;
    /** 是否同步图片到美团 */
    syncLogo2Mt?: boolean;
    /** 是否同步图片到微盟 */
    syncLogo2Wm?: boolean;
    uuid: string;
    version: number;
  }) => post<{ traceId: string }>('/api/pcweb/mdata/online/goods/modify', params, {}, 'onlineGoods'),

  /** 批量设置商品类目 */
  modifyCategory: (params: {
    /** 第三方平台商品类目id */
    platCategoryId: string;
    /** 商品的uuid */
    uuidList: string[];
  }) => post<{ traceId: string }>('/api/pcweb/mdata/online/goods/modifyCategory', params, {}, 'onlineGoods'),

  /** 批量设置商品类目 */
  modifySpu: (params: {
    /** 商品名称 */
    goodsName: string;
    logoUrls: string[];
    onlineGroupId: string;
    /** 多规格标识 */
    onlineSpu: string;
    /** 第三方平台商品类目id */
    platCategoryId: string;
    spuList: IMdataOnlineGoodsOnlineSpuModifyDto[];
    /**是否同步图片到商品档案 */
    syncLogo2Data?: boolean;
    /**是否同步图片到商品档案 */
    syncLogo2Eleme?: boolean;
    /**是否同步图片到商品档案 */
    syncLogo2Jd?: boolean;
    /**是否同步图片到商品档案 */
    syncLogo2Mt?: boolean;
    /**是否同步图片到商品档案 */
    syncLogo2Wm?: boolean;
  }) => post<{ traceId: string }>('/api/pcweb/mdata/online/goods/modifySpu', params, {}, 'onlineGoods'),

  /**修改线上商品的库存和价格 */
  modifyStock: (params: {
    /** 市场价 */
    marketPrice?: number;
    /** 线上零售价 */
    onlineRetailPrice?: number;
    /** 库存 */
    stock?: number;
    /** 标识 */
    uuid: string;
  }) => post<{ traceId: string }>('/api/pcweb/mdata/online/goods/modifyStock', params, {}, 'onlineGoods'),

  /** 一键拉取微盟商品 */
  pullOnlineGoods: (params: { filter: { channel: ChannelEnum; storeId?: string } }) =>
    post<{ traceId: string }>('/api/pcweb/mdata/online/goods/pullOnlineGoods', params, {}, 'onlineGoods'),

  /** 同步商品 */
  syncOnlineGoods: (params: { uuids: string[]; operateList: string[] }) =>
    post<{ traceId: string }>('/api/pcweb/mdata/online/goods/syncOnlineGoods', params, {}, 'onlineGoods'),

  /** 删除 */
  remove: (params: { uuid: string }) => post<{ traceId: string }>('/api/pcweb/mdata/online/goods/remove', params, {}, 'onlineGoods'),

  /** 批量删除 */
  batchRemove: (params: { uuids: string[] }) =>
    post<{ traceId: string }>('/api/pcweb/mdata/online/goods/remove/batch', params, {}, 'onlineGoods'),

  /** 批量设置分组 */
  saveInfo: (params: { onlineGroupId: string; uuids: string[] }) =>
    post<IMdataOnlineGoods>('/api/pcweb/mdata/online/goods/saveInfo', params, {}, 'onlineGoods'),
  /** 批量上下架 */
  updown: (params: { onlineGoodsStatus: string; uuids: string[] }) =>
    post<{ traceId: string }>('/api/pcweb/mdata/online/goods/updown', params, {}, 'onlineGoods'),

  /** 获取线上各平台商品类目 */
  getCategoryMap: () =>
    post<{
      elemeCategoryList: IMdataOnlineGoodsCategory[];
      jddjCategoryList: IMdataOnlineGoodsCategory[];
      meituanCategoryList: IMdataOnlineGoodsCategory[];
    }>('/api/pcweb/mdata/online/goods/getCategoryMap',{}, {}, 'onlineGoods'),
  /** 解绑 */
  unBanding: (params: { uuid: string }) => post<{ traceId: string }>('/api/pcweb/mdata/online/goods/unBanding', params, {}, 'onlineGoods'),
  /** 绑定 */
  banding: (params: { uuid: string; platSkuId: string }) =>
    post<{ traceId: string }>('/api/pcweb/mdata/online/goods/banding', params, {}, 'onlineGoods'),
  /**查询平台商品 */
  getPlatGoods: (params: {
    /**饿了么的商品自定义ID */
    customSkuId?: string;
    /**饿了么平台商品条码 */
    platBarcode?: string;
    /**平台商品标识(美团商品店内码/货号或者饿了么商品API专用ID) */
    platGoodsId?: string;
    uuid: string;
  }) => post<IMdatagetPlatGoods>('/api/pcweb/mdata/online/goods/getPlatGoods', params, {}, 'onlineGoods')
};

export default onlineGoods;
