import { StoreProcessTypeEnum } from '@/dataModel/inv/storeProcess/enum';
// import {
//   IParamsProducts,
//   IParamsRaws,
//   //IWrhProcessDetail,
//   IStoreProcessQueryList
// } from '@/dataModel/inv/storeProcess/interface';
import {
  IWrhProcessQueryList,
  IWrhProcessDetail,
  IParamsRaws,
  IParamsProducts
} from '@/dataModel/wrh/process/interface';
import request from '@/utils/request';
const { post } = request;

const wrhProcess = {
  /**
   * 查询
   */
  query: (
    params: {
      filter: {
        endDate?: string;
        goodsKeyword?: string;
        id?: string;
        recipeKeyword?: string;
        recipeType?: StoreProcessTypeEnum;
        startDate?: string;
        status?: string;
        settleNo?: number;
        creator?: string;
      };
    } & IPageParams
  ) => post<IPageData<IWrhProcessQueryList>>('/api/pcweb/inv/wrh/process/query', params),

  /**
   * 添加
   */
  create: (params: {
    filter: {
      products: IParamsProducts[];
      raws: IParamsRaws[];
      recipe: number;
      remark: string;
    };
  }) => post<{ traceId: string; uuid: string }>('/api/pcweb/inv/wrh/process/create', params),

  /**
   * 编辑
   */
  modify: (params: {
    filter: {
      products: IParamsProducts[];
      raws: IParamsRaws[];
      recipe: number;
      remark: string;
      uuid: string;
      version: number;
    };
  }) => post<{ traceId: string; uuid: string }>('/api/pcweb/inv/wrh/process/modify', params),

  /**
   * 查看信息
   */
  get: (params: { id: string; uuid: string }) => post<IWrhProcessDetail>('/api/pcweb/inv/wrh/process/get', params),

  /**
   * 删除
   */
  remove: (params: { uuid: string }) => post<{ traceId: string }>('/api/pcweb/inv/wrh/process/remove', params),

  /**
   * 审核
   */
  approve: (params: { uuid: string; version: number }) =>
    post<{ traceId: string }>('/api/pcweb/inv/wrh/process/approve', params)
};
export default wrhProcess;
