import request from '@/utils/request';
import { IHolderAuthCode, IHolderAuthCodeCount } from '@/dataModel/equipment/holderAuthCode/interface';
import { HolderAuthCodeStatusEnum } from '@/dataModel/equipment/holder/enum';

export default {
  /**
   * 分页查询手持设备
   * @param params
   * @returns
   */
  query: (
    params?: {
      filter?: {
        deviceSn?: string; // 设备 SN 号
        status?: HolderAuthCodeStatusEnum; // 激活状态
        storeId?: string; // 门店 Id
      };
    } & IPageParams
  ) => request.post<IPageData<IHolderAuthCode>>('/api/pcweb/equipment/holderAuthCode/query', params),

  /**
   * 查询详情
   * @param params
   * @returns
   */
  getDetail: (params: { uuid: string }) =>
    request.post<IHolderAuthCode>('/api/pcweb/equipment/holderAuthCode/get', params),

  /**
   * 删除手持设备授权码
   * @param params
   * @returns
   */
  delete: (params: { uuid: string }) => request.post('/api/pcweb/equipment/holderAuthCode/delete', params),

  /**
   * 查询统计信息
   */
  getCount: () => request.post<IHolderAuthCodeCount>('/api/pcweb/equipment/holderAuthCode/getCount')
};
