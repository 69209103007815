import {
  IMgoodsShelvesQueryItem,
  IStoreShelvesQueryFllter,
  IMnogoodsBindQueryItem
} from '@/dataModel/mdata/storeShelves/interface';
import request from '@/utils/request';

export default {
  /** 货架位列表分页查询 */
  query: (params: { filter: Partial<IStoreShelvesQueryFllter> }) =>
    request.post<IPageData<IMgoodsShelvesQueryItem>>('/api/pcweb/mdata/store/shelves/query', params),
  /** 查询货架未绑定商品 */
  queryGoods: (params: { filter: Partial<{ searchKey?: string; sortId: string }>; shelves: string }) =>
    request.post<IPageData<IMnogoodsBindQueryItem>>('/api/pcweb/mdata/store/shelves/queryGoods', params),
  /**批量新建门店货架位 */
  saveBatch: (params: { fullIds: string[] }) =>
    request.post<IPageData<IMnogoodsBindQueryItem>>('/api/pcweb/mdata/store/shelves/saveBatch', params),
  /**绑定货架位商品 */
  bindGoods: (params: { goodsIds: string[]; shelves: string }) =>
    request.post<IPageData<IMnogoodsBindQueryItem>>('/api/pcweb/mdata/store/shelves/bindGoods', params),
  /**解绑货架位商品 */
  relieveGoods: (params: { goodsIds: string[]; shelves: string }) =>
    request.post<IPageData<IMnogoodsBindQueryItem>>('/api/pcweb/mdata/store/shelves/relieveGoods', params),
  /**批量删除货架位 */
  removeBatch: (params: { shelvesUuids: string[] }) =>
    request.post<IPageData<IMnogoodsBindQueryItem>>('/api/pcweb/mdata/store/shelves/removeBatch', params),
  /**货架位商品查询 */
  queryDetail: (params: { filter: Partial<{ searchKey?: string; sortId?: string }>; shelves: string }) =>
    request.post<IPageData<IMnogoodsBindQueryItem>>('/api/pcweb/mdata/store/shelves/queryDetail', params)
};
