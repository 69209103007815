import {
  IWrhTransferQueryBill,
  IWrhTransferQueryGoods,
  IWrhTransferGetDetails,
  IWrhTransferGet,
  IWrhTransferBatchSubmit
} from '@/dataModel/business/wrhTransfer/interface';
import request from '@/utils/request';
const { post } = request;

const wrhTransfer = {
  /**
   * 分页查询仓库调拨表单
   */
  queryBill: (
    params: {
      filter: {
        createStartTime?: string;
        createEndTime?: string;
        reason?: string;
        status?: string;
        goodsInfo?: string;
      };
    } & IPageParams
  ) => post<IPageData<IWrhTransferQueryBill>>('/api/pcweb/wrh/transfer/queryBill', params),
  /**
   * 分页查询仓库调拨添加商品
   */
  queryGoods: (
    params?: {
      wrh: string;
      filter?: {
        searchKey?: string;
        sortId?: string;
      };
    } & IPageParams
  ) => post<IPageData<IWrhTransferQueryGoods>>('/api/pcweb/wrh/transfer/queryGoods', params),
  /**
   * 保存仓库调拨单
   */
  save: (params: {
    version?: number;
    uuid?: string;
    targetStatus: string;
    storeId?: string;
    details: Array<any>;
    info: {
      reason: string;
      receiver: string;
      remark: string;
      sender: string;
    };
  }) => post<any>('/api/pcweb/wrh/transfer/save', params),
  /**
   * 获取明细列表
   */
  getDetails: (params: { uuid: string }) =>
    post<IWrhTransferGetDetails[]>('/api/pcweb/wrh/transfer/getDetails', params),
  /**
   * 获取单据
   */
  get: (params: { uuid: string }) => post<IWrhTransferGet>('/api/pcweb/wrh/transfer/get', params),
  /**
   *  删除
   */
  remove: (params: { uuid: string }) => post<any>('/api/pcweb/wrh/transfer/remove', params),
  /**
   *  提交
   */
  submit: (params: { uuid: string; version: number }) => post<any>('/api/pcweb/wrh/transfer/submit', params),
  /**
   * 总部作废仓库调拨单
   */
  aborted: (params: { uuid: string; version: number; targetStatus: string }) =>
    post<any>('/api/pcweb/wrh/transfer/aborted', params),
  /**
   *  批量删除
   */
  batchRemove: (params: { uuids: string[] }) =>
    post<{ failedCount: number; successCount: number }>('/api/pcweb/wrh/transfer/batchRemove', params),
  /**
   *  批量提交
   */
  batchSubmit: (params: { infos: Array<IWrhTransferBatchSubmit> }) =>
    post<any>('/api/pcweb/wrh/transfer/batchSubmit', params),
  /**
   * 分页查询仓库调拨表单明细
   */
  queryDetail: (
    params: {
      bill: string;
      filter?: {
        goodsKeyWord?: string;
      };
    } & IPageParams
  ) => post<IPageData<IWrhTransferGetDetails>>('/api/pcweb/wrh/transfer/queryDetail', params),
  getGoodsByIoTask: (params: { senderUuid: string; taskUuid: string }) =>
    post<any>('/api/pcweb/wrh/transfer/getGoodsByIoTask', params)
};
export default wrhTransfer;
