import { SupplierBillTypeEnum } from '@/dataModel/dict/enum';
import { ICheckSupplier } from '@/dataModel/finance/checkSupplier/interface';
import request from '@/utils/request';

export default {
  checkBatch: (params: {
    /**
     * 核对订单列表
     */
    checkList: {
      /**
       * 订单类型
       */
      billType: SupplierBillTypeEnum;
      /**
       * 标识
       */
      uuid: string;
    }[];
  }) => request.post('/api/pcweb/finance/check/supplier/checkBatch', params),
  query: (
    params: {
      /**
       * 核对订单列表
       */
      filter: {
        /**
         * 单据类型
         */
        billType?: SupplierBillTypeEnum;
        /**
         * 制单结束时间
         */
        endCreateTime?: string;
        /**
         * 单号
         */
        id?: string;
        /**
         * 是否已经核对
         */
        isChecked: boolean;
        /**
         * 期号
         */
        settleNo?: string;
        /**
         * 制单开始时间
         */
        startCreateTime?: string;
        /**
         * 门店/仓库编码名称
         */
        wrhInfo?: string;
      };
    } & IPageParams
  ) => request.post<IPageData<ICheckSupplier>>('/api/pcweb/finance/check/supplier/query', params)
};
