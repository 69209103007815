import { PrintingBillClassEnum } from '@/dataModel/dict/enum';
import { IPrinting } from '@/dataModel/system/printing/interface';
import request from '@/utils/request';

export default {
  recover: (params: { billType: string }) => request.post('/api/pcweb/system/printing/recover', params),

  modify: (params: { billType: PrintingBillClassEnum; name: string; url: string }) =>
    request.post('/api/pcweb/system/printing/modify', params),

  query: (
    params: {
      filter?: {
        billName?: string;
        templateName?: string;
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => request.post<IPageData<IPrinting>>('/api/pcweb/system/printing/query', params)
};
