import request from '@/utils/request';
import {
  QueryMakeUpDifferenceListRes,
  GetDetailRes,
  DiffGoodRes,
  SaveReq
} from '@/dataModel/purchase/makeUpDifference/interface';
const { post } = request;

interface SaveLineRes {
  currentPurchaseTotal: number;
}
const makeUpDifference = {
  /** 补差协议列表*/
  queryMakeUpDifferenceList: (
    params: {
      filter: {
        createdEndDate?: string;
        createdStartDate?: string;
        goodsKeyword?: string;
        id?: string;
        orgList?: string[];
        statusList?: string[];
        subdiffEndDate?: string;
        subdiffStartDate?: string;
        supplierUuid?: string;
      };
    } & IPageParams
  ) => post<IPageData<QueryMakeUpDifferenceListRes>>('/api/pcweb/finance/supplier/subdiff/agreement/query', params),
  /** 组织列表*/
  queryOrgList: (params: Record<string, unknown>) =>
    post<
      {
        label: string;
        id: string;
        name: string;
        uuid: string;
      }[]
    >('/api/pcweb/finance/supplier/subdiff/agreement/orgList', params),
  /** 供应商列表*/
  querySupplierList: (params: Record<string, unknown>) =>
    post<{ id: string; name: string; uuid: string }[]>(
      '/api/pcweb/finance/supplier/subdiff/agreement/supplierList',
      params
    ),
  /** 补差协议详情*/
  getDetail: (params: { id?: string; uuid?: string }) =>
    post<GetDetailRes>('/api/pcweb/finance/supplier/subdiff/agreement/get', params),
  /** 中止补差协议*/
  goodsAbort: (params: { agreementGoodsUuids: string[]; uuid: string }) =>
    post<GetDetailRes>('/api/pcweb/finance/supplier/subdiff/agreement/goodsAbort', params),
  /** 补差协议详情*/
  getGoodsList: (
    params: {
      bill: string;
      filter: {
        goodsIds?: string[];
        keyword?: string;
      };
    } & IPageParams
  ) => post<IPageData<DiffGoodRes>>('/api/pcweb/finance/supplier/subdiff/agreement/queryDetail', params),
  /** 补差协议详情*/
  queryGoods: (
    params: {
      filter?: {
        keyword?: string;
        sortId?: string;
      };
      supplier: string;
    } & IPageParams
  ) => post<IPageData<DiffGoodRes>>('/api/pcweb/finance/supplier/subdiff/agreement/queryGoods', params),
  /** 保存*/
  save: (params: SaveReq) => post<any>('/api/pcweb/finance/supplier/subdiff/agreement/save', params),
  /** 删除*/
  remove: (params: { uuid: string }) => post<any>('/api/pcweb/finance/supplier/subdiff/agreement/remove', params),
  /** 修改状态*/
  modifyStatus: (params: { targetStatus: string; uuid: string; version: number }) =>
    post<any>('/api/pcweb/finance/supplier/subdiff/agreement/modifyStatus', params),
  /** 导入商品*/
  getGoodsByIoTask: (params: { taskUuid: string }) =>
    post<any>('/api/pcweb/finance/supplier/subdiff/agreement/getGoodsByIoTask', params)
};

export default makeUpDifference;
