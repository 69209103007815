import request from '@/utils/request';
import { IDeptQuery, IAccountdeptQuery } from '@/dataModel/mdata/dept/interface';
const { post } = request;

const dept = {
  /** 分页查询客户档案列表 */
  query: (
    params?: {
      /** 部门名称/编码 */
      searchKey?: string;
      /** 关联分类 */
      sortId?: string;
    } & IPageParams
  ) => post<IPageData<IDeptQuery>>('/api/pcweb/mdata/dept/query', params),

  /** 获取部门编码 */
  getGenerateId: (params?: {
    /** 上级部门id */
    parentId: string;
    /** 部门层级 */
    level: number;
  }) => post<{ id: string; traceId: string }>('/api/pcweb/mdata/dept/generateId', params),

  /** 新增部门 */
  create: (params?: {
    /** 部门编码 */
    id: string;
    /** 编码名称 */
    name: string;
    nuid: string;
    /** 上级部门id */
    parentId: string;
    /** 部门层级 */
    level: number;
  }) => post('/api/pcweb/mdata/dept/create', params),

  /** 修改部门名称 */
  modify: (params: {
    /** 修改后名称 */
    name: string;
    /** 标识 */
    uuid: string;
    /** 版本号 */
    version: number;
  }) => post('/api/pcweb/mdata/dept/modify', params),

  /** 删除部门 */
  remove: (params: { uuid: string }) => post('/api/pcweb/mdata/dept/remove', params),

  /** 查询商品类别 分页查询 */
  querySort: (
    params: {
      filter: {
        /** 传 false */
        isSort: boolean;
        /** 分类 id */
        sortId?: string;
        /** 部门唯一标识 */
        uuid: string;
      };
    } & IPageParams
  ) => post<IPageData<{ id: string; name: string }[]>>('/api/pcweb/mdata/dept/queryAllSort', params),

  /** 关联部门分类 */
  bindSort: (params: {
    /** 标识 */
    uuid: string;
    /** 分类集合 */
    sortIdList: string[];
  }) =>
    post<{ reason: string; result: string; traceId: string; uuid: string }>('/api/pcweb/mdata/dept/bindSort', params),
  /** 分页查询核算部门列表 */
  accountdeptQuery: (
    params?: {
      /** 部门名称/编码 */
      searchKey?: string;
      /** 关联分类 */
      sortId?: string;
    } & IPageParams
  ) => post<IPageData<IAccountdeptQuery>>('/api/pcweb/mdata/accountdept/query', params),
  /** 新增核算部门 */
  accountdeptCreate: (params?: {
    /** 部门编码 */
    id: string;
    /** 编码名称 */
    name: string;
    nuid: string;
    /** 上级部门id */
    parentId: string;
    /** 部门层级 */
    level: number;
  }) => post('/api/pcweb/mdata/accountdept/create', params),
  /** 获取部门编码 */
  accountdeptGetGenerateId: (params?: {
    /** 上级部门id */
    parentId: string;
    /** 部门层级 */
    level: number;
  }) => post<{ id: string; traceId: string }>('/api/pcweb/mdata/accountdept/generateId', params),
  /** 修改部门名称 */
  accountdeptModify: (params: {
    /** 修改后名称 */
    name: string;
    /** 标识 */
    uuid: string;
    /** 版本号 */
    version: number;
  }) => post('/api/pcweb/mdata/accountdept/modify', params),
  /** 删除部门 */
  accountdeptRemove: (params: { uuid: string }) => post('/api/pcweb/mdata/accountdept/remove', params)
};

export default dept;
