import request from '@/utils/request';
import {
  IReportStoreSummary,
  IReportStoreSummaryTotal,
  IReportStoreSummaryGeneralSort,
  IReportStoreSummaryMediumSort,
  IReportStoreSummarySubSort,
  IReportStoreSummaryGoods,
  IReportSummaryStoreClassify
} from '@/dataModel/finance/reportSummaryStore/interface';
const { post } = request;

export default {
  /**查询进销存汇总报合计 废弃 */
  queryTotalByStore: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        goodsId?: string; //商品编码
        storeId?: string; //门店编码
        goodsBarcode?: string; //商品条码
        goodsName?: string; //商品名称
        sortId?: string; //商品分类
        searchKey?: string; //模糊查询
      };
    } & IPageParams
  ) => post<IReportStoreSummaryTotal>('/api/pcweb/finance/report/summary/store/queryTotalByStore', params),
  /**按门店查询进销存汇总报 */
  queryByStore: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        storeId?: string; //门店Id
      };
    } & IPageParams
  ) => post<IPageData<IReportStoreSummary>>('/api/pcweb/finance/report/summary/store/queryByStore', params),
  /**按门店查询进销存汇总报2.0接口 */
  queryByStoreNew: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        isStore?: boolean; //是否为门店
        isSummary?: boolean; // 是否统计
        wrhIdList?: Array<[]>; //门店或仓库编码
      };
    } & IPageParams
  ) => post<IPageData<IReportStoreSummary>>('/api/pcweb/finance/report/summary/store/queryByStoreNew', params),
  /**按门店查询进销存汇总报合计 */
  queryByStoreTotal: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        storeId?: string; //门店Id
      };
    } & IPageParams
  ) => post<IReportStoreSummaryTotal>('/api/pcweb/finance/report/summary/store/queryByStoreTotal', params),
  /**按商品分类大类查询 */
  queryByGeneralSort: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        storeId?: string; //门店编码
        sortId?: string; //商品分类
      };
    } & IPageParams
  ) =>
    post<IPageData<IReportStoreSummaryGeneralSort>>(
      '/api/pcweb/finance/report/summary/store/queryByGeneralSort',
      params
    ),
  /**按商品分类大类查询合计 废弃 */
  queryByGeneralSortTotal: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        storeId?: string; //门店编码
        sortId?: string; //商品分类
      };
    } & IPageParams
  ) => post<IReportStoreSummaryTotal>('/api/pcweb/finance/report/summary/store/queryByGeneralSortTotal', params),
  /**按商品分类中类查询 */
  queryByMediumSort: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        storeId?: string; //门店编码
        sortId?: string; //商品分类
      };
    } & IPageParams
  ) =>
    post<IPageData<IReportStoreSummaryMediumSort>>('/api/pcweb/finance/report/summary/store/queryByMediumSort', params),
  /**按商品分类中类查询合计 */
  queryByMediumSortTotal: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        storeId?: string; //门店编码
        sortId?: string; //商品分类
      };
    } & IPageParams
  ) => post<IReportStoreSummaryTotal>('/api/pcweb/finance/report/summary/store/queryByMediumSortTotal', params),
  /**按商品分类小类查询 */
  queryBySubSort: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        storeId?: string; //门店编码
        sortId?: string; //商品分类
      };
    } & IPageParams
  ) => post<IPageData<IReportStoreSummarySubSort>>('/api/pcweb/finance/report/summary/store/queryBySubSort', params),
  /**按商品分类小类查询合计 */
  queryBySubSortTotal: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        storeId?: string; //门店编码
        sortId?: string; //商品分类
      };
    } & IPageParams
  ) => post<IReportStoreSummaryTotal>('/api/pcweb/finance/report/summary/store/queryBySubSortTotal', params),
  /**按商品查询 */
  queryByGoodsId: (
    params: {
      createEndTime?: string; //结束时间
      createStartTime?: string; //开始时间
      goodsId?: string; //商品编码
      storeId?: string; //门店编码
      goodsBarcode?: string; //商品条码
      goodsName?: string; //商品名称
      sortId?: string; //商品分类
      searchKey?: string; //模糊查询
    } & IPageParams
  ) => post<IPageData<IReportStoreSummaryGoods>>('/api/pcweb/finance/report/summary/store/queryByGoodsId', params),
  /**按商品查询合计 */
  queryByGoodsIdTotal: (
    params: {
      createEndTime?: string; //结束时间
      createStartTime?: string; //开始时间
      goodsId?: string; //商品编码
      storeId?: string; //门店编码
      goodsBarcode?: string; //商品条码
      goodsName?: string; //商品名称
      sortId?: string; //商品分类
      searchKey?: string; //模糊查询
    } & IPageParams
  ) => post<IReportStoreSummaryTotal>('/api/pcweb/finance/report/summary/store/queryByGoodsIdTotal', params),

  /***
   *
   * 进销存2.0接口
   *
   *
   **/
  /* 按照门店查询合计 */
  getTotalByStoreNew: (
    parmas: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        isStore?: boolean; //是否为门店
        isSummary?: boolean; // 是否统计
        wrhIdList?: Array<[]>; //门店或仓库编码
      };
    } & IPageParams
  ) => post<IReportStoreSummaryTotal>('/api/pcweb/finance/report/summary/store/getTotalByStore', parmas),
  /* 分类查询 */
  queryBySort: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        isStore?: boolean; //是否为门店
        isSummary?: boolean; //是否统计
        sortIdList?: Array<[]>; //类别集合
        sortType?: Array<[]>; //类别维度
        wrhIdList?: Array<[]>;
      };
    } & IPageParams
  ) => post<IPageData<IReportSummaryStoreClassify>>('/api/pcweb/finance/report/summary/store/queryBySort', params),
  /* 分类查询合计 */
  getTotalBySort: (
    param: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        isStore?: boolean; //是否为门店
        isSummary?: boolean; //是否统计
        sortIdList?: Array<[]>; //类别集合
        sortType?: Array<[]>; //类别维度
        wrhIdList?: Array<[]>;
      };
    } & IPageParams
  ) => post<IReportSummaryStoreClassify>('/api/pcweb/finance/report/summary/store/getTotalBySort', param),

  /**
   *  按照商品查询
   */
  queryByGoods: (
    param: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        isStore?: boolean; //是否为门店
        isSummary?: boolean; //是否统计
        sortIdList?: Array<[]>; //类别集合
        sortType?: Array<[]>; //类别维度
        wrhIdList?: Array<[]>;
      };
    } & IPageParams
  ) => post<IPageData<IReportSummaryStoreClassify>>('/api/pcweb/finance/report/summary/store/queryByGoods', param),
  /**
   *  按照商品查询合计
   */
  getTotalByGoods: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        isStore?: boolean; //是否为门店
        isSummary?: boolean; //是否统计
        sortIdList?: Array<[]>; //类别集合
        sortType?: Array<[]>; //类别维度
        wrhIdList?: Array<[]>;
      };
    } & IPageParams
  ) => post<IReportSummaryStoreClassify>('/api/pcweb/finance/report/summary/store/getTotalByGoods', params)
};
