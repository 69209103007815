import {
  BusinessModelEnum,
  EffectInvBillClassEnum,
  StoreGainStatusEnum,
  SupplierTypeEnum
} from '@/dataModel/dict/enum';
import {
  IBusinessInv,
  IFinanceInv,
  IInvBatch,
  IInvBatchTotal,
  IInvFlow,
  IInvStock,
  IInvStockTotal,
  IInvLockDetail
} from '@/dataModel/inv/query/interface';
import { StoreProcessTypeEnum } from '@/dataModel/inv/storeProcess/enum';
import request from '@/utils/request';
const { post } = request;

const checkTask = {
  /**
   * 查询总部or门店业务库存  废弃
   */
  businessInv: (
    params: {
      filter?: {
        /**
         * 商品编码/条码/名称
         */
        goodsInfo: string;
        /**
         * 分类id
         */
        sortId: string;
        /**
         * 供应商uuid
         */
        supplier: string;
        /**
         * 仓库/门店id
         */
        wrhId: string;
        /**库存数量最小值 */
        minTotalQty: number;
        /**库存数量最大值 */
        maxTotalQty: number;
      };
      /**排序 */
      orderBy?: {
        /**排序方向 */
        direction: string;
        /**字段 */
        field: string;
      };
      /**
       * 是否查询门店商品,false:查询仓库商品
       */
      isStore: boolean;
    } & IPageParams
  ) => post<IPageData<IBusinessInv>>('/api/pcweb/inv/query/businessInv', params),
  /**查询门店or总部业务库存合计 废弃 */
  businessInvCount: (
    params: {
      filter?: {
        /**
         * 商品编码/条码/名称
         */
        goodsInfo: string;
        /**
         * 分类id
         */
        sortId: string;
        /**
         * 供应商uuid
         */
        supplier: string;
        /**
         * 仓库/门店id
         */
        wrhId: string;
        /**库存数量最小值 */
        minTotalQty: number;
        /**库存数量最大值 */
        maxTotalQty: number;
      };
      /**
       * 是否查询门店商品,false:查询仓库商品
       */
      isStore: boolean;
    } & IPageParams
  ) => post<IPageData<IBusinessInv>>('/api/pcweb/inv/query/businessInvCount', params),

  /**
   * 查询总部or门店库存
   */
  queryInvStock: (
    params: {
      filter?: {
        /**经营方式 */
        businessModel: BusinessModelEnum;
        /**商品编码/条码/名称 */
        goodsKeyword: string;
        /**库存数量最小值 */
        minQty: number;
        /**库存数量最大值 */
        maxQty: number;
        /**数值范围 */
        rangeModel: string;
        /**分类id */
        sortIds: string[];
        /**首选供应供应商uuid */
        supplierIds: string[];
        /**仓库uuid */
        wrhs: string[];
        /**门店uuid */
        storeList: string[];
      };
      /**
       * 是否查询总部库存,false:查询门店库存
       */
      isWrh: boolean;
      /**排序 */
      orderBy?: {
        /**排序方向 */
        direction: string;
        /**字段 */
        field: string;
      };
    } & IPageParams
  ) => post<IPageData<IInvStock>>('/api/pcweb/inv/query/query', params),
  /**
   * 查询门店or总部库存合计
   */
  queryInvStockTotal: (
    params: {
      filter?: {
        /**经营方式 */
        businessModel: BusinessModelEnum;
        /**商品编码/条码/名称 */
        goodsKeyword: string;
        /**库存数量最小值 */
        minQty: number;
        /**库存数量最大值 */
        maxQty: number;
        /**数值范围 */
        rangeModel: string;
        /**分类id */
        sortIds: string[];
        /**首选供应供应商uuid */
        supplierIds: string[];
        /**仓库uuid */
        wrhs: string[];
        /**门店uuid */
        storeList: string[];
      };
      /**
       * 是否查询总部库存,false:查询门店库存
       */
      isWrh: boolean;
    } & IPageParams
  ) => post<IPageData<IInvStockTotal>>('/api/pcweb/inv/query/querySum', params),
  /**
   * 查询批次库存
   */
  queryInvBatch: (
    params: {
      filter?: {
        /**经营方式 */
        businessModel: BusinessModelEnum;
        /**商品编码/条码/名称 */
        goodsKeyword: string;
        /**批次 */
        batchId: string;
        /**批次号 */
        batchNo: string;
        /**到效期截止 */
        validDateStart: string;
        /**到效期起始 */
        validDateEnd: string;
        /**分类id */
        sortIds: string[];
        /**首选供应供应商uuid */
        supplierIds: string[];
        /**仓库uuid */
        wrhs: string[];
        /**门店uuid */
        storeList: string[];
      };
      /**排序 */
      orderBy?: {
        /**排序方向 */
        direction: string;
        /**字段 */
        field: string;
      };
    } & IPageParams
  ) => post<IPageData<IInvBatch>>('/api/pcweb/inv/query/queryBatch', params),
  /**
   * 查询效期库存
   */
  queryValidDateInv: (
    params: {
      filter?: {
        /**经营方式 */
        businessModel: BusinessModelEnum;
        /**商品编码/条码/名称 */
        goodsKeyword: string;
        // /**批次 */
        // batchId: string;
        /**批次号 */
        batchNo: string;
        /**到效期截止 */
        validDateStart: string;
        /**到效期起始 */
        validDateEnd: string;
        /**分类id */
        sortIds: string[];
        /**首选供应供应商uuid */
        supplierIds: string[];
        /**仓库uuid */
        wrhs: string[];
        /**门店uuid */
        stores: string[];
        /**门店uuid */
        goodsStatusList: string[];
      };
      /**排序 */
      orderBy?: {
        /**排序方向 */
        direction: string;
        /**字段 */
        field: string;
      };
    } & IPageParams
  ) => post<IPageData<IInvBatch>>('/api/pcweb/inv/query/queryValidDateInv', params),
  /**
   * 查询批次库存合计
   */
  queryInvBatchTotal: (
    params: {
      filter?: {
        /**经营方式 */
        businessModel: BusinessModelEnum;
        /**商品编码/条码/名称 */
        goodsKeyword: string;
        /**批次 */
        batchId: string;
        /**批次号 */
        batchNo: string;
        /**到效期截止 */
        validDateStart: string;
        /**到效期起始 */
        validDateEnd: string;
        /**分类id */
        sortIds: string[];
        /**首选供应供应商uuid */
        supplierIds: string[];
        /**仓库uuid */
        wrhs: string[];
        /**门店uuid */
        storeList: string[];
      };
    } & IPageParams
  ) => post<IPageData<IInvBatchTotal>>('/api/pcweb/inv/query/queryBatchSum', params),
  /**
   * 查询效期库存合计
   */
  getVaildDateInvSum: (
    params: {
      filter?: {
        /**经营方式 */
        businessModel: BusinessModelEnum;
        /**商品编码/条码/名称 */
        goodsKeyword: string;
        // /**批次 */
        // batchId: string;
        /**批次号 */
        batchNo: string;
        /**到效期截止 */
        validDateStart: string;
        /**到效期起始 */
        validDateEnd: string;
        /**分类id */
        sortIds: string[];
        /**首选供应供应商uuid */
        supplierIds: string[];
        /**仓库uuid */
        wrhs: string[];
        /**门店uuid */
        stores: string[];
      };
    } & IPageParams
  ) => post<IPageData<IInvBatchTotal>>('/api/pcweb/inv/query/getValidDateInvSum', params),

  /**
   * 查询批次库存合计
   */
  getSourceBillInfo: (params: {
    /**原单据标识 uuid */
    bill: string;
    /**单据类型 */
    billClass: EffectInvBillClassEnum;
  }) =>
    post<{
      /**单据状态 */
      status: {
        id: StoreGainStatusEnum;
        name: string;
      };
      /**仓库加工单-加工类型 */
      recipeType: {
        id: StoreProcessTypeEnum;
        name: string;
      };
      /**是否线上订单 ture 是 */
      isOnlineOrder: boolean;
    }>('/api/pcweb/inv/query/getSourceBillInfo', params),

  /**
   * 查询库存流水总部or门店
   */
  invFlow: (
    params: {
      filter?: {
        /**
         * 截止时间
         */
        endTime: string;
        /**
         * 商品编码/条码/名称
         */
        goodsInfo: string;
        /**
         * 开始时间
         */
        startTime: string;
        /**
         * 分类
         */
        sortId: string;
        /**
         * 业务类型
         */
        sourceBillClass: string;
        /**
         * 业务单号
         */
        sourceBillId: string;
        /**
         * 仓库/门店id
         */
        wrhId: string;
        /**经营方式 */
        businessModel: BusinessModelEnum;
      };
      /**
       * 是否查询门店商品,false:查询仓库商品
       */
      isStore: boolean;
    } & IPageParams
  ) => post<any>('/api/pcweb/inv/query/invFlow', params),
  /**
   * 查询财务库存
   */
  financeInv: (
    params: {
      filter?: {
        /**
         * 商品编码/条码/名称
         */
        goodsInfo: string;
        /**
         * 分类id
         */
        sortId: string;
        /**
         * 供应商uuid
         */
        supplier: string;
        /**
         * 仓库/门店id
         */
        wrhId: string;
        /**库存数量最小值 */
        minTotalQty: number;
        /**库存数量最大值 */
        maxTotalQty: number;
      };
      /**排序 */
      orderBy?: {
        /**排序方向 */
        direction: string;
        /**字段 */
        field: string;
      };
      /**
       * 是否查询门店商品,false:查询仓库商品
       */
      isStore: true;
    } & IPageParams
  ) => post<IPageData<IFinanceInv>>('/api/pcweb/inv/query/financeInv', params),
  /**查询门店or总部财务库存合计 */
  /**
   * 查询财务库存
   */
  financeInvCount: (
    params: {
      filter?: {
        /**
         * 商品编码/条码/名称
         */
        goodsInfo: string;
        /**
         * 分类id
         */
        sortId: string;
        /**
         * 供应商uuid
         */
        supplier: string;
        /**
         * 仓库/门店id
         */
        wrhId: string;
        /**库存数量最小值 */
        minTotalQty: number;
        /**库存数量最大值 */
        maxTotalQty: number;
      };
      /**
       * 是否查询门店商品,false:查询仓库商品
       */
      isStore: true;
    } & IPageParams
  ) => post<IPageData<IFinanceInv>>('/api/pcweb/inv/query/financeInvCount', params),
  /**
   * 门店库存流水
   */

  /**
   *查询合计
   */
  invFlowCount: (
    params: {
      filter?: {
        /**
         * 截止时间
         */
        endTime: string;
        /**
         * 商品编码/条码/名称
         */
        goodsInfo: string;
        /**
         * 开始时间
         */
        startTime: string;
        /**
         * 分类
         */
        sortId: string;
        /**
         * 业务类型
         */
        sourceBillClass: string;
        /**
         * 业务单号
         */
        sourceBillId: string;
        /**
         * 仓库/门店id
         */
        wrhId: string;
        /**经营方式 */
        businessModel: BusinessModelEnum;
      };
      /**
       * 是否查询门店商品,false:查询仓库商品
       */
      isStore: boolean;
    } & IPageParams
  ) => post<IPageData<IInvFlow>>('/api/pcweb/inv/query/invFlowCount', params),
  /* 获取占用库存明细 */
  getLockDetails: (params: {
    /* 商品唯一标识 */
    goods?: string;
    /* 仓库或门店标识 */
    wrh?: string;
  }) => post<IInvLockDetail[]>('/api/pcweb/inv/query/getLockDetails', params)
};
export default checkTask;
