import {
  IBusinessStoreReturnDetail,
  IBusinessStoreReturnDetailItem,
  IBusinessStoreReturnItem,
  IStoreReturnGoods
} from '@/dataModel/business/storeReturn/interface';
import request from '@/utils/request';
const { post } = request;

const storeReturn = {
  /**
   * 分页查询退货单
   * @param params
   * @returns
   */
  query: (
    params: {
      filter: {
        applyMan?: string;
        endDate?: string;
        id?: string;
        startDate?: string;
        status?: string[];
        goodsInfo?: string;
      };
    } & IPageParams
  ) => post<IPageData<IBusinessStoreReturnItem>>('/api/pcweb/business/store/return/query', params),
  /**
   * 分页查询退货单详情
   * @param params
   * @returns
   */
  queryDetail: (
    params: {
      bill: string;
      filter?: {
        key?: string;
      };
    } & IPageParams
  ) => post<IPageData<IBusinessStoreReturnDetailItem>>('/api/pcweb/business/store/return/queryDetail', params),
  /**
   * 分页查询退货单详情
   * @param params
   * @returns
   */
  get: (params: { bill?: string; id?: string }) =>
    post<{ billInfo: IBusinessStoreReturnDetail; traceId: string }>('/api/pcweb/business/store/return/get', params),
  /**
   * 分页查询退货单详情
   * @param params
   * @returns
   */
  create: (params: { details: { qtyStr: string; reason: string; uuid: string }[]; reason: string; remark: string }) =>
    post<{ traceId: string; uuid: string }>('/api/pcweb/business/store/return/create', params),
  /**
   * 修改退货单
   * @param params
   * @returns
   */
  modify: (params: {
    version?: number;
    bill: string;
    details: { qtyStr: string; reason: string; uuid: string }[];
    reason: string;
    remark: string;
  }) => post<{ traceId: string; uuid: string }>('/api/pcweb/business/store/return/modify', params),
  /**
   * 提交退货单申请
   * @param params
   * @returns
   */
  submit: (params: { bill: string; version: number }) => post<any>('/api/pcweb/business/store/return/submit', params),
  /**
   * 提交退货单申请
   * @param params
   * @returns
   */
  getAllDetails: (params: { bill: string }) =>
    post<IBusinessStoreReturnDetailItem[]>('/api/pcweb/business/store/return/getAllDetails', params),

  /**
   * 删除
   * @param params
   * @returns
   */
  remove: (params: { bill: string }) => post<any>('/api/pcweb/business/store/return/remove', params),
  /**
   *获取总部通知退货商品
   * @param params
   * @returns
   */
  getNotices: () => post<IStoreReturnGoods[]>('/api/pcweb/business/store/return/getNotices'),

  /**
   *分页查询商品
   * @param params
   * @returns
   */
  queryGoods: (params: { filter: { goodsIds?: string[]; keyword?: string; sortId?: string } } & IPageParams) =>
    post<IPageData<IStoreReturnGoods>>('/api/pcweb/business/store/return/queryGoods', params),

  /**
   *审核同意
   * @param params
   * @returns
   */
  approve: (params: {
    bill: string;
    remark?: string;
    version: number;
    details: { approveQty: number; returnPrice: number; uuid: string }[];
  }) => post<any>('/api/pcweb/business/store/return/approve', params),

  /**
   *审核拒绝
   * @param params
   * @returns
   */
  reject: (params: { bill: string; rejectReason: string; version: number }) =>
    post<any>('/api/pcweb/business/store/return/reject', params),
  /**
   * 导入
   */
  /**获取导入任务明细 */
  getGoodsByIoTask: (params: { taskUuid: string }) =>
    post<IStoreReturnGoods[]>('/api/pcweb/business/store/return/getGoodsByIoTask', params),

  /**创建并提交退货单 */
  createAndSubmit: (params: {
    details: { qtyStr: string; reason: string; uuid: string }[];
    reason: string;
    remark: string;
    nuid?: string;
  }) => post('/api/pcweb/business/store/return/createAndSubmit', params),
  /** 批量同意 */
  ///api/pcweb/business/store/return/batchApprove
  batchApprove: params => post('/api/pcweb/business/store/return/batchApprove', params),
  // /api/pcweb/business/store/return/batchReject 批量拒绝
  batchReject: params => post('/api/pcweb/business/store/return/batchReject', params)
};
export default storeReturn;
