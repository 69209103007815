import request from '@/utils/request';
const { post } = request;
import {
  ISupplierBackendPurchaseOrder,
  ISupplierPurchaseOrderDetails
} from '@/dataModel/purchase/supplierPurchaseOrder/interface';

export default {
  /**分页查询表单 */
  query: (
    params: {
      filter?: {
        deadDateEnd?: string;
        deadDateStart?: string;
        demandDateEnd?: string;
        demandDateStart?: string;
        id?: string;
        receiverId?: string;
        supplyOrderStatus?: string;
      };
    } & IPageParams
  ) => post<IPageData<ISupplierBackendPurchaseOrder>>('/api/pcweb/supplierPurchase/query', params),
  /**修改状态 */
  submit: (params: {
    id: string;
    planDeliveryDate: string;
    /* supplierId?: string; */
    supplyOrderStatus: string;
    uuid: string;
    version: number;
  }) => post('/api/pcweb/supplierPurchase/submit', params),
  /**分页查询明细 */
  queryDetails: (
    params: {
      filter?: {
        keyword?: string;
      };
      bill: string;
      /* supplierId: string; */
    } & IPageParams
  ) => post<IPageData<ISupplierPurchaseOrderDetails>>('/api/pcweb/supplierPurchase/queryDetails', params),
  /**单头信息 */
  get: (params: { /* supplierId?: string; */ uuid: string }) =>
    post<ISupplierBackendPurchaseOrder>('/api/pcweb/supplierPurchase/get', params),
  /**批量确认 */
  submitBatch: (params: {
    orderBatchModifications: {
      uuid: string;
      version: number;
    }[];
    planDeliveryDate: string;
    /*  supplierId?: string; */
    supplyOrderStatus: string;
  }) => post('/api/pcweb/supplierPurchase/submitBatch', params)
};
