import { GoodsAdjustButtonEnumEnum } from '@/dataModel/dict/enum';
import {
  IGetBasicInfo,
  IGetDetail,
  IQuery,
  Good,
  IQueryGoods,
  IGoodsRetailPriceAdjustStores,
  IQueryLaunchGoods,
  IGoodsRetailPriceAdjustGoodsInfo
} from '@/dataModel/retail/adjustPrice/interface';
import { ISelectGoods } from '@/pages/HeadquartersBackend/Retail/AdjustPrice/Add/interface';
import request from '@/utils/request';
const { post } = request;

const adjustPrice = {
  /**
   * 作废
   */
  abort: (params: {
    /**
     * 	订单uuid
     */
    uuid: string;
    /**
     * 	版本
     */
    version: string;
  }) => post('/api/pcweb/retail/adjust/price/abort', params),
  /**
   * 审核
   */
  approve: (params: {
    /**
     * 	订单uuid
     */
    uuid: string;
    /**
     * 	版本
     */
    version: string;
  }) => post('/api/pcweb/retail/adjust/price/approve', params),
  /**
   * 查询单头信息
   */
  getBasicInfo: (params: {
    /**
     * 	订单编号
     */
    id: string;
  }) => post<IGetBasicInfo>('/api/pcweb/retail/adjust/price/getBasicInfo', params),
  /**
   * 查询调价单详情-编辑用
   */
  getDetail: (params: {
    /**
     * 	订单编号
     */
    id: string;
  }) => post<IGetDetail>('/api/pcweb/retail/adjust/price/getDetail', params),
  /**
   * 查询调价单详情-编辑用
   */
  getStoreScopeList: (params: {
    /**
     * 	订单标识
     */
    uuid: string;
  }) => post<IGoodsRetailPriceAdjustStores[]>('/api/pcweb/retail/adjust/price/getStoreScopeList', params),
  /**
   * 查询调价单列表
   */
  query: (
    params: {
      /**
       * 	调价单分页查询filter
       */
      filter?: {
        /**
         * 	制单截止时间
         */
        createEndTime?: string;
        /**
         * 	制单开始时间
         */
        createStartTime?: string;
        /**
         * 	商品编号/名称/条码
         */
        goodsInfo?: string;
        /**
         * 	订单单号
         */
        id?: string;
        /**
         * 	状态列表
         */
        statusList?: string[];
      };
    } & IPageParams
  ) => post<IPageData<IQuery>>('/api/pcweb/retail/adjust/price/query', params),
  /**
   * 分页查询订单商品明细
   */
  queryDetails: (
    params: {
      /**
       * 	调价单分页查询filter
       */
      filter?: {
        /**
         * 	订单单号
         */
        billId?: string;
        /**
         * 	商品编号/名称/条码
         */
        goodsInfo?: string;
      };
    } & IPageParams
  ) => post<IPageData<Good>>('/api/pcweb/retail/adjust/price/queryDetails', params),
  /**
   * 查询商品列表-添加商品时用
   */
  queryGoods: (
    params: {
      /**
       * 	商品查询filter
       */
      filter?: {
        /**
         * 	商品名称/编码/条码
         */
        goodsInfo?: string;
        /**
         * 	分类id
         */
        sortId?: string;
      };
    } & IPageParams
  ) => post<IPageData<IQueryGoods>>('/api/pcweb/retail/adjust/price/queryGoods', params),
  /**
   * 查询商品列表-添加商品时用
   */
  save: (params: {
    /**
     * 	商品明细
     */
    detailList: {
      /**
       * 	限价新最高价
       */
      limitMaxPriceNew: string;
      /**
       * 	限价新最低价
       */
      limitMinPriceNew: string;
      /**
       * 	门店新会员价
       */
      memberPriceNew: string;
      /**
       * 	商品单位munitId
       */
      munitUuid: string;
      /**
       * 	备注
       */
      remark: string;
      /**
       * 	门店新零售价
       */
      retailPriceNew: string;
      /**
       * 	标准新会员价
       */
      stdMemberPriceNew: string;
      /**
       * 	标准新零售价
       */
      stdRetailPriceNew: string;
      /**
       * isAllStore
       * 是否全部门店
       * */
      isAllStore: boolean;
    }[];
    /**
     * 	是否修改现价,默认否
     */
    isAdjustLimit: boolean;
    /**
     * 	是否修改标准零售价和会员价,默认否
     */
    isAdjustStd: boolean;
    /**
     * 	是否提交
     */
    isSubmit: boolean;
    /**
     * 	生效时间
     */
    launchDate?: string;
    /**
     * 	备注
     */
    remark: string;
    /**
     * 	门店id列表
     */
    storeIdList: string[];
    /**
     * 	订单uuid,修改时必传
     */
    uuid?: string;
    /**
     * 	订单version,修改时必传
     */
    version?: string;
    /**操作状态 */
    status?: GoodsAdjustButtonEnumEnum;
    /**是否全部门店 */
    isAllStore?: boolean;
  }) => post('/api/pcweb/retail/adjust/price/save', params),
  /**
   * 提交-列表页
   */
  submit: (params: {
    /**
     * 	订单标识
     */
    uuid: string;
    /**
     * 	版本号
     */
    version: string;
  }) => post('/api/pcweb/retail/adjust/price/submit', params),
  /**
   * 提交-列表页
   */
  remove: (params: {
    /**
     * 	订单标识
     */
    uuid: string;
  }) => post('/api/pcweb/retail/adjust/price/remove', params),

  // /**
  //  * 	查询门店范围列表
  //  */
  // getStoreScopeList: (params: { uuid: string }) => post<any>('/api/pcweb/retail/adjust/price/getStoreScopeList', params)
  /**商品调价报表 */
  queryLaunchGoods: (
    params: {
      filter?: {
        endDate?: string; //生效结束日期
        searchKey?: string; //商品编码、名称、条码/PLU码
        sortId?: string; //分类
        startDate?: string; //生效开始日期
      };
    } & IPageParams
  ) => post<IPageData<IQueryLaunchGoods>>('/api/pcweb/retail/adjust/price/queryLaunchGoods', params),

  /**获取生成打印表uuid */
  saveLaunchGoodsPrint: (params: {
    filter?: {
      endDate?: string; //生效结束日期
      searchKey?: string; //商品编码、名称、条码/PLU码
      sortId?: string; //分类
      startDate?: string; //生效开始日期
    };
  }) => post<any>('/api/pcweb/retail/adjust/price/saveLaunchGoodsPrint', params),
  /**提交并审核-列表页 */
  submitAndApprove: (params: {
    /**
     * 	订单标识
     */
    uuid: string;
    /**
     * 	版本号
     */
    version: string;
  }) => post('/api/pcweb/retail/adjust/price/submitAndApprove', params),
  /**批量删除 */
  removeBatch: (params: { uuids: string[] }) =>
    post<{ failedCount: number; successCount: number }>('/api/pcweb/retail/adjust/price/removeBatch', params),
  /**批量提交 */
  submitBatch: (params: { goodsAdjusts: { uuid: string; version: string }[] }) =>
    post<{ failedCount: number; successCount: number }>('/api/pcweb/retail/adjust/price/submitBatch', params),
  /** 批量审核  */
  submitBatchApprove: (param: { goodsAdjusts: { uuid: string; version: string }[] }) =>
    post<{ failedCount: number; successCount: number }>('/api/pcweb/retail/adjust/price/batchApprove', param),
  /**
   *
   * 根据标识导入任务明细
   */
  getGoodsByIoTask: (param: { taskUuid?: string }) =>
    post<ISelectGoods[]>('/api/pcweb/retail/adjust/price/getGoodsByIoTask', param)
};

export default adjustPrice;
