import { IDirectBack, IDirectBackDetailGoods, IDirectBackGoods } from '@/dataModel/business/DirectBack/interface';
import { DirectBackStatusEnum } from '@/dataModel/dict/enum';
import { ISupplier } from '@/dataModel/mdata/supplier/interface';
import request from '@/utils/request';
const { post } = request;

const DirectBack = {
  /**分页查询单据 */
  query: (
    params: {
      filter: {
        id: string;
        status: DirectBackStatusEnum;
      };
    } & IPageParams
  ) => post<IPageData<IDirectBack>>('/api/pcweb/business/direct/back/queryBill', params),

  /**提交单据*/
  submit: (params: { uuid: string; version: number }) => post<any>('/api/pcweb/business/direct/back/submit', params),

  /**删除单据*/
  remove: (params: { uuid: string }) => post<any>('/api/pcweb/business/direct/back/remove', params),

  /**获取单头*/
  get: (params: { id?: string; uuid?: string }) => post<IDirectBack>('/api/pcweb/business/direct/back/get', params),

  /**分页查询单据明细 */
  queryDetail: (
    params: {
      bill: string;
      filter?: {
        goodsKeyWord?: string;
      };
    } & IPageParams
  ) => post<IPageData<IDirectBackDetailGoods>>('/api/pcweb/business/direct/back/queryDetail', params),

  /**分页查询商品*/
  queryGoods: (
    params: {
      supplier: string;
      filter: {
        goodsSearchKey?: string;
        sortId?: string;
      };
    } & IPageParams
  ) => post<IPageData<IDirectBackGoods>>('/api/pcweb/business/direct/back/queryGoods', params),

  /**保存（创建、修改、保存并提交）*/
  save: (params: {
    uuid: string;
    details: {
      goodsId: string;
      goodsMunitUuid: string;
      price: number;
      qtyStr: string;
      reason: string;
    }[];
    info: {
      reason: string;
      remark: string;
      backTime: string;
      supplier: string;
    };
    targetStatus: DirectBackStatusEnum;
    version: number;
  }) => post<any>('/api/pcweb/business/direct/back/save', params),

  /**获取商品列表（新增或编辑*/
  getGoods: (params: { bill: string }) => post<IDirectBackGoods[]>('/api/pcweb/business/direct/back/getGoods', params),
  /**
   * 根据导入任务获取明细信息
   * @param params
   */
  getGoodsByIoTask: (params: { taskUuid: string }) =>
    post<IDirectBackGoods[]>('/api/pcweb/business/direct/back/getGoodsByIoTask', params),

  /**
   * 获取直送供应商列表
   * @param params
   */
  getSupplierList: (params: { keyWord?: string } = {}) =>
    post<ISupplier[]>('/api/pcweb/business/direct/back/getSupplierList', params)
};

export default DirectBack;
