import { WholeSaleReportDimensionEnum } from '@/dataModel/dict/enum';
import {
  IReportByCustomer,
  IReportByGoods,
  IReportBySort,
  IReportByStore,
  IReportByGoodsDetail,
  IReportCount
} from '@/dataModel/wholesale/report';
import request from '@/utils/request';
const { post } = request;

const report = {
  /** 按客户汇总查询 */
  queryByCustomer: (
    params: {
      filter?: {
        /** 开始时间 */
        startDate?: string;
        /** 结束时间 */
        endDate?: string;
      };
    } & IPageParams
  ) => post<IPageData<IReportByCustomer>>('/api/pcweb/wholesale/report/queryByCustomer', params),

  /** 按商品汇总查询 */
  queryByGoods: (
    params: {
      filter?: {
        /** 开始时间 */
        startDate?: string;
        /** 结束时间 */
        endDate?: string;
      };
    } & IPageParams
  ) => post<IPageData<IReportByGoods>>('/api/pcweb/wholesale/report/queryByGoods', params),
  /** 按商品汇总查询 */
  queryBySort: (
    params: {
      filter?: {
        /** 开始时间 */
        startDate?: string;
        /** 结束时间 */
        endDate?: string;
      };
    } & IPageParams
  ) => post<IPageData<IReportBySort>>('/api/pcweb/wholesale/report/queryBySort', params),
  /** 按商品汇总查询 */
  queryByStore: (
    params: {
      filter?: {
        /** 开始时间 */
        startDate?: string;
        /** 结束时间 */
        endDate?: string;
      };
    } & IPageParams
  ) => post<IPageData<IReportByStore>>('/api/pcweb/wholesale/report/queryByStore', params),
  /** 仓库汇总 */
  queryByWrh: (
    params: {
      filter?: {
        /** 开始时间 */
        startDate?: string;
        /** 结束时间 */
        endDate?: string;
      };
    } & IPageParams
  ) => post<IPageData<IReportByStore>>('/api/pcweb/wholesale/report/queryByWrh', params),
  /** 按商品汇总查询 */
  queryByGoodsDetail: (
    params: {
      filter?: {
        /** 开始时间 */
        startDate?: string;
        /** 结束时间 */
        endDate?: string;
      };
    } & IPageParams
  ) => post<IPageData<IReportByGoodsDetail>>('/api/pcweb/wholesale/report/queryDetail', params),

  /** 统计查询 */
  queryCount: (
    params: {
      filter?: {
        /** 单据类型 */
        billClass?: string;
        /** 单号 */
        billId?: string;
        /** 客户信息 */
        customerInfo?: string;
        /** 结束时间 */
        endDate?: string;
        /** 关键字 */
        goodsKeyWord?: string;
        /** 业务员信息 */
        salesmanInfo?: string;
        /** 分类编码 */
        sortId?: string;
        /** 开始时间 */
        startDate?: string;
        /** 门店编码 */
        storeId?: string;
      };
    },
    state: WholeSaleReportDimensionEnum
  ) => post<IReportCount>('/api/pcweb/wholesale/report/getCount', { ...params, dimension: state })
};

export default report;
