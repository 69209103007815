import { StoreLossBillStatusEnum } from '@/dataModel/business/storeLoss/enum';
import {
  IBusinessStoreLoss,
  IBusinessStoreLossOperateParams,
  IStoreLossDetailBasicInfo,
  IStoreLossDetailImgs,
  IStoreLossDetailList,
  IStoreLossGoods
} from '@/dataModel/business/storeLoss/interface';
import request from '@/utils/request';

const { post } = request;

const storeLoss = {
  /**
   * 创建
   */
  create: (params: {
    details: {
      goodsUuid: string;
      qty: number;
      reason: string;
    }[];
    images: string[];
    reason: string;
    remark?: string;
  }) => post<{ traceId: string; uuid: string }>('/api/pcweb/business/store/loss/create', params),

  /**
   * 获取详情基础信息
   */
  get: (params: { bill?: string; id?: string }) =>
    post<IStoreLossDetailBasicInfo>('/api/pcweb/business/store/loss/get', params),

  /**
   * 提交
   */
  submit: (params: { bill: string; version: number }) =>
    post<{ traceId: string; version: number }>('/api/pcweb/business/store/loss/submit', params),

  /**
   * 修改
   */
  modify: (
    params: {
      bill: string;
      version: number;
    } & IBusinessStoreLossOperateParams
  ) => post<{ traceId: string; version: number }>('/api/pcweb/business/store/loss/modify', params),

  /**
   * 分页查
   */
  query: (
    params: {
      filter: {
        startDate?: string;
        endDate?: string;
        id?: string;
        status?: StoreLossBillStatusEnum;
        goodsKeyword?: string;
        reason?: string;
        storeKeyword?: string;
        statusList?: string[];
      };
    } & IPageParams
  ) => post<IPageData<IBusinessStoreLoss>>('/api/pcweb/business/store/loss/queryBill', params),

  /**
   * 分页详情list
   */
  queryDetailList: (
    params: {
      bill: string;
      filter?: {
        keyword: string;
      };
    } & IPageParams
  ) => post<IPageData<IStoreLossDetailList>>('/api/pcweb/business/store/loss/queryDetail', params),
  /**
   *添加商品
   */
  queryGoods: (params: { filter: { keyword: string; sortId: string } } & IPageParams) =>
    post<IPageData<IStoreLossGoods>>('/api/pcweb/business/store/loss/queryGoods', params),
  /**
   * 删除
   */
  remove: (params: { bill: string }) => post<{ traceId: string }>('/api/pcweb/business/store/loss/remove', params),

  /**
   * 获取报损单图片
   */
  getImage: (params: { bill: string }) =>
    post<IStoreLossDetailImgs[]>('/api/pcweb/business/store/loss/getImage', params),

  /**
   * 同意/拒绝报损申请
   */
  examineStoreLoss: (params: {
    agreeStatus: boolean;
    bill: string;
    examines: {
      ctotal: number;
      goods: string;
      goodsQpc: number;
      goodsType: string;
      goodsWeight: number;
      planQtyStr: number;
      price: number;
      qtyStr: string;
      retailPrice: number;
      taxRate: number;
      total: number;
      uuid: string;
      version: number;
    }[];
    version: number;
  }) => post<{ traceId: string }>('/api/pcweb/business/store/loss/examineStoreLoss', params),

  /**
   * 根据导入任务获取商品
   */
  getGoodsByIoTask: (params: { taskUuid: string }) =>
    post<IStoreLossDetailList[]>('/api/pcweb/business/store/loss/getGoodsByIoTask', params),

  /**
   * 批量同意/拒绝报损单据
   */
  batchAgreeOrReject: (params: { uuids: string[]; status: boolean }) =>
    post<{ failedCount: number; successCount: number }>('/api/pcweb/business/store/loss/batchAgreeOrReject', params)
};

export default storeLoss;
