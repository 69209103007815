import { MdataTypeEnum } from '@/dataModel/dict/enum';
import { IRuleMdataItem } from '@/dataModel/system/ruleMdata/interface';
import request from '@/utils/request';
const { post } = request;

const ruleMdata = {
  /**
   * 分页查询
   */
  query: (params: IPageParams) => post<IPageData<IRuleMdataItem>>('/api/pcweb/system/rule/mdata/query', params),

  /**
   * 修改
   */
  modify: (params: { flowLength: number; manualInput: boolean; mdataType: string; prefix: string; use: boolean }) =>
    post<any>('/api/pcweb/system/rule/mdata/modify', params),

  /**
   * 重置
   */
  reset: (params: { mdataType: string }) => post<any>('/api/pcweb/system/rule/mdata/reset', params),

  /**
   * 检测前缀是否可以使用
   */
  check: (params: { mdataType: string; prefix: string }) => post<any>('/api/pcweb/system/rule/mdata/check', params),

  /**
   * 获取详情
   */
  get: (params: {
    mdataType: MdataTypeEnum;
    /** 商品分类(用于商品档案添加商品选择分类获取自动编码规则) */
    sortId?: string;
  }) => post<IRuleMdataItem>('/api/pcweb/system/rule/mdata/get', params)
};
export default ruleMdata;
