import { IoTaskInStatusEnum } from '@/dataModel/dict/enum';
import { IIoIn } from '@/dataModel/system/ioIn/interface';
import request from '@/utils/request';

const { post } = request;

const invImport = {
  queryInvInt: (
    params?: {
      filter?: {
        status?: IoTaskInStatusEnum;
      };
    } & IPageParams
  ) => post<IPageData<IIoIn>>('/api/pcweb/system/io/in/queryInvInt', params)
};

export default invImport;
