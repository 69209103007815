import { PayBillClassEnum, PayBusinessTypeEnum, PayStatusEnum, PayTypeEnum } from '@/dataModel/dict/enum';
import { IFeeSupplier } from '@/dataModel/finance/feeSupplier/interface';
import {
  IFinancePay,
  IFinancePayDetailInOut,
  IFinancePayFees,
  IFinancePayPrepays,
  IFinancePaySettle
} from '@/dataModel/finance/pay/interface';
import request from '@/utils/request';

const { post } = request;

export default {
  /** 查分页 */
  query: (params?: {
    filter?: {
      idKeyword: IFinancePay['id'];
      billToKeyword: string;
      settleNo: number;
      payDate: string;
      startDate: string;
      endDate: string;
      status: { id: PayStatusEnum; name: string };
    };
  }) => post<IPageData<IFinancePay>>('/api/pcweb/finance/pay/query', params),

  /** 查详情 */
  get: (params: { id?: IFinancePay['id']; uuid?: IFinancePay['uuid'] }) =>
    post<IFinancePay>('/api/pcweb/finance/pay/get', params),

  /** 获取单据列表（用于新增查询各类单据） */
  getBills: (params: {
    billClass: PayBillClassEnum;
    businessType: PayBusinessTypeEnum.Supplier | PayBusinessTypeEnum.Store;
    supplierId: string;
  }) =>
    post<{ fees: IFinancePayFees[]; inouts: any[]; prepays: IFinancePayPrepays[]; settles: IFinancePaySettle[] }>(
      '/api/pcweb/finance/pay/getBills',
      params
    ),
  /** 获取单据明细 */
  getDetails: (params: { id: string }) =>
    post<{
      fees: IFinancePayFees[];
      inouts: IFinancePayDetailInOut[];
      prepays: IFinancePayPrepays[];
      settles: IFinancePaySettle[];
    }>('/api/pcweb/finance/pay/getDetails', params),
  /** 修改状态 */
  modifyState: (params: { targetStatus: PayStatusEnum; uuid: IFinancePay['uuid']; version: number }) =>
    post('/api/pcweb/finance/pay/modifyState', params),

  /** 创建、保存、保存并提交 */
  save: (params: {
    uuid?: IFinancePay['uuid'];
    version?: IFinancePay['version'];
    targetStatus: PayStatusEnum.Init | PayStatusEnum.Applyed;
    create: {
      feeUuids: IFeeSupplier['uuid'][];
      billClass: PayBillClassEnum;
      businessType: PayBusinessTypeEnum;
      payType: PayTypeEnum;
    } & Pick<IFinancePay, 'bankAccount' | 'remark'>;
  }) =>
    post<{
      uuid: IFinancePay['uuid'];
      version: IFinancePay['version'];
    }>('/api/pcweb/finance/pay/save', params),
  /** 删除 */
  remove: (params: { uuid: string }) => post<{ traceId: string }>('/api/pcweb/finance/pay/remove', params),
  /** 根据结算单获取 */
  getBySettle: (params: { settleUuid: string }) => post<IFinancePay[]>('/api/pcweb/finance/pay/getBySettle', params)
};
