import request from '@/utils/request';
const { post } = request;
import { IPageCustomerData, ICustomerDataDetail } from '@/dataModel/finance/customerManage/interface';

export default {
  /*客户台账汇总 */
  queryCustomer: (
    param: {
      filter?: {
        customerId?: Array<[]>;
        /* 期号 */
        settleNo?: string;
      };
    } & IPageParams
  ) => post<IPageData<IPageCustomerData>>('/api/pcweb/finance/standingbook/customer/query', param),
  /* 客户台账总计 */
  queryTotal: (param: {
    filter?: {
      customerId?: Array<[]>;
      /* 期号 */
      settleNo?: string;
    };
  }) => post<IPageCustomerData>('/api/pcweb/finance/standingbook/customer/queryTotal', param),

  /* 分页查询客户台账明细 */
  queryCustomerDetail: (
    param: {
      filter?: {
        /* 单据类型 */
        billType?: string;
        customerId?: Array<[]>;
        /* 结束时间 */
        end?: string;
        /* 开始时间 */
        start?: string;
        /* 期号 */
        settleNo?: string;
      };
    } & IPageParams
  ) => post<IPageData<ICustomerDataDetail>>('/api/pcweb/finance/standingbook/customer/queryDetail', param),

  /* 客户台账明细总计 */
  queryDetailTotal: (
    param: {
      filter?: {
        /* 单据类型 */
        billType?: string;
        customerId?: Array<[]>;
        /* 结束时间 */
        end?: string;
        /* 开始时间 */
        start?: string;
        /* 期号 */
        settleNo?: string;
      };
    } & IPageParams
  ) => post<ICustomerDataDetail>('/api/pcweb/finance/standingbook/customer/queryDetailTotal', param),
  /* 期号内数据处理 */
  saveDataAll: (param: {
    customerIds?: Array<[]>;
    /* 截止日期 */
    endDate?: string;
    /* 期号 */
    settleNo?: string;
  }) => post('/api/pcweb/finance/standingbook/customer/saveDataAll', param),
  // 单挑数据处理
  saveDataSingle: (param: {
    /* 单据类型 */
    billType?: string;
  }) => post('/api/pcweb/finance/standingbook/customer/saveDataSingle', param)
};
