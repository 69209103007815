import { ICheckGoods, ICheckStoresPrepare, IGetPool, ILastPool, IGetSetting } from '@/dataModel/dist/pool/interface';
import request from '@/utils/request';
const { post } = request;

const pool = {
  /**
   * 获取提单配置
   *
   */
  get: () => post<IGetSetting>('/api/pcweb/dist/lading/get'),
  /**
   * 修改提单配置
   */
  modify: (params: { timeType: string; use: boolean }) =>
    post<{ traceId: string; uuid: string }>('/api/pcweb/dist/lading/save', params),

  /**
   * 判断波次是否完结
   */
  pass: () => post<{ flag: boolean; traceId: string }>('/api/pcweb/dist/pool/pass'),

  /**
   * 获取最后波次  -> 判断波次是否完结 false
   */
  last: () => post<ILastPool>('/api/pcweb/dist/pool/last'),

  /**
   * 获取基本信息   -> 判断波次是否完结 true
   */
  getPool: () => post<IGetPool>('/api/pcweb/dist/pool/getPool'),

  /**
   * 预查门店
   */
  checkStoresPrepare: () => post<ICheckStoresPrepare>('/api/pcweb/dist/pool/check'),

  /**
   * 预查商品
   */
  checkGoodsPrepare: (
    params: {
      batchId: string;
      filter?: {
        searchKey: string;
      },
      orderBy?: {
        direction: string;
        field: string;
      };
    } & IPageParams
  ) => post<IPageData<ICheckGoods>>('/api/pcweb/dist/pool/queryDistPoolSum', params),
  /**
   * 订货明细
   */
  queryDistPool: (
    params: {
      batchId: string;
      filter?: {
        searchKey: string;
        requireId: string;
        sortIds: string[],
        storeId:string
      },
      orderBy?: {
        direction: string;
        field: string;
      };
    } & IPageParams
  ) => post<IPageData<ICheckGoods>>('/api/pcweb/dist/pool/queryDistPool', params),

  /**
   * 查看商品
   */
  queryLack: (
    params: {
      batchId: string;
      filter: {
        goodsType: string;
        searchKey: string;
      };
      orderBy?: {
        direction: string;
        field: string;
      };
    } & IPageParams
  ) => post<IPageData<ICheckGoods>>('/api/pcweb/dist/pool/queryLack', params),

  /**
   * 完成
   */
  finished: (params: { batchId: string; version: number }) =>
    post<{ traceId: string }>('/api/pcweb/dist/pool/finished', params),
  /**
   *手动提单检查
   */
  ladingCheckManual: () =>
    post<{
      ladingCheck: boolean;
      reason: string;
    }>('/api/pcweb/dist/lading/ladingCheckManual'),
  /**
   *更新订货数量
   */
  updateDistPoolQty: (params:{ qty: string;
      uuid: string;version:number}) =>
    post<any>('/api/pcweb/dist/pool/updateDistPoolQty',params),
  /**
   *提单记录
   */
  queryDistBatch: (params: {
    } & IPageParams) =>
    post<IPageData<any>>('/api/pcweb/dist/pool/queryDistBatch',params),
  /**
   * 提单
   */
  lading: (params: {
    filter?: {
      senderIds?: string[];
      storeIds?: string[];
      excludeSortIds?: string[];
      excludeGoodsIds?: string[];
      sortIds?: string[];
      goodsIds?: string[];
    };
    ladingType: string;
    status: string;
  }) => post<{ traceId: string }>('/api/pcweb/dist/pool/lading', params)
};
export default pool;
