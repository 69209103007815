import { ChannelEnum, OnlineOrderCommentScopeEnum, OnlineOrderCommentTypeEnum } from '@/dataModel/dict/enum';
import { IOnlineOrderComment, IOnlineOrderCount } from '@/dataModel/retail/onlineOrderComment/interface';
import request from '@/utils/request';

export default {
  query: (
    params: {
      filter?: {
        commentScoreEq?: OnlineOrderCommentScopeEnum;
        commentTimeGreaterEq?: string;
        commentTimeLessEq?: string;
        hasContent?: string;
        platformEq?: ChannelEnum;
        commentTypeIn?: OnlineOrderCommentTypeEnum;
        replied?: string;
        storeIdNameLike?: string;
        orderNumberLike?: string;
      };
    } & IPageParams
  ) => request.post<IPageData<IOnlineOrderComment>>('/api/pcweb/onlineorder/comment/query', params, {}, 'onlineOrder'),
  /**回复评价 */
  reply: (params: { content: string; uuid: string }) => request.post('/api/pcweb/onlineorder/comment/reply', params, {}, 'onlineOrder'),
  /** 修改回复 */
  modify: (params: { content: string; uuid: string }) =>
    request.post('/api/pcweb/onlineorder/comment/reply/modify', params, {}, 'onlineOrder'),
  /**  查询订单类型数量 */
  getCount: (params: {
    filter?: {
      commentScoreEq?: OnlineOrderCommentScopeEnum;
      commentTimeGreaterEq?: string;
      commentTimeLessEq?: string;
      hasContent?: string;
      platformEq?: ChannelEnum;
      commentTypeIn?: OnlineOrderCommentTypeEnum;
      replied?: string;
      storeIdNameLike?: string;
      orderNumberLike?: string;
    };
  }) => request.post<IOnlineOrderCount>('/api/pcweb/onlineorder/comment/getCount', params, {}, 'onlineOrder')
};
