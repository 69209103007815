import { IJointGoodsInv, IJointGoodsInvCount } from '@/dataModel/inv/jointGoodsInv';
import request from '@/utils/request';
const { post } = request;

const jointGoodsInv = {
  /**查询 */
  query: (
    params?: {
      filter?: {
        storeId: string; //门店
        keyWord: string; //商品信息
      };
    } & IPageParams
  ) => post<IPageData<IJointGoodsInv>>('/api/pcweb/inv/store/supplier/query', params),
  /**统计 */
  count: (params?: {
    filter?: {
      storeId: string; //门店
      keyWord: string; //商品信息
    };
  }) => post<IJointGoodsInvCount>('/api/pcweb/inv/store/supplier/count', params)
};

export default jointGoodsInv;
