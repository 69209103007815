import request from '@/utils/request';
import {
  ISellunionManage,
  ISellunionManageByDetail,
  ISellunionManageGetCount
} from '@/dataModel/finance/sellunionManage/interface';
const { post } = request;

export default {
  /**按商品查询 */
  queryByGoods: (
    params: {
      filter?: {
        supplier?: string; //供应商
        storeId?: string; //门店
        occurDateEnd?: string; //出账结束日期
        occurDateBegin?: string; //出账开始日期
        billId?: string; //单号
        billClass?: string; //单据类型
        goodsKeyWord?: string; //商品信息
      };
    } & IPageParams
  ) => post<IPageData<ISellunionManage>>('/api/pcweb/finance/sellunion/account/queryByGoods', params),
  /**按明细查询 */
  queryByDetail: (
    params: {
      filter?: {
        supplier?: string; //供应商
        storeId?: string; //门店
        occurDateEnd?: string; //出账结束日期
        occurDateBegin?: string; //出账开始日期
        billId?: string; //单号
        billClass?: string; //单据类型
        goodsKeyWord?: string; //商品信息
      };
    } & IPageParams
  ) => post<IPageData<ISellunionManageByDetail>>('/api/pcweb/finance/sellunion/account/queryByDetail', params),
  /**查询明细合计 */
  getCount: (
    params: {
      filter?: {
        barcode?: string; //商品条码
        billClass?: string; //单据类型
        billId?: string; //单号
        goodsId?: string; //商品编码
        goodsKeyWord?: string; //商品信息
        goodsName?: string; //商品名称
        occurDateBegin?: string; //出账开始日期
        occurDateEnd?: string; //出账结束日期
        supplier?: string; //供应商
        storeId?: string; //门店
        isDetail?: boolean; //是否为明细维度
      };
    } & IPageParams
  ) => post<IPageData<ISellunionManageGetCount>>('/api/pcweb/finance/sellunion/account/getCount', params),
  /**查询商品合计 */
  getCountByGoods: (
    params: {
      filter?: {
        barcode?: string; //商品条码
        billClass?: string; //单据类型
        billId?: string; //单号
        goodsId?: string; //商品编码
        goodsKeyWord?: string; //商品信息
        goodsName?: string; //商品名称
        occurDateBegin?: string; //出账开始日期
        occurDateEnd?: string; //出账结束日期
        supplier?: string; //供应商
        storeId?: string; //门店
        isDetail?: boolean; //是否为明细维度
      };
    } & IPageParams
  ) => post<IPageData<ISellunionManageGetCount>>('/api/pcweb/finance/sellunion/account/getCountByGoods', params)
};
