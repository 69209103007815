import * as React from 'react';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import cloneDeep from 'lodash/cloneDeep';
import { getPlainNode } from '@/utils/utils';
import { getNavData } from '@/config/routers';
import renderRoutes from '@/utils/renderRoutes';

const Router = require('dva/router').routerRedux.ConnectedRouter;

// 累加路由path 添加/app/xxx/:pid/:storeId/ 这个前缀
function getRouteData(navData, path) {
  if (!navData.some(item => item.layout === path) || !navData.filter(item => item.layout === path)[0].children) {
    return null;
  }
  const route = cloneDeep(navData.filter(item => item.layout === path));
  const nodeList = getPlainNode(route);
  return nodeList;
}
function RouterConfig({ history, app }) {
  const navData = getNavData(app);
  const newRoutes = getRouteData(navData, 'BasicLayout');
  return (
    <ConfigProvider locale={zhCN}>
      <Router history={history}>{renderRoutes(newRoutes, {})}</Router>
    </ConfigProvider>
  );
}

export default RouterConfig;
