import { RecipeInvTypeEnum, RecipeTypeEnum, RecipeScopeEnum } from '@/dataModel/dict/enum';
import { RecipeGoodsTypeEnum } from '@/dataModel/inv/recipe/enum';
import { IInvRecipe, IInvRecipeDetail, IINvRecipeGoods } from '@/dataModel/inv/recipe/interface';
import { StoreProcessTypeEnum } from '@/dataModel/inv/storeProcess/enum';
import request from '@/utils/request';

const { post } = request;
const recipe = {
  // 添加
  create: (params: {
    id?: string;
    invType: RecipeInvTypeEnum;
    name: string;
    products: { goods: string; goodsMunitUuid: string; qty: number; line: number; remark?: string }[];
    raws: { goods: string; goodsMunitUuid: string; qty: number; line: number; remark?: string }[];
    recipeType: RecipeTypeEnum;
    remark?: string;
  }) => post<{ traceId: string; uuid: string }>('/api/pcweb/inv/recipe/create', params),

  // 分页查询
  get: (params: { id: string; uuid: string; wrhId?: string }) =>
    post<IInvRecipeDetail>('/api/pcweb/inv/recipe/get', params),

  // 编辑
  modify: (params: {
    invType: RecipeInvTypeEnum;
    name: string;
    products: { goods: string; goodsMunitUuid: string; qty: number; line: number; remark?: string }[];
    raws: { goods: string; goodsMunitUuid: string; qty: number; line: number; remark?: string }[];
    recipeType: RecipeTypeEnum;
    remark: string;
    uuid: string;
    version: number;
  }) => post<{ traceId: string; version: number }>('/api/pcweb/inv/recipe/modify', params),

  // 分页查询
  query: (
    params: {
      filter?: {
        createdEnd?: string;
        createdStart?: string;
        creatorKey?: string;
        invType?: RecipeInvTypeEnum;
        key?: string;
        recipeType?: RecipeTypeEnum | StoreProcessTypeEnum;
        isStore?: boolean;
        scope?: RecipeScopeEnum;
      };
    } & IPageParams
  ) => post<IPageData<IInvRecipe>>('/api/pcweb/inv/recipe/query', params),

  // 分页查询商品
  queryGoods: (
    params: { filter?: { searchKey?: string; sortId?: string } } & IPageParams,
    queryType: RecipeGoodsTypeEnum
  ) => {
    const queryGoods = {
      [RecipeGoodsTypeEnum.PRODUCTS]: 'queryProductGoods', // 分页查询成品商品
      [RecipeGoodsTypeEnum.RAWS]: 'queryRawGoods' // 分页查询原料商品
    };
    return post<IPageData<IINvRecipeGoods>>(`/api/pcweb/inv/recipe/${queryGoods[queryType]}`, params);
  },

  // 删除`
  remove: (uuid: string) => post('/api/pcweb/inv/recipe/remove', { uuid })
};

export default recipe;
