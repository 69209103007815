import { IDirectAllocBackGoods, IDirectAllocBack } from '@/dataModel/business/directAllocBack/interface';
import { DirectAllocBackBillClassEnum, DirectAllocBackStatusEnum } from '@/dataModel/dict/enum';
import request from '@/utils/request';
const { post } = request;

const directAllocBack = {
  /**
   * 分页查询（直配或直送）退货单
   */
  query: (
    params: {
      filter?: {
        endDate?: string;
        goodsKeyword?: string;
        idKeyword?: string;
        settleNo?: number | string;
        startDate?: string;
        status?: string;
        storeId?: string;
        supplierKeyword?: string;
      };
    } & IPageParams
  ) => post<IPageData<IDirectAllocBack>>('/api/pcweb/business/direct/alloc/back/query', params),

  /**
   * 批量删除
   * @param params
   */
  removeBatch: (params: { uuids: string[] }) => post<any>('/api/pcweb/business/direct/alloc/back/removeBatch', params),

  /**
   * 删除
   * @param params
   */
  remove: (params: { uuid: string }) => post<any>('/api/pcweb/business/direct/alloc/back/remove', params),

  /**
   * 获取单头
   * @param params
   */
  get: (params: { uuid: string }) => post<IDirectAllocBack>('/api/pcweb/business/direct/alloc/back/get', params),

  /**
   * 分页查询明细
   * @param params
   */
  queryDetails: (params: { bill: string; filter: { keyword: string } } & IPageParams) =>
    post<IPageData<IDirectAllocBackGoods>>('/api/pcweb/business/direct/alloc/back/queryDetails', params),

  /**
   * 修改状态
   * @param params
   */
  modifyStatus: (params: { uuid: string; version: number; targetStatus: DirectAllocBackStatusEnum }) =>
    post<any>('/api/pcweb/business/direct/alloc/back/modifyStatus', params),

  /**
   * 保存（创建、保存、保存并提交、保存并退货）
   * @param params
   */
  save: (params: {
    info: {
      billClass: DirectAllocBackBillClassEnum;
      details: {
        goodsId: string;
        goodsMunit: string;
        goodsQpcStr: string;
        price: number;
        qty: number;
        qtyStr: string;
        reason?: string;
        remark?: string;
        taxRate: number;
      }[];
      occurDate: string;
      reason?: string;
      remark?: string;
      storeId: string;
      supplier: string;
    };
    targetStatus: string;
    uuid?: string;
    version?: number;
  }) => post<any>('/api/pcweb/business/direct/alloc/back/save', params),

  /**
   * 获取明细
   * @param params
   */
  getDetails: (params: { bill: string }) =>
    post<IDirectAllocBackGoods[]>('/api/pcweb/business/direct/alloc/back/getDetails', params),

  /**
   * 根据导入任务获取明细信息
   * @param params
   */
  getDetailsByIoTask: (params: { taskUuid: string }) =>
    post<IDirectAllocBackGoods[]>('/api/pcweb/business/direct/alloc/back/getDetailsByIoTask', params)
};
export default directAllocBack;
