import {
  BaseBatchResult,
  BatchModifyDiscountRequest,
  BatchModifyTotalRequest,
  PosDiscountFlow,
  PosDiscountSettings,
  PosDiscountSettingsQueryFilter,
  PosDiscountSettingsQueryFlowFilter,
  PosDiscountSettingsQueryFlowResult
} from '@/dataModel/retail/cashierDiscount/interface';
import request from '@/utils/request';
const { post } = request;

const cashierDisCount = {
  // /api/pcweb/retail/discount/batchModifyDiscount 设置折扣比例
  batchModifyDiscount: (params: BatchModifyDiscountRequest) =>
    post<BaseBatchResult>('/api/pcweb/retail/discount/batchModifyDiscount', params, {}, 'pos'),
  /**
 * 
/api/pcweb/retail/discount/batchModifyTotal
设置折扣金额
 */
  batchModifyTotal: (params: BatchModifyTotalRequest) =>
    post<BaseBatchResult>('/api/pcweb/retail/discount/batchModifyTotal', params, {}, 'pos'),
  /**
 * /api/pcweb/retail/discount/query
查询整单折扣配置列表
 */
  query: (params: { filter: PosDiscountSettingsQueryFilter } & IPageParams) =>
    post<IPageData<PosDiscountSettings>>('/api/pcweb/retail/discount/query', params, {}, 'pos'),

  /**
 * /api/pcweb/retail/discount/queryFlow
查询整单折扣流水
 */
  queryFlow: (params: { filter: PosDiscountSettingsQueryFlowFilter } & IPageParams) =>
    post<IPageData<PosDiscountFlow>>('/api/pcweb/retail/discount/queryFlow', params, {}, 'pos'),
  /**
 * /api/pcweb/retail/discount/get
查询整单
 */
  get: (params: { userId: string }) => post<PosDiscountSettings>('/api/pcweb/retail/discount/get', params, {}, 'pos'),
  /**
   * /api/pcweb/retail/discount/getFlowCount
查询整单折扣流水
   */
  getFlowCount: (params: { filter: PosDiscountSettingsQueryFlowFilter }) =>
    post<PosDiscountSettingsQueryFlowResult>('/api/pcweb/retail/discount/getFlowCount', params, {}, 'pos')
};
export default cashierDisCount;
