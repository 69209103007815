import { WholesaleSettleBatchStatusEnum } from '@/dataModel/dict/enum';
import { IFinanceWholesaleBill } from '@/dataModel/finance/wholesaleBill/interface';
import { ICustomer } from '@/dataModel/mdata/customer/interface';
import request from '@/utils/request';

const { post } = request;

export default {
  /** 创建批发出账单 */
  create: (params: {
    /** 单据标识列表 */
    bills: string[];
    /** 生成结算单状态 */
    status: string;
  }) => post<{ traceId: string; uuid: string }>('/api/pcweb/finance/wholesale/bill/create', params),
  /** 获取批次任务信息 */
  get: (params: { uuid: string }) =>
    post<{
      /** 业务单据数量 */
      billCount: number;
      /**客户数量 */
      customerCount: number;
      /** 结束时间 */
      endTime: string;
      /** 总用时（秒） */
      seconds: number;
      /** 生成结算单数量 */
      settleCount: number;
      /** 起始时间 */
      startTime: string;
      status: {
        id: WholesaleSettleBatchStatusEnum;
        name: string;
      };
      /** 任务标识 */
      uuid: string;
    }>('/api/pcweb/finance/wholesale/bill/get', params),
  /** 分页查询单据 */
  query: (
    params: {
      customers: ICustomer['uuid'][];
      filter: {
        /** 对账截止日期 */
        accountEnd: string;
        /** 对账截止日期 */
        accountStart?: string;
        /** 制单时间截止于 */
        endDate?: string;
        /** 单据编码查询关键词 */
        idKeyword?: string;
        /** 制单时间起始于 */
        startDate?: string;
      };
    } & IPageParams
  ) => post<IPageData<IFinanceWholesaleBill>>('/api/pcweb/finance/wholesale/bill/query', params)
};
