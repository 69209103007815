import request from '@/utils/request';
const { post } = request;

const wholesaleUse = {
  /** 获取组织 */
  accountdeptGetAl: (
    params: {
      filter?: {
        parentId?: string;
        searchKey?: string;
      };
    } & IPageParams
  ) => post<any>('/api/pcweb/mdata/accountdept/getAll', params),
  /** 获取组织 */
  query: (
    params: {
      filter?: {
        accountOrgId: string;
        createdEnd: string;
        createdStart: string;
        id: string;
        receiver: string;
        searchKey: string;
        settleNo: number;
        status: string;
        statusList: string;
        wrhId: number;
      };
    } & IPageParams
  ) => post<any>('/api/pcweb/inv/use/wrh/query', params),
  /**
   * 分页查询商品
   */
  queryGoods: (
    params: {
      filter: {
        searchKey?: string;
        sortId?: string;
      };
      wrh: string;
    } & IPageParams
  ) => post<IPageData<any>>('/api/pcweb/inv/use/wrh/queryGoods', params),
  /**
   * 提交/提交并同意
   */
  submit: (params: { uuid: string; version: number }) => post('/api/pcweb/inv/use/wrh/submit', params),
  /**
   * 提交/提交并同意
   */
  remove: (params: { uuid: string }) => post('/api/pcweb/inv/use/wrh/remove', params),
  /**
   * 保存
   */
  save: (params: {
    accountOrg: string;
    accountType: string;
    reason: string;
    receiver: string;
    receiveDate: string;
    receiveStore: string;
    remark: string;
    status: string;
    details: {
      goods: string;
      goodsMunitUuid: string;
      qty: string;
      qtyStr: string;
      remark: string;
    }[];
    nuid: string;
    uuid: string;
    version: number;
    wrh: string;
  }) => post('/api/pcweb/inv/use/wrh/save', params),
  /**查看领用单头 */
  get: (params: { uuid: string }) => post<any>('/api/pcweb/inv/use/wrh/get', params),
  /**
   * 分页查询领用单明细
   */
  queryDetail: (
    params: {
      bill: string;
      filter?: {
        searchKey: string;
      };
    } & IPageParams
  ) => post<IPageData<any>>('/api/pcweb/inv/use/wrh/queryDetail', params),
  /**
   * 同意
   */
  agree: (params: { details: { goods: string; qty: number; qtyStr: string }[]; uuid: string; version: number }) =>
    post('/api/pcweb/inv/use/wrh/agree', params),
  /**
   * 拒绝
   */
  reject: (params: { reason: string; uuid: string; version: number }) => post('/api/pcweb/inv/use/wrh/reject', params),
  /**获取导入任务明细 */
  getGoodsByIoTask: (params: { receiveStoreId: string; taskUuid: string }) =>
    post<any[]>('/api/pcweb/inv/use/wrh/getGoodsByIoTask', params)
};

export default wholesaleUse;
