import {
  TareWeightTemplate,
  TareWeightTemplateQueryRequest,
  BaseRemoveRequest,
  TareWeightTemplateSaveRequest,
  BaseSaveResult,
  BaseRemoveResult
} from '@/dataModel/wrh/sorting/taretemplate/interface';
import request from '@/utils/request';
const { post } = request;

const tareTemplate = {
  query: (
    params: {
      filter: TareWeightTemplateQueryRequest;
    } & IPageParams
  ) => post<IPageData<TareWeightTemplate>>('/api/pcweb/tare/weight/template/query', params),
  /**
     * /api/pcweb/mdata/warehouse/picking/distline/remove
  删除门店线路
     * @param params
     * @returns
     */
  remove: (params: BaseRemoveRequest) => post<BaseRemoveResult>('/api/pcweb/tare/weight/template/remove', params),
  /**
       * /api/pcweb/mdata/warehouse/picking/distline/save
    保存门店线路
       * @param params
       * @returns
       */
  save: (params: TareWeightTemplateSaveRequest) => post<BaseSaveResult>('/api/pcweb/tare/weight/template/save', params),
  /**
       * /api/pcweb/mdata/warehouse/picking/distline/getByName
  根据名称获取门店线路信息
       * @param params
       * @returns
       */
  getByName: (params: { name: string }) => post<TareWeightTemplate>('/api/pcweb/tare/weight/template/getByName', params)
};
export default tareTemplate;
