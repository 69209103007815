import { IMdataBrandCreate, IMdataBrand, IMdataBrandDelete } from '@/dataModel/mdata/brand/interface';
import request from '@/utils/request';

export default {
  /**
   * 新增
   * @param params
   */
  create: (params: { id: string; name: string }) =>
    request.post<IMdataBrandCreate>('/api/pcweb/mdata/brand/create', params),
  /**
   * 通过标识获取品牌信息
   * @param params
   */
  get: (params: { uuid: string }) => request.post<IMdataBrand[]>('/api/pcweb/mdata/brand/get', params),
  /**
   * 获取所有品牌列表
   */
  getAll: () => request.post<IMdataBrand[]>('/api/pcweb/mdata/brand/getAll'),
  /**
   * 通过编码获取品牌信息
   * @param params
   */
  getById: (params: { id: string }) => request.post<IMdataBrand>('/api/pcweb/mdata/brand/getById', params),
  /**
   * 修改品牌
   * @param params
   */
  modify: (params: { name: string; uuid: string; version: number }) =>
    request.post<IMdataBrandCreate>('/api/pcweb/mdata/brand/modify', params),
  /**
   * 分页查询商品
   * @param params
   */
  query: (
    params: {
      filter: {
        key: string;
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => request.post<IPageData<IMdataBrand[]>>('/api/pcweb/mdata/brand/query', params),
  /**
   * 删除品牌
   * @param params
   */
  remove: (params: { uuid: string }) => request.post<IMdataBrandDelete>('/api/pcweb/mdata/brand/remove', params)
};
