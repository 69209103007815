import {
  IFinanceReceiptFees,
  IFinanceReceiptWholesale,
  IFinanceReceiptWholesaleSettle,
  IReceipt,
  IReceiptDetails
} from '@/dataModel/finance/receipt/interface';
import request from '@/utils/request';
import {
  PayBusinessTypeEnum,
  PayTypeEnum,
  ReceiptBillTypeEnum,
  ReceiptBusinessTypeEnum,
  ReceiptStatusEnum
} from '@/dataModel/dict/enum';

const { post } = request;

const receipt = {
  /**
   *  收款记录
   */
  query: (
    params: {
      filter?: {
        billId?: string;
        billToId?: string;
        endDate?: string;
        receiptDate?: string;
        settleNo?: string;
        startDate?: string;
        status?: string;
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => post<IPageData<IReceipt>>('/api/pcweb/finance/receipt/query', params),
  /**
   * 保存（状态变更）
   */
  save: (params: { billId: string; billType?: ReceiptBillTypeEnum; version: number; status: ReceiptStatusEnum }) =>
    post('/api/pcweb/finance/receipt/save', params),
  /**
   * 收款单(单号)
   */
  get: (params: { billId: string }) => post<IReceipt>('/api/pcweb/finance/receipt/get', params),
  /**
   * 获取单据列表
   * @param params
   * @returns
   */
  getBillInfo: (params: {
    bill?: string; // 单据标识
    billType?: ReceiptBillTypeEnum; // 批发结算单单据类型
    businessType: ReceiptBusinessTypeEnum; // 业务类型
    target: string; // 目标客户、供应商标识
  }) =>
    post<{
      fees: IFinanceReceiptFees[];
      sales: IFinanceReceiptWholesale[];
      settles: IFinanceReceiptWholesaleSettle[];
    }>('/api/pcweb/finance/receipt/getBillInfo', params),

  /**
   * 获取批发单信息
   */
  getWholeSaleInfo: (params: {
    customer: string; // 客户标识
  }) => post<IFinanceReceiptWholesale[]>('/api/pcweb/finance/receipt/getWholeSaleInfo', params),
  /**
   * 收款单（费用、批发明细）
   */
  getDetails: (params: { id: string }) =>
    post<{ fees: IFinanceReceiptFees[]; sales: IFinanceReceiptWholesale[]; settles: IFinanceReceiptWholesaleSettle[] }>(
      '/api/pcweb/finance/receipt/getDetails',
      params
    ),
  /**
   * 收款单（费用明细）
   */
  queryDetails: (params: { billId: string }) =>
    post<IPageData<IReceiptDetails>>('/api/pcweb/finance/receipt/queryDetails', params),
  /**
   * 新增
   */
  create: (params: {
    bankAccount?: string; // 银行账户uuid
    billToId: string;
    /** 单据类型 */
    billType: ReceiptBillTypeEnum;
    billUuids?: string[];
    payBusinessType: string;
    payType: string;
    receiptDate?: string;
    receiptTotal?: number;
    remark?: string;
  }) => post('/api/pcweb/finance/receipt/create', params),
  /**
   * 修改
   */
  modify: (params: {
    billId?: string;
    billToId: string;
    billUuids?: string[];
    payBusinessType: string;
    payType: string;
    receiptDate?: string;
    receiptTotal?: number;
    remark?: string;
    version?: number;
  }) => post('/api/pcweb/finance/receipt/modify', params),
  /**
   * 删除
   */
  remove: (params: { uuid: string }) => post('/api/pcweb/finance/receipt/remove', params),
  /**
   * 提交收款单
   */
  submit: (params: {
    bankAccount?: string;
    billId?: string;
    billToId: string;
    billUuids: string[];
    payBusinessType: string;
    payType: string;
    receiptDate?: string;
    receiptTotal?: number;
    remark?: string;
    status?: ReceiptStatusEnum;
    version?: number;
  }) => post('/api/pcweb/finance/receipt/submit', params),
  /**
   * 保存/修改收款单, 以及收款单状态的变更
   */
  saveStatus: (params: {
    bankAccount?: string;
    /** 单据单号-除保存外必传 */
    billId?: string;
    /** 供应商&门店-保存、修改时必传 */
    billToId: string;
    billType?: ReceiptBillTypeEnum;
    billUuids: string[];
    payBusinessType: PayBusinessTypeEnum;
    payType: PayTypeEnum;
    /** 应收款日期-保存、修改时必传 */
    receiptDate?: string;
    /** 款金额-保存、修改时必传 */
    receiptTotal: number;
    remark: string;
    targetStatus: ReceiptStatusEnum;
    version: number;
  }) => post<{ billId: string; traceId: string; version: number }>('/api/pcweb/finance/receipt/saveStatus', params)
};
export default receipt;
