import request from '@/utils/request';
const { post } = request;
import { ISaleAmtBySort, ISaleTotalByDay } from '@/dataModel/finance/dailySettlement/interface';
import { SortClassEnum } from '@/dataModel/dict/enum';

export default {
  /**大类销售 */
  querySaleAmtBySort: (
    params: {
      filter?: {
        endDate?: string;
        sortId?: string;
        startDate?: string;
        sortClass: SortClassEnum;
      };
    } & IPageParams
  ) => post<IPageData<ISaleAmtBySort>>('/api/pcweb/business/turnovermanage/accountquery/querySaleAmtBySort', params),
  /**大类销售汇总 */
  querySaleAmtBySortSum: (params: {
    filter?: {
      endDate?: string;
      sortId?: string;
      startDate?: string;
      sortClass: SortClassEnum;
    };
  }) => post('/api/pcweb/business/turnovermanage/accountquery/querySaleAmtBySortSum', params),
  /**营业收入 */
  querySaleTotalByDay: (
    params: {
      filter?: {
        currencyId?: string;
        endDate?: string;
        startDate?: string;
      };
    } & IPageParams
  ) => post<IPageData<ISaleTotalByDay>>('/api/pcweb/business/turnovermanage/accountquery/querySaleTotalByDay', params),
  /**营业收入统计 */
  querySaleTotalByDaySum: (params: {
    filter?: {
      currencyId?: string;
      endDate?: string;
      startDate?: string;
    };
  }) => post('/api/pcweb/business/turnovermanage/accountquery/querySaleTotalByDaySum', params)
};
