import { IPointQueryGoods, IPointQueryGoodsFilter, IPointQueryGoodsTotal } from '@/dataModel/member/point/interface';
import request from '@/utils/request';
const { post } = request;

const pointQueryGoods = {
  /** 积分兑换商品分页查询 */
  query: (
    params: {
      filter?: IPointQueryGoodsFilter;
    } & IPageParams
  ) => post<IPageData<IPointQueryGoods>>('/api/pcweb/member/point/queryGoods', params),

  /** 积分兑换商品汇总查询 */
  queryTotal: (
    params: {
      filter?: IPointQueryGoodsFilter;
    } & IPageParams
  ) => post<IPointQueryGoodsTotal>('/api/pcweb/member/point/getGoodsStat', params)
};

export default pointQueryGoods;
