import {
  ISettingDataGroupClass,
  ISystemSettingDataGroupGetDataGroup
} from '@/dataModel/system/settingDataGroup/interface';
import request from '@/utils/request';
const { post } = request;
export default {
  /**
   * 获取数据权限组分类信息
   */
  getDataGroupClass: (params: {
    filter: {
      isGetAll: boolean;
    };
  }) => post<ISettingDataGroupClass>('/api/pcweb/system/setting/datagroup/getDataGroupClass', params),
  /**
   * 获取数据权限组权限信息
   */
  getDataGroup: (params: { uuid: string }) =>
    post<ISystemSettingDataGroupGetDataGroup>('/api/pcweb/system/setting/datagroup/getDataGroup', params),
  /**
   * 修改
   */
  modify: (params: {
    dataGroupDetailModifyRequests: {
      object: string;
      objectId: string;
      objectName: string;
    }[];
    dataScope: string;
    groupType: string;
    id: string;
    name: string;
    remark: string;
    uuid: string;
    version: number;
  }) => post<any>('/api/pcweb/system/setting/datagroup/modify', params),
  /**
   * 新增
   */
  create: (params: {
    dataGroupDetailRequests?: {
      object: string;
      objectId: string;
      objectName: string;
    }[];
    dataScope: string;
    groupType: string;
    id?: string;
    name: string;
    remark?: string;
  }) => post<any>('/api/pcweb/system/setting/datagroup/create', params),
  /**
   * 删除
   */
  remove: (params: { uuid: string }) => post<any>('/api/pcweb/system/setting/datagroup/remove', params)
};
