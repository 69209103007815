import request from '@/utils/request';
import {
  QueryPurchasePlanListRes,
  PurchasePlanDetail,
  GetStoreRes,
  PurchasePlanStoreDetail,
  PurchasePlanSaveLine,
  BaseCreateResult,
  PurchasePlanSubmitResult,
  ISaveLineRes
} from '@/dataModel/purchase/purchasePlan/interface';
const { post } = request;

const purchasePlan = {
  /** 采购计划列表*/
  queryPurchasePlanList: (
    params: {
      filter: {
        batchNo?: string;
        goodsSearchKey?: string;
        id?: string;
        statusList?: string[];
        endDate?: string; //制单结束日期
        startDate?: string; //制单开始日期
      };
    } & IPageParams
  ) => post<IPageData<QueryPurchasePlanListRes>>('/api/pcweb/purchase/plan/query', params),

  /** 采购计划单据商品明细*/
  queryPurchasePlanDetail: (
    params: {
      bill: string;
      filter: {
        goodsSearchKey?: string;
        bill?: string;
        queryType?: string;
        sortId?: string;
        supplier?: string;
        /** 是否异常商品*/
        isErrorGoods?: boolean;
      };
    } & IPageParams
  ) => post<IPageData<PurchasePlanDetail>>('/api/pcweb/purchase/plan/queryDetail', params),

  /** 采购计划商品门店订购明细*/
  purchasePlanGetStore: (params: { detailUuid: string; bill: string }) =>
    post<GetStoreRes[]>('/api/pcweb/purchase/plan/getStore', params),

  /** 采购计划单据明细*/
  purchasePlanGetDetail: (params: { id: string; uuid: string }) =>
    post<PurchasePlanStoreDetail>('/api/pcweb/purchase/plan/get', params),

  /** 获取采购计划异常商品数目*/
  getErrorGoodsCount: (params: { bill: string }) =>
    post<{ errorGoodsCount: number }>('/api/pcweb/purchase/plan/getErrorGoodsCount', params),

  /** 商品行保存*/
  purchasePlanSaveLine: (params: {
    goodsInfo: {
      detailUuid: string;
      supplierInfos: any[];
    };
    uuid: string;
  }) => post<ISaveLineRes>('/api/pcweb/purchase/plan/saveLine', params),

  /** 一键生成*/
  submit: (params: { orderTargetStatus?: string; version: number; uuid: string; all: boolean; detailUuids?: any[] }) =>
    post<BaseCreateResult>('/api/pcweb/purchase/plan/submit', params),

  /** 一键生成结果查询*/
  getSubmitResult: (params: { uuid: string }) =>
    post<PurchasePlanSubmitResult>('/api/pcweb/purchase/plan/getSubmitResult', params),

  /** 一键生成结果确认*/
  sureSubmitResult: (params: { uuid: string }) => post<PurchasePlanSaveLine>('/api/pcweb/purchase/plan/sure', params),
  /** 采购计划门店订货明细*/
  storeOrderMsg: (params: { detailUuid: string }) => post<GetStoreRes[]>('/api/pcweb/purchase/plan/getStore', params),
  /** 批量设置采购员*/
  modifyPurchaser: (params: {
    /** 旧的采购员uuid*/
    detailUuids: string[];
    /** 采购计划单头uuid*/
    bill: string;
    /** 修改完的采购员uuid*/
    purchaser: string;
  }) => post<any[]>('/api/pcweb/purchase/plan/batchModifyPurchaser', params),
  /** 批量设置供应商*/
  setSupplier: (params: { detailUuids: string[]; supplier: string; uuid: string }) =>
    post<GetStoreRes[]>('/api/pcweb/purchase/plan/batchModifySupplier', params),
  /** 设置供应商*/
  setOneSupplier: (params: { detailUuid: string; supplier: string; uuid: string; oldSupplier: string }) =>
    post<GetStoreRes[]>('/api/pcweb/purchase/plan/modifySupplier', params),
  /** 采购信息明细*/
  purchaseMsgGetDetail: (params: { bill: string; detailUuid: string }) =>
    post<any>('/api/pcweb/purchase/plan/getDetail', params),
  /** 门店订货信息明细*/
  getPurchaseStoreInfo: (params: { bill: string; detailUuid: string }) =>
    post<any>('/api/pcweb/purchase/plan/getPurchaseStoreInfo', params),
  /** 门店订货信保存*/
  saveStore: (params: { uuid: string; detailUuid: string; storeInfos: any[] }) =>
    post<any>('/api/pcweb/purchase/plan/saveStore', params),
  /** 门店订货信保存*/
  finish: (params: { uuid: string; version: number }) => post<any>('/api/pcweb/purchase/plan/finish', params),
  /**
查询门店销售数据*/
  getStoreSell: (params: { goods: string; store: string }) =>
    post<any>('/api/pcweb/purchase/plan/getStoreSell', params),
  /**
查询门店销售数据*/
  getCount: (params: { uuid: string }) => post<any>('/api/pcweb/purchase/plan/getQueryCount', params)
};

export default purchasePlan;
