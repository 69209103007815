import request from '@/utils/request';
import {
  IGetDetailInfo,
  IQueryList,
  IQueryPurchaseOrderList,
  IReturnRecord
} from '@/dataModel/finance/supplierPrePay/interface';
import { ISupplier } from '@/dataModel/mdata/supplier/interface';

const { post } = request;

export default {
  /**
   * 分页查询
   */
  query: (
    params: {
      filter: {
        endCreateTime: string;
        endPayDate: string;
        id: number;
        startCreateTime: string;
        startPayDate: string;
        status: string;
        supplierInfo: string;
      };
    } & IPageParams
  ) => post<IPageData<IQueryList>>('/api/pcweb/finance/supplier/prepay/query', params),

  /**
   * 保存新增
   */
  save: (params: {
    bankAccount: string;
    payDate: string;
    payTotal: number;
    payType: string;
    purchaseOrder: string;
    purchaser: string;
    supplier: string;
    targetStatus: string;
    uuid?: string;
    version?: number;
  }) => post<{ traceId: string; uuid: string; version: number }>('/api/pcweb/finance/supplier/prepay/save', params),

  /**
   * 删除
   */
  remove: (params: { uuid: string }) => post('/api/pcweb/finance/supplier/prepay/remove', params),

  /**
   * 批量删除
   */
  removeBatch: (params: { uuids: string[] }) =>
    post<{ failedNum: number; successNum: number }>('/api/pcweb/finance/supplier/prepay/removeBatch', params),

  /**
   * 提交
   */
  submit: (params: { uuid: string; version: number }) =>
    post<{ traceId: string; successNum: number }>('/api/pcweb/finance/supplier/prepay/submit', params),

  /**
   * 审核
   */
  approve: (params: { uuid: string; version: number }) =>
    post<{ traceId: string; successNum: number }>('/api/pcweb/finance/supplier/prepay/approve', params),

  /**
   * 付款
   */
  pay: (params: { uuid: string; version: number }) =>
    post<{ traceId: string; successNum: number }>('/api/pcweb/finance/supplier/prepay/pay', params),

  /**
   * 作废
   */
  abort: (params: { uuid: string; version: number }) =>
    post<{ traceId: string; successNum: number }>('/api/pcweb/finance/supplier/prepay/abort', params),

  /**
   * 查询详情
   */
  getDetail: (params: { uuid: string }) => post<IGetDetailInfo>('/api/pcweb/finance/supplier/prepay/get', params),

  /**
   * 查询返款记录
   */
  getReturnLogs: (params: { uuid: string }) =>
    post<IReturnRecord[]>('/api/pcweb/finance/supplier/prepay/getReturnLogs', params),

  /**
   * 还款
   */
  refund: (params: { uuid: string; returnTotal: number; version: number }) =>
    post<{ traceId: string; successNum: number }>('/api/pcweb/finance/supplier/prepay/refund', params),

  /**
   * 查询采购订单
   */
  queryPurchaseOrder: (
    params: { filter: { id?: string; maxTotal?: number; minTotal?: number }; currentPrepayUuid?: string } & IPageParams
  ) => post<IPageData<IQueryPurchaseOrderList>>('/api/pcweb/finance/supplier/prepay/queryPurchaseOrder', params),

  /**
   * 根据供应商查询预付款单列表
   */
  getBySupplier: (params: { supplierId: ISupplier['id'] }) =>
    post<IGetDetailInfo[]>('/api/pcweb/finance/supplier/prepay/getBySupplier', params)
};
