import { IPurchaseSaleProfit, IPurchaseSaleProfitTotal } from '@/dataModel/finance/purchaseSaleProfit';
import request from '@/utils/request';
const { post } = request;

export default {
  /**进销毛利报表 */
  queryPurchaseSaleProfit: (
    params: {
      filter?: {
        startDate: string /**开始日期 */;
        endDate: string; // 结束日期
        storeIdList: string[]; // 门店
        sortType: string; // 类别维度
        sortIdList: string[]; // 分类集合
      };
    } & IPageParams
  ) => post<IPageData<IPurchaseSaleProfit>>('/api/pcweb/finance/report/margin/query', params),

  /**进销毛利报表合计 */
  getByPurchaseSaleProfitTotal: (params: {
    filter?: {
      startDate: string /**开始日期 */;
      endDate: string; // 结束日期
      storeIdList: string[]; // 门店
      sortType: string; // 类别维度
      sortIdList: string[]; // 分类集合
    };
  }) => post<IPurchaseSaleProfitTotal>('/api/pcweb/finance/report/margin/getTotal', params)
};
