import {
  IReportSale,
  INewReportSaleTotal,
  INewReportSale,
  INewReportSaleTotalByGoods,
  INewReportSaleByGoods,
  INewStreList,
  INewStreListTotal
} from '@/dataModel/finance/reportSale/interface';
import request from '@/utils/request';

const { post } = request;

export default {
  query: (
    params: {
      filter?: {
        endDate: string; //结束日期
        sortId: string[]; //分类
        startDate: string; //开始日期
        storeId: string[]; //门店
        supplierType: string; //供应商
        taxRate: string[]; //税率
      };
    } & IPageParams
  ) => post<IPageData<IReportSale>>('/api/pcweb/finance/sale/account/query', params),
  //IPageData<INewReportSale>
  newQuery: (
    params: {
      filter?: {
        businessModels: string;
        endTime: string;
        saleMethod: string;
        sortClass: string;
        sortIds: string[];
        startTime: string;
        stores: string[];
      };
    } & IPageParams
  ) => post<any>('/api/pcweb/report/drpt/queryDrptSaleFinanceSort', params),
  newQueryCount: (params: {
    filter?: {
      businessModels: string;
      endTime: string;
      saleMethod: string;
      sortClass: string;
      sortIds: string[];
      startTime: string;
      stores: string[];
    };
  }) => post<INewReportSaleTotal>('/api/pcweb/report/drpt/getDrptSaleFinanceSortCount', params),
  queryCount: (params: {
    filter?: {
      endDate: string; //结束日期
      sortId: string[]; //分类
      startDate: string; //开始日期
      storeId: string[]; //门店
      supplierType: string; //供应商
      taxRate: string[]; //税率
    };
  }) => post<any>('/api/pcweb/finance/sale/account/getCount', params),
  /**查税率列表 */
  getTaxRate: (params: {
    filter?: {
      endDate: string; //结束日期
      sortId: string[]; //分类
      startDate: string; //开始日期
      storeId: string[]; //门店
      supplierType: string; //供应商
      taxRate: string[]; //税率
    };
  }) => post<number[]>('/api/pcweb/finance/sale/account/getTaxRate', params),
  //IPageData<INewReportSaleByGoods>
  newQueryByGoods: (
    params: {
      filter?: {
        businessModels: string;
        endTime: string;
        noTaxSku: boolean;
        saleMethod: string;
        searchKey: string;
        sortIds: string[];
        startTime: string;
        stores: string[];
        suppliers?: string[];
      };
    } & IPageParams
  ) => post<any>('/api/pcweb/report/drpt/queryDrptSaleFinanceSku', params),
  newQueryCountByGoods: (params: {
    filter?: {
      businessModels: string;
      endTime: string;
      noTaxSku: boolean;
      saleMethod: string;
      searchKey: string;
      sortIds: string[];
      startTime: string;
      stores: string[];
      suppliers?: string[];
    };
  }) => post<INewReportSaleTotalByGoods>('/api/pcweb/report/drpt/getDrptSaleFinanceSkuCount', params),

  // 门店端查询 IPageData<INewStreList>
  getStroeQuery: (
    param: {
      filter?: {
        startTime?: string;
        endTime?: string;
        sortClass?: string;
        sortIds?: Array<[]>;
        saleMethod?: string;
        stores?: Array<[]>;
        businessModels?: Array<[]>;
      };
    } & IPageParams
  ) => post<any>('/api/pcweb/report/drpt/queryDrptSaleFinanceStore', param),
  getStroeQueryAll: (
    param: {
      filter?: {
        startTime?: string;
        endTime?: string;
        sortClass?: string;
        sortIds?: Array<[]>;
        saleMethod?: string;
        stores?: Array<[]>;
        businessModels?: Array<[]>;
      };
    } & IPageParams
  ) => post<INewStreListTotal>('/api/pcweb/report/drpt/getDrptSaleFinanceStoreCount', param)
};
