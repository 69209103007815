import request from '@/utils/request';
import { ISort, ISortGoods } from '@/dataModel/mdata/sort/interface';

const { post } = request;

const sort = {
  /**
   * 分类查询所有
   * @returns
   */
  getAll: (params?: { goodsTypes?: string[]; isSumSku?: boolean }) =>
    post<ISort[]>('/api/pcweb/mdata/sort/getAll', params),
  /**
   * 获得
   * @param params
   * @returns
   */
  get: params => post<ISort[]>('/api/pcweb/mdata/sort/get', params),
  /**
   * 分页查询分类资料
   * @param params
   * @returns
   */
  query: params => post<IPageData<ISort[]>>('/api/pcweb/mdata/sort/query', params),
  /**
   * 分类新增
   * @param params
   * @returns
   */
  create: params => post('/api/pcweb/mdata/sort/create', params),
  /**
   * 分类修改
   * @param params
   * @returns
   */
  modify: params => post('/api/pcweb/mdata/sort/modify', params),
  /**
   * 分类删除
   * @param params
   * @returns
   */
  remove: params => post('/api/pcweb/mdata/sort/remove', params),
  /**
   * 批量添加商品到指定分类
   * @param params
   * @returns
   */
  goodsAdd: params => post('/api/pcweb/mdata/sort/goods/add', params),
  /**
   * 批量移动商品到指定分类
   * @param params
   * @returns
   */
  goodsMove: params => post('/api/pcweb/mdata/sort/goods/move', params),
  /**
   * 分页查询指定分类下的商品，支持查询未指定分类的商品
   * @param params
   * @returns
   */
  goodsQuery: params => post<IPageData<ISortGoods[]>>('/api/pcweb/mdata/sort/goods/query', params)
};

export default sort;
