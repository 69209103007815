import { IStoreMarket, IStoreMarketDetailGoods, IStoreMarketGoods } from '@/dataModel/business/storeMarket/interface';
import { StoreDirectStatusEnum } from '@/dataModel/dict/enum';
import request from '@/utils/request';
const { post } = request;

const storeMarket = {
  /**分页查询单据 */
  query: (
    params: {
      filter: {
        creatorId: string;
        endDate: string;
        startDate: string;
        idLike: string;
        goodsInfo?: string;
        supplerId: string;
      };
    } & IPageParams
  ) => post<IPageData<IStoreMarket>>('/api/pcweb/business/store/market/query', params),

  /**获取商品列表（新增或编辑*/
  getGoods: (params: { bill?: string; keyWord?: string }) =>
    post<IStoreMarketGoods[]>('/api/pcweb/business/store/market/getGoods', params),

  /**分页查询商品*/
  queryGoods: (
    params: {
      filter: {
        keyWord?: string;
        sortId?: string;
      };
    } & IPageParams
  ) => post<IPageData<IStoreMarketGoods>>('/api/pcweb/business/store/market/queryGoods', params),

  /**保存（创建、修改、保存并提交）*/
  save: (params: {
    bill: string;
    info: {
      details: {
        goodsMunitUuid: string;
        price: number;
        qty: number;
        remark: string;
        productDate?: string;
        validDate?: string;
      }[];
      occurDate: string;
      remark: string;
      supplierId: string;
    };
    targetStatus: StoreDirectStatusEnum;
    version: number;
  }) => post<any>('/api/pcweb/business/store/market/save', params),

  /**获取单头*/
  get: (params: { id?: string; uuid?: string }) => post<IStoreMarket>('/api/pcweb/business/store/market/get', params),

  /**提交单据*/
  submit: (params: { bill: string; version: number }) => post<any>('/api/pcweb/business/store/market/submit', params),

  /**删除单据*/
  remove: (params: { uuid: string }) => post<any>('/api/pcweb/business/store/market/remove', params),

  /**分页查询单据明细 */
  queryDetail: (
    params: {
      bill: string;
      filter: {
        keyword?: string;
      };
    } & IPageParams
  ) => post<IPageData<IStoreMarketDetailGoods>>('/api/pcweb/business/store/market/queryDetail', params),

  /**
   * 作废单据
   */
  abort: (params: { bill: string; version: number }) => post<any>('/api/pcweb/business/store/market/abort', params),

  /**
   * 根据导入任务获取明细信息
   * @param params
   */
  getGoodsByIoTask: (params: { taskUuid: string }) =>
    post<IStoreMarketGoods[]>('/api/pcweb/business/store/market/getGoodsByIoTask', params),
  /**
   * 单据测试
   * @param params
   */
  saveBill: (params: { billContent: string; businessType: string }) =>
    post<any>('/api/pcweb/cache/bill/saveBill', params),
  /**
   *  清空
   * @param params
   */
  clear: (params: { businessType: string }) => post<any>('/api/pcweb/cache/bill/clear', params),
  /**
   *  清空
   * @param params
   */
  saveDetail: (params: { businessType: string; detailList: any }) =>
    post<any>('/api/pcweb/cache/bill/saveDetail', params),
  /**
   *  清空
   * @param 获取单据缓存
   */
  getBill: (params: { businessType: string }) => post<any>('/api/pcweb/cache/bill/get', params)
};

export default storeMarket;
