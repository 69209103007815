import { IOmallPriceLevel, IOmallPriceLevelGoods } from '@/dataModel/mdata/omallPriceLevel/interface';
import request from '@/utils/request';

const omallPriceLevel = {
  query: () => request.post<IOmallPriceLevel[]>('/api/pcweb/mdata/omall/price/level/query'),
  modifyLine: (params: {
    levelLineList: {
      uuid: string;
      line: number;
    }[];
  }) => request.post('/api/pcweb/mdata/omall/price/level/modifyLine', params),
  remove: (params: { uuid: string }) => request.post('/api/pcweb/mdata/omall/price/level/remove', params),
  save: (params: {
    //transportFeeUuid: string;
    name: string;
    levelGoods: {
      discountFixedPrice: number;
      discountRate: number;
      fixedPrice: number;
      goodsUuid: string;
      levelPriceType: string;
    }[];
  }) => request.post('/api/pcweb/mdata/omall/price/level/save', params),
  get: (params: { uuid: string }) => request.post<IOmallPriceLevel>('/api/pcweb/mdata/omall/price/level/get', params),
  modify: (params: {
    //transportFeeUuid: string;
    name: string;
    levelGoods: {
      discountFixedPrice: number;
      discountRate: number;
      fixedPrice: number;
      goodsUuid: string;
      levelPriceType: string;
    }[];
    uuid: string;
    version: number;
  }) => request.post('/api/pcweb/mdata/omall/price/level/modify', params),
  getLevelGoodsList: (params: { uuid: string }) =>
    request.post<IOmallPriceLevelGoods[]>('/api/pcweb/mdata/omall/price/level/getLevelGoodsList', params),
  queryLevelGoods: (
    params: {
      filter?: {
        goodsKeyword?: string;
        isNonePrice?: boolean;
      };
      uuid: string;
    } & IPageParams
  ) => request.post<IPageData<IOmallPriceLevelGoods>>('/api/pcweb/mdata/omall/price/level/queryLevelGoods', params),
  queryGoodsForAdd: (
    params: {
      filter?: {
        keyword?: string;
        sortId?: string;
      };
    } & IPageParams
  ) => request.post<IPageData<IOmallPriceLevelGoods>>('/api/pcweb/mdata/omall/price/level/queryGoodsForAdd', params),
  checkName: (params: { name: string }) =>
    request.post<{
      info: string;
      success: boolean;
    }>('/api/pcweb/mdata/omall/price/level/checkName', params),
  getGoodsByIoTask: (params: { taskUuid: string }) =>
    request.post<IOmallPriceLevelGoods[]>('/api/pcweb/mdata/omall/price/level/getGoodsByIoTask', params),

  /** 修改客户等级名*/
  modifyName: (params: { name: string; uuid: string; version: number }) =>
    request.post<IOmallPriceLevelGoods[]>('/api/pcweb/mdata/omall/price/level/modifyName', params),
  /** 添加商品*/
  addGoods: (params: { goodsList: string[]; levelUuid: string }) =>
    request.post<IOmallPriceLevelGoods[]>('/api/pcweb/mdata/omall/price/level/addGoods', params),
  queryGoods: (
    params: {
      filter?: {
        keyword?: string;
        sortId?: string;
      };
      levelUuid: string;
    } & IPageParams
  ) => request.post<IPageData<IOmallPriceLevelGoods>>('/api/pcweb/mdata/omall/price/level/queryGoods', params),
  saveGoods: (params: {
    discountFixedPrice: number | string;
    discountRate: number | string;
    fixedPrice: number | string;
    levelPriceType: string;
    lineUuid: string;
    version: number;
  }) => request.post<IOmallPriceLevelGoods>('/api/pcweb/mdata/omall/price/level/saveGoods', params),
  create: (params: { name: string; nuid: string }) =>
    request.post<IOmallPriceLevelGoods>('/api/pcweb/mdata/omall/price/level/create', params),
  removeGoods: (params: { levelUuid: string; lineUuids: string[] }) =>
    request.post<IOmallPriceLevelGoods>('/api/pcweb/mdata/omall/price/level/removeGoods', params)
};
export default omallPriceLevel;
