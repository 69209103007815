import { RoleValidateFieldEnum } from '@/dataModel/dict/enum';
import { ISettingRole, ISettingRoleGroup } from '@/dataModel/system/settingRole';
import request from '@/utils/request';

const { post } = request;

const settingRole = {
  /**
   * 查询所有角色-下拉框用
   */
  queryAllGroup: (params?: { wid?: string }) =>
    post<ISettingRoleGroup[]>('/api/pcweb/system/setting/role/queryAllGroup', params),

  /**
   * 查询所有角色-不含分组信息
   */
  queryAll: () => post<ISettingRole[]>('/api/pcweb/system/setting/role/queryAll'),

  /**
   * 查询所有角色-含分组信息
   */
  queryRoleInfo: (params?: { roleInfo?: ISettingRole['id'] | ISettingRole['name'] }) =>
    post<{
      groupVoList: ISettingRoleGroup[];
      roleVoList: ISettingRole[];
    }>('/api/pcweb/system/setting/role/queryRoleInfo', params),

  /**
   * 校验角色管理某个字段是否重复
   */
  validateField: (params: { target: RoleValidateFieldEnum; value: any }) =>
    post<{
      /**
       * true通过, false不通过
       */
      result: boolean;
    }>('/api/pcweb/system/setting/role/validateField', params),

  /**
   * 新建分组
   */
  createGroup: (params: { id?: ISettingRoleGroup['id']; name: ISettingRoleGroup['name'] }) =>
    post<any>('/api/pcweb/system/setting/role/createGroup', params),

  /**
   * 修改分组
   */
  modifyGroup: (params: {
    uuid: ISettingRoleGroup['uuid'];
    name: ISettingRoleGroup['name'];
    version: ISettingRoleGroup['version'];
  }) => post<any>('/api/pcweb/system/setting/role/modifyGroup', params),

  /**
   * 删除分组
   */
  deleteGroup: (params: { uuid: ISettingRoleGroup['uuid'] }) =>
    post<any>('/api/pcweb/system/setting/role/deleteGroup', params),

  /**
   * 新增角色
   */
  create: (params: { name: ISettingRole['name']; id?: ISettingRole['id']; roleGroup?: ISettingRole['roleGroup'] }) =>
    post<any>('/api/pcweb/system/setting/role/create', params),

  /**
   * 修改角色
   */
  modify: (params: {
    name: ISettingRole['name'];
    uuid: ISettingRole['uuid'];
    version: ISettingRole['version'];
    roleGroup?: ISettingRole['roleGroup'];
  }) => post<any>('/api/pcweb/system/setting/role/modify', params),

  /**
   * 删除角色
   */
  deleteRole: (params: { uuid: ISettingRole['uuid'] }) =>
    post<any>('/api/pcweb/system/setting/role/deleteRole', params),

  /**
   * 移动角色
   */
  move: (params: {
    uuid: ISettingRole['uuid'];
    version: ISettingRole['version'];
    roleGroup?: ISettingRole['roleGroup'];
  }) => post<any>('/api/pcweb/system/setting/role/move', params),

  /**
   * 查角色详情-含权限id
   */
  getDetail: (params: { id?: ISettingRole['id']; uuid?: ISettingRole['uuid'] }) =>
    post<ISettingRole>('/api/pcweb/system/setting/role/getDetail', params),

  /**
   * 保存角色权限明细
   */
  saveRolePerm: (params: {
    uuid: ISettingRole['uuid'];
    version: ISettingRole['version'];
    permIdList: ISettingRole['permIdList'];
  }) => post<any>('/api/pcweb/system/setting/role/saveRolePerm', params)
};

export default settingRole;
