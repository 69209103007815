import request from '@/utils/request';
import { IEquipmentCashRegisterQuery, IAuthCodeCountInfo } from '@/dataModel/equipment/authorizeCode/interface';

const { post } = request;

export default {
  /**
   * 查询授权码统计信息
   * @param params
   * @returns
   */
  getCountInfo: () => post<IAuthCodeCountInfo>('/api/pcweb/equipment/authCode/getCountInfo', {}, {}, 'pos'),
  /**
   * 分页查询授权码
   * @param params
   * @returns
   */
  query: (
    params: {
      filter?: {
        deviceSn?: string;
        id?: string;
        status?: string;
        storeUuid?: string;
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => post<IPageData<IEquipmentCashRegisterQuery>>('/api/pcweb/equipment/authCode/query', params, {}, 'pos'),

  /**
   * 导出授权码文件
   * @param params
   * @returns
   */
  export: (params: { ids: string[] }) =>
    post<{
      url: string;
      fileName: string;
    }>('/api/pcweb/equipment/authCode/export', params, {}, 'pos')
};
