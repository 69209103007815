import request from '@/utils/request';
import { IOMallHome } from '@/dataModel/oMall/home/interface';
import { IReqParamsOMallHomeSetup } from './interface';

const oMallHome = {
  /** 获取首页装修配置 */
  get: () => request.post<IOMallHome>('/api/pcweb/omall/home/get'),

  /** 修改首页装修配置 */
  setup: (params: IReqParamsOMallHomeSetup) => request.post<IOMallHome>('/api/pcweb/omall/home/setup', params)
};

export default oMallHome;
