import { IQueryItems } from '@/dataModel/finance/checkCustomer/interface';
import request from '@/utils/request';

export default {
  /**
   * 总部分页查询待核对/已核对
   */
  query: (
    params: {
      filter: {
        accountStatus?: string;
        receiptStatus?: string;
        billType: string;
        createDateBegin: string;
        createDateEnd: string;
        customerSearchKey: string;
        id: string;
        org: string;
        salesManSearchKey: string;
        settleNo: number;
      };
      isChecked: boolean;
    } & IPageParams
  ) => request.post<IPageData<IQueryItems>>('/api/pcweb/finance/check/customer/queryBill', params),

  /**
   * 完成核对/批量核对--列表页
   */
  batchCheck: (params: {
    checks: {
      billType: string;
      uuid: string;
      version: number;
    }[];
  }) =>
    request.post<{ failedCount: number; successCount: number }>('/api/pcweb/finance/check/customer/batchCheck', params)
};
