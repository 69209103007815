import { ITicketStore } from '@/dataModel/equipment/ticketStore';
import request from '@/utils/request';

const { post } = request;

const ticketStore = {
  /** 查询小票设置详情 */
  get: () => post<ITicketStore>('/api/pcweb/retail/ticket/store/get', {}, {}, 'pos'),
  /** 修改门店小票设置 */
  modify: (
    params: Pick<
      ITicketStore,
      | 'autoPrintAfterSale'
      | 'autoPrintAfterSaleReturn'
      | 'encryptFlowNo'
      | 'printCopies'
      | 'saleReturnTicketTemplate'
      | 'saleTicketTemplate'
      | 'ticketWidth'
      | 'uuid'
      | 'version'
    >
  ) => post<{ version: ITicketStore['version'] }>('/api/pcweb/retail/ticket/store/modify', params, {}, 'pos')
};

export default ticketStore;
