import request from '@/utils/request';

export default {
  // /** 门店销售-订单管理-自提 */
  pickup: (params: { uuid: string; pickUpCode: string; platform: string }) =>
    request.post('/api/pcweb/onlineorder/pickup', params, {}, 'onlineOrder'),
  //**门店销售-订单刊-接单 */
  take: (params: { uuid: string }) => request.post('/api/pcweb/onlineorder/take', params, {}, 'onlineOrder'),
  //**门店销售-订单刊-取消订单 */
  cancel: (params: { uuid: string; reason: string }) => request.post('/api/pcweb/onlineorder/cancel', params, {}, 'onlineOrder'),
  //**门店销售-订单刊-拒绝订单 */
  refuse: (params: { uuid: string; reason: string }) => request.post('/api/pcweb/onlineorder/refuse', params, {}, 'onlineOrder'),
  //**门店销售-订单刊-拣货完成 */
  // finished: (params: { uuid: string; number: string }) =>
  //   request.post('/api/pcweb/onlineorder/prepare/finished', params, {}, 'onlineOrder'),
  finished: (params: {
    uuid: string;
    number?: string;
    details?: {
      /** 订单行id */
      orderLineId: string;
      /** 实拣数量 */
      pickingQty: number;
      /** 实拣重量 */
      pickingWeights: number[];
    }[];
  }) => request.post('/api/pcweb/onlineorder/prepare/finished', params, {}, 'onlineOrder'),
  //**门店销售-订单刊-配合完成-门店自配 */
  storefinished: (params: { uuid: string }) => request.post('/api/pcweb/onlineorder/prepare/finished', params, {}, 'onlineOrder'),
  /**配送完成。用于门店自配 */
  deliverFinished: (params: { uuid: string }) => request.post('/api/pcweb/onlineorder/deliver/finished', params, {}, 'onlineOrder')
};
