import { IMemberPoint } from '@/dataModel/member/memberPoint/interface';
import request from '@/utils/request';
const { post } = request;
export default {
  /**总部人员查询门店商品配送到店的商品明细 */
  query: (
    params: {
      filter?: {
        goodsInfo?: string;
        memberInfo?: string;
        operator?: string;
        orderChannel?: string;
        orderTimeGreaterEq?: string;
        orderTimeLessEq?: string;
        storeId?: string;
      };
    } & IPageParams
  ) => post<IPageData<IMemberPoint>>('/api/pcweb/member/point/query', params)
};
