import { IQueryAdvertising, IGet, IQueryMedia } from '@/dataModel/retail/secscreen/interface';
import request from '@/utils/request';
import { SecScreenMediaTypeEnum } from '@/dataModel/dict/enum';

const { post } = request;

const secscreen = {
  /**
   * 分页查询广告方案
   */
  queryAdvertising: (
    params: {
      filter?: {
        /**
         * effecting:生效中 noEffect：待生效 effected：已失效
         */
        effect?: string;
        /**
         * 方案名称
         */
        name?: string;
        /**
         * 生效门店编码
         */
        storeId?: string;
      };
    } & IPageParams
  ) => post<IPageData<IQueryAdvertising>>('/api/pcweb/retail/secscreen/queryAdvertising', params, {}, 'pos'),
  /**
   * 获取广告方案详情
   */
  get: (params: {
    /**
     * 广告方案唯一标识
     */
    uuid: string;
  }) => post<IGet>('/api/pcweb/retail/secscreen/get', params, {}, 'pos'),
  /**
   * 新增媒体中心
   */
  createMedia: (params: {
    /**
     * 媒体素材列表
     */
    medias: {
      /**
       * 文件格式
       */
      extion: string;
      /**
       * 文件类型 image：图片，video：视频
       */
      mediaType: SecScreenMediaTypeEnum;
      /**
       * 文件格式
       */
      name: string;
      /**
       * 文件URl
       */
      url: string;
    }[];
  }) => post('/api/pcweb/retail/secscreen/createMedia', params, {}, 'pos'),
  /**
   * 分页查询图片/视频素材管理
   */
  queryMedia: (
    params: {
      /**
       * 文件类型
       */
      mediaType: SecScreenMediaTypeEnum;
      /**
       * 过滤
       */
      filter?: {
        /**
         * 素材格式
         */
        extion?: string;
        /**
         * 素材名称
         */
        name?: string;
      };
    } & IPageParams
  ) => post<IPageData<IQueryMedia>>('/api/pcweb/retail/secscreen/queryMedia', params, {}, 'pos'),
  /**
   * 删除素材
   */
  removeMedia: (params: {
    /**
     * 媒体素材唯一标识
     */
    uuid: string;
  }) => post('/api/pcweb/retail/secscreen/removeMedia', params, {}, 'pos'),
  /**
   * 新增广告方案
   */
  createAdvertising: (params: {
    /** 截止天 */
    cycleDayEnd: string;
    /** 起始天 */
    cycleDayStart: string;
    /** 周期模式 */
    cycleMode: string;
    /** 截止时间 */
    cycleTimeEnd: string;
    /** 起始时间 */
    endTime: string;
    /** 起始时间 */
    intervalSecond: number;
    /** 广告方案名称 */
    mediaUrls: {
      extion?: string;
      /** 图片视频顺序 */
      index: number;
      mediaType: SecScreenMediaTypeEnum;
      name?: string;
      url1: string;
    }[];
    /** 开始时间 */
    startTime: string;
    /** 门店ID */
    storeId: string;
    /** 门店范围类型 all：全部门店，assign：指定门店*/
    storeScope: string;
    /** 门店唯一标识列表 */
    stores: string;
    /** 广告方案名称 */
    name: string;
  }) => post('/api/pcweb/retail/secscreen/createAdvertising', params, {}, 'pos'),
  /**
   * 编辑广告方案
   */
  modifyAdvertising: (params: {
    /** 截止天 */ cycleDayEnd: string;
    /** 起始天 */
    cycleDayStart: string;
    /** 周期模式 */
    cycleMode: string;
    /** 截止时间 */
    cycleTimeEnd: string;
    /** 起始时间 */
    endTime: string;
    /** 起始时间 */
    intervalSecond: number;
    /** 广告方案名称 */
    mediaUrls: {
      extion?: string;
      /** 图片视频顺序 */
      index: number;
      mediaType: SecScreenMediaTypeEnum;
      name?: string;
      url1: string;
    }[];
    /** 开始时间 */
    startTime: string;
    /** 门店ID */
    storeId: string;
    /** 门店范围类型 all：全部门店，assign：指定门店*/
    storeScope: string;
    /** 门店唯一标识列表 */
    stores: string;
    /** 广告方案名称 */
    name: string;
    uuid: string;
  }) => post('/api/pcweb/retail/secscreen/modifyAdvertising', params, {}, 'pos'),
  /**
   * 删除广告方案
   */
  removeAdvertising: (params: { uuid: string }) =>
    post('/api/pcweb/retail/secscreen/removeAdvertising', params, {}, 'pos')
};

export default secscreen;
