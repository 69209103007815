import { ChannelEnum, StoreStatusEnum, StoreTypeEnum } from '@/dataModel/dict/enum';
import { IExchangeLoginStore, IMdataStoreQuery, IStore, IStoreType } from '@/dataModel/mdata/store/interface';
import request from '@/utils/request';

export default {
  // create: params => request.post('/api/pcweb/mdata/store/create', params),

  // remove: params => request.post('/api/pcweb/mdata/store/remove', params),

  // modify: params => request.post('/api/pcweb/mdata/store/modify', params),

  // query: (params?: any) => request.post<IPageData<any>>('/api/pcweb/mdata/store/query', params),

  // get: (params: { uuid: string }) => request.post('/api/pcweb/mdata/store/get', params)
  getAll: () => request.post<IStore[]>('/api/pcweb/mdata/store/getAll'),

  /** 获取所有已用/可用门店数 */
  getAllEnabledAndUnLock: () =>
    request.post<{
      availableCount: number; // 可用门店数量
      usedCount: number; // 已用门店数量
    }>('/api/pcweb/mdata/store/getAllEnabledAndUnLock'),
  /**
   * 分页查询所有门店资料（权限）
   * @returns
   */
  queryAllHasPrivilege: (
    params: {
      filter?: {
        /** 配送方案 uuid */
        distSchema?: string;
        locationCity?: string;
        locationCounty?: string;
        locationProvince?: string;
        organization?: string;
        searchKey?: string;
        storeLevel?: string;
        storeType?: StoreTypeEnum;
        status?: StoreStatusEnum;
        /**是否包含开通线上渠道 */
        containSaleChannel?: boolean;
        /**过滤不包含的门店 */
        excludeWmStoreIds?: string[];
        storeModelList?: string[];
      };
    } & IPageParams
  ) =>
    request.post<
      IPageData<IStore> & {
        /** 可用门店数量 */
        licenseStoreCount: number;
      }
    >('/api/pcweb/mdata/store/queryAllHasPrivilege', params),
  /**
   *
   * @param params 下拉查询所有门店
   * @returns
   */
  getAllHasPrivilege: () => request.post<IStore[]>('/api/pcweb/mdata/store/getAllHasPrivilege'),
  /**
   * 分页查询所有门店资料
   * @returns
   */
  query: (
    params: {
      filter?: {
        /** 配送方案 uuid */
        distSchema?: string;
        locationCity?: string;
        locationCounty?: string;
        locationProvince?: string;
        organization?: string;
        searchKey?: string;
        storeLevel?: string;
        storeType?: StoreTypeEnum;
        status?: StoreStatusEnum;
        /**是否包含开通线上渠道 */
        containSaleChannel?: boolean;
        /**过滤不包含的门店 */
        excludeWmStoreIds?: string[];
        storeModelList?: string[];
        /**平台key */
        channel?: ChannelEnum;
      };
    } & IPageParams
  ) =>
    request.post<
      IPageData<IStore> & {
        /** 可用门店数量 */
        licenseStoreCount: number;
      }
    >('/api/pcweb/mdata/store/query', params),
  /**
   *
   * @param params 店型
   * @returns /api/pcweb/mdata/store/type/getAll
   */
  getAllStoreType: () => {
    return request.post<IStoreType[]>('/api/pcweb/mdata/store/type/getAll');
  },
  saveStoreAllotDataBatch: (params: { allotUuid: string; qty: number; storeUuids: string[] }) => {
    return request.post('/api/pcweb/business/wrh/allot/pre/saveStoreAllotDataBatch', params);
  },
  /**
   *
   * @param params 保存 /api/pcweb/mdata/store/type/save 保存
   * @returns
   */
  addStoreSave: params => {
    return request.post('/api/pcweb/mdata/store/type/save', params);
  },
  /**
   *
   * @param params /api/pcweb/mdata/store/type/modify 修改
   * @returns
   */
  modifyStoreData: (params: { name; remark; uuid; version }) => {
    return request.post('/api/pcweb/mdata/store/type/modify', params);
  },
  /**
   *
   * @param params /api/pcweb/mdata/store/type/remove
删除
   * @returns
   */
  removeStoreModel: (params: { uuid: string }) => {
    return request.post('/api/pcweb/mdata/store/type/remove', params);
  },
  /**
   *
   * @param params
   * @returns
   */
  modifyStore: params => {
    return request.post('/api/pcweb/mdata/store/modifyStore', params);
  },
  /**
   * 根据供应商ID查询门店资料
   * @returns
   */
  querySupplierStore: (
    params: {
      filter?: {
        /**供应商id */
        supplier: string;
        /** 配送方案 uuid */
        distSchema?: string;
        locationCity?: string;
        locationCounty?: string;
        locationProvince?: string;
        organization?: string;
        searchKey?: string;
        storeLevel?: string;
        storeType?: StoreTypeEnum;
        status?: StoreStatusEnum;
        /**是否包含开通线上渠道 */
        containSaleChannel?: boolean;
      };
    } & IPageParams
  ) =>
    request.post<
      IPageData<IMdataStoreQuery> & {
        /** 可用门店数量 */
        licenseStoreCount: number;
      }
    >('/api/pcweb/mdata/store/querySupplierStore', params),
  /**
   * 查询门店详情
   * @returns
   */
  get: (params: { uuid: string }) => request.post<IStore>('/api/pcweb/mdata/store/get', params),

  /**
   * 启用门店
   * @returns
   */
  start: (params: { store: string; version: number }) => request.post<any>('/api/pcweb/mdata/store/start', params),

  /**
   * 停用门店
   * @returns
   */
  stop: (params: { store: string; version: number }) => request.post<any>('/api/pcweb/mdata/store/stop', params),

  /**
   * 添加门店资料
   * @returns
   */
  create: (params: {
    contactPhone: number;
    customerPhone: string;
    id: string;
    level?: string;
    locationAddress: string;
    locationCity: string;
    locationCityName: string;
    locationCounty: string;
    locationCountyName: string;
    locationProvince: string;
    locationProvinceName: string;
    logoUrl?: string;
    manager: string;
    name: string;
    organization: string;
    square?: number;
    storeType?: string;
  }) => request.post<any>('/api/pcweb/mdata/store/create', params),

  /**
   * 修改门店资料
   * @returns
   */
  modify: (
    params: Partial<{
      contactPhone: number;
      customerPhone: string;
      id: string;
      level?: string;
      locationAddress: string;
      locationCity: string;
      locationCityName: string;
      locationCounty: string;
      locationCountyName: string;
      locationProvince: string;
      locationProvinceName: string;
      logoUrl?: string;
      manager: string;
      name: string;
      organization: string;
      square?: number;
      storeType?: string;
      uuid: string;
      version: number;
      distSchema?: string;
    }>
  ) => request.post<any>('/api/pcweb/mdata/store/modify', params),

  /**
   * 修改门店组织
   * @returns
   */
  alterOrganizationBatch: (params: { organization: string; uuidList: string[] }) =>
    request.post<any>('/api/pcweb/mdata/store/alterOrganizationBatch', params),

  /**
   * 获取可登录门店信息-含总部
   */
  getLoginAbleStore: (params: { mobile: string }) =>
    request.post<IExchangeLoginStore[]>('/api/pcweb/mdata/store/getLoginAbleStore', params),

  /**
   * 校验字段
   */
  validate: (params: { target: string; value: string }) => request.post<any>('/api/pcweb/mdata/store/validate', params),
  /**
   * 获取供应商支持的门店
   */
  queryStoreForSupplier: () => request.post<any>('/api/pcweb/mdata/store/queryStoreForSupplier'),
  /**获取门店编码 */
  getNewId: () => request.post<any>('/api/pcweb/mdata/store/getNewId'),

  /**查询商品对应门店绑定的线上销售渠道 */
  getChannelsByStore: (params: { storeIds: string[]; munitUuid: string }) =>
    request.post<{
      [storeId: string]: {
        selectedSaleChannels: ChannelEnum[];
        allSaleChannels: ChannelEnum[];
      };
    }>('/api/pcweb/mdata/online/store/getChannels/bystore', params)
};
