import { IQueryCabinet, ISearchData, IStoreCabinetData, ISupplierListData } from '@/dataModel/mdata/cabinet/interface';
import request from '@/utils/request';

export default {
  /** 获取所有柜组资料 */
  queryCabinet: (params?: { selectStoreId: string }) =>
    request.post<IQueryCabinet[]>('/api/pcweb/mdata/cabinet/getAll', params),

  /** 添加门店商品到柜组 */
  bindCabinetGoods: (params: { storeGoodsUuid: string; uuid: string }) =>
    request.post<{ traceId: string }>('/api/pcweb/mdata/cabinet/bindCabinetGoods', params),

  /** 根据父类id获取柜组资料 */
  getByParentId: (params: { parentId: string; selectStoreId: string }) =>
    request.post<IQueryCabinet[]>('/api/pcweb/mdata/cabinet/getByParentId', params),

  /** 删除 */
  remove: (params: { uuid: string }) => request.post<{ traceId: string }>('/api/pcweb/mdata/cabinet/remove', params),

  /** 保存柜组资料 */
  save: (params: {
    name: string;
    parentId?: string;
    rate?: number;
    selectStoreId: string;
    supplierId?: string;
    uuid?: string;
    version?: number;
  }) => request.post<{ traceId: string }>('/api/pcweb/mdata/cabinet/save', params),

  /** 获取门店列表【用于列表查询】 */
  getStoreListForSearch: () => request.post<ISearchData[]>('/api/pcweb/mdata/cabinet/store/getStoreListForSearch'),

  /** 获取供应商列表【用于列表查询】 */
  getSupplierListForSearch: () =>
    request.post<ISearchData[]>('/api/pcweb/mdata/cabinet/store/getSupplierListForSearch'),

  /** 根据条件筛选 */
  getsByFilter: (params: { selectStoreId: string; supplierId: string }) =>
    request.post<IStoreCabinetData[]>('/api/pcweb/mdata/cabinet/store/getsByFilter', params),

  /** 获取门店列表【用于添加柜组】 */
  getStoreListForAdd: () => request.post<ISearchData[]>('/api/pcweb/mdata/cabinet/store/getStoreListForAdd'),

  /** 添加门店柜组 */
  create: (params: { selectStoreId: string }) =>
    request.post<{ traceId: string; uuid: string }>('/api/pcweb/mdata/cabinet/store/create', params),

  /** 复制 */
  copy: (params: { sourceStoreId: string; targetStoreId: string }) =>
    request.post<{ traceId: string; uuid: string }>('/api/pcweb/mdata/cabinet/store/copy', params),

  /** 删除门店 */
  removeStore: (params: { uuid: string }) =>
    request.post<{ traceId: string }>('/api/pcweb/mdata/cabinet/store/remove', params),

  /** 根据门店编码获取 */
  getByStoreId: (params?: { selectStoreId: string }) =>
    request.post<IStoreCabinetData>('/api/pcweb/mdata/cabinet/store/getByStoreId', params),

  /** 获取供应商列表 */
  getSupplierList: () => request.post<ISupplierListData[]>('/api/pcweb/mdata/cabinet/getSupplierList')
};
