import request from '@/utils/request';
import {
  IReportDaily,
  IReportDailyTotal,
  IReportDailyGeneralSort,
  IReportDailyMediumSort,
  IReportDailySubSort,
  IReportDailyGoods
} from '@/dataModel/finance/reportDaily/interface';
const { post } = request;

export default {
  /**查询进销存日报合计 废弃 */
  queryTotalByWrh: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        goodsId?: string; //商品编码
        storeId?: string; //门店编码
        goodsBarcode?: string; //商品条码
        goodsName?: string; //商品名称
        sortId?: string; //商品分类
        searchKey?: string; //模糊查询
      };
    } & IPageParams
  ) => post<IReportDailyTotal>('/api/pcweb/finance/report/daily/wrh/queryTotalByStore', params),
  /**按仓库查询进销存日报 */
  queryByWrh: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        storeId?: string; //门店Id
      };
    } & IPageParams
  ) => post<IPageData<IReportDaily>>('/api/pcweb/finance/report/daily/wrh/queryByStore', params),
  /**按仓库查询进销存日报合计 */
  queryByWrhTotal: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        storeId?: string; //门店Id
      };
    } & IPageParams
  ) => post<IReportDailyTotal>('/api/pcweb/finance/report/daily/wrh/queryByStoreTotal', params),
  /**按大类查询进销存日报 */
  queryByGeneralSort: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        sortId?: string; //商品分类
      };
    } & IPageParams
  ) => post<IPageData<IReportDailyGeneralSort>>('/api/pcweb/finance/report/daily/wrh/queryByGeneralSort', params),
  /**按大类查询进销存日报合计 */
  queryByGeneralSortTotal: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        sortId?: string; //商品分类
      };
    } & IPageParams
  ) => post<IReportDailyTotal>('/api/pcweb/finance/report/daily/wrh/queryByGeneralSortTotal', params),
  /**按中类查询进销存日报 */
  queryByMediumSort: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        sortId?: string; //商品分类
      };
    } & IPageParams
  ) => post<IPageData<IReportDailyMediumSort>>('/api/pcweb/finance/report/daily/wrh/queryByMediumSort', params),
  /**按中类查询进销存日报合计 */
  queryByMediumSortTotal: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        sortId?: string; //商品分类
      };
    } & IPageParams
  ) => post<IReportDailyTotal>('/api/pcweb/finance/report/daily/wrh/queryByMediumSortTotal', params),
  /**按小类查询进销存日报 */
  queryBySubSort: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        sortId?: string; //商品分类
      };
    } & IPageParams
  ) => post<IPageData<IReportDailySubSort>>('/api/pcweb/finance/report/daily/wrh/queryBySubSort', params),
  /**按小类查询进销存日报合计 */
  queryBySubSortTotal: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        sortId?: string; //商品分类
      };
    } & IPageParams
  ) => post<IReportDailyTotal>('/api/pcweb/finance/report/daily/wrh/queryBySubSortTotal', params),
  /**按商品查询进销存日报 */
  queryByGoodsId: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        sortId?: string; //商品分类
        goodsId?: string; //商品编码
        goodsBarcode?: string; //商品条码
        goodsName?: string; //商品名称
        searchKey?: string; //模糊查询
      };
    } & IPageParams
  ) => post<IPageData<IReportDailyGoods>>('/api/pcweb/finance/report/daily/wrh/queryByGoodsId', params),
  /**按商品查询进销存日报合计 */
  queryByGoodsIdTotal: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        sortId?: string; //商品分类
        goodsId?: string; //商品编码
        goodsBarcode?: string; //商品条码
        goodsName?: string; //商品名称
        searchKey?: string; //模糊查询
      };
    } & IPageParams
  ) => post<IReportDailyTotal>('/api/pcweb/finance/report/daily/wrh/queryByGoodsIdTotal', params),
  /***
   *
   * 进销存2.0
   */
  /* 按门店仓库查询 */
  queryByStoreNew: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        isStore?: boolean; // 是否为门店
        isSummary: boolean; // 是否统计
        wrhIdList: Array<[]>; // 门店或仓库编码
      };
    } & IPageParams
  ) => post<IPageData<IReportDaily>>('/api/pcweb/finance/report/daily/wrh/queryByStoreNew', params),

  /* 按门店仓库查询合计 */
  getTotalByStoreNew: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        isStore?: boolean; // 是否为门店
        isSummary: boolean; // 是否统计
        wrhIdList: Array<[]>; // 门店或仓库编码
      };
    } & IPageParams
  ) => post<IReportDaily>('/api/pcweb/finance/report/daily/wrh/getTotalByStore', params),

  /* 按分类查询 */
  queryBySort: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        isStore?: boolean; // 是否为门店
        isSummary?: boolean; // 是否统计
        wrhIdList?: Array<[]>; // 门店或仓库编码
        sortIdList?: Array<[]>; // 类别集合
        sortType?: Array<[]>; //类别维度
      };
    } & IPageParams
  ) => post<IPageData<IReportDaily>>('/api/pcweb/finance/report/daily/wrh/queryBySort', params),

  /* 按分类查询合计 */
  getTotalBySort: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        isStore?: boolean; // 是否为门店
        isSummary?: boolean; // 是否统计
        wrhIdList?: Array<[]>; // 门店或仓库编码
        sortIdList?: Array<[]>; // 类别集合
        sortType?: Array<[]>; //类别维度
      };
    } & IPageParams
  ) => post<IReportDaily>('/api/pcweb/finance/report/daily/wrh/getTotalBySort', params),

  /* 按照单品分类 */
  queryByGoods: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        isStore?: boolean; // 是否为门店
        isSummary?: boolean; // 是否统计
        wrhIdList?: Array<[]>; // 门店或仓库编码
        sortIdList?: Array<[]>; // 类别集合
        searchKey?: string; //商品编码/商品名称/商品条码
      };
    } & IPageParams
  ) => post<IPageData<IReportDaily>>('/api/pcweb/finance/report/daily/wrh/queryByGoods', params),

  /* 按照单品分类合计 */
  getTotalByGoods: (
    params: {
      filter?: {
        createEndTime?: string; //结束时间
        createStartTime?: string; //开始时间
        isStore?: boolean; // 是否为门店
        isSummary?: boolean; // 是否统计
        wrhIdList?: Array<[]>; // 门店或仓库编码
        sortIdList?: Array<[]>; // 类别集合
        searchKey?: string; //商品编码/商品名称/商品条码
      };
    } & IPageParams
  ) => post<IReportDaily>('/api/pcweb/finance/report/daily/wrh/getTotalByGoods', params)
};
