import request from '@/utils/request';
import { IBusinessOutGoods, IBusinessOutOrderInfo } from '@/dataModel/business/out/interface';
import { OrderIntoStoreStatusEnum, OrderIntoStoreTypeEnum } from '@/dataModel/business/orderStore/enum';
import { IBusinessOrderStore } from '@/dataModel/business/orderStore/interface';
import { AllocOutTypeEnum } from '@/dataModel/dict/enum';
const { post } = request;

const out = {
  /**
   * 查询入库单
   * @param params
   * @returns
   */
  query: (
    params: {
      id?: string;
      recvEndTime?: string;
      recvStartTime?: string;
      senderInfo?: string;
      status: string;
      type: string;
      goodsInfo?: string;
    } & IPageParams
  ) => post<{ items: IBusinessOrderStore[]; totalCount: number }>('/api/pcweb/business/out/query', params),
  /**
   * 查询入库商品不分页面
   * @param params
   * @returns
   */
  get: (params: { id: string; type: AllocOutTypeEnum }) =>
    post<{
      createInfo: IOperatorInfo;
      status:
        | OrderIntoStoreStatusEnum.APPROVED
        | OrderIntoStoreStatusEnum.INIT
        | OrderIntoStoreStatusEnum.RECEIVED
        | OrderIntoStoreStatusEnum.SENDED
        | OrderIntoStoreStatusEnum.VERIFIED;
      diffBillId: string;
      total: number;
      sendCount: string;
      recvCount: number;
      senderName: string;
      senderId: string;
      id: string;
      version: number;
      statusName: string;
      detailItems: IBusinessOutGoods[];
    }>('/api/pcweb/business/out/getById', params),
  /**
   * 查询入库商品分页面
   * @param params
   * @returns
   */
  getList: (
    params: {
      id: string;
      type: AllocOutTypeEnum;
      goodsInfo: string;
    } & IPageParams
  ) =>
    post<{
      recvTotal: number;
      recvCount: number;
      recvOperName: string;
      sendTime: string;
      totalCount: number;
      createInfo: IOperatorInfo;
      status:
        | OrderIntoStoreStatusEnum.APPROVED
        | OrderIntoStoreStatusEnum.INIT
        | OrderIntoStoreStatusEnum.RECEIVED
        | OrderIntoStoreStatusEnum.SENDED
        | OrderIntoStoreStatusEnum.VERIFIED;
      diffBillId: string;
      total: number;
      sendCount: string;
      senderName: string;
      senderId: string;
      recvTime: string;
      id: string;
      detailItems: IBusinessOutGoods[];
      statusName: string;
      purOrderId: string;
      purOrderUuid: string;
    }>('/api/pcweb/business/out/getByIdWithPage', params),
  /**
   * 保存或提交入库商品
   * @param params
   * @returns
   */
  save: (params: {
    version: number;
    id: string;
    asDraft: boolean;
    goodsList: {
      line: number;
      recvQty: number;
      recvQtyStr: string;
      remark: string;
      validDate?: any;
      productDate?: any;
    }[];
    type: OrderIntoStoreTypeEnum.WRH | OrderIntoStoreTypeEnum.DIRECT;
  }) => post<any>('/api/pcweb/business/out/save', params),
  /**按订单收货明细-用于展示明细 */
  getOrderInfo: (params: { id: string }) => post<IBusinessOutOrderInfo>('/api/pcweb/business/out/getOrderInfo', params),
  /**按订单收货明细--用于收货*/
  getOrderInfoForIn: (params: { id: string }) =>
    post<IBusinessOutOrderInfo>('/api/pcweb/business/out/getOrderInfoForIn', params),
  /**按订单收货明细 */
  saveOrder: (params: {
    details: {
      goodsId: string;
      qty: number;
      remark: string;
      validDate?: any;
      productDate?: any;
    }[];
    isReceived: boolean;
    orderUuid: string;
  }) => post('/api/pcweb/business/out/saveOrder', params)
};

export default out;
