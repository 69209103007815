import request from '@/utils/request';
import { IOMallSpu } from '@/dataModel/oMall/spu/interface';

const oMallSpu = {
  /** 订货商城 查 Spu 列表 */
  query: (
    params: {
      filters: {
        /** 分类编码等于 */
        categoryCodeEq?: string;
        /** 是否有图片 */
        hasImage?: boolean;
        /** 关键字 */
        keyword?: string;
        /** 状态等于 */
        statusEq?: string;
        /** 类型等于 */
        typeEq?: string;
        /** 是否上架 */
        up?: boolean;
      };
    } & IPageParams
  ) => request.post<IPageData<IOMallSpu>>('/api/pcweb/omall/spu/query', params)
};

export default oMallSpu;
