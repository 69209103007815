import {
  IAllocationQueryByBill,
  IAllocationQueryByDetail,
  IQueryFilterParams
} from '@/dataModel/business/storeAllocationQuery/interface';
import request from '@/utils/request';
const { post } = request;

const storeAllocationQuery = {
  /** 按单据 */
  queryByBill: (
    params: {
      filter?: IQueryFilterParams;
    } & IPageParams
  ) => post<IPageData<IAllocationQueryByBill>>('/api/pcweb/business/storetransfer/queryByBill', params),

  /** 按明细 */
  queryByDetail: (
    params: {
      filter?: IQueryFilterParams;
    } & IPageParams
  ) => post<IPageData<IAllocationQueryByDetail>>('/api/pcweb/business/storetransfer/queryByBillDetail', params)
};

export default storeAllocationQuery;
