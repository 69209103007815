import { IBusinessTurnovermanageAccountQueryStore } from '@/dataModel/business/turnoverManageAccountQueryStore/interface';
import request from '@/utils/request';
const { post } = request;
const turnoverManageManageAccountQueryStore = {
  queryBusinessFundsByStore: (
    params: {
      filter: {
        isDiff: boolean;
        startDate: string;
        endDate: string;
        storeId: string;
        currencyIds?: string[];
      };
    } & IPageParams
  ) => post<IPageData<IBusinessTurnovermanageAccountQueryStore>>('/api/pcweb/finance/revenue/bill', params),
  queryBusinessFundsTotalByStore: (
    params: {
      filter: {
        isDiff: boolean;
        startDate: string;
        endDate: string;
        storeId: string;
        currencyIds?: string[];
      };
    } & IPageParams
  ) => post<IPageData<IBusinessTurnovermanageAccountQueryStore>>('/api/pcweb/finance/revenue/billTotal', params)
};

export default turnoverManageManageAccountQueryStore;
