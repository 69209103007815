import request from '@/utils/request';
import {
  IStoreMemberRegister,
  IStoreMemberRegisterTotal,
  IStoreMemberCardBind,
  IStoreMemberCardBindTotal
} from '../../../dataModel/member/storeMemberRegister/interface';
const { post } = request;

const storeMemberRegister = {
  /**门店-会员注册汇总 */
  queryStoreMemberRegister: (
    params: {
      filter?: {
        beginDate?: string; //开始时间
        endDate?: string; //结束时间
        channel?: string; // 渠道
        templateCardName?: string; // 模板名称
      };
    } & IPageParams
  ) => post<IPageData<IStoreMemberRegister>>('/api/pcweb/member/info/queryStoreMemberRegister', params),

  /**门店-会员注册汇总统计 */
  queryStoreMemberRegisterTotal: (
    params: {
      filter?: {
        beginDate?: string; //开始时间
        endDate?: string; //结束时间
        channel?: string; // 渠道
        templateCardName?: string; // 模板名称
      };
    } & IPageParams
  ) => post<IStoreMemberRegisterTotal>('/api/pcweb/member/info/queryStoreMemberRegisterTotal', params),

  /**门店-会员实体卡绑定 */
  queryStoreMemberCardBind: (
    params: {
      filter?: {
        beginDate?: string; //开始时间
        endDate?: string; //结束时间
        cashierSearchKey?: string; // 员工编码，名称
        templateCardName?: string; // 模板名称
      };
    } & IPageParams
  ) => post<IPageData<IStoreMemberCardBind>>('/api/pcweb/member/info/queryStoreMemberCardBind', params),

  /**门店-会员实体卡绑定统计 */
  queryStoreMemberCardBindTotal: (
    params: {
      filter?: {
        beginDate?: string; //开始时间
        endDate?: string; //结束时间
        cashierSearchKey?: string; // 员工编码，名称
        templateCardName?: string; // 模板名称
      };
    } & IPageParams
  ) => post<IStoreMemberCardBindTotal>('/api/pcweb/member/info/queryStoreMemberCardBindTotal', params)
};

export default storeMemberRegister;
