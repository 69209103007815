import { PosDeviceTypeEnum } from '@/dataModel/dict/enum';
import {
  ISaleGroupSort,
  ISalePos,
  ISaleSortGoods,
  ISaleStoreTemplate,
  ISaleTemplate,
  ISaleTemplateDetail
} from '@/dataModel/retail/sale/interface';
import request from '@/utils/request';

const { post } = request;

const sale = {
  /**
   * 总部销售面板展示
   * 总部storeId传null门店不用传
   */
  getHeadAllSaleGroup: (params?: {
    filter?: {
      storeIdOrName?: any;
    };
    storeId?: string;
  }) => post<ISaleTemplate[]>('/api/pcweb/retail/sale/getHeadAllSaleGroup', params, {}, 'pos'),

  /**
   * 门店销售面板展示
   *
   */
  getStoreAllSaleGroup: () => post<ISaleStoreTemplate[]>('/api/pcweb/retail/sale/getStoreAllSaleGroup', {}, {}, 'pos'),

  /**
   * 新增销售面板
   * 总部storeId传null门店不用传
   */
  addSaleGroup: (params: { name: string; storeId?: string }) =>
    post<any>('/api/pcweb/retail/sale/addSaleGroup', params, {}, 'pos'),

  /**
   * 复制销售面板
   * 总部storeId传null门店不用传
   */
  copySaleGroup: (params: { name: string; uuid: string; storeId?: string }) =>
    post<any>('/api/pcweb/retail/sale/copySaleGroup', params, {}, 'pos'),

  /**
   * 编辑销售面板
   */
  modifySaleGroup: (params: { name: string; uuid: string; version: number }) =>
    post<any>('/api/pcweb/retail/sale/modifySaleGroup', params, {}, 'pos'),

  /**
   * 获取模版详情
   */
  get: (params: { uuid: string }) => post<ISaleTemplateDetail>('/api/pcweb/retail/sale/get', params, {}, 'pos'),

  /**
   * 修改门店
   */
  modifyStore: (params: {
    uuid: string;
    version: number;
    storeInfos: {
      id: string;
      name: string;
      uuid: string;
    }[];
  }) => post<any>('/api/pcweb/retail/sale/modifyStore', params, {}, 'pos'),

  /**
   * 删除销售模版
   */
  removeSaleGroup: (params: { uuid: string }) => post<any>('/api/pcweb/retail/sale/removeSaleGroup', params, {}, 'pos'),

  /**
   * 销售面板类目树形结构展示
   */
  getAllSaleGroupSort: (params: { saleGroup: string }) =>
    post<ISaleGroupSort[]>('/api/pcweb/retail/sale/getAllSaleGroupSort', params, {}, 'pos'),

  /**
   * 销售面板类目树形结构展示
   */
  getAllSaleGroupSortNew: (params: { saleGroup: string }) =>
    post<ISaleGroupSort[]>('/api/pcweb/retail/sale/getAllSaleGroupSortNew', params, {}, 'pos'),

  /**
   * 新增类目
   * level 1大类目 2小类目
   */
  createSort: (params: { level: 1 | 2; name: string; parentId: string; saleGroup: string }) =>
    post<any>('/api/pcweb/retail/sale/createSort', params, {}, 'pos'),

  /**
   * 点击类目获取商品列表
   *
   */
  getGoods: (params: { uuid: string }) => post<ISaleSortGoods[]>('/api/pcweb/retail/sale/getGoods', params, {}, 'pos'),

  /**
   * 分页查询商品列表
   *
   */
  queryGoods: (
    params: {
      filter?: {
        goodsNameOrIdOrBarcode?: string;
        goodsType?: string;
        noBarCode?: boolean;
        sortId?: string;
        status?: string;
      };
    } & IPageParams
  ) => post<IPageData<ISaleSortGoods>>('/api/pcweb/retail/sale/queryGoods', params, {}, 'pos'),

  /**
   * 添加商品
   *
   */
  addGoods: (params: { saleGroup: string; saleGroupSort: string; spuIds?: string[]; skuIds?: string[] }) =>
    post<any>('/api/pcweb/retail/sale/addGoods', params, {}, 'pos'),

  /**
   * 批量删除商品
   */
  batchRemoveGoods: (params: { uuids: string[] }) =>
    post<any>('/api/pcweb/retail/sale/batchRemoveGoods', params, {}, 'pos'),

  /**
   * 商品排序
   */
  goodsRank: (params: { ranks: { seqNo: number; uuid: string }[] }) =>
    post<any>('/api/pcweb/retail/sale/goodsRank', params, {}, 'pos'),

  /**
   * 类目拖曳排序
   */
  dragSort: (params: { uuid: string; level: number; targetSeqNo: number }) =>
    post<any>('/api/pcweb/retail/sale/dragSort', params, {}, 'pos'),

    /**
   * 类目拖曳排序New
   */
  dragSortNew: (params: { saleGroup: string; saleGroupSortUuids?:string[],level:number }) =>
    post<any>('/api/pcweb/retail/sale/dragSortNew', params, {}, 'pos'),
  /**
   * 编辑类目
   */
  modifySort: (params: { uuid: string; name: string; version: number }) =>
    post<any>('/api/pcweb/retail/sale/modifySort', params, {}, 'pos'),

  /**
   * 删除类目
   */
  removeSort: (params: { uuid: string }) => post<any>('/api/pcweb/retail/sale/removeSort', params, {}, 'pos'),

  /**
   *
      门店添加收银机->收银机列表
   */
  getPos: (params: { uuid: string }) => post<ISalePos[]>('/api/pcweb/retail/sale/getPos', params, {}, 'pos'),

  /**
   *
      添加收银机
   */
  addPos: (params: { saleGroup: string; posAddVos: { posId: string; posType: PosDeviceTypeEnum }[] }) =>
    post<any>('/api/pcweb/retail/sale/addPos', params, {}, 'pos')
};

export default sale;
