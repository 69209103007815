import {
  TicketBusinessTypeEnum,
  TicketTemplateDigitalNumberEnum,
  TicketTemplateDigitalNumberPositionEnum,
  TicketTemplateModeEnum
} from '@/dataModel/dict/enum';
import { ITicketListItem,ITicketDetail } from '@/dataModel/finance/ticketTemplate/interface';
import request from '@/utils/request';

const { post } = request;

const newTicketTemplate = {
  /**
   * 查询小票模板列表
   */
  query: (params?: {
    filter:{"businessType":string,"store"?: string}
  }& IPageParams) => post<IPageData<ITicketListItem>>('/api/pcweb/mdata/receipt/template/query', params, {}, 'pos'),
  /**
   * 查询小票模板列表
   */
  getByStore: (params?: {
    "businessTypes":string[]
  }) => post<any>('/api/pcweb/mdata/receipt/template/getByStore', params, {}, 'pos'),
  /**
   * 新增小票模板
   */
  createSaleOrReturn: (params?: ITicketDetail ) => post('/api/pcweb/mdata/receipt/template/createSaleOrReturn', params, {}, 'pos'),
  /**
   * 新增小票模板
   */
  modifySaleOrReturn: (params?: ITicketDetail ) => post('/api/pcweb/mdata/receipt/template/modifySaleOrReturn', params, {}, 'pos'),
  /**
   * 小票模板详情
   */
  getSaleOrReturn: (params?: {uuid:string} ) => post('/api/pcweb/mdata/receipt/template/getSaleOrReturn', params, {}, 'pos'),
  /**
   * 生效
   */
  getEffectStores: (params?: {uuid:string} ) => post('/api/pcweb/mdata/receipt/template/getEffectStores', params, {}, 'pos'),
  /**
   * 生效
   */
  distribute: (params?: { uuid: string; stores:string[]} ) => post('/api/pcweb/mdata/receipt/template/distribute', params, {}, 'pos'),
  /**
   * 删除
   */
  remove: (params?: { uuid: string; } ) => post('/api/pcweb/mdata/receipt/template/remove', params, {}, 'pos'),
  /**
   * 预览
   */
  previewReceipt: (params?: { uuid: string; } ) => post('/api/pcweb/mdata/receipt/template/previewReceipt', params, {}, 'pos'),

};

export default newTicketTemplate;
