import { IStore } from '@/dataModel/mdata/store/interface';
import { ICashierPosLog, IStorePosLog } from '@/dataModel/system/posLog/interface';
import { ISettingUser } from '@/dataModel/system/settingUser';
import request from '@/utils/request';

const { post } = request;

const posLog = {
  /**分页异常监控【门店汇总】 */
  getStoreSum: (params: { filter: { endTime: string; startTime: string; stores: IStore['uuid'][] } }) =>
    post<IStorePosLog>('/api/pcweb/system/pos/log/getStoreSum', params),

  /**分页异常监控【门店】*/
  queryStore: (params: { filter: { endTime: string; startTime: string; stores: IStore['uuid'][] } } & IPageParams) =>
    post<IPageData<IStorePosLog>>('/api/pcweb/system/pos/log/queryStore', params),

  /**分页异常监控【员工汇总】 */
  getCashierSum: (params: {
    filter: { endTime: string; startTime: string; stores: IStore['uuid'][]; cashiers: ISettingUser['uuid'] };
  }) => post<ICashierPosLog>('/api/pcweb/system/pos/log/getCashierSum', params),

  /**分页异常监控【员工】*/
  queryCashier: (
    params: {
      filter: { endTime: string; startTime: string; stores: IStore['uuid'][]; cashiers: ISettingUser['uuid'] };
    } & IPageParams
  ) => post<IPageData<ICashierPosLog>>('/api/pcweb/system/pos/log/queryCashier', params)
};

export default posLog;
