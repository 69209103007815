import request from '@/utils/request';
import {
  IDashboardBusinessInfo,
  IDashboardCommonlyUsedModule,
  IDashboardGetOrgInfo,
  IDashboardMessage,
  IWeather
} from '@/dataModel/dashboard/interface';
import { IMunit } from '@/dataModel/mdata/goods/interface';
const { post } = request;

const dashboard = {
  /**
   * 查询经营数据
   */
  getBusinessInfo: () => post<IDashboardBusinessInfo>('/api/pcweb/home/getBusinessInfo'),
  /**
   * 查询轮播图
   */
  getCarouselPic: () => post<string[]>('/api/pcweb/home/getCarouselPic'),
  /**
   * 查询常用模块
   */
  getCommonlyUsedModule: () => post<IDashboardCommonlyUsedModule[]>('/api/pcweb/home/getCommonlyUsedModule'),
  /**
   * 查询登录组织信息
   */
  getOrgInfo: () => post<IDashboardGetOrgInfo>('/api/pcweb/home/getOrgInfo'),

  /**
   * 查询门店天气
   */
  queryStoreWeather: (params: { cityName?: string; countryName?: string; endDate: string; startDate: string }) =>
    post<IWeather[]>('/api/pcweb/home/queryStoreWeather', params),

  /** 查询单位 */
  queryMunits: () => post<IMunit>('/api/pcweb/home/queryMunits'),

  /**查询消息 */
  queryMessage: (params: IPageParams) => post<IPageData<IDashboardMessage>>('/api/pcweb/home/message/query', params),

  /**获取未读消息数量 */
  getMessageCount: () => post<{ count: number }>('/api/pcweb/home/message/getMessageCount'),

  /**设置已读 */
  setRead: (params: { uuid: string; version: number }) => post<any>('/api/pcweb/home/message/setRead', params),
  /** 推荐有奖 */
  recommend: (params: {}, opt?: { escapeCheck?: boolean }) => post<any>('/api/pcweb/recommend/recommend', params, opt),
};

export default dashboard;
