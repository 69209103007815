import { ISettingOrg, ISettingOrgInfo } from '@/dataModel/system/settingOrg/interface';
import request from '@/utils/request';

const { post } = request;

const settingOrg = {
  /**
   * 查询组织结构列表-不含门店
   * @param params detp 部门 area 区域
   */
  queryOrg: (params: {
    /**
     * smallestOrgType detp 部门 area 区域
     */
    smallestOrgType: 'dept' | 'area';
  }) => post<ISettingOrg[]>('/api/pcweb/system/setting/org/queryOrg', params),
  /**
   * 查询组织结构列表-含门店和员工数量
   * @params empInfo 员工手机/编号/姓名
   */
  queryOrgInfo: (params: {
    /**
     * empInfo 员工手机/编号/姓名
     */
    empInfo: string;
  }) => post<ISettingOrgInfo[]>('/api/pcweb/system/setting/org/queryOrgInfo', params),
  /**
   * 移动部门,区域,门店
   */
  move: (params: {
    /**
     * 类型: area：区域,dept：部门,store门店
     */
    orgType: string;
    /**
     * 移动至…uuid
     */
    parentUuid: string;
    /**
     * 被移动的节点uuid
     */
    uuid: string;
    /**
     * 版本
     */
    version: number;
  }) => post('/api/pcweb/system/setting/org/move', params),
  /**
   * 删除部门or区域
   */
  delete: (params: {
    /**
     * 组织标识
     */
    uuid: string;
  }) => post('/api/pcweb/system/setting/org/delete', params),
  /**
   * 新建部门or区域
   */
  save: (params: {
    /**
     * 编码
     */
    id: string;
    /**
     * 名称
     */
    name: string;
    /**
     * 类型area：区域，dept：部门
     */
    orgType: string;
    /**
     * 上级标识
     */
    parentUuid: string;
    /**
     * 备注
     */
    remark: string;
  }) => post('/api/pcweb/system/setting/org/save', params),
  /**
   * 修改部门or区域
   */
  modify: (params: {
    /**
     * 部门or区域名称
     */
    name: string;
    /**
     * 上级uuid
     */
    parentUuid: string;
    /**
     * 备注
     */
    remark: string;
    /**
     * 要修改的节点uuid
     */
    uuid: string;
    /**
     * 版本
     */
    version: string;
  }) => post('/api/pcweb/system/setting/org/modify', params)
};

export default settingOrg;
