import request from '@/utils/request';
import {
  QueryDifferenceTableListRes,
  GetDetailRes,
  DiffGoodRes,
  SaveReq
} from '@/dataModel/finance/differenceTable/interface';
const { post } = request;

interface SaveLineRes {
  currentPurchaseTotal: number;
}
const differenceTable = {
  /** 补差单列表*/
  queryDifferenceTableList: (
    params: {
      filter: {
        createdEndDate?: string;
        createdStartDate?: string;
        goodsKeyword?: string;
        id?: string;
        org?: string;
        statusList?: string[];
        subdiffEndDate?: string;
        subdiffStartDate?: string;
        supplierUuid?: string;
      };
    } & IPageParams
  ) => post<IPageData<QueryDifferenceTableListRes>>('/api/pcweb/finance/supplier/subdiff/query', params),
  /** 补差协议详情*/
  getDetail: (params: { id?: string; uuid?: string }) =>
    post<GetDetailRes>('/api/pcweb/finance/supplier/subdiff/get', params),
  /** 补差协议详情*/
  getGoodsList: (
    params: {
      bill: string;
      filter: {
        goodsIds?: string[];
        keyword?: string;
      };
    } & IPageParams
  ) => post<IPageData<DiffGoodRes>>('/api/pcweb/finance/supplier/subdiff/queryDetail', params),
  /** 补差协议详情*/
  queryGoods: (
    params: {
      filter?: {
        keyword?: string;
        sortId?: string;
      };
      supplierUuid: string;
    } & IPageParams
  ) => post<IPageData<DiffGoodRes>>('/api/pcweb/finance/supplier/subdiff/queryGoods', params),
  /** 补差协议详情*/
  save: (params: SaveReq) => post<any>('/api/pcweb/finance/supplier/subdiff/save', params),
  /** 补差协议详情*/
  remove: (params: { uuid: string }) => post<any>('/api/pcweb/finance/supplier/subdiff/remove', params),
  /** 补差协议详情*/
  modifyStatus: (params: { targetStatus: string; uuid: string; version: number }) =>
    post<any>('/api/pcweb/finance/supplier/subdiff/modifyStatus', params),
  /** 费用项目*/
  queryFeeItem: () => post<any>('/api/pcweb/finance/supplier/subdiff/getFeeItemList'),
  /** 费用项目*/
  generateFee: (params: { bills: string[]; feeItem: string; nuid: string; merge: boolean }) =>
    post<{ taskUuid: string }>('/api/pcweb/finance/supplier/subdiff/generateFee', params),
  /**全量生成费用单时获取的供应商总数 */
  getSupplierCount: (params: {
    filter: {
      createdEndDate?: string;
      createdStartDate?: string;
      goodsKeyword?: string;
      id?: string;
      org?: string;
      statusList?: string[];
      subdiffEndDate?: string;
      subdiffStartDate?: string;
      supplierUuid?: string;
    };
  }) =>
    post<{
      /**供应商总数 */
      supplierCount: number;
    }>('/api/pcweb/finance/supplier/subdiff/generateFeeAllSupplier', params),
  /**全部生成费用单获取数据 */
  generateAllFee: (params: {
    filter: {
      createdEndDate?: string;
      createdStartDate?: string;
      goodsKeyword?: string;
      id?: string;
      org?: string;
      statusList?: string[];
      subdiffEndDate?: string;
      subdiffStartDate?: string;
      supplierUuid?: string;
    };
    feeItem: string;
    merge: boolean;
  }) => post<{ taskUuid: string }>('/api/pcweb/finance/supplier/subdiff/generateFeeAll', params),
  /** 费用项目*/
  getFeeTask: (params: { uuid: string }) =>
    post<{
      failCount: number;
      feeCount: number;
      ignoreCount: number;
      status: { id: string; name: string };
      supplierCount: number;
    }>('/api/pcweb/finance/supplier/subdiff/getFeeTask', params),
  /** 销售商品*/
  getSaleGoodsList: (params: { endDate: string; startDate: string; orgUuid: string; supplierUuid: string }) =>
    post<DiffGoodRes[]>('/api/pcweb/finance/supplier/subdiff/getSaleGoodsList', params),
  /** 填充数量*/
  getSaleCount: (params: {
    endDate: string;
    startDate: string;
    orgUuid: string;
    supplierUuid: string;
    goodsList: string[];
  }) => post<DiffGoodRes>('/api/pcweb/finance/supplier/subdiff/getSaleCount', params),
  /** 导入商品*/
  getGoodsByIoTask: (params: { taskUuid: string }) =>
    post<DiffGoodRes[]>('/api/pcweb/finance/supplier/subdiff/getGoodsByIoTask', params)
};

export default differenceTable;
