import { ISpec } from '@/dataModel/mdata/spec/interface';
import request from '@/utils/request';
import specCompose from './compose';

const dao = {
  // 查全部规格
  getAll: () => request.post<ISpec[]>('/api/pcweb/mdata/spec/getAll'),
  // 新增规格名
  create: (params: { id: string; name: string }) =>
    request.post<{ uuid: string }>('/api/pcweb/mdata/spec/create', params),
  // 修改规格名
  modify: (params: { name: string; version: string; uuid: string }) => request.post('/api/pcweb/mdata/spec/modify', params), // prettier-ignore
  //删除规格值：
  checkSpecIsReference: (params: { specUuid: string; specValue: string }) =>
    request.post('api/pcweb/mdata/spec/checkSpecIsReference', params),
  // 删除规格
  removeSpec: (uuid: string) => request.post('/api/pcweb/mdata/spec/removeSpec', { uuid }),
  // 删除分组
  removeGroup: (uuid: string) => request.post('/api/pcweb/mdata/spec/removeGroup', { uuid }),
  // 获取规格分组列表
  getGroupValuesList: (specUuid: string) => request.post('/api/pcweb/mdata/spec/getGroupValuesList', { specUuid }),
  // 新增分组
  createGroup: (params: {nuid?:string; name: string; specUuid: string }) => request.post('/api/pcweb/mdata/spec/createGroup', params), // prettier-ignore
  // 修改规格分组
  modifiGroup: (params: { name: string; groupUuid: string; version: number; }) => request.post('/api/pcweb/mdata/spec/modifyGroup', params), // prettier-ignore
  // 保存规格值
  saveSpecValues: (params: { specUuid: string; values: string[] }) =>
    request.post('/api/pcweb/mdata/spec/saveSpecValues', params),
  // 保存分组规格值
  saveGroupValues: (params: { groupUuid: string; values: string[] }) =>
    request.post('/api/pcweb/mdata/spec/saveGroupValues', params),
  // 根据规格获取 spu
  getSpuBySpec: (uuid: string) =>
    request.post<{ spec: string; specValue: string; spu: string; spuId: string }[]>(
      '/api/pcweb/mdata/spec/getSpuBySpec',
      { uuid }
    )
};

export default { ...dao, ...specCompose };
