import {
  ISellunionBackDetailBasic,
  IQuerySellunionBackDetailItems,
  IQuerySellunionBackGoodsList,
  IQuerySellunionBackItems,
  IQuerySellunionBackSupplier
} from '@/dataModel/business/sellunionBack/interface';
import { SellunionBackStatusEnum } from '@/dataModel/dict/enum';
import request from '@/utils/request';
const { post } = request;

const sellunionBack = {
  /* * 分页查询单据 */
  query: (
    params: {
      filter: {
        createEnd: string;
        createStart: string;
        goodsKeyword: string;
        idLike: string;
        inEnd: string;
        inStart: string;
        status: SellunionBackStatusEnum;
        storeId?: string;
        supplierKeyword: string;
      };
    } & IPageParams
  ) => post<IPageData<IQuerySellunionBackItems>>('/api/pcweb/business/sellunion/back/query', params),

  /** 获取联营供应商 */
  getSupplier: () => post<IQuerySellunionBackSupplier[]>('/api/pcweb/business/sellunion/back/getSupplier'),

  /** 分页查询商品 -- Modal */
  queryGoods: (
    params: {
      filter: {
        goodsType?: string;
        keyword: string;
        sortId: string;
      };
      supplier: string;
    } & IPageParams
  ) => post<IPageData<IQuerySellunionBackGoodsList>>('/api/pcweb/business/sellunion/back/queryGoods', params),

  /** 获取商品列表 -- 回显表格 */
  getModifyGoods: (params: { uuid: string }) =>
    post<IQuerySellunionBackGoodsList[]>('/api/pcweb/business/sellunion/back/getModifyGoods', params),

  /** 保存联营进货单 */
  save: (params: {
    bill?: string;
    creation: {
      details: {
        goodsId: string;
        goodsQpcStr: string;
        qty: number;
        remark?: string;
      }[];
      backDate: string;
      remark: string;
      supplier: string;
    };
    targetStatus: SellunionBackStatusEnum;
    version?: number;
  }) => post<{ traceId: string; version: number; uuid: string }>('/api/pcweb/business/sellunion/back/save', params),

  /** 提交进货 */
  submit: (params: { uuid: string; version: number }) =>
    post<{ traceId: string; version: number }>('/api/pcweb/business/sellunion/back/submit', params),

  /** 删除单据 */
  remove: (params: { uuid: string }) => post<{ traceId: string }>('/api/pcweb/business/sellunion/back/remove', params),

  /** 分页查询单据明细 -- 详情 */
  queryDetail: (params: { bill: string; filter: { keyword: string } } & IPageParams) =>
    post<IPageData<IQuerySellunionBackDetailItems>>('/api/pcweb/business/sellunion/back/queryDetail', params),

  /** 根据单据标识或单据编码获取单据信息 -- 单头 */
  get: (params: { uuid: string }) => post<ISellunionBackDetailBasic>('/api/pcweb/business/sellunion/back/get', params),

  /** 提交进货(批量) */
  submitBatch: (params: { uuids: string[] }) =>
    post<{ failedCount: number; successCount: number }>('/api/pcweb/business/sellunion/back/submitBatch', params),
  /** 导入 */
  getGoodsByIoTask: (params: { taskUuid: string[] }) =>
    post<IQuerySellunionBackGoodsList[]>('/api/pcweb/business/sellunion/back/getGoodsByIoTask', params)
};
export default sellunionBack;
