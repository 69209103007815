import request from '@/utils/request';
import { IPurchaseInSaveDetails, IPurchaseInGet, IPurchaseInGetDetails } from '@/dataModel/purchase/in/interface';
const { post } = request;
const purchaseIn = {
  /**
   * 分页查询表单
   */
  query: (
    params: {
      filter: {
        endDate?: string;
        goodsKeyword?: string;
        id?: string;
        order?: number;
        purchaser?: string;
        settleNo?: string;
        startDate?: string;
        status?: string;
        supplier?: string;
        warehouse?: string;
      };
    } & IPageParams
  ) => post<IPageData<IPurchaseInGet>>('/api/pcweb/purchase/in/query', params),
  /**
   * 保存采购入库单
   */
  save: (params: {
    details?: IPurchaseInSaveDetails | IPurchaseInGetDetails[];
    uuid?: string;
    version?: number;
    targetStatus?: string;
    info?: {
      receiverDate: string;
      recvOperId: string;
      supplierId: string;
      purchaserId?: string;
      remark?: string;
      orderId?: string;
      transportFee?: number;
      warehouse: string;
    };
  }) => post('/api/pcweb/purchase/in/save', params),
  /**
   * 删除退货单
   */
  remove: (params: { uuid: string }) => post('/api/pcweb/purchase/in/remove', params),
  /**
   *  批量删除
   */
  removeBatch: (params: { uuids: string[] }) => post<any>('/api/pcweb/purchase/in/removeBatch', params),
  /**
   * 分页查询退货单明细
   */
  queryDetail: (
    params: {
      filter?: { keyword?: string };
      bill: string;
    } & IPageParams
  ) => post<IPageData<IPurchaseInGetDetails>>('/api/pcweb/purchase/in/queryDetail', params),
  /**
   * 修改状态
   */
  modifyStatus: (params: { targetStatus: string; uuid: string; version: number }) =>
    post<any>('/api/pcweb/purchase/in/modifyStatus', params),
  /**
   * 获取
   */
  get: (params: {
    // id?: string;
    uuid?: string;
    id?: string;
  }) => post<IPurchaseInGet>('/api/pcweb/purchase/in/get', params),
  /**
   * 获取明细列表
   */
  getDetails: (params: { uuid: string }) => post<IPurchaseInGetDetails[]>('/api/pcweb/purchase/in/getDetails', params)
};

export default purchaseIn;
