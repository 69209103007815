import {
  IEquipmentPosDistributeSettings,
  IEquipmentPosDistributeTask,
  IEquipmentPosDistributeTaskDetail
} from '@/dataModel/equipment/distribute/interface';
import request from '@/utils/request';

const { post } = request;

export default {
  /* 获得自动下发设置 */
  get: () => post<IEquipmentPosDistributeSettings>('/api/pcweb/equipment/distribute/get',{},{},'setting'),
  getNew: () => post<IEquipmentPosDistributeSettings>('/api/pcweb/equipment/distribute/get',{},{},'setting'),

  /** 获取部分门店列表 */
  getStores: (params: { executorId: string }) =>
    post<IEquipmentPosDistributeTaskDetail[]>('/api/pcweb/equipment/distribute/getStores', params,{},'setting'),

    /** 获取部分门店列表 */
  getStoresNew: (params: { executorId: string }) =>
  post<IEquipmentPosDistributeTaskDetail[]>('/api/pcweb/equipment/distribute/getStores', params,{},'setting'),
  /* 手动下发 */
  manualDistribute: (params: { dataRanges: string[]; storeIds: string[] }) =>
    post<{ traceId: string }>('/api/pcweb/equipment/distribute/manualDistribute', params,{},'setting'),
  manualDistributeNew: (params: { dataRanges: string[]; storeIds: string[] }) =>
    post<{ traceId: string }>('/api/pcweb/equipment/distribute/manualDistribute', params,{},'setting'),

  /* 分页查询最近下发记录 */
  queryRecord: (
    params: {
      filter?: {
        /** 下发开始日期 */
        createBegin?: string;
        /** 下发结束日期 */
        createEnd?: string;
        /** 操作人 */
        operCtx?: string;
        /** 门店编码/名称搜索 */
        storeSearchKey?: string;
      };
    } & IPageParams
  ) => post<IPageData<IEquipmentPosDistributeTask>>('/api/pcweb/equipment/distribute/queryRecord', params,{},'setting'),
/* 分页查询最近下发记录 */
queryRecordNew: (
  params: {
    filter?: {
      /** 下发开始日期 */
      createBegin?: string;
      /** 下发结束日期 */
      createEnd?: string;
      /** 操作人 */
      operCtx?: string;
      /** 门店编码/名称搜索 */
      storeSearchKey?: string;
    };
  } & IPageParams
) => post<IPageData<IEquipmentPosDistributeTask>>('/api/pcweb/equipment/distribute/queryRecord', params,{},'setting'),

  /** 分页查询最近下发记录明细 */
  queryRecordDetail: (
    params: {
      /** 任务编码 */
      executorId?: string;
      filter?: {
        /** POS编码 */
        posId: string;
        /** 门店编码/名称搜索 */
        storeSearchKey: string;
      };
    } & IPageParams
  ) => post<IPageData<IEquipmentPosDistributeTaskDetail>>('/api/pcweb/equipment/distribute/queryRecordDetail', params,{},'setting'),
/** 分页查询最近下发记录明细 */
queryRecordDetailNew: (
  params: {
    /** 任务编码 */
    executorId?: string;
    filter?: {
      /** POS编码 */
      posId: string;
      /** 门店编码/名称搜索 */
      storeSearchKey: string;
    };
  } & IPageParams
) => post<IPageData<IEquipmentPosDistributeTaskDetail>>('/api/pcweb/equipment/distribute/queryRecordDetail', params,{},'setting'),

  /** 保存自动下发设置 */
  save: (params: {
    auto: boolean;
    distributeDate?: string;
    /**租户自动下发设置唯一标识 */
    uuid: string;
  }) => post<{ traceId: string; uuid: string; version: number }>('/api/pcweb/equipment/distribute/save', params,{},'setting'),
  /** 保存自动下发设置 */
  saveNew: (params: {
    auto: boolean;
    distributeDate?: string;
    /**租户自动下发设置唯一标识 */
    uuid: string;
  }) => post<{ traceId: string; uuid: string; version: number }>('/api/pcweb/equipment/distribute/save', params,{},'setting')
};
