import request from '@/utils/request';
import {
  IPrePayUsageRecordDump,
  IPrePayUsageRecordFlow,
  IPrePayUsageRecordSale
} from '@/dataModel/member/prePayUsageRecordList/interface';
const { post } = request;

const prePayUsageRecordList = {
  /**
   * 分页查询礼品卡管理使用记录->流水
   */
  queryFlow: (
    params: {
      filter: {
        beginDate?: string;
        creatorName?: string;
        endDate?: string;
        idBuyerCardHolderOrderId?: string;
        orderBys?: any;
        storeName?: string;
      };
    } & IPageParams
  ) => post<IPageData<IPrePayUsageRecordFlow>>('/api/pcweb/member/prepay/queryUsageRecordFlow', params, {}, 'card'),

  /**
   * 分页查询礼品卡管理使用记录->发售
   */
  querySale: (
    params: {
      filter: {
        beginDate?: string;
        creatorName?: string;
        endDate?: string;
        idBuyerCardHolderOrderId?: string;
        orderBys?: any;
        storeName?: string;
      };
    } & IPageParams
  ) => post<IPageData<IPrePayUsageRecordSale>>('/api/pcweb/member/prepay/queryUsageRecordSale', params, {}, 'card'),

  /**
   * 分页查询礼品卡管理使用记录->转储
   */
  queryDump: (
    params: {
      filter: {
        /**卡号/购卡人/交易号 */
        idBuyerCardHolderOrderId?: string;
        /**开始时间 */
        beginDate?: string;
        /**结束时间 */
        endDate?: string;
        /**会员姓名/手机号 */
        memberKeywords?: string;
        /**门店名称 */
        storeName?: string;
        /**操作人id */
        creatorName?: string;
        /**POS机号 */
        posId?: string;
        /**排序 */
        orderBys?: any;
      };
    } & IPageParams
  ) => post<IPageData<IPrePayUsageRecordDump>>('/api/pcweb/member/flow/dump/query', params, {}, 'card')
};

export default prePayUsageRecordList;
