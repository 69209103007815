import request from '@/utils/request';
import {
  IpromoteBillQuery,
  IpromoteBillFilter,
  IpromoteBillQueryDetail,
  IpromoteBillGet
} from '@/dataModel/retail/promoteBill/interface';
import { PromoteBillTypeEnum, PromoteBillStatusEnum } from '@/dataModel/dict/enum';
const { post } = request;

const promoteBill = {
  /** 分页查询促销单 */
  query: (
    params: {
      filter?: IpromoteBillFilter;
    } & IPageParams
  ) => post<IPageData<IpromoteBillQuery>>('/api/pcweb/retail/promoteBill/query', params),

  /**查询单头信息 */
  getBill: (params: { uuid: string }) => post<IpromoteBillQuery>('/api/pcweb/retail/promoteBill/getBill', params),

  /**分页查询商品明细 */
  queryDetail: (
    params: {
      filter?: {
        goodsKeyWord?: string;
        isPrint?: boolean;
      };
      bill?: string;
    } & IPageParams
  ) => post<IPageData<IpromoteBillQueryDetail>>('/api/pcweb/retail/promoteBill/queryDetail', params),

  /**删除 */
  remove: (params: { uuid: string }) => post('/api/pcweb/retail/promoteBill/remove', params),

  /**查询全量详情-编辑 */
  get: (params: { uuid: string }) => post<IpromoteBillGet>('/api/pcweb/retail/promoteBill/get', params),
  /**新建促销单 */
  create: (params: {
    goodsDetails: {
      discountValue?: number;
      promoteValue?: number;
      tenantId?: string;
      promoteGoodsQty?: number;
      remark?: string;
      reason?: string;
      uuid?: string;
      version?: number;
      munitUuid?: string;
    }[];
    promoteType: PromoteBillTypeEnum;
    defaultDiscount?: number;
    promoteValue?: number;
    endTime: string;
    startTime: string;
    targetStatus: string;
    reason: string;
  }) => post<{ traceId: string; uuid: string }>('/api/pcweb/retail/promoteBill/create', params),
  /**新建促销单 */
  modify: (params: {
    goodsDetails: {
      discountValue?: number;
      promoteValue?: number;
      tenantId?: string;
      promoteGoodsQty?: number;
      remark?: string;
      reason?: string;
      uuid?: string;
      version?: number;
      munitUuid?: string;
    }[];
    promoteType: PromoteBillTypeEnum;
    defaultDiscount?: number;
    promoteValue?: number;
    endTime: string;
    startTime: string;
    targetStatus: string;
    reason: string;
  }) => post<{ traceId: string; uuid: string }>('/api/pcweb/retail/promoteBill/modify', params),
  /**  变更促销单状态*/
  changeStatus: (params: { targetStatus: PromoteBillStatusEnum; uuid: string }) =>
    post<any>('/api/pcweb/retail/promoteBill/changeStatus', params),
  /**批量审核 */
  batchApprove: (params: { uuids: string[] }) => post('/api/pcweb/retail/promoteBill/batchApprove', params),
  /**汇报打印结果 */
  reportPrint: (params: { bill: string; detailUuids?: string[] }) =>
    post('/api/pcweb/retail/promoteBill/reportPrint', params)
};
export default promoteBill;
