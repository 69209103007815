import {
  IArrivalInfo,
  IArrivalRateTotal,
  IDisabledOrder,
  IExpireOrder,
  IOnOrderGoods,
  IOnOrderGoodsTotal
} from '@/dataModel/purchase/orderMonitor/interFace';
import request from '@/utils/request';
const { post } = request;

export default {
  /**查询订单到货率分页 */
  queryReceiverRate: (
    params: {
      filter?: {
        billId?: string; //采购订单号
        endDate?: string; //截至日期
        goodsKey?: string; //商品查询关键字
        munit?: string; //单位
        purchaser?: string; //采购员
        startDate?: string; //起始日期
        supplier?: string; //供应商
      };
    } & IPageParams
  ) => post<IPageData<IArrivalInfo>>('/api/pcweb/purchase/order/queryReceiverRate', params),
  /**查询订单到货率统计 */
  queryReceiverRateStat: (params: {
    filter?: {
      billId?: string; //采购订单号
      endDate?: string; //截至日期
      goodsKey?: string; //商品查询关键字
      munit?: string; //单位
      purchaser?: string; //采购员
      startDate?: string; //起始日期
      supplier?: string; //供应商
    };
  }) => post<IArrivalRateTotal>('/api/pcweb/purchase/order/queryReceiverRateStat', params),
  /**查询到期订单分页 */
  queryOverDue: (
    params: {
      filter?: {
        billId?: string; //采购订单号
        endDate?: string; //截至日期
        goodsKey?: string; //商品查询关键字
        munit?: string; //单位
        purchaser?: string; //采购员
        startDate?: string; //起始日期
        supplier?: string; //供应商
      };
    } & IPageParams
  ) => post<IPageData<IExpireOrder>>('/api/pcweb/purchase/order/queryOverDue', params),
  /**查询失效订单分页 */
  queryInvalid: (
    params: {
      filter?: {
        billId?: string; //采购订单号
        endDate?: string; //截至日期
        goodsKey?: string; //商品查询关键字
        munit?: string; //单位
        purchaser?: string; //采购员
        startDate?: string; //起始日期
        supplier?: string; //供应商
        deadEndDate?: string; //失效结束日期
        deadStartDate?: string; //失效开始日期
      };
    } & IPageParams
  ) => post<IPageData<IDisabledOrder>>('/api/pcweb/purchase/order/queryInvalid', params),
  /**查询在途订单分页 */
  queryRouting: (
    params: {
      filter?: {
        billId?: string; //采购订单号
        endDate?: string; //截至日期
        goodsKey?: string; //商品查询关键字
        munit?: string; //单位
        purchaser?: string; //采购员
        startDate?: string; //起始日期
        supplier?: string; //供应商
      };
    } & IPageParams
  ) => post<IPageData<IOnOrderGoods>>('/api/pcweb/purchase/order/queryRouting', params),
  /**查询订单到货率统计 */
  queryRoutingStat: (params: {
    filter?: {
      billId?: string; //采购订单号
      endDate?: string; //截至日期
      goodsKey?: string; //商品查询关键字
      munit?: string; //单位
      purchaser?: string; //采购员
      startDate?: string; //起始日期
      supplier?: string; //供应商
    };
  }) => post<IOnOrderGoodsTotal>('/api/pcweb/purchase/order/queryRoutingStat', params)
};
