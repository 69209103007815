import request from '@/utils/request';
const { post } = request;

const power = {
  /**
   * 分页查询
   */
  showElementList: () => post<string[]>('/api/pcweb/power/showElementList')
};

export default power;
