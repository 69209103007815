import {
  IBusinessWrhGain,
  IBusinessWrhGainBasicInfo,
  IBusinessWrhGainDetails,
  IWrhGainGoods,
  IWrhGainEditDetail
} from '@/dataModel/business/wrhGain/interface';
import { WrhGainStatusEnum } from '@/dataModel/dict/enum';
import request from '@/utils/request';
const { post } = request;

const WrhGain = {
  /**
   * 分页查
   */
  query: (
    params: {
      filter: {
        createStartTime?: string;
        createEndTime?: string;
        id?: string;
        reason?: string;
        status?: WrhGainStatusEnum;
        wrhId?: string;
        goodsInfo?: string;
      };
    } & IPageParams
  ) => post<IPageData<IBusinessWrhGain>>('/api/pcweb/wrh/gain/query', params),
  /**
   * 列表提交报损单
   */
  submit: (params: { uuid: string; version: number }) =>
    post<{ traceId: string; version: number }>('/api/pcweb/wrh/gain/submit', params),
  /**
   * 删除
   */
  remove: (params: { uuid: string }) => post<{ data: string }>('/api/pcweb/wrh/gain/remove', params),
  /**
   * 获取表头信息
   */
  getBasicInfo: (params: { id: string }) => post<IBusinessWrhGainBasicInfo>('/api/pcweb/wrh/gain/getBasicInfo', params),
  /**
   * 查询订单商品明细列表
   */
  queryDetails: (
    params: {
      filter: {
        billId: string;
        goodsInfo: string;
      };
    } & IPageParams
  ) => post<IPageData<IBusinessWrhGainDetails>>('/api/pcweb/wrh/gain/queryDetails', params),
  /**
   * 查询详情-编辑/审核用
   */
  getDetail: (params: { id: string }) => post<IWrhGainEditDetail>('/api/pcweb/wrh/gain/getDetail', params),
  /**
   * 保存/提交
   */
  save: (params: {
    detailList: {
      munitUuid: string;
      planQty: number;
      reason: string;
    }[];
    isSubmit: boolean;
    wrhId: string;
    reason: string;
    uuid?: string;
    version?: number;
  }) => post<{ traceId: string; uuid: string; version: number }>('/api/pcweb/wrh/gain/save', params),
  /**
   * 审核同意
   */
  approve: (params: {
    uuid: string;
    version: number;
    approveRemark: string;
    detailList: { approveQty: number; uuid: string }[];
  }) => post<{ traceId: string; version: string }>('/api/pcweb/wrh/gain/approve', params),
  /**
   * 审核拒绝
   */
  reject: (params: { uuid: string; version: number; approveRemark: string }) =>
    post<{ traceId: string; version: string }>('/api/pcweb/wrh/gain/reject', params),
  /**
   * 查询商品列表-添加商品时用
   */
  queryGoods: (params: { filter: { goodsInfo: string; sortId: string }; wrhId: string } & IPageParams) =>
    post<IPageData<IWrhGainGoods>>('/api/pcweb/wrh/gain/queryGoods', params),

  /**
   * 导入报损
   */
  getGoodsByIoTask: (params: { taskUuid: string }) =>
    post<IBusinessWrhGainDetails[]>('/api/pcweb/wrh/gain/getGoodsByIoTask', params)
};

export default WrhGain;
