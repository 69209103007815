import { EwsPriceTypeEnum } from '@/dataModel/dict/enum';
import { IEws } from '@/dataModel/equipment/ews/interface';
import request from '@/utils/request';

export default {
  create: (params: Partial<IEws>) => request.post('/api/pcweb/equipment/ews/save', params),

  delete: (params: { uuid: string }) => request.post('/api/pcweb/equipment/ews/delete', params),

  modify: (params: Partial<IEws>) => request.post('/api/pcweb/equipment/ews/modified', params),
  // 修改电子秤备注
  updateRemark: (params: Pick<IEws, 'uuid' | 'remark'>) =>
    request.post('/api/pcweb/equipment/ews/updateRemark', params),

  query: (params?: any) => request.post<IPageData<IEws>>('/api/pcweb/equipment/ews/query', params),

  get: (params: { uuid: string }) => request.post<IEws>('/api/pcweb/equipment/ews/get', params),

  /**传秤 */
  sendMessage: (params?: { uuidList: string[] }) => request.post<any>('/api/pcweb/equipment/ews/sendMessage', params),
  /**获取传秤结果 */
  getTransferResult: (params?: { traceId: string }) =>
    request.post<any>('/api/pcweb/equipment/ews/getTransferResult', params),

  /**设置电子秤配置 */
  setting: (params: { configs: { priceType: EwsPriceTypeEnum } }) =>
    request.post<any>('/api/pcweb/equipment/ews/setting', {
      ...params,
      name: 'com.hd123.saas.cx.main.api.equipment.ews.BizEwsConfig'
    }),

  /**查询电子秤配置 */
  getSetting: () =>
    request.post<EwsPriceTypeEnum>('/api/pcweb/equipment/ews/getSetting', {
      name: 'com.hd123.saas.cx.main.api.equipment.ews.BizEwsConfig'
    })
};
