import {
  PurchaseArrivalByGoodsQueryRequest,
  PurchaseArrivalGoodsSum,
  PurchaseArrivalSupplierSum,
  PurchaseArrivalGoods,
  PurchaseArrivalSupplier
} from '@/dataModel/wholesale/purchaseArriveRate';
import request from '@/utils/request';
const { post } = request;

const report = {
  // /api/pcweb/purchase/arrival/getSumByGoods
  getSumByGoods: (params: PurchaseArrivalByGoodsQueryRequest) => {
    return post<PurchaseArrivalGoodsSum>('/api/pcweb/purchase/arrival/getSumByGoods', params);
  },
  //  /api/pcweb/purchase/arrival/getSumBySupplier
  getSumBySupplier: (params: PurchaseArrivalByGoodsQueryRequest) => {
    return post<PurchaseArrivalSupplierSum>('/api/pcweb/purchase/arrival/getSumBySupplier', params);
  },
  //  /api/pcweb/purchase/arrival/queryByGoods
  queryByGoods: (params: PurchaseArrivalByGoodsQueryRequest) => {
    return post<IPageData<PurchaseArrivalGoods>>('/api/pcweb/purchase/arrival/queryByGoods', params);
  },
  //  /api/pcweb/purchase/arrival/queryBySupplier
  queryBySupplier: (params: PurchaseArrivalByGoodsQueryRequest) => {
    return post<IPageData<PurchaseArrivalSupplier>>('/api/pcweb/purchase/arrival/queryBySupplier', params);
  }
};

export default report;
