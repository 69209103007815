import { GoodsModifyPriceBillStatusEnum } from '@/dataModel/business/priceAdjust/enum';
import {
  IBusinessPriceAdjust,
  IBusinessPriceAdjustDetail,
  IBusinessPriceAdjustSearchGoods
} from '@/dataModel/business/priceAdjust/interface';
import { ISort } from '@/dataModel/mdata/sort/interface';
import request from '@/utils/request';

const { post } = request;

const priceAdjust = {
  /**
   * 新增
   */
  create: (params: {
    details: {
      memberPrice: number;
      retailPrice: number;
      remark?: string;
    }[];
    version: number;
    launchTime?: string;
    remark?: string;
  }) => post<IBusinessPriceAdjust>('/api/pcweb/business/price/adjust/create', params),

  /**
   * 获取详情
   */
  get: (params: { bill?: string; id?: string }) =>
    post<{ bill: IBusinessPriceAdjust }>('/api/pcweb/business/price/adjust/get', params),

  /**
   * 提交
   */
  launch: (params: { bill: string; version: number }) =>
    post<IBusinessPriceAdjust>('/api/pcweb/business/price/adjust/launch', params),

  /**
   * 修改
   */
  modify: (params: {
    bill: string;
    details: {
      memberPrice: number;
      retailPrice: number;
      uuid: string;
      remark?: string;
    }[];
    launchTime?: string;
    remark?: string;
    version?: number;
  }) => post<any>('/api/pcweb/business/price/adjust/modify', params),

  /**
   * 分页查
   */
  query: (
    params: {
      filter: {
        startDate: string;
        endDate: string;
        id?: string;
        status?: GoodsModifyPriceBillStatusEnum;
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => post<IPageData<IBusinessPriceAdjust>>('/api/pcweb/business/price/adjust/query', params),

  /**
   * 分页查明细
   */
  queryDetail: (
    params: {
      bill: string;
      filter?: {
        searchKey?: string;
      };
    } & IPageParams
  ) => post<IPageData<IBusinessPriceAdjustDetail>>('/api/pcweb/business/price/adjust/queryDetail', params),

  /**
   * 删除
   */
  remove: (params: { bill: string }) => post<any>('/api/pcweb/business/price/adjust/remove', params),

  /**
   * 撤回
   */
  revoke: (params: { bill: string; version: number }) => post<any>('/api/pcweb/business/price/adjust/revoke', params),

  /**
   * 搜索商品
   */
  queryGoods: (
    params: {
      filter?: { keyword?: string; sortId?: ISort['id'] };
    } & IPageParams
  ) => post<IPageData<IBusinessPriceAdjustSearchGoods>>('/api/pcweb/business/price/adjust/queryGoods', params),
  /**
   * 根据导入任务获取明细信息
   * @param params
   */
  getGoodsByIoTask: (params: { taskUuid: string }) =>
    post<IBusinessPriceAdjustDetail[]>('/api/pcweb/business/price/adjust/getGoodsByIoTask', params)
};

export default priceAdjust;
