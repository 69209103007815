import {
  IDistributionMarinDetail,
  IDistributionMarinStore,
  IDistributionMarinSummary,
  IDistributionMarinTotal,
  IDistributionMarinWrh,
  IQueryFilterParams
} from '@/dataModel/business/distributionMargin/interface';
import request from '@/utils/request';
const { post } = request;

const distributionMargin = {
  /** 按汇总 */
  queryBySummary: (
    params: {
      filter: IQueryFilterParams;
      createDateBegin: string; //开始时间
      createDateEnd: string; //结束时间
    } & IPageParams
  ) => post<IPageData<IDistributionMarinSummary>>('/api/pcweb/business/distribution/margin/queryBySummary', params),

  /** 按汇总统计 */
  getSummaryTotal: (params: {
    filter: IQueryFilterParams;
    createDateBegin: string; //开始时间
    createDateEnd: string; //结束时间
  }) => post<IDistributionMarinTotal>('/api/pcweb/business/distribution/margin/getSummaryTotal', params),

  /**按仓库 */
  queryByWrh: (
    params: {
      filter: IQueryFilterParams;
      createDateBegin: string; //开始时间
      createDateEnd: string; //结束时间
    } & IPageParams
  ) => post<IPageData<IDistributionMarinWrh>>('/api/pcweb/business/distribution/margin/queryByWrh', params),

  /**按仓库统计 */
  getWrhTotal: (params: {
    filter: IQueryFilterParams;
    createDateBegin: string; //开始时间
    createDateEnd: string; //结束时间
  }) => post<IDistributionMarinTotal>('/api/pcweb/business/distribution/margin/getWrhTotal', params),
  /** 按门店 */
  queryByStore: (
    params: {
      filter: IQueryFilterParams;
      createDateBegin: string; //开始时间
      createDateEnd: string; //结束时间
    } & IPageParams
  ) => post<IPageData<IDistributionMarinStore>>('/api/pcweb/business/distribution/margin/queryByStore', params),
  /**按门店统计 */
  getStoreTotal: (params: {
    filter: IQueryFilterParams;
    createDateBegin: string; //开始时间
    createDateEnd: string; //结束时间
  }) => post<IDistributionMarinTotal>('/api/pcweb/business/distribution/margin/getStoreTotal', params),

  /** 按明细 */
  queryByDetail: (
    params: {
      filter: IQueryFilterParams;
      createDateBegin: string; //开始时间
      createDateEnd: string; //结束时间
    } & IPageParams
  ) => post<IPageData<IDistributionMarinDetail>>('/api/pcweb/business/distribution/margin/queryByDetail', params),
  /**按明细统计 */
  getDetailTotal: (params: {
    filter: IQueryFilterParams;
    createDateBegin: string; //开始时间
    createDateEnd: string; //结束时间
  }) => post<IDistributionMarinTotal>('/api/pcweb/business/distribution/margin/getDetailTotal', params)
};

export default distributionMargin;
