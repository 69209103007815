import {
  BaseGetRequest,
  BaseModifyResult,
  Enpuipment,
  HolderEditorRequest,
  PickingBatchBindWrhRequest,
  PickingBindWrhRequest,
  PickingScaleQueryFilter,
  WebBaseResponse
} from '@/dataModel/wrh/sorting/equipment/interface';
import request from '@/utils/request';
const { post } = request;

const sortingEquipment = {
  // /api/pcweb/equipment/pickingscale/authcode/query 分页查询授权码
  authcodeQuery: params => post<IPageData<Enpuipment>>('/api/pcweb/equipment/picking/authcode/query', params),
  // /api/pcweb/equipment/pickingscale/bindWrh 绑定仓库信息
  bindWrh: (params: PickingBindWrhRequest) =>
    post<BaseModifyResult>('/api/pcweb/equipment/picking/device/bindWrh', params),
  batchBindWrh: (params: PickingBatchBindWrhRequest) =>
    post<BaseModifyResult>('/api/pcweb/equipment/picking/device/batchBindWrh', params),
  // /api/pcweb/equipment/pickingscale/delete 删除设备
  delete: (params: BaseGetRequest) => post<WebBaseResponse>('/api/pcweb/equipment/picking/device/delete', params),
  // /api/pcweb/equipment/pickingscale/query 分页查询分拣设备
  pickingscaleQuery: (
    params: {
      filter: PickingScaleQueryFilter;
    } & IPageParams
  ) => post<IPageData<Enpuipment>>('/api/pcweb/equipment/picking/device/query', params),
  // /api/pcweb/equipment/pickingscale/removeActive 移除激活
  removeActive: (params: BaseGetRequest) =>
    post<WebBaseResponse>('/api/pcweb/equipment/picking/device/removeActive', params),
  // /api/pcweb/equipment/picking/device/updateRemark 修改备注
  updateRemark: (params: HolderEditorRequest) =>
    post<WebBaseResponse>('/api/pcweb/equipment/picking/device/updateRemark', params),
  // /api/pcweb/equipment/picking/authcode/getCount 获取分拣设备数量
  getCount: () => post<WebBaseResponse>('/api/pcweb/equipment/picking/authcode/getCount')
};

export default sortingEquipment;
