import request from '@/utils/request';
import { IStoreMemberStoredSummary } from '../../../dataModel/member/storeMemberStoredSummary/interface';
const { post } = request;

const storeMemberStoredSummary = {
  /**总部-门店储值汇总 */
  queryHeadMemberStoredSummary: (
    params: {
      filter?: {
        beginDate?: string; //开始时间
        endDate?: string; //结束时间
        storeSearchKey?: string; //门店编码/名称
      };
    } & IPageParams
  ) => post<IPageData<IStoreMemberStoredSummary>>('/api/pcweb/member/info/queryHeadMemberStoredSummary', params),
  /**总部-门店储值汇总统计 */
  queryHeadMemberStoredSummaryTotal: (params: {
    filter?: {
      beginDate?: string; //开始时间
      endDate?: string; //结束时间
      storeSearchKey?: string; //门店编码/名称
    };
  }) => post<any>('/api/pcweb/member/info/queryHeadMemberStoredSummaryTotal', params),
  /**门店-门店储值汇总 */
  queryStoreMemberStoredSummary: (
    params: {
      filter?: {
        beginDate?: string; //开始时间
        endDate?: string; //结束时间
      };
    } & IPageParams
  ) => post<IPageData<IStoreMemberStoredSummary>>('/api/pcweb/member/info/queryStoreMemberStoredSummary', params),
  /**门店-门店储值汇总统计 */
  queryStoreMemberStoredSummaryTotal: (params: {
    filter?: {
      beginDate?: string; //开始时间
      endDate?: string; //结束时间
    };
  }) => post<any>('/api/pcweb/member/info/queryStoreMemberStoredSummaryTotal', params)
};

export default storeMemberStoredSummary;
