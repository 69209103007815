import {
  IOrderQueryBill,
  IOrderQueryFilter,
  IOutBillList,
  ISaveInfo,
  IWholesaleOrder,
  IWholesaleOrderCustomer,
  IWholesaleOrderGoods
} from '@/dataModel/wholesale/order/interface';
import request from '@/utils/request';

const { post } = request;

const wholesaleOrder = {
  /**获取客户列表 */
  getCustomerList: () => post<IWholesaleOrderCustomer[]>('/api/pcweb/wholesale/order/getCustomerList'),
  /**获取业务员列表 */
  getSalesManList: () => post<{ id: string; name: string }[]>('/api/pcweb/wholesale/order/getSalesManList'),
  /**分页查询添加商品 */
  queryGoods: (
    params: {
      filter?: {
        keyword?: string;
        sortId?: string;
      };
      customerId: string;
    } & IPageParams
  ) => post<IPageData<IWholesaleOrderGoods>>('/api/pcweb/wholesale/order/queryGoods', params),
  /**获取单头信息 */
  get: (params: { id?: string; uuid?: string }) => post<IWholesaleOrder>('/api/pcweb/wholesale/order/get', params),
  /**获取单据明细-编辑 */
  getDetailsForEdit: (uuid: string) =>
    post<IWholesaleOrderGoods[]>('/api/pcweb/wholesale/order/getDetailsForEdit', { uuid }),
  /**获取已转批发单列表 */
  getOutBillList: (params: {
    isAbort?: boolean; //是否为作废单据
    uuid: string;
  }) => post<IOutBillList[]>('/api/pcweb/wholesale/order/getOutBillList', params),
  /**分页查询明细 */
  queryDetail: (
    params: {
      filter?: {
        keyword?: string;
      };
      bill: string; //单据标识
      isAll?: boolean; //是否全部
      isConvert?: boolean; //是否已转
      outBillId?: string; //批发单单号
    } & IPageParams
  ) => post<IPageData<IWholesaleOrderGoods>>('/api/pcweb/wholesale/order/queryDetail', params),
  /**保存单据 */
  save: (params: ISaveInfo) =>
    post<{ traceId: string; uuid: string; version: number }>('/api/pcweb/wholesale/order/save', params),
  /**分页查询批发单-单据 */
  getOrderQuery: (params: { filter: IOrderQueryFilter } & IPageParams) =>
    request.post<IPageData<IOrderQueryBill>>('/api/pcweb/wholesale/order/query', params),

  /**删除单据 */
  orderRemove: (params: { uuid: string }) => post('/api/pcweb/wholesale/order/remove', params),

  /**批量修改状态 */
  batchModifyStatus: (params: { targetStatus: string; bills: { uuid: string; version: number }[] }) =>
    post<any>('/api/pcweb/wholesale/order/batchModifyStatus', params),
  /**转销售单 */
  convertOut: (params: {
    bill: string;
    detailUuids?: string[];
    isAll: boolean;
    outType: string;
    senderId: string;
    version?: number;
  }) => post('/api/pcweb/wholesale/order/convertOut', params),
  /**获取等待的分钟数 */
  getWaitMinute: (uuid: string) => post<number>('/api/pcweb/wholesale/order/getWaitMinute', { uuid })
};

export default wholesaleOrder;
