import {
  IAllocationReportByDetail,
  IAllocationReportByDetailCount,
  IAllocationReportByTotal,
  IAllocationReportByTotalCount
} from '@/dataModel/finance/allocationReport/interface';
import request from '@/utils/request';
const { post } = request;
export default {
  /**调拨汇总 */
  queryByTotal: (
    params: {
      filter?: {
        endDate?: string; // 结束时间
        startDate?: string; // 开始时间
        wrhUuids?: []; // 调出门店uuid/调出仓库uuid 调入门店uuid/调入仓库uuid
        senderUuids?: []; // 调出门店uuid/调出仓库uuid
        receiverUuids?: []; // 调入门店uuid/调入仓库uuid
      };
    } & IPageParams
  ) => post<IPageData<IAllocationReportByTotal>>('/api/pcweb/finance/transfer/report/queryWrh', params),
  /**调拨汇总 总计 */
  queryByTotalCount: (
    params: {
      filter?: {
        endDate?: string; // 结束时间
        startDate?: string; // 开始时间
        wrhUuids?: []; // 调出门店uuid/调出仓库uuid 调入门店uuid/调入仓库uuid
        senderUuids?: []; // 调出门店uuid/调出仓库uuid
        receiverUuids?: []; // 调入门店uuid/调入仓库uuid
      };
    } & IPageParams
  ) => post<IAllocationReportByTotalCount>('/api/pcweb/finance/transfer/report/getWrhSum', params),
  /**调拨明细 */
  queryByDetail: (
    params: {
      filter?: {
        endDate?: string; // 结束时间
        startDate?: string; // 开始时间
        wrhUuids?: []; // 调出门店uuid/调出仓库uuid 调入门店uuid/调入仓库uuid
        senderUuids?: []; // 调出门店uuid/调出仓库uuid
        receiverUuids?: []; // 调入门店uuid/调入仓库uuid
        inBillId?: string; // 调入单号
        outBillId?: string; // 调出单号
        reason?: string; // 调拨原因
        searchKey?: string; // 商品关键字
      };
    } & IPageParams
  ) => post<IPageData<IAllocationReportByDetail>>('/api/pcweb/finance/transfer/report/queryDetail', params),
  /**调拨明细 总计 */
  queryByDetailCount: (
    params: {
      filter?: {
        endDate?: string; // 结束时间
        startDate?: string; // 开始时间
        wrhUuids?: []; // 调出门店uuid/调出仓库uuid 调入门店uuid/调入仓库uuid
        senderUuids?: []; // 调出门店uuid/调出仓库uuid
        receiverUuids?: []; // 调入门店uuid/调入仓库uuid
        inBillId?: string; // 调入单号
        outBillId?: string; // 调出单号
        reason?: string; // 调拨原因
        searchKey?: string; // 商品关键字
      };
    } & IPageParams
  ) => post<IAllocationReportByDetailCount>('/api/pcweb/finance/transfer/report/getDetailSum', params)
};
