import { StorePaymentTypeEnum, TargetsSortTypeEnum } from '@/dataModel/dict/enum';
import {
  ICashierConfigInfo,
  IFinanceConfigInfo,
  IGetBusiness,
  IGetOthers,
  IGetRemind,
  IGoodsConfigInfo,
  INewSetting,
  IOthersConfigInfo,
  IPurchaseDistConfigInfo,
  IStoreBusinessConfigInfo,
  ITargets,
  IWholeSaleConfigInfo
} from '@/dataModel/system/business/interface';

import request from '@/utils/request';

const { post } = request;

/**
 * 导入任务
 */
const business = {
  /** 获取业务设置 */
  getBusiness: () => post<IGetBusiness>('/api/pcweb/system/business/getBusiness'),

  /** 保存业务设置 */
  setBusiness: (params: {
    businessSetConfigInfo: {
      /** 是否开启门店订货自动提交 */
      enableRequireOrderAutoSubmit: boolean;
      /** 门店订货自动提交时间 */
      requireOrderAutoSubmitTime: string;
      enablePayPassword: boolean;
      companyName?: string;
      goodsInfoConfig: {
        origin: boolean;
        sort: boolean;
      };
      monthSettleConfig: {
        isEndMonth: boolean;
        monthDate: number;
      };
      storeConfig?: {
        checkCondition?: string;
        checkOrg?: string;
        checkRule: string;
        qtyStr?: number;
        total?: number;
      };
      supplierBackPrice: string;
      wrhAllocOutRule?: string;
      wrhConfig?: {
        checkCondition?: string;
        checkOrg?: string;
        checkRule: string;
        qtyStr?: number;
        total?: number;
      };
      /**是否允许多收 */
      isAllowMoreIn: boolean;
      /**是否允许多次收货*/
      isAllowMultiIn: boolean;
      /** 收银员缴款方式 */
      storePaymentType: StorePaymentTypeEnum;
    };
  }) => post<{ data: string }>('/api/pcweb/system/business/setBusiness', params),

  /** 获取提醒设置 */
  getRemind: () => post<IGetRemind>('/api/pcweb/system/business/getRemind'),

  /** 保存提醒设置 */
  setRemind: (params: {
    remindSetConfigInfo: {
      goodsLifeDays: number;
      isGoodsLife: boolean;
      isJoinAccount: boolean;
      isPurchaseOrder: boolean;
      isSupplierAgreement: boolean;
      joinAccountBalance: number;
      purchaseOrderDays: number;
      supplierAgreementDays: number;
    };
  }) => post<{ data: string }>('/api/pcweb/system/business/setRemind', params),

  /** 获取其他设置 */
  getOthers: () => post<IGetOthers>('/api/pcweb/system/business/getOthers'),

  /** 获取新-设置 */
  getNewSetting: (params: {
    /** 设置类型 */
    businessType: string;
  }) => post<INewSetting>('/api/pcweb/system/business/getBusinessNew', params),

  /** 保存新-设置 */
  saveNewSetting: (params: {
    /** 设置类型 */
    businessType: string;
    /**商品设置 */
    goodsConfig?: IGoodsConfigInfo;
    /**采配设置 */
    purchaseDistConfig?: IPurchaseDistConfigInfo;
    /**批发设置 */
    wholeSaleConfig?: IWholeSaleConfigInfo;
    /**财务设置 */
    financeConfig?: IFinanceConfigInfo;
    /**收银设置 */
    cashierConfig?: ICashierConfigInfo;
    /**店务设置 */
    storeBusinessConfig?: IStoreBusinessConfigInfo;
    /**其他设置 */
    othersConfig?: IOthersConfigInfo;
  }) => post<any>('/api/pcweb/system/business/setBusinessNew', params),

  /** 保存其他设置 */
  setOthers: (params: {
    othersSetConfigInfo: {
      goodsType: string;
      purchaseOrderBillClass: string;
    };
  }) => post<{ data: string }>('/api/pcweb/system/business/setOthers', params),

  /** 获得当前结转期 */
  current: () => post<{ data: string }>('/api/pcweb/system/month/settle/get/current'),
  /**获取指标设置 */
  getTargets: () => post<ITargets>('/api/pcweb/system/business/getTargets'),
  /**保存某个分类的指标设置 */
  setTargetsSort: (params: {
    targetsSort: {
      numberDays: number;
      salesVolume: number;
      sortIds: string[];
      targetType: TargetsSortTypeEnum;
      uuid?: string;
      label?: string;
    };
  }) => post('/api/pcweb/system/business/setTargetsSort', params),
  /**删除分类指标设置 */
  removeTargetsSort: (params: { label: string; targetType: TargetsSortTypeEnum }) =>
    post('/api/pcweb/system/business/removeTargetsSort', params),
  /**保存指标设置 */
  setTargets: (params: {
    targetsSetConfigInfo: {
      dailySales: number;
      grossMarginMax: number;
      grossMarginMin: number;
      salableDays: number;
      salableSale: number;
      saleMax: number;
      saleMin: number;
      saleroomMax: number;
      saleroomMin: number;
      turnoverDays: number;
      unsalableDays: number;
      unsalableSale: number;
      targetsSortList: {
        numberDays: number;
        salesVolume: number;
        sortIds: string[];
        targetType: TargetsSortTypeEnum;
        uuid: string;
      }[];
    };
  }) => post('/api/pcweb/system/business/setTargets', params),
  /**获取全部门店店型 */
  getAllstoreType: (params: {
  filter?: {
      "includeMasterStore"?: boolean,
      "includeAffiliateStore"?: boolean,
      "privilegeOnly"?: boolean,
      "status"?: string
    }
  }) => post<any>('/api/pcweb/mdata/store/type/getByFilter', params),
  /**获取全部门店区域 */
  getAllstoreArea: (params: {
  }) => post<any>('/api/pcweb/mdata/organization/area/getAll', params),
  /**通过区域，店型查找门店 */
  queryOnlyStore: (params: {
    //     关键条件：searchKey，编码或者名称
    // 关键条件：organization，所属上级组织编码，按照区域查询时使用此字段。
    // 关键条件：status，门店状态：enabled使用中，disabled停用，不传入查询全部
    // 关键条件：storeModel，店型等于
    // 关键条件：privilegeOnly，是否受数据权限控制，默认false。如果控制数据权限，则传入true。（超管返回全部）
    //关键条件：includeMasterStore，是否包含虚拟店，默认false。
    filter?: {
      searchKey?: string;
      organization?: string;
      status?: string;
      storeModel?: string;
      privilegeOnly?: boolean;
      includeMasterStore?: boolean;
      includeAffiliateStore?: boolean;
    }

  }& IPageParams) => post('/api/pcweb/mdata/store/queryOnlyStore', params),
};

export default business;
