import {
  IInventoryByDetail,
  IInventoryByDetailCount,
  IInventoryByTotal,
  IInventoryByTotalCount
} from '@/dataModel/finance/reportInventory/interface';
import request from '@/utils/request';
const { post } = request;
export default {
  /**盘点盈亏汇总 */
  queryByTotal: (
    params: {
      filter?: {
        endDate?: string; // 结束时间
        startDate?: string; // 开始时间
        wrhUuids?: []; // 门店uuid/仓库uuid
      };
    } & IPageParams
  ) => post<IPageData<IInventoryByTotal>>('/api/pcweb/inv/check/in/report/queryWrh', params),
  /**盘点盈亏汇总 总计 */
  queryByTotalCount: (
    params: {
      filter?: {
        endDate?: string; // 结束时间
        startDate?: string; // 开始时间
        wrhUuids?: []; // 门店uuid/仓库uuid
      };
    } & IPageParams
  ) => post<IInventoryByTotalCount>('/api/pcweb/inv/check/in/report/getWrhSum', params),
  /**盘点盈亏明细 */
  queryByDetail: (
    params: {
      filter?: {
        endDate?: string; // 结束时间
        startDate?: string; // 开始时间
        wrhUuids?: []; // 门店uuid/仓库uuid
        billIdLike?: string; // 盈亏单号
        supplierUuids?: []; // 供应商uuids
        searchKey?: string; // 商品名称/商品编码/商品条码
      };
    } & IPageParams
  ) => post<IPageData<IInventoryByDetail>>('/api/pcweb/inv/check/in/report/queryDetail', params),
  /**盘点盈亏明细 总计 */
  queryByDetailCount: (
    params: {
      filter?: {
        endDate?: string; // 结束时间
        startDate?: string; // 开始时间
        wrhUuids?: []; // 门店uuid/仓库uuid
        billIdLike?: string; // 盈亏单号
        supplierUuids?: []; // 供应商uuids
        searchKey?: string; // 商品名称/商品编码/商品条码
      };
    } & IPageParams
  ) => post<IInventoryByDetailCount>('/api/pcweb/inv/check/in/report/getDetailSum', params),
  queryReportByStore: (
    params: {
      filter?: {
        checkInBillId?: string; //盈亏单号
        checkTaskBillId?: string; //盘点任务单号
        endTime?: string; //结束时间
        sortIds?: []; //商品分类ids
        startTime?: string; //开始时间
        storeIds?: []; //门店ids
        supplierUuids: []; //供应商uuids
      };
    } & IPageParams
  ) => post<IPageData<any>>('/api/pcweb/inv/check/in/queryReportByStore', params),
  queryReportBySupplier: (
    params: {
      filter?: {
        checkInBillId?: string; //盈亏单号
        checkTaskBillId?: string; //盘点任务单号
        endTime?: string; //结束时间
        sortIds?: []; //商品分类ids
        startTime?: string; //开始时间
        storeIds: []; //门店ids
        supplierUuids: []; //供应商uuids
      };
    } & IPageParams
  ) => post<IPageData<any>>('/api/pcweb/inv/check/in/queryReportBySupplier', params),
  queryReportCount: (
    params: {
      filter?: {
        checkInBillId?: string; //盈亏单号
        checkTaskBillId?: string; //盘点任务单号
        endTime?: string; //结束时间
        sortIds?: []; //商品分类ids
        startTime?: string; //开始时间
        storeIds?: []; //门店ids
        supplierUuids: []; //供应商uuids
      };
    } & IPageParams
  ) => post<IPageData<any>>('/api/pcweb/inv/check/in/queryReportCount', params)
};
