import {
  IGetData,
  IGetHanShowData,
  IQueryElectronicItems,
  IGetTenantElectConfig
} from '@/dataModel/equipment/electronic/interface';
import request from '@/utils/request';

const { post } = request;
export default {
  /**
   * 查询汉朔电子价签是否开通
   */
  /*  getHanshow: () => post<IGetHanShowData>('/api/pcweb/equipment/electronic/getHanshow'), */
  /**查询租户电子价签是否开通 */
  getTenantElectConfig: () => post<IGetTenantElectConfig>('/api/pcweb/equipment/electronic/getTenantElectConfig'),
  /**
   * 停用/启用
   */
  modifyEnabled: (params: { isEnable: number; store: string; storeId?: string; version: number }) =>
    post<{ traceId: string; version: number }>('/api/pcweb/equipment/electronic/modifyEnabled', params),

  /**
   * 分页查询列表
   */
  query: (params: { filter: { isEnable: number; storeSearchKey: string } } & IPageParams) =>
    post<IPageData<IQueryElectronicItems>>('/api/pcweb/equipment/electronic/query', params),

  /**
   * 同步
   */
  sync: (params: { store: string }) =>
    post<{ traceId: string; version: number }>('/api/pcweb/equipment/electronic/sync', params),

  /**
   * 同步结果
   */
  get: (params: { store: string }) => post<IGetData>('/api/pcweb/equipment/electronic/get', params),

  /**
   * 更新电子价签门店号
   */
  modifyStore: (params: {
    store: IQueryElectronicItems['store'];
    version: IQueryElectronicItems['version'];
    priceTagStoreId: string;
  }) => post<any>('/api/pcweb/equipment/electronic/modifyStore', params)
};
