import { SellbuySettleStatusEnum, SupplierSettleTypeEnum } from '@/dataModel/dict/enum';
import {
  IFinanceSellBuyBill,
  IFinanceSellBuyBillDetail,
  IFinanceSellBuyBillQuota
} from '@/dataModel/finance/sellBuyBill/interface';
import request from '@/utils/request';

const { post } = request;

export default {
  queryBill: (
    params: {
      filter: {
        idKeyword: string;
        startDate: string;
        endDate: string;
        accountStart: string;
        accountEnd: string;
      };
      suppliers: string[];
    } & IPageParams
  ) => post<IPageData<IFinanceSellBuyBill>>('/api/pcweb/finance/sellbuy/bill/queryBill', params),

  /** 提交出账 */
  generate: (params: {
    batchType: SupplierSettleTypeEnum.ByOrder | SupplierSettleTypeEnum.BySale;
    targetStatus: SellbuySettleStatusEnum.Init | SellbuySettleStatusEnum.Applyed;
    uuids: string[];
    bySaleInfo?: {
      adjustTotal: number;
      supplierUuid: string;
    };
  }) => post<{ batchUuid: string; traceId: string }>('/api/pcweb/finance/sellbuy/bill/generate', params),

  /** 获取批次信息 */
  get: (params: { uuid: string }) => post<IFinanceSellBuyBillDetail>('/api/pcweb/finance/sellbuy/bill/get', params),

  /** 获取配额信息 */
  getQuota: (params: { supplierUuid: string }) =>
    post<IFinanceSellBuyBillQuota>('/api/pcweb/finance/sellbuy/bill/getQuota', params)
};
