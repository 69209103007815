import { IJoinAccount, IJoinAccountAmount, IJOinAccountFlow } from '@/dataModel/finance/joinAccount/interface';
import request from '@/utils/request';
import { number } from 'prop-types';
const { post } = request;

type IAmtTotalPageData<T> = {
  items: T[];
  page: number;
  pageSize: number;
  totalCount: number;
  inTotal: number;
  outTotal: number;
};

export default {
  /**获取账户信息 */
  get: (params: { uuid?: string; store?: string; owner?: string }) =>
    post<IJoinAccount>('/api/pcweb/finance/join/account/get', params),
  /**修改信用额度 */
  modifyCredit: (params: { amt: number; uuid: string; version: number }) =>
    post('/api/pcweb/finance/join/account/modifyCredit', params),
  /**修改保证金 */
  modifyDeposit: (params: { amt: number; uuid: string; version: number }) =>
    post('/api/pcweb/finance/join/account/modifyDeposit', params),
  /**修改状态 */
  modifyStatus: (params: { status: number; uuid: string }) =>
    post('/api/pcweb/finance/join/account/modifyStatus', params),
  /**分页查询账户信息*/
  query: (
    params: {
      filter?: {
        amtMax?: number; //最大余额
        amtMin?: number; //最小余额
        status?: string; //账户状态
        storeKey?: string; //门店关键词
      };
    } & IPageParams
  ) => post<IPageData<IJoinAccount>>('/api/pcweb/finance/join/account/query', params),
  /**分页查询流水 */
  queryFlow: (params: {
    filter?: {
      end?: string; //截至日期
      occurType?: string; //发生类型
      start?: string; //起始日期
      tradeClass?: string; //交易类型
    };
  }) => post<IAmtTotalPageData<IJOinAccountFlow>>('/api/pcweb/finance/join/account/queryFlow', params),

  /**获取账户列表 */
  getAccountList: () => post<IJoinAccount['owner'][]>('/api/pcweb/finance/join/account/getAccountList'),

  /**获取账户信息 */
  getForStore: () => post<IJoinAccountAmount>('/api/pcweb/finance/join/account/getForStore')
};
