import {
  IReportStockByStore,
  IReportStockByStoreCount,
  IReportStockBySupplier,
  IReportStockBySupplierCount,
  IPurchaseSupplierQueryResult,
  IReceiverQueryResult,
  IReceiverQueryGoods,
  IResultDetail
} from '@/dataModel/finance/reportStock/interface';
import request from '@/utils/request';
const { post } = request;

export default {
  /**按供应商 */
  queryBySupplier: (
    params: {
      filter?: {
        department: string; //部门
        distType: string; //配送方式
        endDate: string; //结束日期
        receiveBillId: string; //收货单号
        refundBillId: string; //退货单号
        settleType: string; //经营方式
        sortId: string; //分类
        startDate: string; //开始日期
        stores: string[]; //门店
        suppliers: string[]; //供应商
        taxRate: string; //税率
      };
    } & IPageParams
  ) => post<IPageData<IReportStockBySupplier>>('/api/pcweb/finance/purchase/queryBySupplier', params),
  /**按供应商合计 */
  getBySupplierStat: (params: {
    filter?: {
      department: string; //部门
      distType: string; //配送方式
      endDate: string; //结束日期
      receiveBillId: string; //收货单号
      refundBillId: string; //退货单号
      settleType: string; //经营方式
      sortId: string; //分类
      startDate: string; //开始日期
      stores: string[]; //门店
      suppliers: string[]; //供应商
      taxRate: string; //税率
    };
  }) => post<IReportStockBySupplierCount>('/api/pcweb/finance/purchase/getBySupplierStat', params),
  /**按门店 */
  queryStore: (
    params: {
      filter?: {
        department: string; //部门
        distType: string; //配送方式
        endDate: string; //结束日期
        receiveBillId: string; //收货单号
        refundBillId: string; //退货单号
        settleType: string; //经营方式
        sortId: string; //分类
        startDate: string; //开始日期
        stores: string[]; //门店
        suppliers: string[]; //供应商
        taxRate: string; //税率
      };
    } & IPageParams
  ) => post<IPageData<IReportStockByStore>>('/api/pcweb/finance/purchase/queryStore', params),
  /**按门店合计 */
  getByStoreStat: (params: {
    filter?: {
      department: string; //部门
      distType: string; //配送方式
      endDate: string; //结束日期
      receiveBillId: string; //收货单号
      refundBillId: string; //退货单号
      settleType: string; //经营方式
      sortId: string; //分类
      startDate: string; //开始日期
      stores: string[]; //门店
      suppliers: string[]; //供应商
      taxRate: string; //税率
    };
  }) => post<IReportStockByStoreCount>('/api/pcweb/finance/purchase/getByStoreStat', params),
  /* 2.0接口 */
  /* 按供应商 */
  queryBySupplierNew: (
    param: {
      filter?: {
        /* 开始日期 */
        startDate?: string;
        /* 结束日期 */
        endDate?: string;
        /* 供应商标识集合 */
        supplierList?: Array<[]>;
        /* 税率 */
        taxRate?: number;
      };
    } & IPageParams
  ) => post<IPageData<IPurchaseSupplierQueryResult>>('/api/pcweb/finance/purchase/queryBySupplierNew', param),
  // 按供应商汇总
  getTotalBySupplier: (
    param: {
      filter: {
        /* 开始日期 */
        startDate?: string;
        /* 结束日期 */
        endDate?: string;
        /* 供应商标识集合 */
        supplierList?: Array<[]>;
        /* 税率 */
        taxRate?: number;
      };
    } & IPageParams
  ) => post<IPurchaseSupplierQueryResult>('/api/pcweb/finance/purchase/getTotalBySupplier', param),
  /* 按组织 */
  //
  queryByReceiver: (
    param: {
      filter?: {
        /* 开始日期 */
        startDate?: string;
        /* 结束日期 */
        endDate?: string;
        /* 仓库/门店标识集合 */
        reveivers?: Array<[]>;
        /* 税率 */
        taxRate?: number;
      };
    } & IPageParams
  ) => post<IPageData<IReceiverQueryResult>>('/api/pcweb/finance/purchase/queryByReceiver', param),
  /* 按组织汇总 */
  getTotalByReceiver: (
    param: {
      filter?: {
        /* 开始日期 */
        startDate?: string;
        /* 结束日期 */
        endDate?: string;
        /* 仓库/门店标识集合 */
        reveivers?: Array<[]>;
        /* 税率 */
        taxRate?: number;
      };
    } & IPageParams
  ) => post<IReceiverQueryResult>('/api/pcweb/finance/purchase/getTotalByReceiver', param),
  /* 按商品 */
  queryBySku: (
    param: {
      filter?: {
        /* 开始日期 */
        startDate?: string;
        /* 结束日期 */
        endDate?: string;
        /* 分类集合 */
        sortIdList?: Array<[]>;
        /* 税率 */
        taxRate?: number;
        goodsKeyword?: string;
      };
    } & IPageParams
  ) => post<IPageData<IReceiverQueryGoods>>('/api/pcweb/finance/purchase/queryBySku', param),
  // 按商品汇总
  getTotalBySku: (
    param: {
      filter?: {
        /* 开始日期 */
        startDate?: string;
        /* 结束日期 */
        endDate?: string;
        /* 分类集合 */
        sortIdList?: Array<[]>;
        /* 税率 */
        taxRate?: number;
        goodsKeyword?: string;
      };
    } & IPageParams
  ) => post<IReceiverQueryGoods>('/api/pcweb/finance/purchase/getTotalBySku', param),
  /* 详情 */
  queryByDetail: (
    param: {
      filter?: {
        /* 单号（模糊匹配） */
        billId?: string;
        billType?: string;
        businessType?: string;
        endDate?: string;
        goodsKeyword?: string;
        // 仓库/门店标识集合
        reveivers?: [];
        startDate?: string;
        // 供应商标识集合
        supplierList?: [];
        // 税率
        taxRate?: number;
      };
    } & IPageParams
  ) => post<IPageData<IResultDetail>>('/api/pcweb/finance/purchase/queryByDetail', param),
  // 详情汇总
  getTotalByDetail: (
    param: {
      filter?: {
        /* 单号（模糊匹配） */
        billId?: string;
        billType?: string;
        businessType?: string;
        endDate?: string;
        goodsKeyword?: string;
        // 仓库/门店标识集合
        reveivers?: [];
        startDate?: string;
        // 供应商标识集合
        supplierList?: [];
        // 税率
        taxRate?: number;
      };
    } & IPageParams
  ) => post<IResultDetail>('/api/pcweb/finance/purchase/getTotalByDetail', param)
};
