import distributiondiff from './business/distributiondiff';
import out from './business/out';
import priceAdjust from './business/priceAdjust';
import requireApi from './business/requireApi';
import returnOrder from './business/returnOrder';
import storeIn from './business/storeIn';
import storeMarket from './business/storeMarket';
import storeBack from './business/storeBack';
import directBack from './business/directBack';
import storeReturn from './business/storeReturn';
import storeTransfer from './business/storeTransfer';
import storeLoss from './business/storeLoss';
import storeDirectIn from './business/storeDirectIn';
import priceTag from './business/priceTag';
import ews from './equipment/ews';
import ewsTemplate from './equipment/ewsTemplate';
import pos from './equipment/pos';
import authCode from './equipment/authCode';
import distribute from './equipment/distribute';
import electronic from './equipment/electronic';
import brand from './mdata/brand';
import dict from './mdata/dict';
import goods from './mdata/goods';
import sort from './mdata/sort';
import spec from './mdata/spec';
import store from './mdata/store';
import bank from './mdata/bank';
import onlineGoods from './mdata/onlineGoods';
import onlineGroup from './mdata/onlineGroup';
import onlineRule from './mdata/onlineRule';
import onlineStore from './mdata/onlineStore';
import supplier from './mdata/supplier';
import templateRequire from './mdata/templateRequire';
import templatePriceTag from './mdata/templatePriceTag';
import supplierAgreement from './mdata/supplierAgreement';
import storeGain from './business/storeGain';
import returnNotify from './business/returnNotify';
import wrhLoss from './business/wrhLoss';
import wrhGain from './business/wrhGain';
import wrhTransfer from './business/wrhTransfer';
import directAllocBack from './business/directAllocBack';
import turnovermanageStoreincome from './business/turnovermanageStoreincome';
import turnoverManageManageAccountQueryStore from './business/turnoverManageAccountQueryStore';
import turnoverManageAccountQuery from './business/turnoverManageAccountQuery';
import wrhAllocOut from './business/allocOut';
import sellunionIn from './business/sellunionIn';
import sellunionBack from './business/sellunionBack';
import memberInfo from './member/memberIno';
import memberCard from './member/memberCard';
import prePayManagement from './member/prePayManagement';
import pickCardTypeManagement from './member/pickCardTypeManagement';
import prePayCardType from './member/prePayCardType';
import pickCardType from './member/pickCardType';
import PickCardUsageRecordList from './member/PickCardUsageRecordList';
import prePayUsageRecordList from './member/prePayUsageRecordList';
import settingUser from './system/settingUser';
import settingDataGroup from './system/settingDataGroup';
import settingRole from './system/settingRole';
import settingOrg from './system/settingOrg';
import warehouse from './mdata/warehouse';
import settingMenu from './system/settingMenu';
import reasonSetting from './mdata/reasonSetting';
import reason from './mdata/reason';
import customer from './mdata/customer';
import ticketTemplate from './retail/ticketTemplate';
import sale from './retail/sale';
import saleSelfPos from './retail/saleSelfPos';
import secscreen from './retail/secscreen';
import paymentMethod from './retail/paymentMethod';
import cashierDisCount from './retail/cashierDisCount';
import purchaseOrder from './purchase/order';
import purchaseBack from './purchase/back';
import purchaseIn from './purchase/in';
import purchaseQuery from './purchase/query';
import purchaseGoods from './purchase/goods';
import distSchema from './dist/schema';
import pool from './dist/pool';
import ruleMdata from './system/ruleMdata';
import ruleBill from './system/ruleBill';
import ruleBulk from './system/ruleBulk';
import rulePromotion from './system/rulePromotion';
import printing from './system/printing';
import ioIn from './system/ioIn';
import ioOut from './system/ioOut';
import business from './system/business';
import posSettings from './retail/posSettings';
import ticketStore from './retail/ticketStore';
import adjustPrice from './retail/adjustPrice';
import orderManagement from './retail/orderManagement';
import reformManagement from './retail/reformManagement';
import feeItem from './finance/feeItem';
import feeSupplier from './finance/feeSupplier';
import prePay from './finance/supplierPrePay';
import pay from './finance/pay';
import sellBuyBill from './finance/sellBuyBill';
import sellBuySettle from './finance/sellBuySettle';
import sellUnionSettle from './finance/sellunionSettle';
import backWrhAlloc from './business/backWrhAlloc';
import sellunionAgreement from './finance/sellunionAgreement';
import sellunionManage from './finance/sellunionManage';
import supplierManage from './finance/supplierManage';
import financeAdjustPrice from './finance/adjustPrice';
import joinPay from './finance/joinPay';
import joinDeduct from './finance/joinDeduct';
import checkTask from './inv/checkTask';
import query from './inv/query';
import checkIn from './inv/checkIn';
import recipe from './inv/recipe';
import storeProcess from './inv/storeProcess';
import use from './inv/use';
import dashboard from './dashboard/index';
import receipt from './finance/receipt';
import checkSupplier from './finance/checkSupplier';
import rule from './mdata/rule';
import billLog from './business/billLog';
import log from './system/log';
import wholesaleBack from './wholesale/back';
import wholesaleOut from './wholesale/out';
import warehouseOut from './wholesale/warehouse';
import storement from './wholesale/orderManagement';
import businessRecord from './finance/businessRecord';
import reportDailyStore from './finance/reportDailyStore';
import reportSummaryStore from './finance/reportSummaryStore';
import reportDaily from './finance/reportDaily';
import reportMonthly from './finance/reportMonthly';
import reportMonthStore from './finance/reportMonthStore';
import reportSummary from './finance/reportSummary';
import munit from './fale/munit';
import holderAuthCode from './equipment/holderAuthCode';
import barcodePrint from './retail/barcodePrint';
import checkCustomer from './finance/checkCustomer';
import wholesaleBill from './finance/wholesaleBill';
import wholesaleSettle from './finance/wholesaleSettle';
import holder from './equipment/holder';
import invImport from './system/invImport';
import munitSetting from './system/munit';
import sellBuyAccount from './finance/sellBuyAccount';
import joinAccount from './finance/joinAccount';
import orderMonitor from './purchase/orderMonitor';
import contract from './finance/contract';
import orderQueryReference from './purchase/orderQueryReference';
import supplierPurchaseOrder from './purchase/supplierPurchaseOrder';
import settingUserForm from './system/settingUserForm';
import customerAccount from './finance/customerAccount';
import jointGoodsInv from './inv/jointGoodsInv';
import jointGoodsSale from './retail/jointGoodsSale';
import keyboardSettings from './retail/keyboardSettings';
import memberStoredTrans from './member/memberStoredTrans/index';
import storeMemberStoredSummary from './member/storeMemberStoredSummary/index';
import memberStoredSummary from './member/memberStoredSummary/index';
import memberMoneyFlow from './member/memberMoneyFlow';
import cabinet from './mdata/cabinet';
import consumeSummary from './member/consumeSummary';
import consumeGoods from './member/consumeGoods';
import distributionQuery from './business/distributionQuery';
import guiderReport from './finance/guiderReport';
import memberPoint from './member/memberPoint';
import storeMemberRegister from './member/storeMemberRegister';
import headMemberRegister from './member/headMemberRegister';
import pointQueryGoods from './member/point';
import replenish from './business/replenish';
import dailySettlement from './finance/dailySettlement';
import appStore from './system/appStore';
import reportSale from './finance/reportSale';
import reportStock from './finance/reportStock';
import purchaseSaleProfit from './finance/purchaseSaleProfit';
import reportPrepayment from './finance/reportPrepayment';
import reportInventory from './finance/reportInventory';
import reportLoss from './finance/reportLoss';
import promotionStatistics from './finance/promotionStatistics';
import promotionStoreSales from './finance/promotionStoreSales';
import dept from './mdata/dept';
import wholesaleReport from './wholesale/report';
import lading from './purchase/lading';
import shelves from './mdata/storeShelves';
import distributionPlatformStore from './mdata/DistributionPlatformStore';
import lossReport from './wholesale/lossReport';
import storeLossReport from './business/storeLossReport';
import StoreQueryOrder from './business/storeQueryOrder';
import posLog from './system/posLog';
import wrhWholesaleBack from './wholesale/wrhWholesaleBack';
import dataCheck from './system/dataCheck/index';
import purchasePlan from './purchase/purchasePlan';
import settingReportConfig from './system/settingReportConfig';
import crossWarehouse from './purchase/crossWarehouse';
import promoteBill from './retail/promoteBill';
import wrhAlloc from './wholesale/wrhAlloc';
import wholesaleUse from './wholesale/use';
import WMS from './mdata/WMS';
import memberDepositChange from './member/memberDepositChange';
import priceCalcPlan from './retail/priceCalcPlan';
import onlineOrderComment from './retail/onlineOrderComment';
import queryStat from './retail/queryStat';
import omallPriceCustome from './mdata/omallPriceCustome';
import omallPriceLevel from './mdata/omallPriceLevel';
import wholesaleOrder from './wholesale/order';
import oMallHome from './oMall/home';
import posOrder from './retail/posOrder';
import config from './oMall/config';
import freight from './oMall/freight';
import oMallGoods from './oMall/goods';
import sku from './oMall/sku';
import oMallSpu from './oMall/spu';
import power from './power';
import customerRecharge from './finance/customerRecharge';
import distributionMargin from './business/distributionMargin';
import storeAllocationQuery from './business/storeAllocationQuery';
import wrhProcess from './business/wrhProcess';
import customerExamine from './business/customerExamine';
import makeUpDifference from './purchase/makeUpDifference';
import differenceTable from './finance/differenceTable';
import wrhAllot from './business/wrhAllot';
import settingBill from './system/settingBill';
import purchaseArriveRate from './purchase/purchaseArriveRate';
import cardPurchase from './finance/cardPurchase';
import giftCard from './finance/giftCard';
import customerManage from './finance/customerManage';
import allocationReport from './finance/allocationReport';
import supplierDifference from './finance/supplierDifference';
import buyCard from './finance/buyCard';
import storeRoute from './business/storeRoute';
import sortingEquipment from './business/sortingequipments';
import sortingWaves from './business/sortingwaves';
import pickOrder from './mdata/pickOrder';
import tareTemplate from './business/tareTemplate';
import pickOrderBoard from './mdata/pickOrderBoard';
import storePromoteSalesReport from './business/storePromoteSalesReport';
import newTicketTemplate from './finance/ticketTemplate';

const api = {
  /** 店务 */
  business: {
    /** 商品调价单 */
    priceAdjust,
    /** 订货 */
    requireApi,
    /** 收货入库 */
    out,
    /** 自采入库 */
    storeIn,
    /** 自采入库(市采) */
    storeMarket,
    /** 自采退货(市采) */
    storeBack,
    /** 直送退货 */
    directBack,
    /** 收货差异 */
    distributiondiff,
    /** 退货申请 */
    storeReturn,
    /** 门店退货 */
    returnOrder,
    /** 门店调拨 */
    storeTransfer,
    /** 门店报损 */
    storeLoss,
    /** 门店报溢 */
    storeGain,
    /** 直送收货 */
    storeDirectIn,
    /** 价签 */
    priceTag,
    /** 退货通知 */
    returnNotify,
    /** 仓库报损 */
    wrhLoss,
    /** 仓库报溢 */
    wrhGain,
    /** 仓库调拨 */

    wrhTransfer,
    /** 门店日记账 */
    turnovermanageStoreincome,
    turnoverManageManageAccountQueryStore,
    /** 配货单 */
    wrhAllocOut,
    /**总部采购-门店采购-门店退货 */
    directAllocBack,

    /**  对账查询 */
    turnoverManageAccountQuery,
    /**  门店退仓单 */
    backWrhAlloc,
    /** 单据操作日志 */
    billLog,
    /** 联营进货 */
    sellunionIn,
    /** 联营退货 */
    sellunionBack,
    /**门店查询 */
    distributionQuery,
    /** 门店补货方案 */
    replenish,
    /**门店报损报表 */
    storeLossReport,
    /**总部商品-门店订货查询 */
    StoreQueryOrder,
    /** 配货毛利查询 */
    distributionMargin,
    /** 门店调拨查询 */
    storeAllocationQuery,
    /**仓库加工 */
    wrhProcess,
    /**客户审核 */
    customerExamine,
    /** 仓库分拣api */
    wrhAllot,
    /** 门店线路 */
    storeRoute,
    /** 拣货设备 */
    sortingEquipment,
    /** 拣货波次 */
    sortingWaves,
    /** 皮重模板 */
    tareTemplate,
    /** 促销价查询报表 */
    storePromoteSalesReport
  },
  /** 会员 */
  member: {
    /** 会员资料 */
    memberInfo,
    /** 会员卡 */
    memberCard,
    /** 礼品卡管理 */
    prePayManagement,
    /** 提货卡管理 */
    pickCardTypeManagement,
    /** 礼品卡类型 */
    prePayCardType,
    /** 提货卡类型 */
    pickCardType,
    /** 提货卡流水 */
    PickCardUsageRecordList,
    /** 礼品卡类型 */
    prePayUsageRecordList,
    /**储值交易查询 */
    memberStoredTrans,
    /**门店储值汇总 */
    storeMemberStoredSummary,
    /**总部储值汇总*/
    memberStoredSummary,
    /** 储值资金流向查询 */
    memberMoneyFlow,
    /**消费汇总 */
    consumeSummary,
    /**会员热销品 */
    consumeGoods,
    /**积分兑换流水 */
    memberPoint,
    /** 门店会员注册汇总 */
    storeMemberRegister,
    /** 总部会员注册,发卡汇总 */
    headMemberRegister,
    /** 积分兑换商品汇总 */
    pointQueryGoods,
    /**储值金额变动 */
    memberDepositChange
  },
  /** 资料 */
  mdata: {
    /** 外卖平台管理 */
    distributionPlatformStore,
    /** 商品 */
    goods,
    /** 规格 */
    spec,
    /** 分类 */
    sort,
    /** 供应商 */
    supplier,
    /** 订货模版 */
    templateRequire,
    /** 价签模板 */
    templatePriceTag,
    /** 品牌资料 */
    brand,
    /** 字典 */
    dict,
    /** 门店 */
    store,
    /** 供应商采购协议 */
    supplierAgreement,
    /** 仓库资料 */
    warehouse,

    /** 银行账号资料 */
    bank,

    /** 线上商品库 */
    onlineGoods,

    /** 线上商品分组 */
    onlineGroup,

    /** 线上发布规则 */
    onlineRule,

    /** 网店管理 */
    onlineStore,
    /** 规则 */
    rule,
    /** 客户档案 */
    customer,
    /** 原因设置 */
    reason,
    /** 门店柜组 */
    cabinet,
    /**门店货架位 */
    shelves,
    /** 商品部门 */
    dept,
    /** wms 高级仓储服务 */
    WMS,
    /** 约定批发价 */
    omallPriceCustome,
    /**客户等级 */
    omallPriceLevel,
    /** 拣货单*/
    pickOrder,
    /** 拣货看板*/
    pickOrderBoard
  },
  equipment: {
    /** 电子秤管理 */
    ews,
    /** 电子秤模版 */
    ewsTemplate,
    /** 收银机管理 */
    pos,
    /** 授权码管理 */
    authCode,
    /** 资料下发 */
    distribute,
    /** 手持设备授权码 */
    holderAuthCode,
    /** 手持设备管理 */
    holder,
    /** 电子价签 */
    electronic
  },
  /** 财务 */
  finance: {
    /** 费用项目 */
    feeItem,
    /** 预付款 */
    prePay,
    /** 付款单 */
    pay,
    /** 经销出账 */
    sellBuyBill,
    /** 费用单 */
    feeSupplier,
    /**经销结算 */
    sellBuySettle,
    /**联营结算 */
    sellUnionSettle,
    /** 联营协议 */
    sellunionAgreement,
    /**联营对账查询 */
    sellunionManage,
    /**供应商台账 */
    supplierManage,
    /** 收款 */
    receipt,
    /**供应商单据核对 */
    checkSupplier,
    /**交易流水 */
    businessRecord,
    /**门店进销存日报 */
    reportDailyStore,
    /**门店进销存月报 */
    reportMonthStore,
    /**门店进销存汇总 */
    reportSummaryStore,
    /**总部进销存日报 */
    reportDaily,
    /** 批发单据复核 */
    checkCustomer,
    /** 批发出账 */
    wholesaleBill,
    /** 批发结算 */
    wholesaleSettle,
    /**总部进销存月报 */
    reportMonthly,
    /**总部进销存汇总 */
    reportSummary,
    /** 价格调整 */
    financeAdjustPrice,
    /** 经销对账查询汇总 */
    sellBuyAccount,
    /** 加盟店缴款 */
    joinPay,
    /** 加盟店扣款 */
    joinDeduct,
    /**加盟账户管理 */
    joinAccount,
    /** 客户对账查询 */
    customerAccount,
    /** 礼品卡对账查询 */
    cardPurchase,
    /** 礼品卡统计 */
    giftCard,
    /** 购卡对账 */
    buyCard,
    /**导购员业绩报表 */
    guiderReport,
    /** 供应商合约 */
    contract,
    /**日结表 */
    dailySettlement,
    /**销售报表 */
    reportSale,
    /**进货报表 */
    reportStock,
    /**进销毛利报表 */
    purchaseSaleProfit,
    /**预付款报表 */
    reportPrepayment,
    /**盘点盈亏 */
    reportInventory,
    /**调拨 */
    allocationReport,
    /**损耗报表 */
    reportLoss,
    /**付款审核 */
    customerRecharge,
    /**补差单 */
    differenceTable,
    /** 促销报表 */
    promotionStatistics,
    /* 客户台账 */
    customerManage,
    /**供应商补差 */
    supplierDifference,
    /** 门店促销 */
    promotionStoreSales,
    /** 新小票模板 */
    newTicketTemplate
  },
  /** 系统设置 */
  system: {
    /** 数据权限组 */
    settingDataGroup,
    /** 统管理员工 */
    settingOrg,
    /** 员工 */
    settingUser,
    /** 角色 */
    settingRole,
    /** 权限菜单 */
    settingMenu,
    /** 原因设置 */
    reasonSetting,
    /** 资料编码 */
    ruleMdata,
    /** 单据编码规则 */
    ruleBill,
    /** 秤码规则* */
    ruleBulk,
    /**促销码规则 */
    rulePromotion,
    /** 打印设置 */
    printing,
    /** 导入任务 */
    ioIn,
    /** 导出任务 */
    ioOut,
    /** 操作日志 */
    log,
    /** 业务设置 */
    business,
    /** 期初库存导入*/
    invImport,
    /**单位设置 */
    munitSetting,
    /** 用户表格设置 */
    settingUserForm,
    /**应用中心 */
    appStore,
    /**收银机监控 */
    posLog,
    /**数据检查 */
    dataCheck,
    /**表格配置 */
    settingReportConfig,
    /**系统所有单据信息的设置 */
    settingBill
  },
  /** 零售 */
  retail: {
    /** 小票模板 */
    ticketTemplate,
    /** 销售面板 */
    sale,
    /** 自助收银面板 */
    saleSelfPos,
    /** 副屏设置 */
    secscreen,
    /** 付款方式 */
    paymentMethod,
    /** 收银折扣规则 */
    cashierDisCount,
    /** 收银机参数配置 */
    posSettings,
    /** 小票设置 */
    ticketStore,
    /** 商品调价 */
    adjustPrice,
    /** 特殊条码打印 */
    barcodePrint,
    /** 线上订单 */
    orderManagement,
    /**** 退款单 */
    reformManagement,
    /** 供应商端商品销售查询 */
    jointGoodsSale,
    /** 键盘设置 */
    keyboardSettings,
    /** 促销单* */
    promoteBill,
    /** 价格方案* */
    priceCalcPlan,
    /** 评价管理 */
    onlineOrderComment,
    /**员工拣货查询 */
    queryStat,
    /**pos订单 */
    posOrder
  },
  /** 采购 */
  purchase: {
    // 采购商品查询
    goods: purchaseGoods,
    /** 采购订单 */
    order: purchaseOrder,
    /** 采购退货 */
    purchaseBack,
    /** 采购进货 */
    purchaseIn,
    /**采购查询 */
    purchaseQuery,
    /**供应商端采购订单 */
    supplierPurchaseOrder,
    /**采购订单监控 */
    orderMonitor,
    /** 采购订货参考 */
    orderQueryReference,
    /**采购提单 */
    lading,
    /**采购计划 */
    purchasePlan,
    /**越库 */
    crossWarehouse,
    /**补差协议 */
    makeUpDifference,
    /** 采购到货率报表 */
    purchaseArriveRate
  },
  /** 配送 */
  dist: {
    /** 配送方案 */
    schema: distSchema,
    /** 配货作业 */
    pool
  },
  /** 盘点 */
  inv: {
    /** 盘点任务 */
    checkTask,
    /** 库存查询 */
    query,
    /** 盘点结果 */
    checkIn,
    /** 加工模板 */
    recipe,
    /** 门店加工 */
    storeProcess,
    /** 门店领用 */
    use,
    /** 供应商端商品库存查询 */
    jointGoodsInv
  },
  /** 首页 */
  dashboard: {
    ...dashboard
  },
  /** 销售 */
  wholesale: {
    /**门店批发退货 */
    back: wholesaleBack,
    /**门店批发发货 */
    out: wholesaleOut,
    /**门店销售-线上订单 */
    orderManagement: storement,
    /**仓库报损 */
    lossReport,
    /** 总-批发销售查询 */
    wholesaleReport,
    /** 总-c批发仓库销售单查询 */
    wholesaleOut: warehouseOut,
    /** 总-c批发仓库销售退货*/
    wrhWholesaleBack: wrhWholesaleBack,
    /**配货订单 */
    wrhAlloc,
    /**批发订单 */
    wholesaleOrder,
    wholesaleUse
  },
  /** fale */
  fale: {
    /** 计量单位 */
    munit
  },
  /** 订货商城 */
  oMall: {
    /** 首页装修 */
    home: oMallHome,
    /** 小程序后台设置 */
    config,
    goods: oMallGoods,
    sku,
    /** Spu 商品 */
    spu: oMallSpu,
    freight
  },
  /** 海鼎权限 */
  power: {
    ...power
  }
};

export default api;
