import {
  IBusinessRecordByFlow,
  IBusinessRecordByGoods,
  IBusinessRecordByPayType
} from '@/dataModel/finance/businessRecord/interface';
import request from '@/utils/request';

export default {
  /**
   * 按商品查询
   * @param params
   * @returns
   */
  queryByGoods: (
    params: {
      filter?: {
        cashierId?: string;
        createEndTime?: string;
        createStartTime?: string;
        flowId?: string;
        goodsInfo?: string;
        payType?: string;
        storeId?: string;
        billClass?: string;
        posId?: string;
      };
    } & IPageParams
  ) => request.post<IPageData<IBusinessRecordByGoods>>('/api/pcweb/finance/businessrecord/queryByGoods', params),

  /**
   * 按付款方式查询分页数据
   * @param params
   * @returns
   */
  queryByPayType: (
    params: {
      filter?: {
        cashierId?: string;
        createEndTime?: string;
        createStartTime?: string;
        flowId?: string;
        payType?: string;
        storeId?: string;
        billClass?: string;
        posId?: string;
      };
    } & IPageParams
  ) => request.post<IPageData<IBusinessRecordByPayType>>('/api/pcweb/finance/businessrecord/queryByPayType', params),

  /**
   * 按流水查询分页数据
   * @param params
   * @returns
   */
  queryFlow: (
    params: {
      filter?: {
        cashierId?: string;
        createEndTime?: string;
        createStartTime?: string;
        payType?: string;
        storeId?: string;
        billClass?: string;
        posId?: string;
        flowId?: string;
      };
    } & IPageParams
  ) => request.post<IPageData<IBusinessRecordByFlow>>('/api/pcweb/finance/businessrecord/queryRecord', params),

  /**
   * 查询流水统计信息

   * @param params
   * @returns
   */
  queryRecordCount: (params: {
    filter?: {
      cashierId?: string;
      createEndTime?: string;
      createStartTime?: string;
      payType?: string;
      storeId?: string;
      flowId?: string;
      billClass?: string;
      posId?: string;
    };
  }) => request.post<{ countTotalAmount: number }>('/api/pcweb/finance/businessrecord/queryRecordCount', params),

  /**
   * 查询商品统计信息
   * @param params
   * @returns
   */
  queryGoodsCount: (params: {
    filter?: {
      cashierId?: string;
      createEndTime?: string;
      createStartTime?: string;
      flowId?: string;
      goodsInfo?: string;
      payType?: string;
      storeId?: string;
      billClass?: string;
      posId?: string;
    };
  }) =>
    request.post<{
      countFavourtTotal: number;
      countRealityTotal: number;
      countShouldTotal: number;
      countTotalAmount: number;
      countTotalQty: number;
    }>('/api/pcweb/finance/businessrecord/queryGoodsCount', params),

  /**
   * 查询付款方式统计信息
   * @param params
   * @returns
   */
  queryPayTypeCount: (params: {
    filter?: {
      cashierId?: string;
      createEndTime?: string;
      createStartTime?: string;
      flowId?: string;
      payType?: string;
      storeId?: string;
      billClass?: string;
      posId?: string;
    };
  }) =>
    request.post<{
      countFavourtTotal: number;
      countRealityTotal: number;
      countShouldTotal: number;
      countTotalAmount: number;
      countTotalQty: number;
    }>('/api/pcweb/finance/businessrecord/queryPayTypeCount', params),

  /**
   * 获取付款方式列表
   * @param params
   * @returns
   */
  queryPayTypeList: () =>
    request.post<
      {
        id: string;
        name: string;
      }[]
    >('/api/pcweb/finance/businessrecord/queryPayTypeList'),
  /**
   * 获取pos机号列表
   * @param params
   * @returns
   */
  queryPosIdList: () =>
    request.post<
      {
        id: string;
      }[]
    >('/api/pcweb/finance/businessrecord/queryPosIdList')
};
