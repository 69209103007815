/* 促销报表 */

import {
  IByGoodsCountResult,
  IByGoodsCountResultTotal,
  IPromoteSkuFavour,
  IPromoteSkuFavourQueryCountResult,
  IActivityInfo,
  IqueryGoodsParam,
  IPromoteSkuFavourQueryFilter
} from '@/dataModel/finance/promotionStatistics/interface';
import request from '@/utils/request';
const { post } = request;

export default {
  /* 获得促销类型 */
  // getActive:(
  //   param:{
  //     filter?:{

  //     }
  //   }
  // ) => post<>('/api/pcweb/promtion/activity/get',param)

  /* 促销-按商品 IPageData<IByGoodsCountResult> */
  querySku: (
    param: {
      filter?: IqueryGoodsParam;
    } & IPageParams
  ) => post<any>('/api/pcweb/promtion/report/querySku', param),

  /* 促销-按照商品汇总 */
  querySkuCount: (
    param: {
      filter?: IqueryGoodsParam;
    } & IPageParams
  ) => post<IByGoodsCountResultTotal>('/api/pcweb/promtion/report/querySkuCount', param),

  /* 促销--按优惠 */
  queryFavourQueryFilter: (
    param: {
      filter?: IPromoteSkuFavourQueryFilter;
    } & IPageParams
  ) => post<IPageData<IPromoteSkuFavour>>('/api/pcweb/promtion/report/query', param),

  /* 促销--按优惠汇总 */
  queryFavourQueryFilterCount: (
    param: {
      filter?: IPromoteSkuFavourQueryFilter;
    } & IPageParams
  ) => post<IPromoteSkuFavourQueryCountResult>('/api/pcweb/promtion/report/queryCount', param),

  /* 分页查询促销活动 */
  queryActive: (
    param: {
      filter?: {
        /* spu uuid */
        spu?: string;
      };
    } & IPageParams
  ) => post<IPageData<IActivityInfo>>('/api/pcweb/promtion/activity/query', param)
};
