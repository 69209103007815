import { LossReportDimensionEnum, WrhLossStatusEnum } from '@/dataModel/dict/enum';
import { ISort } from '@/dataModel/mdata/sort/interface';
import { ILossReport } from '@/dataModel/wholesale/lossReport/interface';
import request from '@/utils/request';
const { post } = request;
const lossReport = {
  /** 报损合计查询接口 */
  getCount: (params: {
    dimension: LossReportDimensionEnum;
    filter?: {
      billId?: string;
      endDate?: string;
      goodsInfo?: string;
      reason?: string;
      sortId?: ISort['id'];
      startDate?: string;
      status?: WrhLossStatusEnum;
      statusList?: WrhLossStatusEnum[];
      supplierInfo?: string;
      storeId?: string;
      storeIds?: string[];
    };
  }) =>
    post<{ lossCount: number; qty: number; total: number }>('/api/pcweb/business/store/loss/report/getCount', params),

  /**门店汇总分页查询 */
  queryByStore: (
    params: {
      filter?: {
        endDate?: string;
        startDate?: string;
        storeId?: string;
      };
    } & IPageParams
  ) => post<IPageData<ILossReport>>('/api/pcweb/business/store/loss/report/queryByStore', params),

  /**商品汇总分页查询 */
  queryByGoods: (
    params: {
      filter?: {
        endDate?: string;
        startDate?: string;
        goodsInfo?: string;
        sortId?: string;
        supplierInfo?: string;
      };
    } & IPageParams
  ) => post<IPageData<ILossReport>>('/api/pcweb/business/store/loss/report/queryByGoods', params),

  /**分类汇总分页查询 */
  queryBySort: (
    params: {
      filter?: {
        endDate?: string;
        startDate?: string;
        sortId?: string;
      };
    } & IPageParams
  ) => post<IPageData<ILossReport>>('/api/pcweb/business/store/loss/report/queryBySort', params),

  /**供应商汇总分页查询 */
  queryBySupplier: (
    params: {
      filter?: {
        endDate?: string;
        startDate?: string;
        supplierInfo?: string;
      };
    } & IPageParams
  ) => post<IPageData<ILossReport>>('/api/pcweb/business/store/loss/report/queryBySupplier', params),

  /**明细分页查询 */
  queryDetail: (
    params: {
      filter?: {
        endDate?: string;
        startDate?: string;
        goodsInfo?: string;
        storeId?: string;
        sortId?: string;
        billId?: string;
      };
    } & IPageParams
  ) => post<IPageData<ILossReport>>('/api/pcweb/business/store/loss/report/queryDetail', params)
};

export default lossReport;
