import { StoreStatusEnum, StoreTypeEnum } from '@/dataModel/dict/enum';
import { IStore } from '@/dataModel/mdata/store/interface';
import {
  BaseGetRequest,
  BaseRemoveRequest,
  BaseRemoveResult,
  BaseSaveResult,
  DistLine,
  DistLineQueryFilter,
  DistLineSaveRequest,
  DistLineStore
} from '@/dataModel/wrh/sorting/route/interface';
import request from '@/utils/request';
const { post } = request;

const storeRoute = {
  /**
   * api/pcweb/mdata/warehouse/picking/distline/get 获取门店线路信息
   * @param params
   * @returns
   */
  get: (params: BaseGetRequest) => post<DistLine>('/api/pcweb/mdata/warehouse/picking/distline/get', params),
  /**
   * /api/pcweb/mdata/warehouse/picking/distline/query 分页查询门店线路
   * @param params
   * @returns
   */
  query: (
    params: {
      filter: DistLineQueryFilter;
    } & IPageParams
  ) => post<IPageData<DistLine>>('/api/pcweb/mdata/warehouse/picking/distline/query', params),
  /**
   * /api/pcweb/mdata/warehouse/picking/distline/remove
删除门店线路
   * @param params
   * @returns
   */
  remove: (params: BaseRemoveRequest) =>
    post<BaseRemoveResult>('/api/pcweb/mdata/warehouse/picking/distline/remove', params),
  /**
     * /api/pcweb/mdata/warehouse/picking/distline/save
  保存门店线路
     * @param params
     * @returns
     */
  save: (params: DistLineSaveRequest) =>
    post<BaseSaveResult>('/api/pcweb/mdata/warehouse/picking/distline/save', params),
  /**
     * /api/pcweb/mdata/warehouse/picking/distline/getByName
根据名称获取门店线路信息
     * @param params
     * @returns
     */
  getByName: (params: { name: string }) =>
    post<DistLine>('/api/pcweb/mdata/warehouse/picking/distline/getByName', params),
  /**
  * /api/pcweb/mdata/warehouse/picking/distline/queryStore
分页查询门店信息
  * @param params
  * @returns
  */
  queryStore: (
    params: {
      filter?: {
        /** 配送方案 uuid */
        distSchema?: string;
        locationCity?: string;
        locationCounty?: string;
        locationProvince?: string;
        organization?: string;
        searchKey?: string;
        storeLevel?: string;
        storeType?: StoreTypeEnum;
        status?: StoreStatusEnum;
        /**是否包含开通线上渠道 */
        containSaleChannel?: boolean;
        /**过滤不包含的门店 */
        excludeWmStoreIds?: string[];
        storeModelList?: string[];
      };
    } & IPageParams
  ) =>
    request.post<
      IPageData<IStore> & {
        /** 可用门店数量 */
        licenseStoreCount: number;
      }
    >('/api/pcweb/mdata/warehouse/picking/distline/queryStore', params),
  /**
   * /api/pcweb/mdata/warehouse/picking/distline/queryDistStore 分页查询门店信息
   * @param params
   * @returns
   */
  queryDistStore: (
    params: {
      lineUuid: string;
    } & IPageParams
  ) => request.post<IPageData<IStore>>('/api/pcweb/mdata/warehouse/picking/distline/queryDistStore', params)
};
export default storeRoute;
