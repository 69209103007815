import request from '@/utils/request';
import {
  IReportMonthly,
  IReportMonthlyTotal,
  IReportMonthlyGeneralSort,
  IReportMonthlyMediumSort,
  IReportMonthlySubSort,
  IReportMonthlyGoods
} from '@/dataModel/finance/reportMonthly/interface';
const { post } = request;

export default {
  /**查询进销存月报合计 废弃 */
  /*   queryTotalByWrh: (
    params: {
      filter?: {
        goodsId?: string; //商品编码
        storeId?: string; //门店编码
        goodsBarcode?: string; //商品条码
        goodsName?: string; //商品名称
        sortId?: string; //商品分类
        settleNo?: number; //期号
        searchKey?: string; //模糊查询
      };
    } & IPageParams
  ) => post<IReportMonthlyTotal>('/api/pcweb/finance/report/month/wrh/queryTotalByStore', params), */
  /** 门店/仓库维度 */
  queryByWrh: (
    params: {
      filter?: {
        settleNo?: number; //期号
        storeId?: string; //仓库Id
      };
    } & IPageParams
  ) => post<IPageData<IReportMonthly>>('/api/pcweb/finance/report/month/wrh/queryByStore', params),
  /** 门店/仓库维度合计 */
  queryByWrhTotal: (
    params: {
      filter?: {
        settleNo?: number; //期号
        storeId?: string; //仓库Id
      };
    } & IPageParams
  ) => post<IReportMonthlyTotal>('/api/pcweb/finance/report/month/wrh/queryByStoreTotal', params),
  /** 门店/仓库维度合计 */
  /* queryByWrhTotal: (
    params: {
      filter?: {
        settleNo?: number; //期号
        storeId?: string; //仓库Id
      };
    } & IPageParams
  ) => post<IReportMonthlyTotal>('/api/pcweb/finance/report/month/wrh/queryByStoreTotal', params), */
  /**按商品分类大类查询 */
  queryByGeneralSort: (
    params: {
      filter?: {
        settleNo?: number; //期号
        storeId?: string; //门店编码
        sortId?: string; //商品分类
      };
    } & IPageParams
  ) => post<IPageData<IReportMonthlyGeneralSort>>('/api/pcweb/finance/report/month/wrh/queryByGeneralSort', params),
  /**按商品分类大类查询合计 */
  queryByGeneralSortTotal: (
    params: {
      filter?: {
        settleNo?: number; //期号
        storeId?: string; //门店编码
        sortId?: string; //商品分类
      };
    } & IPageParams
  ) => post<IReportMonthlyTotal>('/api/pcweb/finance/report/month/wrh/queryByGeneralSortTotal', params),
  /**按商品分类中类查询 */
  queryByMediumSort: (
    params: {
      filter?: {
        settleNo?: number; //期号
        storeId?: string; //门店编码
        sortId?: string; //商品分类
      };
    } & IPageParams
  ) => post<IPageData<IReportMonthlyMediumSort>>('/api/pcweb/finance/report/month/wrh/queryByMediumSort', params),
  /**按商品分类中类查询合计 */
  queryByMediumSortTotal: (
    params: {
      filter?: {
        settleNo?: number; //期号
        storeId?: string; //门店编码
        sortId?: string; //商品分类
      };
    } & IPageParams
  ) => post<IReportMonthlyTotal>('/api/pcweb/finance/report/month/wrh/queryByMediumSortTotal', params),
  /**按商品分类小类查询 */
  queryBySubSort: (
    params: {
      filter?: {
        settleNo?: number; //期号
        storeId?: string; //门店编码
        sortId?: string; //商品分类
      };
    } & IPageParams
  ) => post<IPageData<IReportMonthlySubSort>>('/api/pcweb/finance/report/month/wrh/queryBySubSort', params),
  /**按商品分类小类查询合计 */
  queryBySubSortTotal: (
    params: {
      filter?: {
        settleNo?: number; //期号
        storeId?: string; //门店编码
        sortId?: string; //商品分类
      };
    } & IPageParams
  ) => post<IReportMonthlyTotal>('/api/pcweb/finance/report/month/wrh/queryBySubSortTotal', params),
  /**按商品查询 */
  queryByGoodsId: (
    params: {
      filter?: {
        settleNo?: number; //期号
        goodsId?: string; //商品编码
        storeId?: string; //门店编码
        goodsBarcode?: string; //商品条码
        goodsName?: string; //商品名称
        sortId?: string; //商品分类
        searchKey?: string; //模糊查询
      };
    } & IPageParams
  ) => post<IPageData<IReportMonthlyGoods>>('/api/pcweb/finance/report/month/wrh/queryByGoodsId', params),
  /**按商品查询合计 */
  queryByGoodsIdTotal: (
    params: {
      filter?: {
        settleNo?: number; //期号
        goodsId?: string; //商品编码
        storeId?: string; //门店编码
        goodsBarcode?: string; //商品条码
        goodsName?: string; //商品名称
        sortId?: string; //商品分类
        searchKey?: string; //模糊查询
      };
    } & IPageParams
  ) => post<IReportMonthlyTotal>('/api/pcweb/finance/report/month/wrh/queryByGoodsIdTotal', params),

  /**
   *
   * 进销存2.0接口
   */
  /* 按照 仓库维度查询 */
  queryByStoreNew: (
    params: {
      filter?: {
        isStore?: boolean; // 是否为门店
        settleNo?: string; // 期号
        wrhIdList?: Array<[]>; // 门店或仓库编码
      };
    } & IPageParams
  ) => post<IPageData<IReportMonthly>>('/api/pcweb/finance/report/month/wrh/queryByStoreNew', params),
  /* 按照 仓库维度查询合计 */
  getTotalByStoreNew: (
    params: {
      filter?: {
        isStore?: boolean; // 是否为门店
        settleNo?: string; // 期号
        wrhIdList?: Array<[]>; // 门店或仓库编码
      };
    } & IPageParams
  ) => post<IReportMonthly>('/api/pcweb/finance/report/month/wrh/getTotalByStore', params),

  /* 按照分类查询 */
  queryBySort: (
    params: {
      filter?: {
        isStore?: boolean; // 是否为门店
        settleNo?: string; // 期号
        wrhIdList?: Array<[]>; // 门店或仓库编码
        sortIdList?: Array<[]>; // 类别集合
        sortType?: Array<[]>; // 类别维度
      };
    } & IPageParams
  ) => post<IPageData<IReportMonthly>>('/api/pcweb/finance/report/month/wrh/queryBySort', params),
  /* 按照分类查询合计 */
  getTotalBySort: (
    params: {
      filter?: {
        isStore?: boolean; // 是否为门店
        settleNo?: string; // 期号
        wrhIdList?: Array<[]>; // 门店或仓库编码
        sortIdList?: Array<[]>; // 类别集合
        sortType?: Array<[]>; // 类别维度
      };
    } & IPageParams
  ) => post<IReportMonthly>('/api/pcweb/finance/report/month/wrh/getTotalBySort', params),

  /* 按商品维度分页查询 */
  queryByGoods: (
    params: {
      filter?: {
        isStore?: boolean; // 是否为门店
        wrhIdList?: Array<[]>; // 门店或仓库编码
        sortIdList?: Array<[]>; // 类别集合
        sortType?: Array<[]>; // 类别维度
        searchKey?: string; // 商品编码/商品名称/商品条码
      };
    } & IPageParams
  ) => post<IPageData<IReportMonthly>>('/api/pcweb/finance/report/month/wrh/queryByGoods', params),
  /* 按商品合计 */
  getTotalByGoods: (
    params: {
      filter?: {
        isStore?: boolean; // 是否为门店
        wrhIdList?: Array<[]>; // 门店或仓库编码
        sortIdList?: Array<[]>; // 类别集合
        sortType?: Array<[]>; // 类别维度
        searchKey?: string; // 商品编码/商品名称/商品条码
      };
    } & IPageParams
  ) => post<IReportMonthly>('/api/pcweb/finance/report/month/wrh/getTotalByGoods', params)
};
