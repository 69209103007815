import request from '@/utils/request';
import {
  ISellBuyAccount,
  ICountByBill,
  ICountByDetail,
  ISellBuyQuery,
  ICountBySale
} from '@/dataModel/finance/sellBuyAccount/interface';
import { SellBuyQueryStatus } from '@/dataModel/finance/sellBuyAccount/enum';

export default {
  /** 列表查询 */
  queryBill: (params: { filter: ISellBuyQuery }, status: SellBuyQueryStatus) =>
    request.post<IPageData<ISellBuyAccount>>(`/api/pcweb/finance/sellbuy/account/${status}`, params),

  /** 统计 - 按单据 */
  getBillCount: (params: { filter: ISellBuyQuery }) =>
    request.post<ICountByBill>('/api/pcweb/finance/sellbuy/account/getBillCount', params),

  /** 统计 - 按明细 */
  getDetailCount: (params: { filter: ISellBuyQuery }) =>
    request.post<ICountByDetail>('/api/pcweb/finance/sellbuy/account/getDetailCount', params),

  /** 统计 - 按销售 */
  getSaleCount: (params: { filter: ISellBuyQuery }) =>
    request.post<ICountBySale>('/api/pcweb/finance/sellbuy/account/getSaleCount', params)
};
