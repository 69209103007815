import { LossAccountQueryResult } from '@/dataModel/finance/reportLoss/interface';
import request from '@/utils/request';
const { post } = request;
export interface IfilterParams {
  /* 单号 */
  billId?: string;
  /* 日期开始*/
  startDate: string;
  /* 日期结束 */
  endDate: string;
  /* 商品关键字 */
  keywords?: string;
  /* 组织uuid */
  orgs?: Array<[]>;
  /* 供应商uuid */
  suppliers?: Array<[]>;
  /* 损益类型 */
  type?: string;
  /* 报损/报溢原因 */
  reason?: string;
}
export interface IfilterDetail {
  /*单号  */
  billId?: string;
  /* 结束时间 */
  endDate: string;
  /* 商品关键字 */
  keywords?: string;
  /* 组织uuid */
  orgs?: Array<[]>;
  /* 报损/报溢原因 */
  reason?: string;
  /* 开始日期 */
  startDate: string;
  /* 供应商uuid */
  suppliers?: Array<[]>;
  /* 损益类型 */
  type?: string;
}

export default {
  query: (
    params: {
      filter?: {
        endDate: string; //结束日期
        sortId: string[]; //分类
        startDate: string; //开始日期
        storeId: string[]; //门店
      };
    } & IPageParams
  ) => post<IPageData<LossAccountQueryResult>>('/api/pcweb/finance/loss/account/query', params),
  queryCount: (params: {
    filter?: {
      endDate: string; //结束日期
      sortId: string[]; //分类
      startDate: string; //开始日期
      storeId: string[]; //门店
    };
  }) => post<any>('/api/pcweb/finance/loss/account/getCount', params),
  /* 损溢汇总2.0 */
  queryAll: (params: { filter?: IfilterParams & IPageParams }) =>
    post<IPageData<LossAccountQueryResult>>('/api/pcweb/report/sy/query', params),
  /* 损益汇总合计.0 */
  queryAllSum: (params: { filter?: IfilterParams & IPageParams }) =>
    post<LossAccountQueryResult>('/api/pcweb/report/sy/querySum', params),
  /* 损益明细2.0 */
  queryDetail: (params: { filter?: IfilterDetail & IPageParams }) =>
    post<IPageData<LossAccountQueryResult>>('/api/pcweb/report/sy/detail', params),
  /* 损益明细合计2.0 */
  queryDetailSum: (params: { filter: IfilterDetail & IPageParams }) =>
    post<LossAccountQueryResult>('/api/pcweb/report/sy/detailSum', params)
};
