import request from '@/utils/request';
import { CheckInStatusEnum, CheckTaskBillClassEnum, CheckTaskSourceClassEnum } from '@/dataModel/dict/enum';
import { IInvCheckIn, IInvCheckInGoods } from '@/dataModel/inv/checkIn/interface';
const { post } = request;

const checkIn = {
  /**
   * 分页查询
   */
  query: (
    params: {
      filter?: {
        billClass?: CheckTaskBillClassEnum;
        endDate?: string;
        idLike?: string;
        sourceClass?: CheckTaskSourceClassEnum;
        startDate?: string;
        status?: CheckInStatusEnum;
        taskIdLike?: string;
        wrh?: string;
        goodsInfo?: string;
      };
    } & IPageParams
  ) => post<IPageData<IInvCheckIn>>('/api/pcweb/inv/check/in/query', params),
  /**
   * 分页查询明细
   */
  queryDetails: (
    params: {
      bill: string;
      filter?: {
        isGain?: boolean;
        needRecheck?: boolean;
        keyword?: string;
        sortId?: string;
        status?: string;
        isChecked?: boolean; //是否已审核
        maxDiffQty?: number; //最大盈亏数量
        maxDiffTotal?: number; //最大盈亏金额
        minDiffQty?: number; //最小盈亏数量
        minDiffTotal?: number; //最小盈亏金额
        sortIds?: string[];
      };
      orderBy?: {
        direction: string;
        field: string;
      };
    } & IPageParams
  ) => post<IPageData<IInvCheckInGoods>>('/api/pcweb/inv/check/in/queryDetails', params),
  /**
   * 获取单头
   */
  get: (params: { uuid: string }) => post<IInvCheckIn>('/api/pcweb/inv/check/in/get', params),
  /**
   * 提交盘入结果
   */
  submit: (params: {
    isAll?: boolean; //是否全部提交（如果部分提交，必须提交行明细）
    detailUuids?: string[]; //部分提交，明细行标识列表
    uuid?: string;
    version?: number;
    //items: Array<{ status: string; uuid: string }>
  }) => post<{ taskUuid: string }>('/api/pcweb/inv/check/in/submit', params),

  /**
   * 提交盘入结果
   */
  getSubTask: (params: {
    uuid?: string;
    //items: Array<{ status: string; uuid: string }>
  }) => post<{ status: { id: string; name: string } }>('/api/pcweb/inv/check/in/getSubTask', params),
  /**
   * 提交盘入结果优化--保存行操作
   *
   */
  saveLines: (params: { bill: string; status: string; lines: string[] }) =>
    post('/api/pcweb/inv/check/in/saveLines', params),
  /**
   * 保存备注
   * @param params
   * @returns
   */
  saveRemark: (params: { detailUuid: string; remark: string; version: number }) =>
    post('/api/pcweb/inv/check/in/saveRemark', params)
};

export default checkIn;
