import request from '@/utils/request';
const { post } = request;
import {
  ISupplierFilter,
  ISupplierAccount,
  ISupplierCount,
  ISupplierFilterDetail,
  ISupplierAccountDetail,
  ISupplierCountDetail,
  ISupplierSbSum,
  IFilterParam
} from '@/dataModel/finance/supplierManage/interface';

export default {
  /** 按单据 分页查询 */
  queryBill: (params: { filter: ISupplierFilter }) =>
    request.post<IPageData<ISupplierAccount>>(`/api/pcweb/finance/standingbook/cashier/query`, params),

  /** 按单据 统计 */
  getBillCount: (params: { filter: ISupplierFilter }) =>
    request.post<ISupplierCount>('/api/pcweb/finance/standingbook/cashier/queryTotal', params),

  /** 按明细 分页查询 */
  queryDetail: (params: { filter: ISupplierFilterDetail }) =>
    request.post<IPageData<ISupplierAccountDetail>>(`/api/pcweb/finance/standingbook/detail/query`, params),

  /** 按明细 统计 */
  getDetailCount: (params: { filter: ISupplierFilterDetail }) =>
    request.post<ISupplierCountDetail>('/api/pcweb/finance/standingbook/detail/queryTotal', params),

  /* 供应商台账2.0 */

  /* 分页查询汇总 */
  getAllQuery: (
    param: {
      filter?: IFilterParam;
    } & IPageParams
  ) => post<IPageData<ISupplierSbSum>>('/api/pcweb/finance/standingbook/supplier/query', param),
  /* 分页查询供应商台账汇总合计 */
  getAllQuerySum: (
    param: {
      filter?: IFilterParam;
    } & IPageParams
  ) => post<ISupplierSbSum>('/api/pcweb/finance/standingbook/supplier/querySum', param),

  /* 分页查询供应商台账应付明细 */
  getShouldPay: (
    param: {
      filter?: IFilterParam;
    } & IPageParams
  ) => post<IPageData<ISupplierSbSum>>('/api/pcweb/finance/standingbook/supplier/shouldPay', param),
  /* 分页查询供应商台账应付明细合计 */
  getShouldPaySum: (
    param: {
      filter?: IFilterParam;
    } & IPageParams
  ) => post<ISupplierSbSum>('/api/pcweb/finance/standingbook/supplier/shouldPaySum', param),

  /* 分页查询供应商台账预付明细  */
  getPrepay: (
    params: {
      filter?: IFilterParam;
    } & IPageParams
  ) => post<IPageData<ISupplierSbSum>>('/api/pcweb/finance/standingbook/supplier/prepay', params),
  /* 分页查询供应商台账预付明细合计 */
  getPrepaySum: (
    params: {
      filter?: IFilterParam;
    } & IPageParams
  ) => post<ISupplierSbSum>('/api/pcweb/finance/standingbook/supplier/prepaySum', params),

  /* 分页查询供应商台账费用明细 */
  getFee: (
    parmas: {
      filter?: IFilterParam;
    } & IPageParams
  ) => post<IPageData<ISupplierSbSum>>('/api/pcweb/finance/standingbook/supplier/fee', parmas),
  /* 分页查询供应商台账费用明细合计 */
  getFeeSum: (
    parmas: {
      filter?: IFilterParam;
    } & IPageParams
  ) => post<ISupplierSbSum>('/api/pcweb/finance/standingbook/supplier/feeSum', parmas)
};
