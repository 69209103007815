import request from '@/utils/request';
import { IConsumeGoods } from '@/dataModel/member/consumeGoods/interface';
const { post } = request;

const consumeGoods = {
  /**总部-会员热销品 */
  queryHeadMemberConsumeGoods: (
    params: {
      filter?: {
        beginDate?: string; //开始时间
        endDate?: string; //结束时间
        storeSearchKey?: string; //门店
      };
    } & IPageParams
  ) => post<IPageData<IConsumeGoods>>('/api/pcweb/member/info/queryHeadMemberConsumeGoods', params),
  /**总部-会员热销品统计 */
  queryHeadMemberConsumeGoodsTotal: (params: {
    filter?: {
      beginDate?: string; //开始时间
      endDate?: string; //结束时间
      storeSearchKey?: string; //门店
    };
  }) => post<any>('/api/pcweb/member/info/queryHeadMemberConsumeGoodsTotal', params),
  /**门店-会员热销品 */
  queryStoreMemberConsumeGoods: (
    params: {
      filter?: {
        beginDate?: string; //开始时间
        endDate?: string; //结束时间
      };
    } & IPageParams
  ) => post<IPageData<IConsumeGoods>>('/api/pcweb/member/info/queryStoreMemberConsumeGoods', params),
  /**门店-会员热销品统计 */
  queryStoreMemberConsumeGoodsTotal: (params: {
    filter?: {
      beginDate?: string; //开始时间
      endDate?: string; //结束时间
    };
  }) => post<any>('/api/pcweb/member/info/queryStoreMemberConsumeGoodsTotal', params)
};

export default consumeGoods;
