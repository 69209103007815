import {
  IBusinessStoreDirectIn,
  IBusinessStoreDirectInDetail,
  IBusinessStoreDirectInGoods,
  IBusinessStoreDirectInSupplier
} from '@/dataModel/business/storeDirectIn/interface';
import request from '@/utils/request';
const { post } = request;

const storeDirectIn = {
  /** 获取单头信息 */
  get: (params: { id: string; uuid: string }) =>
    post<IBusinessStoreDirectIn>('/api/pcweb/business/store/direct/in/get', params),
  /** 获取商品列表编辑 */
  getGoods: (params: { bill: string }) =>
    post<IBusinessStoreDirectInGoods[]>('/api/pcweb/business/store/direct/in/getGoods', params),
  /**获取直送供应商列表 */
  getSupplierList: (params?: { keyWord: string }) =>
    post<IBusinessStoreDirectInSupplier[]>('/api/pcweb/business/store/direct/in/getSupplierList', params),
  /** 分页查询 */
  query: (
    params: {
      creatorId?: string;
      startDate?: string;
      endDate?: string;
      idLike?: string;
      supplierKey?: string;
      goodsInfo?: string;
    } & IPageParams
  ) => post<IPageData<IBusinessStoreDirectIn>>('/api/pcweb/business/store/direct/in/query', params),
  queryDetail: (
    params: {
      bill: string;
      filter?: {
        keyword?: string;
      };
    } & IPageParams
  ) => post<IPageData<IBusinessStoreDirectInDetail>>('/api/pcweb/business/store/direct/in/queryDetail', params),
  queryGoods: (
    params: {
      filter?: {
        keyWord?: string;
        sortId?: string;
      };
      supplier: string;
    } & IPageParams
  ) => post<IPageData<IBusinessStoreDirectInGoods>>('/api/pcweb/business/store/direct/in/queryGoods', params),
  receive: (params: { bill: string; version: number }) =>
    post<{ traceId: string; version: number }>('/api/pcweb/business/store/direct/in/receive', params),
  /** 删除 */
  remove: (params: { uuid: string }) => post<{ traceId: string }>('/api/pcweb/business/store/direct/in/remove', params),
  /** 保存（创建、保存、保存并提交） */
  save: (params: {
    bill?: string;
    nuid?: string;
    info: {
      occurDate: string;
      remark?: string;
      supplier: string;
      details: { goodsMunitUuid: string; qty: number; productDate: string; inPrice?: number }[];
    };
    targetStatus: string;
    version?: number;
  }) => post<{ traceId: string; uuid: string; version: number }>('/api/pcweb/business/store/direct/in/save', params),
  /** 提交入库 */
  submit: (params: { bill: string; version: number }) =>
    post<{ traceId: string; version: number }>('/api/pcweb/business/store/direct/in/submit', params),
  /**获取导入任务明细 */
  getGoodsByIoTask: (params: { taskUuid: string }) =>
    post<IBusinessStoreDirectInGoods[]>('/api/pcweb/business/store/direct/in/getGoodsByIoTask', params)
};

export default storeDirectIn;
