import {
  IWrhAllocOut,
  IWrhAllocOutGoods,
  IWrhAllocOutInv,
  IWrhAllocOrderSubmitVo
} from '@/dataModel/business/wrhAllocOut/interface';
import { PrintStatusEnum, WrhOutStatusEnum } from '@/dataModel/dict/enum';
import request from '@/utils/request';

export default {
  /** 配货单列表 - 分页 */
  query: (
    params: {
      filter: Partial<{
        batchId: string;
        billId: string;
        endDate: string;
        settleNo: number;
        startDate: string;
        /** 单据状态 */
        status: WrhOutStatusEnum;
        storeSearchKey: string;
        wrhSearchKey: string;
        goodsInfo?: string;
        /**打印状态 */
        printStatus?: PrintStatusEnum;
      }>;
    } & IPageParams
  ) => request.post<IPageData<IWrhAllocOut>>('/api/pcweb/business/wrh/alloc/out/query', params),
  /** 查商品 */
  queryGoods: (
    params: {
      filter: Partial<{ sortId: string; searchKey: string }>;
      receiverId: string;
      wrhId: string;
    } & IPageParams
  ) => request.post<IPageData<IWrhAllocOutGoods>>('/api/pcweb/business/wrh/alloc/out/queryGoods', params),
  /** 查批次号 */
  queryBusinessInventory: (params: { goods: string; wrhId: string }) =>
    request.post<IWrhAllocOutInv[]>('/api/pcweb/business/wrh/alloc/out/queryBusinessInventory', params),
  /** 获取单头 */
  get: (billId: string) => request.post<IWrhAllocOut>('/api/pcweb/business/wrh/alloc/out/get', { billId }),
  /** 商品明细 */
  details: (params: { billId: string; filter?: { searchKey?: string } } & IPageParams) =>
    request.post<IPageData<IWrhAllocOutGoods>>('/api/pcweb/business/wrh/alloc/out/details', params),
  /** 新增 */
  create: (params: any) => request.post('/api/pcweb/business/wrh/alloc/out/create', params),
  /** 提交 */
  submit: (params: any) => request.post('/api/pcweb/business/wrh/alloc/out/submit', params),
  /** 修改 */
  modify: (params: any) => request.post('/api/pcweb/business/wrh/alloc/out/modify', params),
  /** 删除 */
  remove: (params: { billId: string }) => request.post('/api/pcweb/business/wrh/alloc/out/remove', params),
  /** 发货 */
  sended: (params: {
    items: {
      billId: string;
      version: number;
    }[];
  }) => request.post<any>('/api/pcweb/business/wrh/alloc/out/sended', params),
  /** 废除 */
  abolish: (params: { billId: string; version: number }) =>
    request.post('/api/pcweb/business/wrh/alloc/out/abolish', params),

  /** 保存发货 */
  sendedSave: (params: { items: { qty: number; uuid: string }[] }) =>
    request.post<{ version: number }>('/api/pcweb/business/wrh/alloc/out/sendedSave', params),
  /**获取导入任务明细 */
  getGoodsByIoTask: (params: { taskUuid: string }) =>
    request.post<IWrhAllocOutGoods[]>('/api/pcweb/business/wrh/alloc/out/getGoodsByIoTask', params),
  /* 批量提交 */
  submitAll: (params: { items: { bill: string; version: string }[] }) =>
    request.post<IWrhAllocOrderSubmitVo>('/api/pcweb/business/wrh/alloc/out/batchSubmit', params),
  /**配货单设置打印时间 */
  setPrintStatus: (params: {
    /**仓配单编码列表 */
    ids: string[];
    /**打印状态 */
    printStatus: PrintStatusEnum;
  }) => request.post('/api/pcweb/business/wrh/alloc/out/setPrintStatus', params)
};
