import {
  ISaveParams,
  IBckWrhAlloc,
  IBckWrhAllocDetail,
  IBckWrhAllocGoods
} from '@/dataModel/business/backWrhAlloc/interface';
import { ISelectedGoods } from '@/pages/HeadquartersBackend/Wrh/Alloc/Add/interface';
import request from '@/utils/request';
const { post } = request;

const backWrhAlloc = {
  /**
   *  获取单头信息
   */
  get: (params: {
    /**
     *  单据Id
     */
    id?: string;
    /**
     *  标识
     */
    uuid?: string;
  }) => post<IBckWrhAlloc>('/api/pcweb/business/back/wrh/alloc/get', params),
  /**
   *  获取明细列表
   */
  getDetails: (params: {
    /**
     *  标识
     */
    bill: string;
  }) => post<IBckWrhAllocDetail[]>('/api/pcweb/business/back/wrh/alloc/getDetails', params),
  /**
   *  分页查询单据
   */
  query: (
    params: {
      /**
       *  筛选
       */
      filter?: {
        /**
         *  单据编码类似于
         */
        idLike?: string;
        /**
         *  状态列表
         */
        statusList?: string[];
        /**
         *  门店关键词
         */
        storeKeyword?: string;
      };
    } & IPageParams
  ) => post<IPageData<IBckWrhAlloc>>('/api/pcweb/business/back/wrh/alloc/query', params),
  /**
   *  分页查询明细
   */
  queryDetail: (
    params: {
      /**
       *  标识
       */
      bill?: string;
      /**
       *  筛选
       */
      filter?: {
        /**
         *  查询关键词
         */
        keyword: string;
      };
    } & IPageParams
  ) => post<IPageData<IBckWrhAllocDetail>>('/api/pcweb/business/back/wrh/alloc/queryDetail', params),
  /**
   *  分页查询商品
   */
  queryGoods: (
    params: {
      /**
       *  筛选
       */
      filter?: {
        /**
         *  查询关键词
         */
        goodsKey?: string;
        /**
         *  商品分类
         */
        goodsType?: string;
        /**
         *  分类id
         */
        sortId?: string;
      };
      /**
       *  目标门店id
       */
      targetStoreId: string;
      /**
       *  收货仓库
       */
      targetWhrUuid: string;
    } & IPageParams
  ) => post<IPageData<IBckWrhAllocGoods>>('/api/pcweb/business/back/wrh/alloc/queryGoods', params),
  /**
   *  删除
   */
  remove: (params: {
    /**
     *  删除对象唯一标识
     */
    uuid?: string;
  }) => post('/api/pcweb/business/back/wrh/alloc/remove', params),
  /**
   *  删除
   */
  save: (params: ISaveParams) => post('/api/pcweb/business/back/wrh/alloc/save', params),
  /**获取导入任务明细 */
  getGoodsByIoTask: (params: { taskUuid: string }) =>
    post<ISelectedGoods[]>('/api/pcweb/business/back/wrh/alloc/getGoodsByIoTask', params)
};
export default backWrhAlloc;
