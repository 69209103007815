import { RuleGoodsLogTypeEnum } from '@/dataModel/dict/enum';
import {
  ISellunionGoodsMunitVo,
  ISupplierSellunionAgreement,
  ISupplierSellunionAgreementDetail,
  ISupplierSellunionAgreementRuleGoods,
  ISupplierSellunionAgreementSaveRequest,
  ISupplierSellunionSettleDayInfoVo,
  ISupplierSellunionAgreementInfo,
  ISupplierSellunionAgreementRule,
  ISupplierSellunionAgreementSpecial,
  IStoreAddList,
  IHistoryRate,
  INormalSpecialRate
} from '@/dataModel/finance/sellunionAgreement/interface';
import { IStore } from '@/dataModel/mdata/store/interface';
import { ISupplier } from '@/dataModel/mdata/supplier/interface';
import request from '@/utils/request';

/**
 * 出账周期及出账期查询接口
 */
export interface SupplierSellunionSettleDayInfoGetRequest {
  /**
   * 协议生效结束日期
   * @format date-time
   * @example 2020-10-01
   */
  endDate?: string;

  /**
   * 租户ID
   * @example TENANT1234
   */
  pid?: string;

  /**
   * 出账日
   * @format int32
   */
  settleDay: number;

  /** 结算模式(month：自然月，custom：自定义) */
  settleMode: string;

  /**
   * 统计周期（月）
   * @format int32
   */
  settleMonthInterval: number;

  /**
   * 协议生效开始日期
   * @format date-time
   * @example 2012-12-12
   */
  startDate?: string;

  /**
   * 门店ID
   * @example 99998888
   */
  storeId?: string;

  /**
   * 用户ID
   * @example USER5678
   */
  wid?: string;
}

export default {
  /** ====================== 接口目录错误 ↓ ====================== */
  /**保存列表商品 */
  addRuleGoods: (params: { goodsList: { goodsId: string; rate: number }[]; ruleUuid: string }) =>
    request.post('/api/pcweb/mdata/supplier/sellunion/agreement/addRuleGoods', params),
  queryAgreement: (
    params: {
      filter?: {
        goodsKeyword?: string;
        idLike?: string;
        storeKeyword?: string;
        supplierKeyword?: string;
      };
    } & IPageParams
  ) =>
    request.post<IPageData<ISupplierSellunionAgreement>>(
      '/api/pcweb/mdata/supplier/sellunion/agreement/queryAgreement',
      params
    ),
  /**供应商列表 */
  getSupplierList: () => request.post<ISupplier[]>('/api/pcweb/mdata/supplier/sellunion/agreement/getSupplierList'),
  /**新增或修改协议信息 */
  saveAgreement: (params: {
    contractor: string;
    contractorMobile: string;
    files: { id: string; name: string; size: string; url: string }[];
    id: string;
    ourContractor: string;
    outerBillId: string;
    supplierId: string;
  }) =>
    request.post<{ id: string; traceId: string; uuid: string; version: number }>(
      '/api/pcweb/mdata/supplier/sellunion/agreement/saveAgreement',
      params
    ),
  /**分页查询商品（新增界面） */
  queryGoodsForAdd: (
    params: {
      filter?: {
        goodsType?: string;
        keyword?: string;
        sortId?: string;
      };
      agreementId: string;
      ruleUuid?: string;
    } & IPageParams
  ) =>
    request.post<IPageData<ISupplierSellunionAgreementRuleGoods>>(
      '/api/pcweb/mdata/supplier/sellunion/agreement/queryGoodsForAdd',
      params
    ),
  /**筛选门店 */
  queryStoreForAdd: (
    params: {
      filter?: {
        keyword: string;
      };
      ruleUuid?: string;
      agreementId: string;
    } & IPageParams
  ) => request.post<IPageData<IStore>>('/api/pcweb/mdata/supplier/sellunion/agreement/queryStoreForAdd', params),
  /**根据导入任务标识获取商品 */
  getGoodsByIoTask: (params: { taskUuid: string }) =>
    request.post<ISupplierSellunionAgreementRuleGoods[]>(
      '/api/pcweb/mdata/supplier/sellunion/agreement/getGoodsByIoTask',
      params
    ),
  /**新建规则 */
  createRule: (params: {
    agreementId: string;
    goodsList: {
      goodsId: string;
      rate: number;
    }[];
    name: string;
    specials: { end: string; rate: string; start: string }[];
    stage: {
      maxList: number[];
      rateList: number[];
    };
    storeIds: string[];
  }) => request.post('/api/pcweb/mdata/supplier/sellunion/agreement/createRule', params),
  /**删除协议 */
  removeAgreement: (params: { id: string }) =>
    request.post('/api/pcweb/mdata/supplier/sellunion/agreement/removeAgreement', params),
  /**修改门店列表 */
  modifyStores: (params: { ruleUuid: string; storeIds: string[] }) =>
    request.post('/api/pcweb/mdata/supplier/sellunion/agreement/modifyStores', params),
  /**删除规则 */
  removeRule: (params: { uuid: string }) =>
    request.post('/api/pcweb/mdata/supplier/sellunion/agreement/removeRule', params),
  /** 获取协议头信息 */
  getAgreement: (params: { id: string }) =>
    request.post<ISupplierSellunionAgreementInfo>('/api/pcweb/mdata/supplier/sellunion/agreement/getAgreement', params),
  /**获取规则列表 */
  getRuleList: (params: { id: string }) =>
    request.post<ISupplierSellunionAgreementRule[]>(
      '/api/pcweb/mdata/supplier/sellunion/agreement/getRuleList',
      params
    ),
  /**获取门店列表 */
  getStoreList: (params: { ruleUuid: string }) =>
    request.post<IStore[]>('/api/pcweb/mdata/supplier/sellunion/agreement/getStoreList', params),
  /**分页查询商品 */
  queryRuleGoods: (
    params: {
      filter?: {
        goodsIds?: string[];
        keyword?: string;
      };
      ruleUuid: string;
    } & IPageParams
  ) =>
    request.post<IPageData<ISupplierSellunionAgreementRuleGoods>>(
      '/api/pcweb/mdata/supplier/sellunion/agreement/queryRuleGoods',
      params
    ),
  /**保存列表商品 */
  saveRuleGoods: (params: { goodsList: { goodsId: string; rate: number }[]; ruleUuid: string }) =>
    request.post('/api/pcweb/mdata/supplier/sellunion/agreement/saveRuleGoods', params),
  /**批量修改商品扣率 */
  modifyRateBatch: (params: { goodsIds: string[]; ruleUuid: string; rate: number }) =>
    request.post('/api/pcweb/mdata/supplier/sellunion/agreement/modifyRateBatch', params),
  /**批量删除商品 */
  removeGoodsBatch: (params: { goodsIds: string[]; ruleUuid: string }) =>
    request.post('/api/pcweb/mdata/supplier/sellunion/agreement/removeGoodsBatch', params),
  /**删除商品 */
  removeGoods: (params: { goodsId: string; ruleUuid: string }) =>
    request.post('/api/pcweb/mdata/supplier/sellunion/agreement/removeGoods', params),
  /**修改商品扣率 */
  modifyGoodsRate: (params: { goodsId: string; rate: number; ruleUuid: string }) =>
    request.post('/api/pcweb/mdata/supplier/sellunion/agreement/modifyGoodsRate', params),
  /**获取阶梯扣率 */
  getStageList: (params: { ruleUuid: string }) =>
    request.post('/api/pcweb/mdata/supplier/sellunion/agreement/getStageList', params),
  /**保存阶梯扣率 */
  saveStage: (params: {
    ruleUuid: string;
    stage: {
      maxList: number[];
      rateList: number[];
    };
  }) => request.post('/api/pcweb/mdata/supplier/sellunion/agreement/saveStage', params),
  /**删除阶梯扣率 */
  removeStage: (params: { ruleUuid: string }) =>
    request.post('/api/pcweb/mdata/supplier/sellunion/agreement/removeStage', params),

  /**分页查询特殊扣率 */
  querySpecial: (params: { ruleUuid: string } & IPageParams) =>
    request.post<IPageData<ISupplierSellunionAgreementSpecial>>(
      '/api/pcweb/mdata/supplier/sellunion/agreement/querySpecial',
      params
    ),
  /**保存特殊扣率（新增或编辑）*/
  saveSpecial: (params: {
    ruleUuid: string;
    specialUuid?: string;
    special: {
      end: string;
      start: string;
      rate: number;
    };
  }) => request.post('/api/pcweb/mdata/supplier/sellunion/agreement/saveSpecial', params),

  /**批量设置特殊扣率*/
  batchSetSpecialRate: (params: {
    /**规则标识*/
    bill: string;
    /**扣率*/
    deductionRate: number;
    /**商品uuid*/
    goodsUuid: string[];
    /**生效开始日期*/
    startDate: string;
    /**生效结束日期*/
    endDate: string;
  }) => request.post('/api/pcweb/mdata/supplier/sellunion/ruleGoods/specialBatchSet', params),

  /**编辑常规和特殊扣率*/
  edidAllRate: (params: {
    /**规则标识*/
    bill: string;
    /**商品uuid*/
    goods: string;
    /**常规扣率*/
    deductionRate: number;
    /**特殊扣率列表*/
    specials: {
      /**特殊扣率 */
      deductionRate: number;
      /**生效开始日期*/
      startDate: string;
      /**生效结束日期*/
      endDate: string;
    }[];
  }) => request.post('/api/pcweb/mdata/supplier/sellunion/ruleGoods/rateSet', params),

  /**查询历史的更多扣率*/
  queryHistoryRate: (
    params: {
      filter: {
        /**规则标识*/
        bill: string;
        /**商品uuid*/
        goods: string;
        /**生效开始日期*/
        startDate?: string;
        /**生效结束日期*/
        endDate?: string;
        /**扣率类型*/
        type: RuleGoodsLogTypeEnum;
      };
    } & IPageParams
  ) => request.post<IPageData<IHistoryRate>>('/api/pcweb/mdata/supplier/sellunion/ruleGoods/queryHistory', params),

  /**编辑扣率时查询当前的常规和特殊扣率*/
  queryNormalSpecialRate: (params: {
    /**规则标识*/
    bill: string;
    /**商品uuid*/
    goods: string;
  }) => request.post<INormalSpecialRate>('/api/pcweb/mdata/supplier/sellunion/ruleGoods/get', params),

  /**删除特殊扣率 */
  removeSpecial: (params: { uuid: string }) =>
    request.post('/api/pcweb/mdata/supplier/sellunion/agreement/removeSpecial', params),
  /**修改规则名称 */
  modifyRuleName: (params: { ruleUuid: string; name: string; version: number }) =>
    request.post('/api/pcweb/mdata/supplier/sellunion/agreement/modifyRuleName', params),
  /** ====================== 接口目录错误 ↑====================== */

  /** 添加商品至联营协议 */
  addGoods: (params: {
    /** 供应商标识 */
    supplier: string;
    details: {
      /** 扣率 */
      deductionRate: number;
      /** 商品Id */
      goodsId: string;
      /** 备注 */
      remark?: string;
    }[];
  }) => request.post<any>('/api/pcweb/finance/sellunion/agreement/addGoods', params),

  /** 终止 */
  terminate: (uuid: string) => request.post('/api/pcweb/finance/sellunion/agreement/terminate', { uuid }),

  /** 详情 */
  getDetail: (params: {
    filter?: {
      /** 商品条码/编码/名称 */
      goodsInfo?: string;
    };
    uuid?: string;
    supplier?: string;
  }) => request.post<ISupplierSellunionAgreementDetail>('/api/pcweb/finance/sellunion/agreement/getDetail', params),

  /** 保存 */
  save: (params: ISupplierSellunionAgreementSaveRequest) =>
    request.post('/api/pcweb/finance/sellunion/agreement/save', params),
  queryGoods: (params: { filter?: { goodsInfo?: string; sortId?: string } } & IPageParams) =>
    request.post<IPageData<ISellunionGoodsMunitVo>>('/api/pcweb/finance/sellunion/agreement/queryGoods', params),
  /** 账期 */
  getSettleDayList: (params: SupplierSellunionSettleDayInfoGetRequest) =>
    request.post<ISupplierSellunionSettleDayInfoVo[]>(
      '/api/pcweb/finance/sellunion/agreement/getSettleDayList',
      params
    ),

  /** 设置是否开启柜组 */
  modifyCabinetStatus: (params: { agreementId: string; isCabinet: boolean; version: number }) =>
    request.post<{ traceId: string; version: number }>(
      '/api/pcweb/mdata/supplier/sellunion/agreement/modifyCabinetStatus',
      params
    ),

  /** 获取门店列表，用于添加门店规则 */
  getStoreListForAdd: (params: { agreementId: string }) =>
    request.post<IStoreAddList[]>('/api/pcweb/mdata/supplier/sellunion/agreement/getStoreListForAdd', params),

  /** 添加门店规则 */
  addStoreRule: (params: { agreementId: string; ruleStoreId: string }) =>
    request.post<{ traceId: string; uuid: string }>(
      '/api/pcweb/mdata/supplier/sellunion/agreement/addStoreRule',
      params
    ),
  /**获取门店类型列表 */
  getStoreSortList: () =>
    request.post<
      {
        createInfo: IOperatorInfo;
        lastModifyInfo: IOperatorInfo;
        name: string;
        remark: string;
        tenantId: string;
        uuid: string;
        version: number;
      }[]
    >('/api/pcweb/mdata/store/type/getAll')
};
