import request from '@/utils/request';
import { IStoreOrderReference } from '@/dataModel/purchase/query/interface';
const { post } = request;

const purchaseQuery = {
  storeOrder: (
    params: {
      filter: {
        /**商品，编码，条码 */
        searchKey?: string;
        /**分类 */
        sortId?: string;
        /**门店 */
        store?: string;
        /**供应商 */
        supplier?: string;
        /**
         * 开始时间
         */
        createStartTime: string;
        /**
         * 截止时间
         */
        createEndTime: string;
      };
    } & IPageParams
  ) => post<IPageData<IStoreOrderReference>>('/api/pcweb/purchase/query/storeOrder', params)
};

export default purchaseQuery;
