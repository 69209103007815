import { ISettingMenu } from '@/dataModel/system/settingMenu/interface';
import request from '@/utils/request';

const { post } = request;

const settingMenu = {
  /**
   * 查询角色对应权限
   */
  queryPermissionTable: () =>
    post<{
      hqList: ISettingMenu[];
      posList: ISettingMenu[];
      storeList: ISettingMenu[];
      witPosList: ISettingMenu[];
      assistantList: ISettingMenu[];
    }>('/api/pcweb/system/setting/menu/queryPermissionTable')
};

export default settingMenu;
