import { BankAccountBusinessTypeEnum } from '@/dataModel/dict/enum';
import { IMdataBankAccount } from '@/dataModel/mdata/bank/interface';
import request from '@/utils/request';
const { post } = request;

const bank = {
  /**
   * 创建银行账户
   */
  create: (params: { bankAccountNo: string; bankBranch: string; businessType: string; name: string }) =>
    post<{ traceId: string; uuid: string }>('/api/pcweb/mdata/bank/account/create', params),

  /**
   * 查询银行账户列表
   */

  query: (
    params: {
      filter?: {
        businessType?: BankAccountBusinessTypeEnum | '';
        keyword?: string;
      };
    } & IPageParams
  ) => post<IPageData<IMdataBankAccount>>('/api/pcweb/mdata/bank/account/query', params),

  /**
   * 银行账号查看详情
   */
  get: (params: { uuid: string }) => post<IMdataBankAccount>('/api/pcweb/mdata/bank/account/get', params),

  /**
   * 修改
   */
  modify: (params: {
    bankAccountNo: string;
    bankBranch: string;
    businessType: string;
    name: string;
    uuid: string;
    version: number;
  }) => post<{ traceId: string; version: number }>('/api/pcweb/mdata/bank/account/modify', params),

  /**
   * 删除
   */
  remove: (params: { uuid: string }) => post<{ traceId: string }>('/api/pcweb/mdata/bank/account/remove', params)
};

export default bank;
