import {
  DrptPromoteSku,
  DrptPromoteSkuFavour,
  DrptPromoteSkuFavourQueryCountResult,
  DrptPromoteSkuFavourQueryFilter,
  DrptPromoteSkuQueryCountResult,
  PromoteCount,
  PromoteQueryFilter,
  PromoteSku,
  PromoteStore
} from '@/dataModel/business/storePromoteSalesReport/interface';
import request from '@/utils/request';
const { post } = request;

const storePromoteSalesReport = {
  /**
 * /api/pcweb/promtion/report/query
促销统计-促销优惠明细
 */
  query: (
    params: {
      filter: DrptPromoteSkuFavourQueryFilter;
    } & IPageParams
  ) => post<IPageData<DrptPromoteSkuFavour>>('/api/pcweb/promtion/report/query', params),
  /**
   * /api/pcweb/promtion/report/queryCount
促销统计-促销优惠明细(汇总)
   */
  queryCount: (params: { filter: DrptPromoteSkuFavourQueryFilter }) =>
    post<DrptPromoteSkuFavourQueryCountResult>('/api/pcweb/promtion/report/queryCount', params),
  /**
     * /api/pcweb/promtion/report/queryPromoteSku
促销价查询-按商品
     */
  queryPromoteSku: (params: { filter: PromoteQueryFilter } & IPageParams) =>
    post<IPageData<PromoteSku>>('/api/pcweb/promtion/report/queryPromoteSku', params),
  /**
 * 
 * @param params /api/pcweb/promtion/report/queryPromoteSkuCount
促销价查询-按商品(汇总)
 * @returns 
 */
  queryPromoteSkuCount: (params: { filter: PromoteQueryFilter }) =>
    post<PromoteCount>('/api/pcweb/promtion/report/queryPromoteSkuCount', params),
  /**
 * 
 * @param params /api/pcweb/promtion/report/queryPromoteStore
促销价查询-按门店
 * @returns 
 */
  queryPromoteStore: (params: { filter: PromoteQueryFilter } & IPageParams) =>
    post<IPageData<PromoteStore>>('/api/pcweb/promtion/report/queryPromoteStore', params),
  /**
 * 
 * @param params /api/pcweb/promtion/report/queryPromoteStoreCount
促销价查询-按门店(汇总)
 * @returns 
 */
  queryPromoteStoreCount: (params: { filter: PromoteQueryFilter }) =>
    post<PromoteCount>('/api/pcweb/promtion/report/queryPromoteStoreCount', params),
  /**
 * /api/pcweb/promtion/report/querySku
促销统计-按商品
 */
  querySku: (params: { filter: DrptPromoteSkuFavourQueryFilter } & IPageParams) =>
    post<IPageData<DrptPromoteSku>>('/api/pcweb/promtion/report/querySku', params),
  /**
 * 
/api/pcweb/promtion/report/querySkuCount
促销统计-按商品(汇总)
 */
  querySkuCount: (params: { filter: DrptPromoteSkuFavourQueryFilter }) =>
    post<DrptPromoteSkuQueryCountResult>('/api/pcweb/promtion/report/querySkuCount', params)
};
export default storePromoteSalesReport;
