import {
  IOnlineOrderQueryFilter,
  IOnlineOrder,
  IOnlineOrderDetail,
  IOnlineCount,
  IOrderFee,
  IOnlineStoreLines,
  ITicketGetResultTicketGetResultNum,
  ITicketGetResultTicketGetMpodal
} from '@/dataModel/retail/orderManagement/interface';
import request from '@/utils/request';

export default {
  /** 分页查询 */
  query: (params: { filter: Partial<IOnlineOrderQueryFilter> }) =>
    request.post<IPageData<IOnlineOrder>>('/api/pcweb/onlineorder/query', params, {}, 'onlineOrder'),
  // /** 详情 - 商品信息 */
  getDetails: (params: { uuid: string }) => request.post<IOnlineOrderDetail>('/api/pcweb/onlineorder/get', params, {}, 'onlineOrder'),
  /** 查询订单分类数量*/
  /** 分页查询 */
  getCount: (params: { filter: Partial<IOnlineOrderQueryFilter> }) =>
    request.post<IOnlineCount>('/api/pcweb/onlineorder/getCount', params, {}, 'onlineOrder'),

  /** 第三方配送  取消配送 */
  cancel: (params: { uuid: string }) => request.post('/api/pcweb/onlineorder/myt/delivery/cancel', params, {}, 'onlineOrder'),

  /** 第三方配送   转自配送 */
  deliverySelf: (params: { uuid: string }) => request.post('/api/pcweb/onlineorder/myt/delivery/self', params, {}, 'onlineOrder'),

  /** 第三方配送   配送下单 */
  deliveryOrder: (params: {
    /** 配送方，多个用,号分割(必填) */
    logistics: string;
    /** 标识 */
    uuid: string;
    /** 备注 */
    remark?: string;
    /** 小费 */
    tip?: string;
    /** 发单时间 */
    expectReceiveTime?: number;
  }) => request.post('/api/pcweb/onlineorder/myt/order/send', params, {}, 'onlineOrder'),

  /** 第三方配送  加小费 */
  orderTipAdd: (params: {
    /** 小费 */
    tipAmount: string | number;
    /** 标识 */
    uuid: string;
  }) => request.post('/api/pcweb/onlineorder/myt/orderTip/add', params, {}, 'onlineOrder'),

  /** 第三方配送  获取运费列表 */
  orderFee: (params: { uuid: string }) => request.post<IOrderFee>('/api/pcweb/onlineorder/myt/order/fee', params, {}, 'onlineOrder'),
  /** 获取订单行明细，拣货用 /api/pcweb/onlineorder/lines/get/forprepare */
  getForPrepare: (params: { uuid: string }) =>
    request.post<IOnlineStoreLines[]>('/api/pcweb/onlineorder/lines/get/forprepare', params, {}, 'onlineOrder'),
  /* 获得小票类型 */
  getTicket: (params: { businessType: string; order: string; occurDay?: string }) =>
    request.post<ITicketGetResultTicketGetResultNum>('/api/pcweb/business/ticket/get', params, {}, 'onlineOrder')
};
