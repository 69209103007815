import {
  ISellUnionSettle,
  ISellUnionGoods,
  ISellUnionInvoice,
  ISellUnionSettleBatchResult,
  ISellunionSettleFee,
  ISellunionStore,
  ISellunionSupplier
} from '@/dataModel/finance/sellunionSettle/interface';
import request from '@/utils/request';
const { post } = request;
import { SupplierSellUnionSettleStatusEnum } from '@/dataModel/dict/enum';
import { IFeeSupplier } from '@/dataModel/finance/feeSupplier/interface';

const sellUnionSettle = {
  /** 添加费用单明细 */
  addFeeDetail: (params: { bill?: string; /** 费用单标识 */ fees: string[] }) =>
    post<{ traceId: string }>('/api/pcweb/finance/sellunion/settle/addFeeDetail', params),
  /** 创建生成结算任务 */
  create: (params: {
    endDate: string;
    status: SupplierSellUnionSettleStatusEnum;
    storeIdList: string[];
    supplierIdList: string[];
  }) => post<{ traceId: string; uuid: string }>('/api/pcweb/finance/sellunion/settle/create', params),
  /**
   * 分页查询联营结算表单
   */
  queryBill: (
    params: {
      filter?: {
        createdBegin?: string;
        createdEnd?: string;
        goodsKeyWord?: string;
        id?: string;
        occurDateBegin?: string;
        occurDateEnd?: string;
        status?: string;
        storeKeyWord?: string;
        statusList: SupplierSellUnionSettleStatusEnum[];
        supplierId: string;
        supplierKeyWord?: string;
      };
    } & IPageParams
  ) => post<IPageData<ISellUnionSettle>>('/api/pcweb/finance/sellunion/settle/queryBill', params),
  /**
   * 生成联营结算单
   */
  createSettle: (params: { supplier: string; deadLine: string }) =>
    post('/api/pcweb/finance/sellunion/settle/createSettle', params),
  /**
   * 获取单据
   */
  get: (params: { uuid: string }) => post<ISellUnionSettle>('/api/pcweb/finance/sellunion/settle/get', params),

  /** 获取批次任务信息 */
  getBatch: (params: { uuid: string }) =>
    post<ISellUnionSettleBatchResult>('/api/pcweb/finance/sellunion/settle/getBatch', params),
  /**
   * 获取明细列表
   */
  getDetails: (params: { uuid: string }) =>
    post<ISellUnionGoods[]>('/api/pcweb/finance/sellunion/settle/getDetails', params),
  /**
   * 提交/审核/作废-列表页
   */
  submit: (params: { uuid: string; version: number; targetStatus: SupplierSellUnionSettleStatusEnum }) =>
    post<any>('/api/pcweb/finance/sellunion/settle/submit', params),
  /**
   * 获取发票列表
   */
  getInvoices: (params: { bill: string }) =>
    post<ISellUnionInvoice[]>('/api/pcweb/finance/sellunion/settle/getInvoices', params),

  /** 获取未结算费用单列表 */
  getNoFeeList: (params: { uuid: string } & IPageParams) =>
    post<IPageData<IFeeSupplier>>('/api/pcweb/finance/sellunion/settle/getNoFeeList', params),
  /**
   * 删除发票
   */
  removeInvoice: (params: { uuid: string }) => post('/api/pcweb/finance/sellunion/settle/removeInvoice', params),
  /**
   * 登记发票
   */
  createInvoice: (params: {
    bill: string;
    invoiceCode: string;
    invoiceNo: string;
    invoiceType: string;
    makeDate: string;
    taxRate: number;
    total: number;
  }) => post('/api/pcweb/finance/sellunion/settle/createInvoice', params),
  /**
   * 删除结算单
   */
  removeBill: (params: { uuid: string }) => post('/api/pcweb/finance/sellunion/settle/removeBill', params),

  /** 删除费用单明细 */
  removeFeeDetail: (params: { uuid: string }) =>
    post<{ traceId: string }>('/api/pcweb/finance/sellunion/settle/removeFeeDetail', params),

  /**
   * 分页查询明细列表
   */
  queryDetail: (params: { filter: { uuid: string } } & IPageParams) =>
    post<IPageData<ISellUnionGoods>>('/api/pcweb/finance/sellunion/settle/queryDetail', params),

  /** 分页查询费用明细 */
  queryFeeDetail: (params: { /** 单据标识 */ bill: string } & IPageParams) =>
    post<IPageData<ISellunionSettleFee>>('/api/pcweb/finance/sellunion/settle/queryFeeDetail', params),

  /** 查询费用单-用于添加费用单 */
  queryFeeAdd: (params: { bill: string } & IPageParams) =>
    post<IPageData<IFeeSupplier>>('/api/pcweb/finance/sellunion/settle/queryFeeForAdd', params),

  /** 分页查询门店 */
  queryStore: (
    params: {
      filter?: {
        keyword?: string;
      };
      /** 供应商编码列表 */
      supplierIds: string[];
    } & IPageParams
  ) => post<IPageData<ISellunionStore>>('/api/pcweb/finance/sellunion/settle/queryStore', params),

  /** 分页查询供应商 */
  querySupplier: (
    params: {
      filter?: {
        keyword?: string;
      };
      /** 门店码列表 */
      storeIds: string[];
    } & IPageParams
  ) => post<IPageData<ISellunionSupplier>>('/api/pcweb/finance/sellunion/settle/querySupplier', params),
  /** 供应商确认 */
  supplierConfirm: (params: { uuid: string }) =>
    post<any>('/api/pcweb/finance/sellunion/settle/supplierConfirm', params)
};
export default sellUnionSettle;
