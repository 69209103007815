import { DistTypeEnum } from '@/dataModel/dict/enum';
import { IDistSchema, IDistSchemaDetal, IDistSchemaGoods } from '@/dataModel/dist/schema/interface';
import { getDict } from '@/utils/dict';
import request from '@/utils/request';

type DistSchemaDetail = Pick<IDistSchemaDetal, 'distType' | 'goodsId' | 'priceType' | 'requireMinQty' | 'storeReturn'> &
  Partial<Pick<IDistSchemaDetal, 'distFx' | 'distPrice'>> & {
    munitUuid: string;
    senderUuid: string;
  };

export default {
  /** 方案分页 */
  query: (
    params?: {
      filter?: {
        /** 生效门店 ID */
        fromStoreId?: string;
      };
    } & IPageParams
  ) => request.post<IPageData<IDistSchema>>('/api/pcweb/dist/schema/query', params),
  /** 复制新增 */
  copy: (params: { name: string; uuid: string }) => request.post('/api/pcweb/dist/schema/copy', params),
  /** 删除 */
  remove: (uuid: string) => request.post('/api/pcweb/dist/schema/remove', { uuid }),
  /** 新增 */
  create: (params: { name: string; nuid: string }) => request.post('/api/pcweb/dist/schema/create', params),
  /** 配送方案 - 详情 */
  get: (uuid: string) => request.post<IDistSchema>('/api/pcweb/dist/schema/get', { uuid }),
  /** 方案下的商品信息 分页 */
  queryDetail: async (
    params: {
      uuid: string;
      orderBy?: { direction?: string; field?: string };
      filter?: {
        searchKey?: string;
        goodsUuids?: string[];
        distType?: string;
        supplierId?: string;
      };
    } & IPageParams
  ) => {
    const { data } = await request.post<IPageData<IDistSchemaDetal>>('/api/pcweb/dist/schema/queryDetail', params);

    const formuals = getDict().fxFormulaEnum;
    data.items.forEach(item => {
      if (item.distFx) {
        formuals.forEach(formula => {
          item.distFx = item.distFx.replace(formula.id, formula.name);
        });
      }
    });

    return { data };
  },
  /** 批量删商品 */
  removeDetails: (params: {
    /** 方案 uuid */
    schema: string;
    /** 商品 uuid */
    uuids: string[];
    version: number;
  }) =>
    request.post<{ traceId: string; uuid: string; version: number }>('/api/pcweb/dist/schema/removeDetails', params),
  /** 商品档案批量删商品 */
  removeDetailsForMultiSchema: (params: {
    /** details 详情 */
    details: string[];
  }) => request.post<{ traceId: string }>('/api/pcweb/dist/schema/removeDetailsForMultiSchema', params),
  /** 修改商品明细 */
  modifyDetail: (params: { detail: DistSchemaDetail; version: number; uuid: string }) => {
    if (params.detail.distFx) {
      const formuals = getDict().fxFormulaEnum;

      formuals.forEach(formula => {
        params.detail.distFx = params.detail.distFx.replace(formula.name, formula.id);
      });
    }
    return request.post('/api/pcweb/dist/schema/modifyDetail', params);
  },
  /** 修改 */
  modify: (params: { name: string; uuid: string; version: number }) =>
    request.post('/api/pcweb/dist/schema/modify', params),
  /** 修改 */
  getReferenceInfo: (params: { skuUuid: string; supplierUuid: string }) =>
    request.post('/api/pcweb/dist/schema/getReferenceInfo', params),
  /** 分页查商品 */
  queryGoods: (
    params: {
      uuid: string;
      orderBy?: { direction?: string; field?: string };
      filter?: {
        uuids?: string[];
        /** search key */
        searchKey?: string;
        /** 商品分类 */
        sortId?: string;
        /** 是否在方案中已存在 */
        exist?: boolean;
      };
    } & IPageParams
  ) => request.post<IPageData<IDistSchemaGoods>>('/api/pcweb/dist/schema/queryGoods', params),
  /** 添加&编辑 保存商品明细 */
  saveDetails: (params: { uuid: string; version: number; details: any }) => {
    params.details.forEach(item => {
      if (item.distFx) {
        const formuals = getDict().fxFormulaEnum;

        formuals.forEach(formula => {
          item.distFx = item.distFx.replace(formula.name, formula.id);
        });
      }
    });
    return request.post('/api/pcweb/dist/schema/saveDetails', params);
  },
  /** 商品档案添加&编辑 保存商品明细 */
  saveDetailsForMultiSchema: (params: { details: any }) => {
    params.details.forEach(item => {
      if (item.distFx) {
        const formuals = getDict().fxFormulaEnum;

        formuals.forEach(formula => {
          item.distFx = item.distFx.replace(formula.name, formula.id);
        });
      }
    });
    return request.post('/api/pcweb/dist/schema/saveDetailsForMultiSchema', params);
  },
  /** 添加门店 */
  addStores: (params: { schemaUuid: string; storeUuids: string[] }) =>
    request.post('/api/pcweb/dist/schema/addStores', params),

  /** 获取三价 */
  getPrices: (params: { uuid: string; sender: string; goods: string; goodsQpc: string }) =>
    request.post<any>('/api/pcweb/dist/schema/getPrices', params),

  /** 获取商品的参考价格 */
  getReferencePrices: (params: {
    distType: DistTypeEnum;
    sender: string;
    goodsId: string;
    goodsQpc: number;
    goodsMunitUuid: string;
  }) => request.post<any>('/api/pcweb/dist/schema/getReferencePrices', params),

  /** 获取公式的配货价 */
  getDistPrice: (params: { uuid: string }) => request.post<any>('/api/pcweb/dist/schema/getDistPrice', params)
};
