import request from '@/utils/request';
import {
  QueryCrossWarehouseListRes,
  CrossWarehouseDetailReq,
  QueryCrossWarehouseDetailListReq,
  QueryStoreListReq
} from '@/dataModel/purchase/crossWarehouse/interface';
interface SumResult {
  traceId: string;
  uuid: string;
  version: number;
}
const { post } = request;
const crossWarehouse = {
  /** 越库作业列表*/
  queryCrossWarehouseList: (
    params: {
      filter: {
        batchNo?: string;
        goodsSearchKey?: string;
        id?: string;
        statusList?: string[];
        endDate?: string; //制单结束日期
        startDate?: string; //制单开始日期
        purchaseOrderId?: string; //采购订单单号
        purchaser?: string; //采购员标识
        supplier?: string; //供应商标识
        wrh?: string; //仓库标识
      };
    } & IPageParams
  ) => post<IPageData<QueryCrossWarehouseListRes>>('/api/pcweb/purchase/crossdocking/query', params),

  /** 查询越库作业单头*/
  crossWarehouseDetail: (params: { id: string; uuid: string }) =>
    post<CrossWarehouseDetailReq>('/api/pcweb/purchase/crossdocking/get', params),

  /** 越库作业单据商品明细-详情*/
  queryCrossWarehouseDetailList: (
    params: {
      bill: string;
      filter: {
        goodsSearchKey: string;
      };
    } & IPageParams
  ) => post<IPageData<QueryCrossWarehouseDetailListReq>>('/api/pcweb/purchase/crossdocking/queryDetail', params),

  /**  越库作业商品门店订购明细*/
  queryStoreList: (params: { detailUuid: string; orderId: string }) =>
    post<QueryStoreListReq[]>('/api/pcweb/purchase/crossdocking/getStore', params),

  /** 越库作业单据商品明细-新增编辑*/
  getDetailForEdit: (params: { bill: string; orderId: string }) =>
    post<QueryCrossWarehouseDetailListReq[]>('/api/pcweb/purchase/crossdocking/getDetailForEdit', params),
  /** 保存/保存并提交 */
  save: (params: {
    purchaseOrderId: string;
    targetStatus: string;
    version: number;
    saves: any;
    uuid: string;
    // saves: {
    //   goodsId: string;
    //   storeSaves: {
    //     qty: string;
    //     storeId: string;
    //   }[];
    // }[];
  }) => post<SumResult>('/api/pcweb/purchase/crossdocking/save', params),

  /** 列表提交 */
  submit: (params: { version: number; uuid: string }) =>
    post<SumResult>('/api/pcweb/purchase/crossdocking/submit', params),
  /** 列表删除 */
  remove: (params: { uuid: string }) => post<SumResult>('/api/pcweb/purchase/crossdocking/remove', params)
};

export default crossWarehouse;
