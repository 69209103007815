import {
  BusinessModelEnum,
  ChannelEnum,
  GoodsRemoveProcessEnum,
  GoodsTypeEnum,
  OnlineGoodsStatusEnum,
  OnlinePlatformEnum,
  SortDimensionEnum
} from '@/dataModel/dict/enum';
import {
  ISpu,
  ISub,
  IMdataGoodsQueryStoreGoods,
  IGoodsInventoryVo,
  IGoodsSupplierVo,
  IGoodsSchemaVo,
  ISku,
  IQueryByBasic,
  IQueryBySupplier,
  IQueryByPackage,
  IQueryByBarcode,
  IQueryByGoodsStatus,
  IQueryByGoodsInfo,
  IQueryByCombined,
  IQueryByNewGoods,
  IQueryByEws,
  IQueryByOutGoods,
  INegativeInv,
  INegativeProfit,
  IOldInv,
  ILowInv,
  IUnSold,
  IGoodsSellUnionVo,
  IMdataSku,
  IAbnormalLock,
  IAbnormalLockTotal,
  IQueryOnlineAbnormalItems,
  IExchangeGoods,
  INewMdata,
  IGoodsSale,
  IGoodsSaleTotal,
  IQueryByOnline,
  IGoodsAdjustPrice,
  IGoodsSaleSort,
  IGoodsSaleSortTotal,
  IGoodsSaleDailyByStore,
  IGoodsSaleDailyByStoreTotal,
  IGoodsSaleDailyBySort,
  IGoodsSaleDailyBySortTotal,
  IGoodsSaleDailyByGoods,
  IGoodsSaleDailyByGoodsTotal,
  INoSaleGoodsInfo,
  INoSaleGoodsInfoTotal,
  IGoodsSaleByStore,
  IGoodsSaleByStoreTotal
} from '@/dataModel/mdata/goods/interface';
import { ISort } from '@/dataModel/mdata/sort/interface';
import { IStoreBySpu } from '@/dataModel/mdata/store/interface';
import { ISupplier } from '@/dataModel/mdata/supplier/interface';
import request from '@/utils/request';
import goodsCompose from './compose';

export type MapStringString = Record<string, string>;

/**
 * 基础请求
 */
export interface GoodsQueryInventoryRequest {
  /**
   * 仓库或门店
   * @example wrh
   */
  billClass?: string;

  /**
   * 商品编码
   * @example 0005
   */
  goodsId: string;

  /**
   * 租户ID
   * @example TENANT1234
   */
  pid?: string;

  /**
   * 规格
   * @example [{"颜色":"红色"},{"尺码":"L"}]
   */
  spec: MapStringString[];

  /**
   * 门店ID
   * @example 99998888
   */
  storeId?: string;

  /**
   * 用户ID
   * @example USER5678
   */
  wid?: string;
}

const dao = {
  // 获取商品列表 spu
  querySpu: (params?: any) => request.post<IPageData<ISpu>>('/api/pcweb/mdata/goods/query/spu', params),
  newGuerySpu: (params?: any) => request.post<IPageData<any>>('/api/pcweb/v2/mdata/spu/query', params),
  //查询基础商品是否有关联的分级商品
  levelSkuHas: (params?: any) => request.post<any>('/api/pcweb/v2/mdata/sku/levelSku/has', params),
  //新的分级商品查询
  //queryForlevel: (params?: any) => request.post<IPageData<any>>('/api/pcweb/v2/mdata/sku/query/basic/forlevel', params),
  // 商品详情
  get: (params: { uuid?: string; spuUuid?: string }) => request.post<IMdataSku>('/api/pcweb/mdata/goods/get', params),
  // 新--商品详情
  newGet: (params: { uuid?: string; id?: string }) => request.post<INewMdata>('/api/pcweb/v2/mdata/spu/get', params),
  // 创建商品
  getTenantRightsInfo: params => request.post<any>('/api/pcweb/tenant/rights/getTenantRightsInfo', params),
  // 新--商品详情
  updateOnlineMessage: (params: {
    onlineCatalog?: any;
    onlineSlogan?: string;
    saleAttributes: any[];
    skuOnlineName: any;
    spu: string;
    /**美团线上规格 */
    skuMtAttributeValuesMap?: any;
    /**饿了么线上规格 */
    skuElemeAttributeValuesMap?: any;
    /**京东到家线上规格 */
    skuJddjAttributeValuesMap?: any;
  }) => request.post<any>('/api/pcweb/v2/mdata/spu/online/info/update', params),
  // 新--商品详情
  updateSku: (params: {
    onlineCatalog?: any;
    onlineSlogan?: string;
    saleAttributes: any[];
    skuOnlineName: any;
    spu: string;
    /**美团线上规格 */
    skuMtAttributeValuesMap?: any;
    /**饿了么线上规格 */
    skuElemeAttributeValuesMap?: any;
    /**京东到家线上规格 */
    skuJddjAttributeValuesMap?: any;
  }) => request.post<any>('/api/pcweb/v2/mdata/spu/online/info/updateSku', params),
  // 获取计量单位
  queryMunits: () => request.post('/api/pcweb/mdata/goods/query/munits'),
  // 创建商品
  create: params => request.post('/api/pcweb/mdata/goods/create', params),
  newCreate: params => request.post('/api/pcweb/v2/mdata/spu/create', params),
  /**
   * 创建商品 v2
   */
  createNew: params => request.post('/api/pcweb/v2/mdata/spu/savenew', params),
  /**
   * 门店商品复制门店到门店
   */
  copyStoreToStore: (params: { fromStoreId: string; toStoreId: string }) =>
    request.post('/api/pcweb/mdata/goods/copyStoreToStore', params),
  /**
   * 复制商品到门店
   */
  copyStoreGoods: (params: { toStoreId: string; uuids: string[] }) =>
    request.post('/api/pcweb/mdata/goods/copyStoreGoods', params),
  /**
   * 发布门店商品到线上平台
   */
  pushStoreGoods: (params: { onlineChannels: string[]; toStoreId: string }) =>
    request.post('/api/pcweb/mdata/goods/pushStoreGoods', params),
  /**
   * 设置售卖渠道
   */
  modifySale: (params: { offlineStatus: number; onlineChannels: string[]; onlineStatus: number; uuids: string[] }) =>
    request.post('/api/pcweb/mdata/goods/modifySale', params),
  /**
   * 批量设置电子价签商品
   */
  modifyElectronicPriceTag: (params: { isElectronicPriceTag: number; uuids: string[] }) =>
    request.post('/api/pcweb/mdata/goods/modifyElectronicPriceTag', params),
  // 删
  remove: (
    params: {
      uuid: string;
    },
    options
  ) => request.post('/api/pcweb/mdata/goods/remove', params, options),
  /**
   * 删除门店商品
   */
  removeStoreGoods: (params: { uuids: string[] }, options) =>
    request.post('/api/pcweb/mdata/goods/removeStoreGoods', params, options),
  // 子商品分页
  querySub: params => request.post<IPageData<ISub>>('/api/pcweb/mdata/goods/query/sub', params),
  newQuerySub: params => request.post<IPageData<any>>('/api/pcweb/v2/mdata/sku/query', params),
  // 编辑
  modify: params => request.post('/api/pcweb/mdata/goods/modify', params),
  newModify: params => request.post('/api/pcweb/v2/mdata/spu/savemodify', params),
  // 分页查 sku
  querySku: (
    params: {
      filter?: {
        /**
         * 不传默认查所有类型
         */
        bulkGoods?: string;
        goodsType?: GoodsTypeEnum[];
        goodsIds?: string[];
        supplier?: ISupplier['id'];
        sortId?: ISort['id'];
        searchKey?: string;
      };
    } & IPageParams
  ) => request.post<IPageData<ISku>>('/api/pcweb/mdata/goods/query/sku', params),
  newQuerySku: (params: {
    conditions?: {
      /**
       * 不传默认查所有类型
       */
      canSale?: boolean;
      goodsTypeEquals?: string;
      goodsTypeIn?: GoodsTypeEnum[];
      idEquals?: string;
      goodsIds?: string[];
      keyword?: string;
      page?: number;
      pageSize?: number;
      sortIdStartsWith?: string;
    };
  }) => request.post<IPageData<any>>('/api/pcweb/v2/mdata/sku/query', params),
  newQueryLevelSku: (params: {
    conditions?: {
      /**
       * 不传默认查所有类型
       */
      keyword?: string;
      page?: number;
      pageSize?: number;
    };
  }) => request.post<IPageData<any>>('/api/pcweb/v2/mdata/sku/query/basic/forlevel', params),

  // 批量更改商品信息
  modifyGoodsInfo: (params: {
    brandId?: string;
    sortId?: string;
    supplier?: string;
    give?: string;
    changePrice?: string;
    propList?: { goodsPropName: string; goodsPropValue: string }[];
    uuids: string[];
  }) => request.post('/api/pcweb/mdata/goods/modifyGoodsInfo', params),
  // 批量设置销售门店
  modifyGoodsStore: (params: {
    storeIds: string[];
    munitUuids?: string[];
    spuUuids?: string[];
    saleChannelsMap: {
      [storeId: string]: {
        offline: boolean;
        onlineChannels: ChannelEnum[];
      };
    };
  }) => request.post<{ goodsCount: number; storeCount: number }>('/api/pcweb/mdata/goods/modifyGoodsStore', params),
  // 删除商品下的门店
  removeStore: (params: { spuUuid: string; storeIds: string[] }) =>
    request.post('/api/pcweb/mdata/goods/removeStore', params),
  // 分页查询销售门店
  queryStore: (params: { filter: { spuUuid?: string } } & IPageParams) =>
    request.post<IPageData<IStoreBySpu>>('/api/pcweb/mdata/goods/query/store', params),
  // 分页查询销售门店
  queryStoreGoods: (
    params: {
      filter?: {
        goodsStatus?: string;
        line?: string;
        onlineChannels?: string[];
        searchKey?: string;
        stores?: string[];
        sortId?: string;
        supplier?: string;
        supplierType?: string;
        startDate?: string;
        endDate?: string;
        cabinetId?: string;
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => request.post<IPageData<IMdataGoodsQueryStoreGoods>>('/api/pcweb/mdata/goods/query/storeGoods', params),
  // 分页查询商品
  querySkuGoods: (
    params: {
      filter?: {
        goodsStatus?: string;
        line?: string;
        onlineChannels?: string[];
        searchKey?: string;
        stores?: string[];
        sortId?: string;
        supplier?: string;
        supplierType?: string;
        startDate?: string;
        endDate?: string;
        cabinetId?: string;
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => request.post<IPageData<IMdataGoodsQueryStoreGoods>>('/api/pcweb/mdata/goods/query/v2/sku', params),
  /** 库存信息 */
  queryInventoryView: (params: GoodsQueryInventoryRequest & IPageParams) =>
    request.post<IPageData<IGoodsInventoryVo>>('/api/pcweb/inv/query/queryBusinessInvBySpu', params),

  /** 供货信息-联营 */
  querySellUnionView: (params: { /** 商品档案信息 */ spuUuid: string } & IPageParams) =>
    request.post<IPageData<IGoodsSellUnionVo>>('/api/pcweb/mdata/supplier/sellunion/agreement/queryGoodsBySpu', params),

  /** 供货信息-采购 */
  querySupplierView: (params: { spu: string } & IPageParams) =>
    request.post<IPageData<IGoodsSupplierVo>>('/api/pcweb/mdata/supplier/agreement/queryDetailBySpu', params),

  /** 参考数据 */
  querySchemaView: (params: { goodsId: string } & IPageParams) =>
    request.post<IPageData<IGoodsSchemaVo>>('/api/pcweb/dist/schema/queryDistInfoBySpu', params),

  /** [一键导入]导入门店商品 */
  importToStoreGoods: (params: { goodsCreated: string; toStoreId: string }) =>
    request.post<any>('/api/pcweb/mdata/goods/importToStoreGoods', params),

  /** 检查、删除商品档案 */
  checkRemove: (params: { id: string; type: GoodsTypeEnum; version: number; process: GoodsRemoveProcessEnum }) =>
    request.post<{
      result: boolean;
      reason: string;
    }>('/api/pcweb/mdata/goods/checkRemove', params),

  // 检查、删除商品档案（批量）
  checkRemoveBatch: (params: { ids: string[]; process: GoodsRemoveProcessEnum }) =>
    request.post<{
      failCount: number;
      failInfoList: {
        id: string;
        name: string;
        reason: string;
      }[];
      result: boolean;
      successCount: number;
      successIdList: {
        id: string;
        name: string;
        reason: string;
      }[];
    }>('/api/pcweb/mdata/goods/checkRemoveBatch', params),

  // 生成商品删除失败原因
  getRemoveFailFileUrl: (params: {
    failInfoList: {
      id: string;
      name: string;
      reason: string;
    }[];
  }) =>
    request.post<{
      url: string;
    }>('/api/pcweb/mdata/goods/getRemoveFailFileUrl', params),

  /** 查询完善建议*/
  suggestionGet: (params: { uuid?: string }) =>
    request.post<string[]>('/api/pcweb/v2/mdata/spu/perfecting/suggestion/get', params),
  /** 关闭完善建议*/
  suggestionClose: (params: { uuid?: string }) =>
    request.post<string[]>('/api/pcweb/v2/mdata/spu/perfecting/suggestion/close', params),
  /** 关闭完善建议*/
  deleteSku: (params: { spu?: string; skus?: string[] }) =>
    request.post<any>('/api/pcweb/v2/mdata/spu/sku/remove', params)
};

const unUsualGoods = {
  /**总部-异常商品-负库存商品查询 */
  queryNegativeInv: (
    params: {
      filter?: {
        endOperateDate?: string; //操作时间-结束
        moreCode?: boolean; //显示一品多码商品
        moreQpcStr?: boolean; //显示一品多包装商品
        moreSupplier?: boolean; //显示一品多供商品
        pluSearchKey?: string; //plu码
        searchKey?: string; //商品信息
        sonSearchKey?: string; //组合商品子商品
        sortId?: string; //分类编码
        startOperateDate?: string; //操作时间-开始
        storeId?: string; //门店id
        supplier?: string; //供应商编码名称
        supplierId?: string; //供应商编码名称
      };
    } & IPageParams
  ) => request.post<IPageData<INegativeInv>>('/api/pcweb/mdata/goods/queryNegativeInv', params),
  /**总部-异常商品-负库存商品查询统计 */
  getNegativeInvStat: (params: {
    filter?: {
      endOperateDate?: string; //操作时间-结束
      moreCode?: boolean; //显示一品多码商品
      moreQpcStr?: boolean; //显示一品多包装商品
      moreSupplier?: boolean; //显示一品多供商品
      pluSearchKey?: string; //plu码
      searchKey?: string; //商品信息
      sonSearchKey?: string; //组合商品子商品
      sortId?: string; //分类编码
      startOperateDate?: string; //操作时间-开始
      storeId?: string; //门店id
      supplier?: string; //供应商编码名称
      supplierId?: string; //供应商编码名称
    };
  }) => request.post<any>('/api/pcweb/mdata/goods/getNegativeInvStat', params),
  /**异常商品-负毛利商品查询 */
  queryNegativeProfit: (
    params: {
      filter?: {
        endDate?: string; //结束时间
        searchKey?: string; //商品信息
        sortId?: string; //分类编码
        startDate?: string; //开始时间
        storeId?: string; //门店id
        supplier?: string; //供应商标识名称
        flowId?: string; //流水号
      };
      isStore: boolean; //区分门店和总部
    } & IPageParams
  ) => request.post<IPageData<INegativeProfit>>('/api/pcweb/mdata/goods/queryNegativeProfit', params),
  /**异常商品-负毛利-统计 */
  getNegativeProfitStat: (params: {
    filter?: {
      endDate?: string; //结束时间
      searchKey?: string; //商品信息
      sortId?: string; //分类编码
      startDate?: string; //开始时间
      storeId?: string; //门店id
      supplier?: string; //供应商标识名称
      flowId?: string; //流水号
    };
    isStore: boolean; //区分门店和总部
  }) => request.post<any>('/api/pcweb/mdata/goods/getNegativeProfitStat', params),
  /**门店-异常商品-负毛利商品查询 -已废弃*/
  /* queryStoreNegativeProfit: (
    params: {
      filter?: {
        endOperateDate?: string; //操作时间-结束
        moreCode?: boolean; //显示一品多码商品
        moreQpcStr?: boolean; //显示一品多包装商品
        moreSupplier?: boolean; //显示一品多供商品
        pluSearchKey?: string; //plu码
        searchKey?: string; //商品信息
        sonSearchKey?: string; //组合商品子商品
        sortId?: string; //分类编码
        startOperateDate?: string; //操作时间-开始
        storeId?: string; //门店id
        supplier?: string; //供应商编码名称
        supplierId?: string; //供应商编码名称
      };
    } & IPageParams
  ) => request.post<IPageData<INegativeProfit>>('/api/pcweb/mdata/goods/queryStoreNegativeProfit', params), */
  /**门店-异常商品-高库龄商品查询 */
  queryOlderInv: (
    params: {
      filter?: {
        storeId?: string; //门店id
        sortId?: string; //分类编码
        //older?: string; //库龄 PX-1808 去掉库龄筛选
        searchKey?: string; //商品信息
      };
    } & IPageParams
  ) => request.post<IPageData<IOldInv>>('/api/pcweb/mdata/goods/queryOlderInv', params),
  /**门店-异常商品-高库龄商品查询统计 */
  getOlderInv: (params: {
    filter?: {
      storeId?: string; //门店id
      sortId?: string; //分类编码
      //older?: string; //库龄 PX-1808 去掉库龄筛选
      searchKey?: string; //商品信息
    };
  }) => request.post<any>('/api/pcweb/mdata/goods/getOlderInv', params),
  /**门店-低库存商品 */
  queryStoreLessInv: (
    params: {
      filter?: {
        endOperateDate?: string; //操作时间-结束
        moreCode?: boolean; //显示一品多码商品
        moreQpcStr?: boolean; //显示一品多包装商品
        moreSupplier?: boolean; //显示一品多供商品
        pluSearchKey?: string; //plu码
        searchKey?: string; //商品信息
        sonSearchKey?: string; //组合商品子商品
        sortId?: string; //分类编码
        startOperateDate?: string; //操作时间-开始
        storeId?: string; //门店id
        supplier?: string; //供应商编码名称
        supplierId?: string; //供应商编码名称
      };
    } & IPageParams
  ) => request.post<IPageData<ILowInv>>('/api/pcweb/mdata/goods/queryStoreLessInv', params),
  /**门店-滞销商品 */
  queryStoreUnsold: (
    params: {
      filter?: {
        endOperateDate?: string; //操作时间-结束
        moreCode?: boolean; //显示一品多码商品
        moreQpcStr?: boolean; //显示一品多包装商品
        moreSupplier?: boolean; //显示一品多供商品
        pluSearchKey?: string; //plu码
        searchKey?: string; //商品信息
        sonSearchKey?: string; //组合商品子商品
        sortId?: string; //分类编码
        startOperateDate?: string; //操作时间-开始
        storeId?: string; //门店id
        supplier?: string; //供应商编码名称
        supplierId?: string; //供应商编码名称
      };
    } & IPageParams
  ) => request.post<IPageData<IUnSold>>('/api/pcweb/mdata/goods/queryStoreUnsold', params),
  /**门店-负库存商品信息 */
  queryStoreNegativeInv: (
    params: {
      filter?: {
        endOperateDate?: string; //操作时间-结束
        moreCode?: boolean; //显示一品多码商品
        moreQpcStr?: boolean; //显示一品多包装商品
        moreSupplier?: boolean; //显示一品多供商品
        pluSearchKey?: string; //plu码
        searchKey?: string; //商品信息
        sonSearchKey?: string; //组合商品子商品
        sortId?: string; //分类编码
        startOperateDate?: string; //操作时间-开始
        storeId?: string; //门店id
        supplier?: string; //供应商编码名称
        supplierId?: string; //供应商编码名称
      };
    } & IPageParams
  ) => request.post<IPageData<INegativeInv>>('/api/pcweb/mdata/goods/queryStoreNegativeInv', params),
  /**门店-异常商品-负库存商品查询统计 */
  getStoreNegativeInvStat: (params: {
    filter?: {
      endOperateDate?: string; //操作时间-结束
      moreCode?: boolean; //显示一品多码商品
      moreQpcStr?: boolean; //显示一品多包装商品
      moreSupplier?: boolean; //显示一品多供商品
      pluSearchKey?: string; //plu码
      searchKey?: string; //商品信息
      sonSearchKey?: string; //组合商品子商品
      sortId?: string; //分类编码
      startOperateDate?: string; //操作时间-开始
      storeId?: string; //门店id
      supplier?: string; //供应商编码名称
      supplierId?: string; //供应商编码名称
    };
  }) => request.post<any>('/api/pcweb/mdata/goods/getStoreNegativeInvStat', params),

  /** 统计-门店-异常商品-拣货异常的商品记录 */
  queryOnlineAbnormal: (params: {
    filter: {
      endDate?: string;
      reason?: string;
      searchKey?: string;
      sortId?: string;
      startDate?: string;
      storeId?: number;
    };
  }) => request.post<IPageData<IQueryOnlineAbnormalItems>>(`/api/pcweb/mdata/goods/queryOnlineAbnormal`, params),

  /**分页-异常商品-库存锁定的商品信息（异常锁定商品） */
  queryLockInv: (
    params: {
      filter?: {
        searchKey?: string; //商品
        storeId?: string; //门店编码
      };
      isStore: boolean;
    } & IPageParams
  ) => request.post<IPageData<IAbnormalLock>>('/api/pcweb/mdata/goods/queryLockInv', params),
  /**统计-异常商品-库存锁定的商品信息（异常锁定商品） */
  getLockInvStat: (params: {
    filter?: {
      searchKey?: string; //商品
      storeId?: string; //门店编码
    };
    isStore: boolean;
  }) => request.post<IAbnormalLockTotal>('/api/pcweb/mdata/goods/getLockInvStat', params),
  /* 异常商品--不动销商品 */
  getQueryNoSale: (params: {
    filter?: {
      /* 经营方式 */
      businessModel?: string;
      /* 查询天数 */
      days?: string;
      /* 商品查询关键词 */
      keyword?: string;
      /* 最大库存 */
      maxQty?: number;
      /* 最小库存 */
      minQty?: number;
      /* 分类编码（可以是大中小类） */
      sortId?: string;
      /* 门店编码 */
      storeId?: string;
    } & IPageParams;
  }) => request.post<IPageData<INoSaleGoodsInfo>>('/api/pcweb/mdata/goods/queryNoSale', params),
  /* 异常商品 不动销 汇总 */
  getNoSaleTotal: (params: {
    filter?: {
      /* 经营方式 */
      businessModel?: string;
      /* 查询天数 */
      days?: string;
      /* 商品查询关键词 */
      keyword?: string;
      /* 最大库存 */
      maxQty?: number;
      /* 最小库存 */
      minQty?: number;
      /* 分类编码（可以是大中小类） */
      sortId?: string;
      /* 门店编码 */
      storeId?: string;
    } & IPageParams;
  }) => request.post<INoSaleGoodsInfoTotal>('/api/pcweb/mdata/goods/getNoSaleStat', params)
};

const goodsInformation = {
  /**总部商品基本信息 */
  queryBasic: (
    params: {
      filter?: {
        endOperateDate?: string; //操作时间-结束
        moreCode?: boolean; //显示一品多码商品
        moreQpcStr?: boolean; //显示一品多包装商品
        moreSupplier?: boolean; //显示一品多供商品
        searchKey?: string; //商品信息
        sortId?: string; //分类编码
        startOperateDate?: string; //操作时间-开始
        storeId?: string; //门店id
        supplier?: string; //供应商编码名称
      };
    } & IPageParams
  ) => request.post<IPageData<IQueryByBasic>>('/api/pcweb/mdata/goods/queryBasic', params),
  /**总部商品供应商查询 */
  querySupplier: (
    params: {
      filter?: {
        endOperateDate?: string; //操作时间-结束
        moreCode?: boolean; //显示一品多码商品
        moreQpcStr?: boolean; //显示一品多包装商品
        moreSupplier?: boolean; //显示一品多供商品
        searchKey?: string; //商品信息
        sortId?: string; //分类编码
        startOperateDate?: string; //操作时间-开始
        storeId?: string; //门店id
        supplier?: string; //供应商编码名称
      };
    } & IPageParams
  ) => request.post<IPageData<IQueryBySupplier>>('/api/pcweb/mdata/goods/querySupplier', params),
  /**总部商品包装查询 */
  queryQpcStr: (
    params: {
      filter?: {
        endOperateDate?: string; //操作时间-结束
        moreCode?: boolean; //显示一品多码商品
        moreQpcStr?: boolean; //显示一品多包装商品
        moreSupplier?: boolean; //显示一品多供商品
        searchKey?: string; //商品信息
        sortId?: string; //分类编码
        startOperateDate?: string; //操作时间-开始
        storeId?: string; //门店id
        supplier?: string; //供应商编码名称
      };
    } & IPageParams
  ) => request.post<IPageData<IQueryByPackage>>('/api/pcweb/mdata/goods/queryQpcStr', params),
  /**总部商品条码查询 */
  queryBarcode: (
    params: {
      filter?: {
        endOperateDate?: string; //操作时间-结束
        moreCode?: boolean; //显示一品多码商品
        moreQpcStr?: boolean; //显示一品多包装商品
        moreSupplier?: boolean; //显示一品多供商品
        searchKey?: string; //商品信息
        sortId?: string; //分类编码
        startOperateDate?: string; //操作时间-开始
        storeId?: string; //门店id
        supplier?: string; //供应商编码名称
      };
    } & IPageParams
  ) => request.post<IPageData<IQueryByBarcode>>('/api/pcweb/mdata/goods/queryBarcode', params),
  /**总部商品商状态查询 */
  queryStateChange: (
    params: {
      filter?: {
        endOperateDate?: string; //操作时间-结束
        moreCode?: boolean; //显示一品多码商品
        moreQpcStr?: boolean; //显示一品多包装商品
        moreSupplier?: boolean; //显示一品多供商品
        searchKey?: string; //商品信息
        sortId?: string; //分类编码
        startOperateDate?: string; //操作时间-开始
        storeId?: string; //门店id
        supplier?: string; //供应商编码名称
      };
    } & IPageParams
  ) => request.post<IPageData<IQueryByGoodsStatus>>('/api/pcweb/mdata/goods/queryStateChange', params),

  /**总部线上商品查询 */
  queryByOnline: (
    params: {
      filter?: {
        /**门店id */
        storeId?: string;
        /**库存数量最小值 */
        minInv?: number;
        /**库存数量最大值 */
        maxInv?: number;
        /**商品分类编码 */
        sortId?: string;
        /**商品编码/条码/名称 */
        goodsSearchKey?: string;
        /**上下架状态 */
        status?: OnlineGoodsStatusEnum;
      };
    } & IPageParams
  ) => request.post<IPageData<IQueryByOnline>>('/api/pcweb/mdata/online/goods/queryOnlineGoodsInfos', params, {}, 'onlineOrder'),

  /**门店商品信息查询 */
  queryStoreBasicInfo: (
    params: {
      filter?: {
        endOperateDate?: string; //操作时间-结束
        moreCode?: boolean; //显示一品多码商品
        moreQpcStr?: boolean; //显示一品多包装商品
        moreSupplier?: boolean; //显示一品多供商品
        searchKey?: string; //商品信息
        sonSearchKey?: string; //组合商品子商品
        sortId?: string; //分类编码
        startOperateDate?: string; //操作时间-开始
        storeId?: string; //门店id
        supplier?: string; //供应商编码名称
      };
    } & IPageParams
  ) => request.post<IPageData<IQueryByGoodsInfo>>('/api/pcweb/mdata/goods/queryStoreBasicInfo', params),
  /**门店商品包装查询 */
  queryStoreQpcStr: (
    params: {
      filter?: {
        endOperateDate?: string; //操作时间-结束
        moreCode?: boolean; //显示一品多码商品
        moreQpcStr?: boolean; //显示一品多包装商品
        moreSupplier?: boolean; //显示一品多供商品
        searchKey?: string; //商品信息
        sonSearchKey?: string; //组合商品子商品
        sortId?: string; //分类编码
        startOperateDate?: string; //操作时间-开始
        storeId?: string; //门店id
        supplier?: string; //供应商编码名称
      };
    } & IPageParams
  ) => request.post<IPageData<IQueryByPackage>>('/api/pcweb/mdata/goods/queryStoreQpcStr', params),
  /**门店商品条码查询 */
  queryStoreBarcode: (
    params: {
      filter?: {
        endOperateDate?: string; //操作时间-结束
        moreCode?: boolean; //显示一品多码商品
        moreQpcStr?: boolean; //显示一品多包装商品
        moreSupplier?: boolean; //显示一品多供商品
        searchKey?: string; //商品信息
        sonSearchKey?: string; //组合商品子商品
        sortId?: string; //分类编码
        startOperateDate?: string; //操作时间-开始
        storeId?: string; //门店id
        supplier?: string; //供应商编码名称
      };
    } & IPageParams
  ) => request.post<IPageData<IQueryByBarcode>>('/api/pcweb/mdata/goods/queryStoreBarcode', params),
  /**门店组合商品查询 */
  queryStoreCombine: (
    params: {
      filter?: {
        endOperateDate?: string; //操作时间-结束
        moreCode?: boolean; //显示一品多码商品
        moreQpcStr?: boolean; //显示一品多包装商品
        moreSupplier?: boolean; //显示一品多供商品
        searchKey?: string; //商品信息
        sonSearchKey?: string; //组合商品子商品
        sortId?: string; //分类编码
        startOperateDate?: string; //操作时间-开始
        storeId?: string; //门店id
        supplier?: string; //供应商编码名称
      };
    } & IPageParams
  ) => request.post<IPageData<IQueryByCombined>>('/api/pcweb/mdata/goods/queryStoreCombine', params),
  /**门店商品新品查询 */
  queryStoreNew: (
    params: {
      filter?: {
        endOperateDate?: string; //操作时间-结束
        moreCode?: boolean; //显示一品多码商品
        moreQpcStr?: boolean; //显示一品多包装商品
        moreSupplier?: boolean; //显示一品多供商品
        pluSearchKey?: string; //plu码
        searchKey?: string; //商品信息
        sonSearchKey?: string; //组合商品子商品
        sortId?: string; //分类编码
        startOperateDate?: string; //操作时间-开始
        storeId?: string; //门店id
        supplier?: string; //供应商编码名称
      };
    } & IPageParams
  ) => request.post<IPageData<IQueryByNewGoods>>('/api/pcweb/mdata/goods/queryStoreNew', params),
  /**门店商品电子秤商品查询 */
  queryStorePlu: (
    params: {
      filter?: {
        endOperateDate?: string; //操作时间-结束
        moreCode?: boolean; //显示一品多码商品
        moreQpcStr?: boolean; //显示一品多包装商品
        moreSupplier?: boolean; //显示一品多供商品
        pluSearchKey?: string; //plu码
        searchKey?: string; //商品信息
        sonSearchKey?: string; //组合商品子商品
        sortId?: string; //分类编码
        startOperateDate?: string; //操作时间-开始
        storeId?: string; //门店id
        supplier?: string; //供应商编码名称
      };
    } & IPageParams
  ) => request.post<IPageData<IQueryByEws>>('/api/pcweb/mdata/goods/queryStorePlu', params),
  /**门店商品-电子秤商品统计 */
  getStorePluStat: (params: {
    filter?: {
      endOperateDate?: string; //操作时间-结束
      moreCode?: boolean; //显示一品多码商品
      moreQpcStr?: boolean; //显示一品多包装商品
      moreSupplier?: boolean; //显示一品多供商品
      pluSearchKey?: string; //plu码
      searchKey?: string; //商品信息
      sonSearchKey?: string; //组合商品子商品
      sortId?: string; //分类编码
      startOperateDate?: string; //操作时间-开始
      state?: string; //商品状态
      storeId?: string; //门店id
      supplier?: string; //供应商编码名称
    };
  }) => request.post<any>('/api/pcweb/mdata/goods/getStorePluStat', params),
  /**门店淘汰商品查询 */
  queryStoreOut: (
    params: {
      filter?: {
        endOperateDate?: string; //操作时间-结束
        moreCode?: boolean; //显示一品多码商品
        moreQpcStr?: boolean; //显示一品多包装商品
        moreSupplier?: boolean; //显示一品多供商品
        pluSearchKey?: string; //plu码
        searchKey?: string; //商品信息
        sonSearchKey?: string; //组合商品子商品
        sortId?: string; //分类编码
        startOperateDate?: string; //操作时间-开始
        storeId?: string; //门店id
        supplier?: string; //供应商编码名称
      };
    } & IPageParams
  ) => request.post<IPageData<IQueryByOutGoods>>('/api/pcweb/mdata/goods/queryStoreOut', params),

  /**查询商品详情h5地址和小程序 最多查询20条 */
  queryGoodsUrl: (params: { goodsMunitList: string[] }) =>
    request.post<{ h5Url: string; miniUrl: string; goodsMunit: string }[]>(
      '/api/pcweb/mdata/goods/queryGoodsUrl',
      params
    ),

  /**总部/门店-商品销售查询-按商品 IPageData<IGoodsSale>*/
  queryGoodsSale: (
    params: {
      filter?: {
        startTime: string; // 开始日期
        endTime: string; // 结束日期
        stores: string[]; // 门店
        sortIds: string[]; // 分类
        statuss: string[]; // 商品状态
        supplierKey: string; // 供应商编码/名称
        searchKey: string; // 商品名称，商品编码/条码/PLU码
        businessModels: BusinessModelEnum[]; // 经营方式
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => request.post<any>('/api/pcweb/report/drpt/query', params),
  /**总部/门店-商品销售查询总计-按商品 */
  queryGoodsSaleTotal: (
    params: {
      filter?: {
        startDate: string; // 开始日期
        endDate: string; // 结束日期
        stores: string[]; // 门店
        sortIds: string[]; // 分类
        statuss: string[]; // 商品状态
        supplierKey: string; // 供应商编码/名称
        searchKey: string; // 商品名称，商品编码/条码/PLU码
        businessModels: BusinessModelEnum[]; // 经营方式
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => request.post<IGoodsSaleTotal>('/api/pcweb/report/drpt/queryCount', params),

  /**总部/门店-商品销售查询-按门店  IPageData<IGoodsSaleByStore>*/
  queryGoodsSaleByStore: (
    params: {
      filter?: {
        startDate: string; // 开始日期
        endDate: string; // 结束日期
        stores: string[]; // 门店
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => request.post<any>('/api/pcweb/report/drpt/queryStore', params),
  /**总部/门店-商品销售查询总计-按门店 */
  queryGoodsSaleByStoreTotal: (
    params: {
      filter?: {
        startDate: string; // 开始日期
        endDate: string; // 结束日期
        stores: string[]; // 门店
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => request.post<IGoodsSaleByStoreTotal>('/api/pcweb/report/drpt/getStoreCount', params),

  /**总部/门店-商品销售查询-按分类  IPageData<IGoodsSaleSort>*/
  queryGoodsSaleSort: (
    params: {
      filter?: {
        startTime: string; // 开始日期
        endTime: string; // 结束日期
        stores: string[]; // 门店
        sortClass: SortDimensionEnum; // 类别维度
        sortIds: string[]; // 分类
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => request.post<any>('/api/pcweb/report/drpt/querySort', params),
  /**总部/门店-商品销售查询总计-按分类 */
  queryGoodsSaleSortTotal: (
    params: {
      filter?: {
        startTime: string; // 开始日期
        endTime: string; // 结束日期
        stores: string[]; // 门店
        sortClass: SortDimensionEnum; // 类别维度
        sortIds: string[]; // 分类
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => request.post<IGoodsSaleSortTotal>('/api/pcweb/report/drpt/querySortCount', params),

  /**总部/门店-门店销售日报(商品)-按门店 IPageData<IGoodsSaleDailyByStore> */
  queryDrptStore: (
    params: {
      filter?: {
        startDate: string; // 开始日期
        endDate: string; // 结束日期
        stores: string[]; // 门店
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => request.post<any>('/api/pcweb/report/drpt/queryDrptStore', params),
  /**总部/门店-商品销售查询总计-按商品 */
  queryDrptStoreCount: (
    params: {
      filter?: {
        startDate: string; // 开始日期
        endDate: string; // 结束日期
        stores: string[]; // 门店
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => request.post<IGoodsSaleDailyByStoreTotal>('/api/pcweb/report/drpt/queryDrptStoreCount', params),

  /**总部/门店-门店销售日报(商品)-按分类 */
  queryDrptSort: (
    params: {
      filter?: {
        startDate: string; // 开始日期
        endDate: string; // 结束日期
        stores: string[]; // 门店
        sortClass: SortDimensionEnum; // 类别维度
        sortIds: string[]; // 分类
        businessModels: BusinessModelEnum[]; // 经营方式
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => request.post<any>('/api/pcweb/report/drpt/queryDrptSort', params),
  /**总部/门店-门店销售日报(商品)-按分类 */
  queryDrptSortCount: (
    params: {
      filter?: {
        startDate: string; // 开始日期
        endDate: string; // 结束日期
        stores: string[]; // 门店
        sortClass: SortDimensionEnum; // 类别维度
        sortIds: string[]; // 分类
        businessModels: BusinessModelEnum[]; // 经营方式
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => request.post<IGoodsSaleDailyBySortTotal>('/api/pcweb/report/drpt/queryDrptSortCount', params),

  /**总部/门店-门店销售日报(商品)-按商品  IPageData<IGoodsSaleDailyByGoods>*/
  queryDrptSku: (
    params: {
      filter?: {
        startDate: string; // 开始日期
        endDate: string; // 结束日期
        stores: string[]; // 门店
        sortIds: string[]; // 分类
        statuss: string[]; // 商品状态
        supplierKey: string; // 供应商编码/名称
        searchKey: string; // 商品名称，商品编码/条码/PLU码
        businessModels: BusinessModelEnum[]; // 经营方式
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => request.post<any>('/api/pcweb/report/drpt/queryDrptSku', params),
  /**总部/门店-门店销售日报(商品)-按商品 */
  queryDrptSkuCount: (
    params: {
      filter?: {
        startDate: string; // 开始日期
        endDate: string; // 结束日期
        stores: string[]; // 门店
        sortIds: string[]; // 分类
        statuss: string[]; // 商品状态
        supplierKey: string; // 供应商编码/名称
        searchKey: string; // 商品名称，商品编码/条码/PLU码
        businessModels: BusinessModelEnum[]; // 经营方式
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => request.post<IGoodsSaleDailyByGoodsTotal>('/api/pcweb/report/drpt/queryDrptSkuCount', params),

  /**门店调价报表 */
  queryGoodsAdjustPrice: (
    params: {
      filter?: {
        startDate: string; // 开始日期
        endDate: string; // 结束日期
        storeIdList: string[]; // 门店
        deptIdList: string[]; // 部门
        sortIdList: string[]; // 分类
        adjustPriceSource: string; // 变价类型
        searchKey: string; // 商品名称，商品编码/条码/PLU码
      };
    } & IPageParams
  ) => request.post<IPageData<IGoodsAdjustPrice>>('/api/pcweb/retail/adjust/price/queryLaunchGoodsRecords', params)
};

const exchangeGoodsQuery = {
  exchangeGoods: (
    params: {
      filter?: {
        endDate?: string; //结束时间
        onlineChanel?: OnlinePlatformEnum; //线上平台
        orderId?: string; //线上订单号
        searchKey?: string; //商品信息
        sortId?: string; //分类Id
        startDate?: string; //开始时间
        storeId?: string; //门店Id
      };
      isStore: boolean;
    } & IPageParams
  ) => request.post<IPageData<IExchangeGoods>>('/api/pcweb/mdata/goods/exchangeGoods', params)
};

export default { ...dao, ...goodsCompose, ...goodsInformation, ...unUsualGoods, ...exchangeGoodsQuery };
