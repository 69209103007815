import {
  ChannelEnum,
  CheckInStatusEnum,
  CheckTaskSearchTypeEnum,
  CustomerOrderTypeEnum,
  DistTypeEnum,
  EntityCardStatusEnum,
  GoodsStatusEnum,
  IoOutBusinessTypeEnum,
  IoTaskInStatusEnum,
  OnlineOrderCommentScopeEnum,
  OnlineOrderCommentTypeEnum,
  PayStatusEnum,
  PosLogModuleEnum,
  PosLogOperationEnum,
  PurchaseOrdBillClassEnum,
  PurchaseOrderStatusEnum,
  ReceiptedStatusEnum,
  ReceiptStatusEnum,
  SellbuySettlePayStatusEnum,
  SellbuySettleStatusEnum,
  SellunionAgreementStatusEnum,
  SupplierAgreementEffectStatusEnum,
  SupplierOrderTypeEnum,
  SupplierPrepayStatusEnum,
  SupplierSellUnionSettleStatusEnum,
  SupplierSettleTypeEnum,
  SupplierStatusEnum,
  SupplierTypeEnum,
  WholesaleSettleStatusEnum,
  WrhLossStatusEnum,
  RecordOrderBillClassEnum,
  SortDimensionEnum,
  BusinessModelEnum,
  PurchaseSubjectTypeEnum,
  UseCountTypeEnum,
  PrePayCardStatusActionTypeEnum
} from '@/dataModel/dict/enum';
import { IMdataBrand } from '@/dataModel/mdata/brand/interface';
import { GoodsTypesEnum } from '@/dataModel/mdata/goods/enum';
import { ISort } from '@/dataModel/mdata/sort/interface';
import { ISupplier } from '@/dataModel/mdata/supplier/interface';
import { IMemberMoneyStore } from '@/dataModel/member/memberMoneyFlow/interface';
import { IIoOut, IioOutTitle, IIoOutTitleGroup } from '@/dataModel/system/ioOut/interface';
import { Moment } from 'moment';
import request from '@/utils/request';
import { IOrderQueryReferenceFilter } from '@/dataModel/purchase/orderQueryReference/interface';
import { AllocQueryOutRequestFilter } from '@/dataModel/business/distributionQuery/interface';

const { post } = request;

const ioOut = {
  /** 取消任务 */
  cancel: (params: { uuid: string; version: number }) =>
    post<{ traceId: string; version: number }>('/api/pcweb/system/io/out/cancel', params),
  /** 新增 */
  create: (params: ApiIoOutCreateParams) => post<{ uuid: IIoOut['uuid'] }>('/api/pcweb/system/io/out/create', params),

  /** 线上商品迁移创建导出任务 */
  createZip: (params: ApiIoOutCreateParams) =>
    post<{ uuid: IIoOut['uuid'] }>('/api/pcweb/system/io/out/createZip', params),

  /** 获取任务详情 */
  get: (params: { uuid: IIoOut['uuid'] }) => post<IIoOut>('/api/pcweb/system/io/out/get', params),

  /** 获取当前业务是否正在执行导出任务 */
  getRunning: (params: { businessType: IoOutBusinessTypeEnum }) =>
    post<IIoOut[]>('/api/pcweb/system/io/out/getRunning', params),

  /** 获取导出记录 */
  getRecords: (
    params: {
      businessType: IoOutBusinessTypeEnum;
    } & IPageParams
  ) => post<IPageData<IIoOut>>('/api/pcweb/system/io/out/getRecords', params),

  /** 获取导出字段配置 */
  getTitles: (params: { businessType: IoOutBusinessTypeEnum }) =>
    post<IIoOutTitleGroup[]>('/api/pcweb/system/io/out/getTitles', params),

  /** 查询导出任务列表 */
  query: (
    params?: {
      /** 业务类型 */
      businessType?: IoOutBusinessTypeEnum;
      /** 创建人编码 */
      creatorId?: string;
      /** 状态 */
      status?: IoTaskInStatusEnum;
      startDate?: string;
      endDate?: string;
    } & IPageParams
  ) => post<IPageData<IIoOut>>('/api/pcweb/system/io/out/query', params),

  /** 线上商品导出模版 */
  transferCreate: (params: ApiIoOutCreateParams) =>
    post<{ uuid: IIoOut['uuid'] }>('/api/pcweb/system/data/transfer/create', params)
};

/**进销存区分门店和总部 */
export enum DailyQueryFilterWrhTypeEnum {
  Headquarters = 0,
  Store = 1
}

/** 注：业务参数设为 非必填 */
export type ApiIoOutCreateParams = {
  businessType: IoOutBusinessTypeEnum;
  titles: IioOutTitle['id'][];
  billDetailOutFilter?: { bill?: string };
  /** 采购到货率-单品 */
  purchaseArrivalGoodsQueryFilter?: {
    startDate?: string;
    endDate?: string;
  };
  /** 采购到货率-供应商 */
  purchaseArrivalSupplierQueryFilter?: {
    startDate?: string;
    endDate?: string;
    supplier?: string;
    sortType?: string;
  };
  /**商品档案 */
  goodsFilter?: {
    searchKey?: string;
    brandId?: IMdataBrand['id'];
    give?: boolean;
    goodsIds?: string[];
    sortIdList?: ISort['id'];
    goodsType?: GoodsTypesEnum[];
    spuIds?: string;
    supplier?: ISupplier['id'];
    orderBy?: {
      direction?: string;
      field?: string;
    };
  };
  /**门店商品档案 */
  storeGoodsFilter?: {
    goodsStatus?: GoodsStatusEnum;
    line?: string;
    onlineChannels?: string[];
    searchKey?: string;
    stores?: string[];
  };
  /**供应商档案 */
  supplierFilter?: {
    isAlloc?: boolean;
    isDirect?: boolean;
    isWrh?: boolean;
    key?: string;
    order?: SupplierOrderTypeEnum;
    searchGoods?: string;
    settleType?: SupplierSettleTypeEnum;
    status?: SupplierStatusEnum;
    type?: SupplierTypeEnum;
    orderBy?: {
      direction?: string;
      field?: string;
    };
  };
  /**采购协议 */
  supplierAgreementFilter?: {
    goodsKey?: string;
    id?: string;
    status?: SupplierAgreementEffectStatusEnum;
    supplierKey?: string;
    outBills?: string[];
  };
  /**联营协议 */
  supplierSellunionAgreementFilter?: {
    id?: string;
    supplierInfo?: string;
    status?: SellunionAgreementStatusEnum;
    storeId?: string;
    goodsInfo?: string;
  };
  /**业务库存 */
  businessInvFilter?: {
    goodsInfo?: string;
    isStore?: boolean;
    sortId?: string;
    supplier?: string;
    wrhId?: string;
    orderBy?: {
      direction?: string;
      field?: string;
    };
  };
  /**财务库存 */
  financeInvFilter?: {
    goodsInfo?: string;
    isStore?: boolean;
    sortId?: string;
    supplier?: string;
    wrhId?: string;
    orderBy?: {
      direction?: string;
      field?: string;
    };
  };
  /**总部/门店库存 */
  invQueryFilter?: {
    /**经营方式 */
    businessModel?: SupplierTypeEnum;
    /**商品编码/条码/名称 */
    goodsKeyword?: string;
    /**库存数量最小值 */
    minQty?: number;
    /**库存数量最大值 */
    maxQty?: number;
    /**数值范围 */
    rangeModel?: string;
    /**分类id */
    sortIds?: string[];
    /**首选供应供应商uuid */
    supplierIds?: string[];
    /**仓库uuid */
    wrhs?: string[];
    /**门店uuid */
    storeList?: string[];
  };
  /**批次库存合计*/
  invBatchQueryFilter?: {
    /**经营方式 */
    businessModel?: SupplierTypeEnum;
    /**商品编码/条码/名称 */
    goodsKeyword?: string;
    /**批次 */
    batchId?: string;
    /**批次号 */
    batchNo?: string;
    /**到效期截止 */
    validDateStart?: string;
    /**到效期起始 */
    validDateEnd?: string;
    /**分类id */
    sortIds?: string[];
    /**首选供应供应商uuid */
    supplierIds?: string[];
    /**仓库uuid */
    wrhs?: string[];
    /**门店uuid */
    storeList?: string[];
  };
  /**配送方案 */
  distSchemaFilter?: {
    goodsIds?: string[];
    searchKey?: string;
    uuid?: string;
  };
  /**盘点详情 */
  checkGoodsFilter?: {
    searchKey?: string;
    sortId?: string;
    taskUuid?: string;
    searchType?: CheckTaskSearchTypeEnum[];
  };
  /** 拣货看板 导出  */
  pickingOutboundQueryRecordsFilter?: {
    isLimitError?: boolean;
    isOutStock?: boolean;
    batch?: string;
  };
  /**库存流水 */
  invFlowFilter?: {
    endTime?: string;
    goodsInfo?: string;
    sortId?: string;
    sourceBillClass?: string;
    sourceBillId?: string;
    startTime?: string;
    wrhId?: string;
    isStore?: boolean;
  };
  onlineGoodsFilter?: {
    /** 是否是全平台 */
    allChannel?: boolean;
    /** 平台 */
    onlineChannels?: string[];
    /** 所属分组 */
    onlineGroupId?: string;
    /** 搜索关键词 */
    searchKey?: string;
    /** 搜索类型 */
    searchType?: string;
    /** 商品分类 */
    sortId?: string;
    /** 门店名称或编码 */
    storeIdOrName?: string;
    /** 门店id列表 */
    storeIds?: string[];
  };
  /**品牌管理 */
  brandFilter?: {
    key?: string;
  };
  /**交易流水（按商品） */
  buyGoodsFilter?: {
    cashierId?: string;
    createEndTime?: string;
    createStartTime?: string;
    flowId?: string;
    goodsId?: string;
    payType?: string;
  };
  /**交易流水（付款方式） */
  buyPayTypeFilter?: {
    cashierId?: string;
    createEndTime?: string;
    createStartTime?: string;
    flowId?: string;
    goodsId?: string;
    payType?: string;
  };
  /** 交易流水（按流水）*/
  buyRecordFilter?: {
    cashierId?: string;
    createEndTime?: string;
    createStartTime?: string;
    flowId?: string;
    goodsId?: string;
    payType?: string;
    billClass?: string;
    posId?: string;
  };
  /**联营对账查询 */
  sellUnionAccountFilter?: {
    barcode?: string;
    billClass?: string;
    billId?: string;
    goodsId?: string;
    goodsKeyWord?: string;
    goodsName?: string;
    occurDateBegin?: string;
    occurDateEnd?: string;
    storeId?: string;
    supplier?: string;
  };
  /** 供应商台账 按单据 */
  standingBookCashierQueryFilter?: {
    /** 结算方式 */
    settleType?: string;
    /** 供应商类型 */
    supplierType?: string;
    /** 供应商唯一标识 */
    uuid?: string;
  };
  /** 供应商台账 按明细 */
  standingBookDetailQueryFilter?: {
    /** 开始日期 */
    startTime?: string;
    /** 结束日期 */
    endTime?: string;
    /** 结算方式 */
    settleType?: string;
    /** 供应商类型 */
    supplierType?: string;
    /** 供应商唯一标识 */
    uuid?: string;
    /** 单据类型 */
    billType?: string;
  };
  /* 供应商台账2.0 */
  supplierSbQueryFilter?: {
    /* 单号 */
    billId?: string;
    /* 变动类型 */
    changeType?: string;
    /* 费用明细单据类型 */
    feeBillType?: string;
    /* 记账日期 */
    occurDate?: string;
    /* 预付明细单据类型 */
    prepayBillType?: string;
    /* 期号 */
    settleNo?: string;
    /* 结算方式 */
    settleType?: string;
    /*  */
    shouldPayBillType?: string;
    /* 供应商ids */
    supplierIds?: Array<[]>;
    /* 供应商类型 */
    supplierType?: Array<[]>;
    /* 开始时间 */
    startDate?: string;
    /* 结束时间 */
    endDate?: string;
  };
  /** 手持设备授权码*/
  holderAuthCodeFilter?: {
    code?: string;
    deviceSn?: string;
    onlineStatus?: string;
    status?: string;
    storeId?: string;
  };
  /** 经销对账查询 */
  sellBuyAccountQueryFilter?: {
    accountStatus?: string;
    billClass?: string;
    billId?: string;
    endData?: string;
    goodsKeyWord?: string;
    page?: number;
    pageSize?: number;
    payStatus?: string;
    pid?: string;
    startDate?: string;
    storeId?: string;
    supplier?: string;
    wid?: string;
  };
  /** 客户对账查询 */
  customerAccountQueryFilter?: {
    accountStatus?: string;
    billClass?: string;
    billId?: string;
    customer?: string;
    endData?: string;
    goodsKeyWord?: string;
    receiptStatus?: string;
    startDate?: string;
    storeId?: string;
  };
  /** 调拨报表-按汇总 */
  transferReportWrhQueryFilter?: {
    endDate?: string; // 结束时间
    startDate?: string; // 开始时间
    wrhUuids?: string[]; // 调出门店uuid/调出仓库uuid 调入门店uuid/调入仓库uuid
    senderUuids?: string[]; // 调出门店uuid/调出仓库uuid
    receiverUuids?: string[]; // 调入门店uuid/调入仓库uuid
  };
  /** 调拨报表-按明细 */
  transferReportDetailQueryFilter?: {
    endDate?: string; // 结束时间
    startDate?: string; // 开始时间
    wrhUuids?: string[]; // 调出门店uuid/调出仓库uuid 调入门店uuid/调入仓库uuid
    senderUuids?: string[]; // 调出门店uuid/调出仓库uuid
    receiverUuids?: string[]; // 调入门店uuid/调入仓库uuid
    inBillId?: string; // 调入单号
    outBillId?: string; // 调出单号
    reason?: string; // 调拨原因
    searchKey?: string; // 商品关键字
  };
  /** 礼品卡对账 */
  memberCardPrepayDrptQueryFilter?: {
    /** 开始日期 */
    startDate?: string;
    /** 结束日期 */
    endDate?: string;
    /** 门店uuid */
    store?: string;
    /** 门店uuid集合 */
    stores?: string[];
    /** 使用次数 */
    useCountType?: UseCountTypeEnum;
    /** 卡类型编码/名称 */
    cardType?: string;
  };
  /** 礼品卡统计 */
  memberCardPrePayForFinanceFilter?: {
    /** 开始日期 */
    startDate?: string;
    /** 结束日期 */
    endDate?: string;
    /** 购卡人id/客户id */
    buyer?: string;
    /** 售卡人id */
    saler?: string;
    /** 门店uuid */
    store?: string;
  };
  /**客户台账  */
  customerStandingBookQueryFilter?: {
    billType?: string;
    customerId?: Array<[]>;
    end?: string;
    settleNo?: string;
    start?: string;
  };
  /**仓库拣货查询 */
  pickingReportQueryFilter?: {
    /* 门店线路uuid列表 */
    distLineUuids?: Array<[]>;
    /* 次截止时间 */
    endDate?: string;
    /* 是否加单商品 */
    isAddOrder?: boolean;
    /* 是否缺货 */
    isLack?: boolean;
    /* 分拣员 */
    pickerUuids?: Array<[]>;
    /* 拣货单号 */
    pickingBillId?: string;
    /* 拣货来源 */
    pickingSource?: string;
    /* 拣货方式 */
    pickingType?: string;
    /* 商品编码/条码/名称 */
    searchKey?: string;
    /* 波次开始时间 */
    startDate?: string;
    /* 拣货状态 */
    status?: string;
    /* 门店id列表 */
    storeIds?: Array<[]>;
    /* 供应商uuid列表 */
    supplierUuids?: Array<[]>;
    /*波次单号  */
    waveId?: string;
    /* 仓库id列表 */
    wrhIds?: Array<[]>;
  };
  /**供应商-订单 */
  purchaseOrderQueryFilter?: {
    deadDateEnd?: string;
    deadDateStart?: string;
    demandDateEnd?: string;
    demandDateStart?: string;
    id?: string;
    receiverId?: string;
    supplyOrderStatus?: string;
  };
  /** 采购订单 */
  purchaseOrderQueryFilterForOut?: {
    billClass?: PurchaseOrdBillClassEnum;
    /** 失效日期-开始 */
    deadDateStart?: string;
    /** 失效日期-结束 */
    deadDateEnd?: string;
    /** 要求到货日期-开始 */
    demandDateStart?: string;
    /** 要求到货日期-结束 */
    demandDateEnd?: string;
    /** 开始日期 */
    startDate?: string;
    /** 截止日期 */
    endDate?: string;
    /** 商品关键词 */
    goodsKeyword?: string;
    /** 单号 */
    id?: string;
    /** 是否用于采购进货查询 */
    isForIn?: boolean;
    /** 采购员 */
    purchaser?: string;
    /** 收货组织id */
    receiverId?: string;
    settleNo?: number;
    status?: { id: PurchaseOrderStatusEnum; name: string };
    statusList?: PurchaseOrderStatusEnum[];
    supplier?: string;
    supplierId?: string;
    supplyOrderStatus?: 'unsure' | 'sure' | 'finished';
    uuids: string[];
  };
  /**盘点库存明细查询 */
  checkInDetailQueryExportFilter?: {
    bill?: string;
    isGain?: boolean;
    keyword?: string;
    needRecheck?: boolean;
    orderBy?: {
      direction: string;
      field: string;
    };
    sortId?: string;
    status?: CheckInStatusEnum;
    isChecked?: boolean; //是否已审核
    maxDiffQty?: number; //最大盈亏数量
    maxDiffTotal?: number; //最大盈亏金额
    minDiffQty?: number; //最小盈亏数量
    minDiffTotal?: number; //最小盈亏金额
    sortIds?: string[];
  };
  /**供应商端库存数据导出 */
  storeGoodsInvQueryFilter?: {
    wrhNameId?: string;
    keyWord?: string;
  };
  /**供应商端销售数据导出 */
  goodsSaleFilter?: {
    endDate?: string;
    keyWord?: string;
    startDate?: string;
    storeIdName?: string;
  };
  /** 门店订货参考导出*/
  storeOrderQueryFilter?: {
    createEndTime?: string;
    createStartTime?: string;
    searchKey?: string;
    sortId?: string;
    store?: string;
    supplier?: string;
  };
  /**盘点流水 */
  checkFlowDetailQueryFilter?: {
    billUuid: string;
  };
  /**总部-热销品 */
  headMemberConsumeGoodsQueryFilter?: {
    beginDate?: string;
    endDate?: string;
    storeSearchKey?: string;
  };
  /**总部-会员消费汇总 */
  headMemberConsumeSummaryQueryFilter?: {
    beginDate?: string;
    channel?: string;
    endDate?: string;
    memberSearchKey?: string;
    storeSearchKey?: string;
  };
  /**总部-门店储值汇总 */
  headMemberStoredSummaryQueryFilter?: {
    beginDate?: string;
    endDate?: string;
    storeSearchKey?: string;
  };
  /**总部-储值资金流向查询 */
  storedFlowDirectionFilter?: {
    startTime?: string;
    endTime?: string;
    storeId?: string;
    homeStoreId?: string;
    heads: IMemberMoneyStore[];
  };
  /** 总部-储值交易查询*/
  headMemberStoredTransactionQueryFilter?: {
    action?: string;
    beginDate?: string;
    cashierName?: string;
    channel?: string;
    endDate?: string;
    memberSearchKey?: string;
    posId?: string;
    storeSearchKey?: string;
  };
  /**总部-处置汇总 */
  memberStoredSummaryQueryFilter?: {
    beginDate?: string;
    endDate?: string;
  };
  /**导购业绩明细 */
  guiderReportDetailQueryFilter?: {
    endTime?: string;
    guider?: string;
    startTime?: string;
    storeId?: string;
  };
  /**导购业绩汇总 */
  guiderReportQueryFilter?: {
    endTime?: string;
    guider?: string;
    startTime?: string;
    storeId?: string;
  };
  /**门店-会员热销品 */
  storeMemberConsumeGoodsQueryFilter?: {
    beginDate?: string;
    endDate?: string;
    storeId?: string;
  };
  /**门店- 会员消费汇总 */
  storeMemberConsumeSummaryQueryFilter?: {
    beginDate?: string;
    channel?: string;
    endDate?: string;
    memberSearchKey?: string;
    storeId?: string;
  };
  /**门店-门店储值汇总 */
  storeMemberStoredSummaryQueryFilter?: {
    beginDate?: string;
    endDate?: string;
    storeId?: string;
  };
  /**门店-门店会员储值交易查询 */
  storeMemberStoredTransactionQueryFilter?: {
    action?: string;
    beginDate?: string;
    cashierName?: string;
    channel?: string;
    endDate?: string;
    memberSearchKey?: string;
    posId?: string;
    storeId?: string;
  };
  /**门店配货查询 */
  outDetailQueryForHeadFilter?: {
    distType?: DistTypeEnum[];
    endDate?: string;
    page: number;
    pageSize: number;
    startDate?: string;
    stores?: string[];
  };
  /**会员卡导出 */
  entityCardQueryFilter?: {
    batchNo?: string;
    cardInfo?: string;
    createEndTime?: string;
    createStartTime?: string;
    saleEndTime?: string;
    saleStartTime?: string;
    salerId?: string;
    status?: EntityCardStatusEnum;
    storeId?: string;
  };
  /**门店商品库 */
  storeGoodsForStoreFilter?: {
    cabinetId?: string;
    endDate?: string;
    goodsStatus?: string;
    line?: string;
    onlineChannels?: string[];
    searchKey?: string;
    sortId?: string;
    startDate?: string;
    stores?: string[];
    supplier?: string;
    supplierType?: string;
  };
  /** 总部-会员注册汇总 */
  headMemberRegisterQueryFilter?: {
    beginDate?: string;
    channel?: string;
    endTime?: string;
    storeSearchKey?: string;
    templateCardName?: string;
  };
  /** 总部-会员发卡汇总 */
  headMemberCardBindQueryFilter?: {
    beginDate?: string;
    endDate?: string;
    cashierSearchKey?: string;
    storeSearchKey?: string;
    templateCardName?: string;
  };
  /** 门店-会员注册汇总 */
  storeMemberRegisterQueryFilter?: {
    beginDate?: string;
    endDate?: string;
    channel?: string;
    templateCardName?: string;
    storeId?: string;
  };
  /** 门店-会员发卡汇总 */
  storeMemberCardBindQueryFilter?: {
    beginDate?: string;
    endDate?: string;
    cashierSearchKey?: string;
    templateCardName?: string;
    storeId?: string;
  };
  /** 总部财务-供应商-经销处账 */
  supplierSellbuySettleFilter?: {
    endCreateTime?: string;
    id?: string;
    isSupplierConfirm?: boolean;
    payStatus?: SellbuySettlePayStatusEnum;
    settleNo?: string;
    startCreateTime?: string;
    status?: SellbuySettleStatusEnum;
    statusList?: SellbuySettleStatusEnum[];
    supplierInfo?: string;
  };
  /** 总部财务-供应商-联营结算 */
  supplierSellunionSettleFilter?: {
    createdBegin?: string;
    createdEnd?: string;
    goodsKeyWord?: string;
    id?: string;
    occurDateBegin?: string;
    occurDateEnd?: string;
    status?: SupplierSellUnionSettleStatusEnum;
    statusList?: SupplierSellUnionSettleStatusEnum[];
    storeKeyWord?: string;
    supplierId?: string;
    supplierKeyWord?: string;
  };
  /** 总部财务-供应商-批发结算 */
  wholesaleSettleFilter?: {
    createEnd?: string;
    createStart?: string;
    customerKeyword?: string;
    idLike?: string;
    isConfirm?: boolean;
    isInvoice?: boolean;
    receiptStatus?: ReceiptedStatusEnum;
    settleNo?: number;
    status?: WholesaleSettleStatusEnum;
    statusList?: WholesaleSettleStatusEnum[];
  };
  /** 总部财务-供应商-收款单 */
  receiptFilter?: {
    billId?: string;
    billToId?: string;
    endDate?: string;
    receiptDate?: string;
    settleNo?: string;
    startDate?: string;
    status?: ReceiptStatusEnum[];
  };
  /** 总部财务-供应商-预付款 */
  supplierPrepayFilter?: {
    endCreateTime?: string;
    endPayDate?: string;
    id?: string;
    startCreateTime?: string;
    startPayDate?: string;
    status?: SupplierPrepayStatusEnum;
    statusList?: SupplierPrepayStatusEnum[];
    supplierInfo?: string;
  };
  /** 总部财务-供应商-付款单 */
  payFilter?: {
    billToKeyword?: string;
    endDate?: string;
    idKeyword?: string;
    payDate?: string;
    settleNo?: string;
    startDate?: string;
    status?: PayStatusEnum;
    statusList?: PayStatusEnum[];
  };
  /**营业款对账-按门店 */
  businessFundsByStoreQueryFilter?: {
    isDiff?: boolean;
    startDate?: string;
    endDate?: string;
    storeId?: string;
  };
  /**营业款对账-按门店 */
  businessFundsByCashierQueryFilter?: {
    cashierId?: boolean;
    currencyId?: string;
    isDiff?: boolean;
    startDate?: string;
    endDate?: string;
    storeId?: string;
  };
  /**门店（总部）进销存日报 */
  dailyQueryFilter?: {
    createEndTime?: string;
    createStartTime?: string;
    searchKey?: string;
    sortId?: string;
    storeId?: string;
    wrhType?: DailyQueryFilterWrhTypeEnum; //1门店 0总部
    isStore?: DailyQueryFilterWrhTypeEnum; // 这里修改字段
  };
  /**门店（总部）进销存月报 */
  monthQueryFilter?: {
    searchKey?: string;
    settleNo?: string;
    sortId?: string;
    wrhType?: DailyQueryFilterWrhTypeEnum; //1门店 0总部
    isStore?: DailyQueryFilterWrhTypeEnum; // 这里修改字段
  };
  /**门店（总部）进销存汇总 */
  summaryQueryFilter?: {
    createEndTime?: string;
    createStartTime?: string;
    searchKey?: string;
    sortId?: string;
    summary?: boolean;
    wrhType?: DailyQueryFilterWrhTypeEnum; //1门店 0总部
    isStore?: DailyQueryFilterWrhTypeEnum; // 这里修改字段
  };
  /**采购订单监控*/
  purchaseOrderMonitorQueryFilter?: {
    billId?: string;
    endDate?: string;
    goodsKey?: string;
    munit?: string;
    purchaser?: string;
    startDate?: string;
    supplier?: string;
    deadStartDate?: string;
    deadEndDate?: string;
  };
  /**商品信息查询-supplier */
  goodsInfoQueryFilter?: {
    endOperateDate?: string;
    isStoreIn?: boolean;
    moreCode?: boolean;
    moreQpcStr?: boolean;
    moreSupplier?: boolean;
    pluSearchKey?: string;
    searchKey?: string;
    sonSearchKey?: string;
    sortId?: string;
    startOperateDate?: string;
    state?: string;
    storeId?: string;
    supplier?: string;
  };
  /**总部端-不动销商品信息查询 */
  noSaleQueryFilter?: {
    /* 经营方式 */
    businessModel?: string;
    /* 查询天数 */
    days?: string;
    /*商品查询关键词  */
    keyword?: string;
    /* 最大库存 */
    maxQty?: number;
    /* 最小库存 */
    minQty?: number;
    /*分类编码（可以是大中小类  */
    sortId?: string;
    /* 门店编码 */
    storeId?: string;
  };
  /** 门店端-不动销商品信息查询  */
  storeAbnormalNoSaleGoods?: {
    /* 经营方式 */
    businessModel?: string;
    /* 查询天数 */
    days?: string;
    /*商品查询关键词  */
    keyword?: string;
    /* 最大库存 */
    maxQty?: number;
    /* 最小库存 */
    minQty?: number;
    /*分类编码（可以是大中小类  */
    sortId?: string;
    /* 门店编码 */
    storeId?: string;
  };
  /* 促销商品统计 */
  promoteSkuFavourQueryFilter?: {
    /* 日期开始 */
    startTime?: string;
    /* 日期结束 */
    endTime?: string;
    /* 流水号 */
    flowId?: string;
    /* 促销类型集合 */
    promoteClasses?: Array<[]>;
    /* 分类编码集合 */
    sortIds?: Array<[]>;
    /* 门店标识集合 */
    stores?: Array<[]>;
  };

  /**异常信息查询-supplierId */
  goodsAdapterQueryFilter?: {
    endDate?: string;
    searchKey?: string;
    sortId?: string;
    startDate?: string;
    storeId?: string;
    supplierId?: string;
  };
  /**总部端-门店调价报表 */
  goodsPriceAdjustRecordQueryFilter?: {
    startDate?: string; // 开始日期
    endDate?: string; // 结束日期
    storeIdList?: string[]; // 门店
    deptIdList?: string[]; // 部门
    sortIdList?: string[]; // 分类
    adjustPriceSource?: string; // 变价类型
    searchKey?: string; // 商品名称，商品编码/条码/PLU码
  };
  /**门店端-门店调价查询-今日调价 */
  priceAdjustLaunchGoodsQueryFilter?: {
    endDate?: string;
    searchKey?: string;
    sortId?: string;
    startDate?: string;
  };
  /** 商品兑换汇总查询 */
  pointGoodsQueryFilter?: {
    end?: string;
    isStore: boolean;
    searchKey?: string;
    start: string;
    storeId?: string;
  };
  /** 采购订单参考 */
  purchaseOrderReferenceByGoodsFilter?: IOrderQueryReferenceFilter;
  replenishSchemaGoodsQueryFilter?: {
    effectiveStatus?: string;
    goodsSearchKey?: string;
    orderBy?: {
      direction: string;
      field: string;
      upper: any;
      upperPurely: any;
    };
    page: number;
    pageSize: number;
    replenish: string;
    sortIds: string[];
    status?: string;
  };
  /**营业收入 */
  businessSaleByDayFilter?: {
    endDate?: string;
    currencyId?: string;
    startDate?: string;
  };
  /**大类销售 */
  businessSaleBySortFilter?: {
    endDate?: string;
    sortId?: string;
    startDate?: string;
    sortClass?: string;
  };
  /** 线上商品分组 */
  onlineGroupQueryFilter?: {
    allChannel?: boolean;
    onlineChannels?: string;
    onlineGroupId?: string;
    searchKey?: string;
    searchType?: string;
    sortId?: string;
    storeIdOrName?: string;
    storeIds?: string[];
  };
  /**负毛利商品导出 */
  goodsNegativeProfitInfoQueryFilter?: {
    endDate?: string;
    searchKey?: string;
    sortId?: string;
    startDate?: string;
    storeId?: string;
    supplier?: string;
  };
  /** 员工导出 */
  userQueryFilter?: {
    dataPermissionUuid?: string;
    organizationUuid?: string;
    rolePermissionUuid?: string;
    userInfo?: string;
  };
  /**预付款报表，汇总导出 */
  prepayQueryReportFilter?: {
    endDate?: string;
    organizationId?: string;
    prepayId?: string;
    purchaseOrderId?: string;
    startDate?: string;
    storeId?: string;
    supplierId?: string;
  };
  /**预付款报表，明细导出 */
  prepayQueryDetailReportFilter?: {
    endDate?: string;
    organizationId?: string;
    prepayId?: string;
    purchaseOrderId?: string;
    startDate?: string;
    storeId?: string;
    supplierId?: string;
  };
  /**进货报表 */
  purchaseReportQueryFilter?: {
    department?: string;
    distType?: string;
    endDate?: string;
    receiveBillId?: string;
    refundBillId?: string;
    settleType?: string;
    sortId?: string;
    startDate?: string;
    stores?: string[];
    suppliers?: string[];
    taxRates?: string[];
  };
  /** 按售卡流水对账查询-筛选条件 */
  saleCardFlowViewQueryFilter?: {
    billClass?: string;
    businessTimeStart?: string;
    businessTimeEnd?: string;
    cashierId?: string;
    endTime?: string;
    flowId?: string;
    payType?: string;
    posId?: string;
    startTime?: string;
  };
  /** 按充值流水对账查询-筛选条件 */
  rechargeFlowViewQueryFilter?: {
    billClass?: string;
    businessTimeStart?: string;
    businessTimeEnd?: string;
    cashierId?: string;
    endTime?: string;
    flowId?: string;
    payType?: string;
    posId?: string;
    startTime?: string;
  };
  /** 按流水对账查询-筛选条件 */
  businessFundsByFlowQueryFilter?: {
    billClass?: string;
    businessTimeStart?: string;
    businessTimeEnd?: string;
    cashierId?: string;
    endTime?: string;
    flowId?: string;
    payType?: string;
    posId?: string;
    startTime?: string;
  };
  /** 按收款流水-筛选条件 */
  revenueFlowQueryFilter?: {
    startTime?: string;
    endTime?: string;
    storeId?: string;
    posId?: string;
    cashierId?: string;
    orderId?: string;
    flowId?: string;
    channel?: string;
    billClass?: string;
    payType?: string;
    tradeId?: string;
    channelTrxNo?: string;
  };
  /**直配收货 */
  directOutFilter?: {
    billId: string;
  };
  saleAccountQueryFilter?: {
    endDate?: string;
    sortIdList?: string[];
    startDate?: string;
    storeIdList?: string[];
    supplierType?: string;
    taxRateList?: string[];
  };
  /**损耗报表*/
  lossAccountQueryFilter?: {
    endDate: string; //结束日期
    sortIdList: string[]; //分类
    startDate: string; //开始日期
    storeIdList: string[]; //门店
  };
  /* 损溢报表 */
  lossGainReportQueryFilter?: {
    startDate?: string;
    endDate?: string;
    billId?: string;
    /* 业务类型 */
    type?: string;
    /* 组织 */
    orgs?: string[];
    /* 原因 */
    reason?: string;
    /* 商品编码/条码/名称 */
    searchKey?: string;
    /* 主供应商 */
    suppliers?: string[];
  };
  /**盘点盈亏明细 */
  checkInReportQueryFilter?: {
    checkInBillId?: string;
    checkTaskBillId?: string;
    endTime?: string;
    sortIds?: string[]; //分类
    startTime?: string;
    storeIds?: string[];
    supplierUuids?: string[];
  };
  /**盘点盈亏汇总 */
  checkInReportWrhQueryFilter?: {
    endDate?: string; // 结束时间
    startDate?: string; // 开始时间
    wrhUuids?: string[]; // 门店uuid/仓库uuid
  };
  /**按门店 */
  checkInReportDetailQueryFilter?: {
    endDate?: string; // 结束时间
    startDate?: string; // 开始时间
    wrhUuids?: string[]; // 门店uuid/仓库uuid
    billIdLike?: string; // 盈亏单号
    supplierUuids?: []; // 供应商uuids
    searchKey?: string; // 商品名称/商品编码/商品条码
  };
  /**报损报表 */
  wrhLossReportQueryFilter?: {
    billId?: string;
    endDate?: string;
    goodsInfo?: string;
    isGoodsCount?: boolean;
    reason?: string;
    sortId?: ISort['id'];
    startDate?: string;
    status?: WrhLossStatusEnum;
    statusList?: WrhLossStatusEnum[];
    supplierInfo?: string;
    wrhId?: string;
    wrhIds?: string[];
  };
  /**采购提单 */
  purchaseLadingOutFilter?: {
    keyword?: string;
    sortId?: string;
    storeIdList?: string[];
    supplierIdList?: string[];
  };
  /**门店报损表表 */
  storeLossReportQueryFilter?: {
    billId?: string;
    endDate?: string;
    goodsInfo?: string;
    reason?: string;
    sortId?: ISort['id'];
    startDate?: string;
    status?: WrhLossStatusEnum;
    statusList?: WrhLossStatusEnum[];
    supplierInfo?: string;
    storeId?: string;
    storeIds?: string[];
  };
  // 门店货架位导出
  storeShelvesQueryFilter?: {
    emptyOnly?: boolean;
    goodsKey?: string;
    levelNum?: string;
    passagewayNum?: string;
    positionNum?: string;
    shelvesNum?: string;
  };
  //异常锁定商品
  goodsLockInvFilter?: {
    storeId?: string;
    searchKey?: string;
  };
  /**总部商品-门店订货查询导出-门店 */
  requireQueryOrdersByStoreFilter?: {
    endDate?: string;
    startDate?: string;
    stores?: string[];
  };
  /**总部商品-门店订货查询导出-明细 */
  requireQueryOrdersDetailFilter?: {
    billId?: string;
    endDate?: string;
    startDate?: string;
    stores?: string[];
  };
  /**总部商品-门店订货查询导出-分类 */
  requireQueryOrdersBySortFilter?: {
    endDate?: string;
    startDate?: string;
    sortIds?: string[];
  };
  /**总部商品-门店订货查询导出-商品 */
  requireQueryOrdersByGoodsFilter?: {
    endDate?: string;
    startDate?: string;
    searchKey?: string;
  };
  /** 线上商品迁移*/
  onlineDataPullFilter?: {
    channel: string;
    storeId: string;
  };
  /** 异常商品查询-拣货异常商品 */
  goodsOnlineTaskAbnormalQueryFilter?: {
    endDate?: string;
    reason?: string;
    searchKey?: string;
    sortId?: string;
    startDate?: string;
    storeId?: number;
  };
  /** 总-批发-批发销售查询 */
  wholeSaleReportQueryFilter?: {
    billId?: string;
    billClass?: string;
    customerId?: string;
    endDate?: string;
    goodsKeyWord?: string;
    salesmanInfo?: string;
    sortId?: string;
    startDate?: string;
    storeId?: string;
  };

  /** 员工收银异常汇总*/
  posLogCashierQueryFilter?: {
    cashiers?: string[];
    endTime?: string;
    posIds?: string[];
    startTime?: string;
    stores?: string[];
  };
  /** 门店收银异常汇总*/
  posLogStoreQueryFilter?: {
    endTime?: string;
    startTime?: string;
    stores?: string[];
  };
  /** pos操作日志*/
  posLogQueryFilter?: {
    cashiers?: string[];
    endTime?: string;
    module?: PosLogModuleEnum;
    operation?: PosLogOperationEnum;
    posId?: string[];
    startTime?: string;
    stores?: string[];
  };
  /** 会员兑换流水查询导出 */
  pointQueryFilter?: {
    goodsInfo?: string;
    memberInfo?: string;
    operator?: string;
    orderChannel?: string;
    orderTimeGreaterEq?: string;
    orderTimeLessEq?: string;
    storeId?: string;
  };
  /** 后端操作日志 */
  backstageLogQueryFilter?: {
    endTime?: string;
    startTime?: string;
  };
  /** 会员付费开卡查询 */
  memberCardQueryFilter?: {
    beginDate?: string;
    endDate?: string;
    memberInfo?: string;
    orderBy?: string;
    orderNo?: string;
    paymentMethodId?: string;
    storeId?: string;
  };

  /* 采购计划导出 */
  purchasePlanDetailQueryFilter?: {
    bill: string;
  };
  /* 越库作业导出 */
  crossDockingDetailQueryFilter?: {
    bill: string;
  };
  /** 总-仓库-配货订单详情 导出 */
  wrhAllocOrderDetailFilter?: {
    bill: string;
  };
  /**总部-储值存款变动 */
  depositChangeFilter?: {
    beginDate?: string;
    endDate?: string;
    storeKeyWord?: string;
    channel?: number;
  };
  /** 评论管理 */
  orderCommentQueryFilter?: {
    commentScoreEq?: OnlineOrderCommentScopeEnum;
    commentTimeGreaterEq?: string;
    commentTimeLessEq?: string;
    hasContent?: string;
    platformEq?: ChannelEnum;
    commentTypeIn?: OnlineOrderCommentTypeEnum;
    replied?: string;
    storeIdNameLike?: string;
    orderNumberLike?: string;
  };
  /**线上订单 */
  onlineOrderFilter?: {
    searchKey?: string;
    customer?: string;
    orderTimeGreaterEq?: string;
    orderTimeLessEq?: string;
    platformEq?: string;
    statusList?: string[];
    storeIdEq?: string;
    typeEq?: string;
  };
  /**策略分析导出 */
  priceCalcPlanFormsFilter?: {
    endDate?: string;
    goodsPositioningList?: string[];
    page?: number;
    pageSize?: number;
    priceCalcPlanUuid: string;
    searchKey?: string;
    sortIdList?: string[];
    startDate?: string;
    storeIdList?: string[];
  };
  /**员工拣货统计查询 */
  employeePickStatQueryFilter?: {
    endDate?: string;
    onlineChanel?: string;
    searchKey?: string;
  };
  /**换货商品查询报表导出 */
  goodsExchangeQueryFilter?: {
    endDate?: string;
    onlineChanel?: string;
    orderId?: string;
    searchKey?: string;
    sortId?: string;
    startDate?: string;
    storeId?: string;
  };
  /** 配货毛利查询 */
  distributionMarginQueryFilter?: {
    allocOutType?: string;
    createDateBegin: string;
    createDateEnd: string;
    goodsSearchKey?: string;
    storeIdList?: string[];
    wrhIdList?: string[];
  };
  /** 礼品卡管理 */
  prePayCardQueryFilter?: {
    batchNo?: string;
    beginId?: string;
    beginValidateDate?: string;
    cardTypeId?: string;
    endId?: string;
    endValidateDate?: string;
    id?: string;
    keyWord?: string;
    maximumBalance?: string;
    maximumFaceAmount?: string;
    maximumSaleAmount?: string;
    minimumBalance?: string;
    minimumFaceAmount?: string;
    minimumSaleAmount?: string;
    orderBys?: any;
    saleChannel?: string;
    salerId?: string;
    saleStoreId?: string;
    useCountType: UseCountTypeEnum;
    status: PrePayCardStatusActionTypeEnum;
  };
  /** 门店调拨查询 */
  storeTransferReportQueryFilter?: {
    startTime?: string; // 开始日期
    endTime?: string; // 结束日期
    receiverIdList?: string[]; // 调入门店编码/名称
    senderIdList?: string[]; // 调出门店编码/名称
    billId?: string; // 单据编码
    goodsSearchKey?: string; // 商品名称、编码、条码
  };
  /** 配货毛利查询 */
  billList?: string[];
  /** 进销毛利报表 */
  jxMarginQueryFilter?: {
    startDate?: string /**开始日期 */;
    endDate?: string; // 结束日期
    storeIdList?: string[]; // 门店
    sortType?: string; // 类别维度
    sortIdList?: string[]; // 分类集合
  };
  /** 商品销售查询-按门店 */
  skuSaleStoreQueryFilter?: {
    startTime: string; // 开始日期
    endTime: string; // 结束日期
    stores?: string[]; // 门店
  };
  /** 商品销售查询-按商品 */
  skuSaleQueryFilter?: {
    startTime: string; // 开始日期
    endTime: string; // 结束日期
    stores?: string[]; // 门店
    sortIds?: string[]; // 分类
    status?: string[]; // 商品状态
    supplierKey?: string; // 供应商编码/名称
    searchKey?: string; // 商品名称，商品编码/条码/PLU码
  };
  /** 商品销售查询-按分类 */
  skuSaleQuerySortFilter?: {
    startTime: string; // 开始日期
    endTime: string; // 结束日期
    stores?: string[]; // 门店
    sortClass?: SortDimensionEnum; // 类别维度
    sortIds?: string[]; // 分类
  };
  /** 门店销售日报(商品)-按门店 */
  drptSaleQueryStoreFilter?: {
    startTime: string; // 开始日期
    endTime: string; // 结束日期
    stores?: string[]; // 门店
  };
  /** 门店销售日报(商品)-按分类 */
  drptSaleQuerySortFilter?: {
    startTime: string; // 开始日期
    endTime: string; // 结束日期
    stores?: string[]; // 门店
    sortClass?: SortDimensionEnum; // 类别维度
    sortIds?: string[]; // 分类
    businessModel?: BusinessModelEnum; // 经营方式
  };
  /** 门店销售日报(商品)-按商品 */
  drptSaleQuerySkuFilter?: {
    startTime: string; // 开始日期
    endTime: string; // 结束日期
    stores?: string[]; // 门店
    sortIds?: string[]; // 分类
    statuss?: string[]; // 商品状态
    supplierKey?: string; // 供应商编码/名称
    searchKey?: string; // 商品名称，商品编码/条码/PLU码
    businessModel?: BusinessModelEnum; // 经营方式
  };
  orderQueryFilter?: {
    /**交易类型 */
    billClass?: RecordOrderBillClassEnum;
    endDate?: string;
    /**商品关键字 */
    goodsKeyWord?: string;
    /**支付方式 */
    payType?: string;
    /**收银机编号 */
    posId?: string;
    /**流水号 */
    searchKey?: string;
    startDate?: string;
    storeId?: string;
  };
  storeTransferQueryFilter?: {
    billList?: string[];
    billId?: string;
    searchKey?: string;
  };
  subdiffQueryFilter?: {
    createdEndDate?: string;
    createdStartDate?: string;
    goodsKeyword?: string;
    id?: string;
    org?: string;
    statusList?: string[];
    subdiffEndDate?: string;
    subdiffStartDate?: string;
    supplierUuid?: string;
  };
  subdiffAgreementQueryFilter?: {
    createdEndDate?: string;
    createdStartDate?: string;
    goodsKeyword?: string;
    id?: string;
    orgList?: string[];
    statusList?: string[];
    subdiffEndDate?: string;
    subdiffStartDate?: string;
    supplierUuid?: string;
  };
  /** 门店进销存日报（按门店）","供应链", "进销存报表 */
  dailyByWrhQueryFilter?: {
    createEndTime?: string; //结束时间
    createStartTime?: string; //开始时间
    isStore?: boolean | number; //是否为门店
    isSummary?: boolean; // 是否统计
    wrhIdList?: Array<[]>; //门店或仓库编码
  };
  /** 门店进销存日报（按类别）", "供应链","进销存报表 */
  dailyBySortQueryFilter?: {
    createEndTime?: string; //结束时间
    createStartTime?: string; //开始时间
    sortIdList?: Array<[]>; //类别集合
    sortType?: string; //类别维度
    wrhType?: number; //仓库类型
  };
  /** "门店进销存日报（按单品）", "供应链", "进销存报表" */
  dailyByGoodsQueryFilter?: {
    createEndTime?: string; // 结束时间
    createStartTime?: string; // 开始时间
    sortIdList?: Array<[]>; // 门店列表
    wrhIdList?: Array<[]>; // 分类列表
    isStore?: boolean | number; // 是总部端还是门店
    searchKey?: string; // 商品编码/商品名称/商品条码
  };
  /** "门店进销存月报（按门店）","供应链","进销存报表" */
  monthByWrhQueryFilter?: {
    settleNo?: number;
    isStore: boolean; // 是否为门店
    wrhIdList?: Array<[]>; //门店或仓库编码
  };
  /** "门店进销存月报（按类别）", "供应链", "进销存报表" */
  monthBySortQueryFilter?: {
    isStore?: any;
    settleNo?: number;
    sortIdList?: Array<[]>; // 类别集合
    sortType?: Array<[]>; // 类别维度
    wrhIdList?: Array<[]>; // 门店或仓库编码
    createStartTime?: string;
    createEndTime: string;
  };
  /** "门店进销存月报（按单品）", "供应链", "进销存报表" */
  monthByGoodsQueryFilter?: {
    isStore?: boolean; // 是否为门店
    searchKey?: string; // 商品编码/商品名称/商品条码
    settleNo?: number;
    sortIdList: Array<[]>; // 商品分类Id
    wrhIdList?: Array<[]>; // 门店或仓库编码
  };
  wrhAllotPoolGoodsQueryFilter?: {
    wrhs?: Array<[]>;
    sortIds?: Array<[]>;
    supplierUuids?: Array<[]>;
    searchKey?: string;
    invLack?: boolean;
  };
  drptSaleFinanceQuerySkuFilter?: {
    businessModels?: string;
    endTime?: string;
    noTaxSku?: boolean;
    saleMethod?: string;
    searchKey?: string;
    sortIds?: string[];
    startTime?: string;
    stores?: string[];
    suppliers?: string[];
  };
  drptSaleFinanceQueryStoreFilter?: {
    startTime?: string;
    endTime?: string;
    sortClass?: string;
    sortIds?: Array<[]>;
    saleMethod?: string;
    stores?: Array<[]>;
    businessModels?: Array<[]>;
  };
  drptSaleFinanceQuerySortFilter?: {
    businessModels?: string;
    endTime?: string;
    saleMethod?: string;
    sortClass?: string;
    sortIds?: string[];
    startTime?: string;
    stores?: string[];
  };
  purchaseGoodsQueryFilter?: {
    startDate?: Moment;
    endDate?: Moment;
    searchKey?: string;
    purchaserId?: string;
    supplier?: string;
    receiverIdList?: Array<[]>;
    businessTypeList?: Array<[]>;
    purchaseType?: PurchaseSubjectTypeEnum;
  };
  purchaseGoodsBySortQueryFilter?: {
    startDate?: Moment;
    endDate?: Moment;
    searchKey?: string;
    purchaserId?: string;
    supplier?: string;
    receiverIdList?: Array<[]>;
    businessTypeList?: Array<[]>;
    purchaseType?: PurchaseSubjectTypeEnum;
  };
  /** 礼品卡消费导出 */
  usageRecordFlowQueryFilter?: {
    startDate?: [moment.Moment, moment.Moment];
    idBuyerCardHolderOrderId?: string;
    salerName?: string;
  };
  /** 礼品卡发售导出 */
  usageRecordSaleQueryFilter?: {
    startDate?: [moment.Moment, moment.Moment];
    idBuyerCardHolderOrderId?: string;
    salerName?: string;
  };
  /** 礼品卡发售转储 */
  memberCardPrepayDumpFlowQueryFilter?: {
    /**卡号/购卡人/交易号 */
    idBuyerCardHolderOrderId?: string;
    /**开始时间 */
    beginDate?: Moment;
    /**结束时间 */
    endDate?: Moment;
    /**会员姓名/手机号 */
    memberKeywords?: string;
    /**门店名称 */
    storeName?: string;
    /**操作人id */
    creatorName?: string;
    /**POS机号 */
    posId?: string;
  };
  /** 导出明细 */
  distPoolViewQueryFilter?: {
    searchKey?: string;
    requireId?: string;
    sortIds?: string[];
    storeId?: string;
    orderBy?: {
      direction: string;
      field: string;
    };
  };
  /** 导出汇总 */
  distPoolSumViewQueryFilter?: {
    searchKey?: string;
    orderBy?: {
      direction: string;
      field: string;
    };
  };
  /** 批发订单 */
  wholeSaleOutQueryFilter?: {
    occurDateStart: string;
    occurDateEnd: string;
    createDateStart: string;
    createDateEnd: string;
  };
  /**门店配货查询导出枚举*/
  storeAllocOutQueryFilter?: AllocQueryOutRequestFilter;
  /**门店退货查询导出枚举 */
  storeAllocBackQueryFilter?: AllocQueryOutRequestFilter;
  /**
   * 供应商补差
   */
  supplierSubdiffReportQueryFilter?: {
    dept?: string[];
    goodsKeyword?: string;
    id?: string;
    orgs?: string[];
    occurDateEnd?: string;
    subdiffEndDate?: string;
    subdiffStartDate?: string;
    suppliers?: string[];
  };
  bill?: string;
  checkFlowOutFilter?: {
    taskBill?: string;
    bill?: string;
  };
  /** 批发订单导出二维表格 */
  wholeSaleOrderQueryFilter?: {
    bills?: string[];
  };
  /** 批发订单导出二维表格 */
  validDateInvQueryFilter?: {
    /**经营方式 */
    businessModel?: SupplierTypeEnum;
    /**商品编码/条码/名称 */
    goodsKeyword?: string;
    /**库存数量最小值 */
    minQty?: number;
    /**库存数量最大值 */
    maxQty?: number;
    /**数值范围 */
    rangeModel?: string;
    /**分类id */
    sortIds?: string[];
    /**首选供应供应商uuid */
    supplierIds?: string[];
    /**仓库uuid */
    wrhs?: string[];
    /**门店uuid */
    storeList?: string[];
  };
  /* 仓库商品管理导出 */
  pickingGoodsQueryFilter?: {
    pickingType?: string;
    searchKey?: string;
    shelfIdLike?: string;
    supplier?: string;
    wrh?: string;
  };
  /** 提货卡导出 */
  pickupCardFlowGoodsQueryFilter?: {
    batchNo?: string;
    beginConsumeTime?: string;
    beginId?: string;
    beginValidateDate?: string;
    cardType?: string;
    endConsumeTime?: string;
    endId?: string;
    endValidateDate?: string;
    idOrBuyer?: string;
    maximumSaleAmount?: string;
    minimumSaleAmount?: string;
    saleChannel?: string;
    salerId?: string;
    saleOperatorId?: string;
    status?: string;
  };
  pickupCardSaleFlowQueryFilter?: {
    batchNo?: string;
    beginConsumeTime?: string;
    beginId?: string;
    beginValidateDate?: string;
    cardType?: string;
    endConsumeTime?: string;
    endId?: string;
    endValidateDate?: string;
    idOrBuyer?: string;
    maximumSaleAmount?: string;
    minimumSaleAmount?: string;
    saleChannel?: string;
    salerId?: string;
    saleOperatorId?: string;
    status?: string;
  };
  pickupCardVerificationFlowQueryFilter?: {
    batchNo?: string;
    beginConsumeTime?: string;
    beginId?: string;
    beginValidateDate?: string;
    cardType?: string;
    endConsumeTime?: string;
    endId?: string;
    endValidateDate?: string;
    idOrBuyer?: string;
    maximumSaleAmount?: string;
    minimumSaleAmount?: string;
    saleChannel?: string;
    salerId?: string;
    saleOperatorId?: string;
    status?: string;
  };
  requireGoodsQueryFilter?: any;
};

export default ioOut;
