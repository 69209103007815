import {
  IBusinessTurnovermanageStoreincome,
  IBusinessTurnovermanageStoreincomeDetails,
  IBusinessTurnovermanageStoreincomeBankAccount,
  IBusinessTurnovermanageStoreincomeShouldTotal
} from '@/dataModel/business/turnovermanageStoreincome/interface';
import { StoreIncomeStatusEnum } from '@/dataModel/dict/enum';
import request from '@/utils/request';
const { post } = request;

const TurnovermanageStoreincome = {
  queryBill: (
    params: {
      filter: {
        createdBegin: string;
        createdEnd: string;
        id: string;
        occurDayBegin: string;
        occurDayEnd: string;
        payDayBegin: string;
        payDayEnd: string;
        status: StoreIncomeStatusEnum | '';
      };
    } & IPageParams
  ) =>
    post<IPageData<IBusinessTurnovermanageStoreincome>>(
      '/api/pcweb/business/turnovermanage/storeincome/queryBill',
      params
    ),
  /**
   * 删除
   */
  remove: (params: { uuid: string }) =>
    post<{ traceId: string }>('/api/pcweb/business/turnovermanage/storeincome/remove', params),
  /**
   * 获取单据
   */
  get: (params: { id?: string; uuid?: string }) =>
    post<IBusinessTurnovermanageStoreincome>('/api/pcweb/business/turnovermanage/storeincome/get', params),
  /**
   * 详情列表
   */
  getDetails: (params: { uuid: string }) =>
    post<IBusinessTurnovermanageStoreincomeDetails[]>(
      '/api/pcweb/business/turnovermanage/storeincome/getDetails',
      params
    ),
  /**
   * 提交
   */
  submit: (params: { uuid: string; version: number }) =>
    post<string>('/api/pcweb/business/turnovermanage/storeincome/submit', params),
  /**
   * 获取最近使用的银行卡
   */
  getLastBankAccount: () =>
    post<null | IBusinessTurnovermanageStoreincomeBankAccount>(
      '/api/pcweb/business/turnovermanage/storeincome/getLastBankAccount'
    ),
  /**
   * 获取应缴金额列表(新增)
   */
  getShouldTotalList: (params: { occurDay: string }) =>
    post<IBusinessTurnovermanageStoreincomeShouldTotal[]>(
      '/api/pcweb/business/turnovermanage/storeincome/getShouldTotalList',
      params
    ),
  /**
   * 保存
   */
  save: (params: {
    details: { amountPay: number; currencyId: string }[];
    info: { bankAccountUuid: string; occurDay: string; payDay: string; remark: string };
    targetStatus: StoreIncomeStatusEnum;
    uuid?: string;
    version?: number;
  }) =>
    post<{ traceId: string; uuid: string; version: number }>(
      '/api/pcweb/business/turnovermanage/storeincome/save',
      params
    )
};

export default TurnovermanageStoreincome;
