import { IOMallConfigResult, ITransportFee } from '@/dataModel/oMall/config/interface';
import request from '@/utils/request';

const config = {
  /** 获取小程序相关设置 */
  getConfig: () => request.post<IOMallConfigResult>('/api/pcweb/omall/config/getConfig'),
  /** 获取运费列表 */
  getTransportFeeList: () => request.post<ITransportFee[]>('/api/pcweb/omall/config/getTransportFeeList'),
  /** 保存小程序相关设置 */
  saveConfig: (params: Omit<IOMallConfigResult, 'createInfo' | 'lastModifyInfo' | 'uuid' | 'version' | 'tenantId'>) =>
    request.post('/api/pcweb/omall/config/saveConfig', params)
};
export default config;
