import request from '@/utils/request';
import {
  IPickStoreList,
  IPickingProgressSum,
  IPickingOutboundOutResult,
  IPickingOutboundList
} from '@/dataModel/wrh/pickOrderBoard/interface';

const { post } = request;
const pickOrderBoard = {
  //  查询门店进度看板汇总列表
  querySumList: (
    parmas: {
      filter?: {
        /* 波次Id */
        waveId?: string;
        /* 门店Id */
        storeId?: string;
        /* 门店线路编码 */
        distLineId?: string;
        wrh?: string;
      };
    } & IPageParams
  ) => post<IPageData<IPickStoreList>>('/api/pcweb/picking/dashboard/queryStoreSum', parmas),

  // 查询进度看板汇总
  dashboardSum: (params: {
    /* 波次Id */
    waveId?: string;
    /* 门店Id */
    storeId?: string;
  }) => post<IPickingProgressSum>('/api/pcweb/picking/dashboard/getSum', params),

  // 出库结果查询
  getOutBoundResult: () =>
    // param: {
    //   /* 出库方式（all-全部出库、distLine-按门店线路、store-按门店） */
    //   outboundType?: string;
    //   /* 门店线路标识集合,按门店线路出库时不允许为空 */
    //   distLines?: Array<[]>;
    //   /* 门店标识集合允许按门店出库时不允许为空 */
    //   stores?: Array<[]>;
    //   /* 门店ID */
    //   storeId?: string;
    // }
    post<IPickingOutboundOutResult>('/api/pcweb/picking/outbound/get'),

  /* 一键出库 */
  outBound: (params: {
    /* 出库方式（all-全部出库、distLine-按门店线路、store-按门店） */
    outboundType?: string;
    /* 门店线路标识集合,按门店线路出库时不允许为空 */
    distLines?: Array<[]>;
    /* 门店标识集合允许按门店出库时不允许为空 */
    stores?: Array<[]>;
    /* 门店ID */
    storeId?: string;
  }) => post('/api/pcweb/picking/outbound/out', params),

  /* 出库记录查询 */
  queryRecordList: (
    params: {
      filter?: Record<string, unknown>;
    } & IPageParams
  ) => post<IPageData<IPickingOutboundOutResult>>('/api/pcweb/picking/outbound/query', params),

  /* 校验结果查询 */
  queryRecords: (
    params: {
      batch?: string;
      filter?: {
        /* 查询条件限制校验不通过的数据， true-校验不通过 */
        isLimitError?: boolean;
        /* 库存超出， true-校验不通过 */
        isOutStock?: boolean;
      };
    } & IPageParams
  ) => post<IPageData<IPickingOutboundList>>('/api/pcweb/picking/outbound/queryRecords', params),
  // 完成出库
  finshOutBound: (param: { uuid?: string; version?: string }) => post('/api/pcweb/picking/outbound/finish', param)
};

export default pickOrderBoard;
