import {
  ISaleSelfGroupSort,
  ISaleSelfPos,
  ISaleSelfPosDetail,
  ISaleSelfSortSGoods,
  ISaleSelfStoreTemplate
} from '@/dataModel/retail/saleSelfPos/interface';
import request from '@/utils/request';

const { post } = request;

const saleSelfPos = {
  /**
   * 总部销售面板展示
   * 总部storeId传null门店不用传
   */
  getOfHead: (params?: { storeIdOrName: string; storeId: string }) =>
    post<ISaleSelfPos[]>('/api/pcweb/retail/sale/selfpos/getOfHead', params, {}, 'pos'),

  /**
   * 新增销售面板
   * 总部storeId传null门店不用传
   */
  add: (params: { name: string; storeId?: string }) =>
    post<any>('/api/pcweb/retail/sale/selfpos/add', params, {}, 'pos'),

  /**
   * 复制销售面板
   * 总部storeId传null门店不用传
   */
  copy: (params: { name: string; uuid: string; storeId?: string }) =>
    post<any>('/api/pcweb/retail/sale/selfpos/copy', params, {}, 'pos'),

  /**
   * 获取模版详情
   */
  getDetail: (params: { uuid: string }) =>
    post<ISaleSelfPosDetail>('/api/pcweb/retail/sale/selfpos/getDetail', params, {}, 'pos'),

  /**
   * 修改门店
   */
  modifyStore: (params: { uuid: string; version: number; stores: string[] }) =>
    post<any>('/api/pcweb/retail/sale/selfpos/modifyStore', params, {}, 'pos'),

  /**
   * 删除销售模版
   */
  remove: (params: { uuid: string }) => post<any>('/api/pcweb/retail/sale/selfpos/remove', params, {}, 'pos'),

  /**
   * 销售面板类目树形结构展示
   */
  getSortOfStore: (params: { selfPosGroup: string }) =>
    post<ISaleSelfGroupSort[]>('/api/pcweb/retail/sale/selfpos/getSortOfStore', params, {}, 'pos'),

  /**
   * 新增类目
   */
  createSort: (params: { name: string; selfPosGroup: string }) =>
    post<any>('/api/pcweb/retail/sale/selfpos/createSort', params, {}, 'pos'),

  /**
   * 编辑类目
   */
  modifySort: (params: { uuid: string; name: string; version: number }) =>
    post<any>('/api/pcweb/retail/sale/selfpos/modifySort', params, {}, 'pos'),

  /**
   * 类目拖曳排序
   */
  dragSort: (params: { uuid: string; targetSeqNo: number }) =>
    post<any>('/api/pcweb/retail/sale/selfpos/dragSort', params, {}, 'pos'),

  /**
   * 分页查询商品列表
   *
   */
  queryGoods: (
    params: {
      filter?: {
        goodsNameOrIdOrBarcode?: string;
        goodsType?: string;
        noBarCode?: boolean;
        sortId?: string;
        status?: string;
      };
    } & IPageParams
  ) => post<IPageData<ISaleSelfSortSGoods>>('/api/pcweb/retail/sale/selfpos/queryGoods', params, {}, 'pos'),

  /**
   * 添加商品
   *
   */
  addGoods: (params: { selfPosGroup: string; selfPosGroupSort: string; spuIds: string[] }) =>
    post<any>('/api/pcweb/retail/sale/selfpos/addGoods', params, {}, 'pos'),

  /**
   * 点击类目获取商品列表
   *
   */
  getGoods: (params: { uuid: string }) =>
    post<ISaleSelfSortSGoods[]>('/api/pcweb/retail/sale/selfpos/getGoods', params, {}, 'pos'),

  /**
   * 商品排序
   */
  goodsRank: (params: { ranks: { seqNo: number; uuid: string }[] }) =>
    post<any>('/api/pcweb/retail/sale/selfpos/goodsRank', params, {}, 'pos'),

  /**
   * 批量删除商品
   */
  batchRemoveGoods: (params: { uuids: string[] }) =>
    post<any>('/api/pcweb/retail/sale/selfpos/batchRemoveGoods', params, {}, 'pos'),

  /**
   * 删除类目
   */
  removeSort: (params: { uuid: string }) => post<any>('/api/pcweb/retail/sale/selfpos/removeSort', params, {}, 'pos'),

  /**
   * 门店销售面板展示
   *
   */
  getOfStore: () => post<ISaleSelfStoreTemplate[]>('/api/pcweb/retail/sale/selfpos/getOfStore', {}, {}, 'pos'),

  /**
   *
      门店添加收银机->收银机列表
   */
  getPos: (params: { uuid: string }) =>
    post<ISaleSelfPos[]>('/api/pcweb/retail/sale/selfpos/getPos', params, {}, 'pos'),

  /**
   *
      添加收银机
   */
  addPos: (params: { uuid: string; posIds: string[] }) =>
    post<any>('/api/pcweb/retail/sale/selfpos/addPos', params, {}, 'pos'),

  /**
   * 编辑销售面板
   */
  modifySaleGroup: (params: { name: string; uuid: string; version: number }) =>
    post<any>('/api/pcweb/retail/sale/selfpos/modify', params, {}, 'pos')
};

export default saleSelfPos;
