import request from '@/utils/request';
const { post } = request;
import { IMunitSetting } from '@/dataModel/system/munit/interface';

export default {
  /**新增 */
  create: (params: { alias?: string; group?: string; line?: number; munit?: string }) =>
    post<IPageData<any>>('/api/pcweb/system/munit/create', params),
  /**查询 */
  query: (params: {
    filter?: {
      munit?: string;
    };
  }) => post<IPageData<IMunitSetting>>('/api/pcweb/system/munit/query', params),
  /**删除 */
  remove: (params: { uuid?: string }) => post<IPageData<any>>('/api/pcweb/system/munit/remove', params)
};
