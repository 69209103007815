import {
  IGetEnableStore,
  IGetSchemaDetail,
  IGetUnconnectedStore,
  IQuery,
  IValidateField
} from '@/dataModel/retail/posSettings/interface';
import request from '@/utils/request';
const { post } = request;

const posSettings = {
  /**
   * 添加门店
   */
  addStore: (params: {
    /**
     * 	添加的门店uuid列表
     */
    storeUuidList: string[];
    /**
     * 	方案uuid
     */
    uuid: string;
    /**
     * 	版本号
     */
    version: string[];
  }) => post('/api/pcweb/retail/pos/settings/addStore', params, {}, 'pos'),
  /**
   * 复制收银机配置
   */
  copySchema: (params: {
    /**
     * 	新配置名称
     */
    name: string;
    /**
     * 	被复制的配置uuid
     */
    uuid: string;
  }) => post('/api/pcweb/retail/pos/settings/copySchema', params, {}, 'pos'),
  /**
   * 创建收银机配置方案
   */
  create: (params: {
    isDataExchange?: boolean;
    name: string;
    roundMode: string;
    memberPriceTag: string;
    memberSwitch: boolean;
    memberText: string;
    nonMemberPriceTag: string;
    nonMemberSwitch: boolean;
    nonMemberText: string;
    printerModel?: string;
    isPhoneLogin: boolean;
    isPhoneLoginSelf: boolean;
    isScanLogin: boolean;
    isScanLoginSelf: boolean;
  }) => post('/api/pcweb/retail/pos/settings/create', params, {}, 'pos'),
  /**
   * 查询配置生效门店列表
   */
  getEnableStore: (params: {
    /**
     * 	配置方案uuid
     */
    uuid: string;
  }) => post<IGetEnableStore[]>('/api/pcweb/retail/pos/settings/getEnableStore', params, {}, 'pos'),
  /**
   * 查询收银机配置细则
   */
  getSchemaDetail: (params: {
    /**
     * 	配置方案uuid
     */
    uuid: string;
  }) => post<IGetSchemaDetail>('/api/pcweb/retail/pos/settings/getSchemaDetail', params, {}, 'pos'),
  /**
   * 查询待选门店列表
   */

  getUnconnectedStore: (
    params: {
      /**
       * 	配置方案uuid
       */
      uuid: string;
      /**
       * 	筛选
       */
      filter?: {
        /**
         * 	门店名称/门店编码
         */
        storeInfo?: string;
      };
    } & IPageParams
  ) => post<IPageData<IGetUnconnectedStore>>('/api/pcweb/retail/pos/settings/getUnconnectedStore', params, {}, 'pos'),
  /**
   * 修改收银机配置方案
   */
  modify: (params: {
    isDataExchange?: boolean;
    /**
     * 	配置名称
     */
    name: string;
    /**
     * 	抹零设置,详见字典
     */
    roundMode: string;
    /**
     * 	配置方案uuid
     */
    uuid: string;
    /**
     * 	版本号
     */
    version: string;
    printerModel?: string;
    isPhoneLogin: boolean;
    isPhoneLoginSelf: boolean;
    isScanLogin: boolean;
    isScanLoginSelf: boolean;
  }) => post('/api/pcweb/retail/pos/settings/modify', params, {}, 'pos'),
  /**
   * 查询收银机配置列表
   */
  query: (params: {
    filter: {
      /**
       * 	生效门店id
       */
      storeId?: string;
    };
  }) => post<IQuery[]>('/api/pcweb/retail/pos/settings/query', params, {}, 'pos'),
  /**
   * 删除收银机配置
   */
  remove: (params: {
    /**
     * 	配置方案uuid
     */
    uuid: string;
  }) => post('/api/pcweb/retail/pos/settings/remove', params, {}, 'pos'),
  /**
   * 主动校验相关字段-是否重复
   */
  validateField: (params: {
    /**
     * 	校验对象，具体看字典枚举
     */
    target: string;
    /**
     * 	校验值
     */
    value: string;
  }) => post<IValidateField>('/api/pcweb/retail/pos/settings/validateField', params, {}, 'pos'),

  /**
   * 获取实时该租户是否开启语音播报功能
   */
  getVoiceSwitch: () => post<boolean>('/api/pcweb/retail/pos/settings/getVoiceSwitch', {}, {}, 'pos')
};

export default posSettings;
