import {
  IPriceTagEdit,
  IPriceTag,
  IPriceTagPrint,
  IPrintByGoodsQueryResult,
  IQueryStoreGoodsByGoods
} from '@/dataModel/business/priceTag/interface';
import { PriceTagStatusEnum, PriceTagTypeEnum, TagTypeEnum } from '@/dataModel/dict/enum';
import { IPrintConfig } from '@/dataModel/equipment/tagPrint/interface';
import { ISpu } from '@/dataModel/mdata/goods/interface';
import request from '@/utils/request';

export default {
  // 分页查询价签模板
  query: (params: {
    filter?: Partial<{ defaults: boolean; status: PriceTagStatusEnum }> & { priceTagType: PriceTagTypeEnum };
  }) => request.post<IPageData<IPriceTag>>('/api/pcweb/business/price/tag/query', params),
  // 新增价签
  create: (params: IPriceTagEdit) => request.post('/api/pcweb/business/price/tag/create', params),
  // 标签修改
  modify: (params: Partial<IPriceTagEdit> & Pick<IPriceTagEdit, 'version'> & { uuid: string }) =>
    request.post('/api/pcweb/business/price/tag/modify', params),
  remove: (uuid: string) => request.post('/api/pcweb/business/price/tag/remove', { uuid }),
  // 查询价签详情
  get: (uuid: string) => request.post<IPriceTag>('/api/pcweb/business/price/tag/get', { uuid }),
  /**
   * 忽略
   */
  ignore: (tagType: TagTypeEnum) =>
    request.post<{ traceId: string }>('/api/pcweb/business/price/tag/ignore', { tagType }),

  // 查询价签卡片
  queryCard: () =>
    request.post<IPageData<{ qty: number; tagType: { id: TagTypeEnum; name: string } }>>(
      '/api/pcweb/business/price/tag/queryCard'
    ),
  // 分页查商品 sku
  querySku: (params: { filter?: Partial<{ goodsIds: string[]; shelfId: string; sortId: string; supplier: string }> }) =>
    request.post<IPageData<ISpu['basic']>>('/api/pcweb/business/price/tag/query/sku', params),
  // 分页查询打印列表
  queryPrint: (params: any) =>
    request.post<IPageData<IPriceTagPrint>>('/api/pcweb/business/price/tag/queryPrint', params),
  // 加入打印列表
  joinPrint: (tagType: TagTypeEnum) => request.post('/api/pcweb/business/price/tag/joinPrint', { tagType }),
  // 添加商品
  addGoods: (params: { uuids: string[]; all?: boolean; supplier?: string; sortId?: string; searchKey?: string }) =>
    request.post('/api/pcweb/business/price/tag/addGoods', params),
  // 移除列表
  removePrint: (uuids: string[]) => request.post('/api/pcweb/business/price/tag/removePrint', { uuids }),
  // 确认打印
  confirmPrint: (params: { items: { uuid: string; qty: number }[] }) =>
    request.post('/api/pcweb/business/price/tag/confirmPrint', params),

  // 根据条件查询商品资料(计量单位)
  munit: (params: { searchKey: string }) => request.post<any[]>('/api/pcweb/business/price/tag/query/munit', params),

  // 计量单位商品加入打印列表
  addGoodsMunit: (params: { munitUuids: string[]; goodsUuids: string[] }) =>
    request.post<any>('/api/pcweb/business/price/tag/addGoodsMunit', params),

  /**保存配置 */
  saveCurrentSettings: (params: {
    isJin?: number;
    marginLeft: number;
    numPreLine: number;
    paddingLevel: number;
    paperDirect: string;
  }) => request.post<any>('/api/pcweb/business/price/tag/saveCurrentSettings', params),

  /**获取配置 */
  getLastPrintSettings: () => request.post<IPrintConfig>('/api/pcweb/business/price/tag/getLastPrintSettings'),
  /** 总部打印-- 获取调价单 */
  getAdjustPriceBill: () => request.post<any>('/api/pcweb/business/price/tag/getAdjustPriceBill'),
  /** 总部打印-- 调价单打印列表 */
  queryPrintByAdjustPriceBill: (
    params: {
      bill: string;
      filter?: any;
    } & IPageParams
  ) => request.post<IPageData<any>>('/api/pcweb/business/price/tag/queryPrintByAdjustPriceBill', params),
  /** 查看商品列表 */
  queryGoodsForAdjustPriceBill: (
    params: {
      filter?: {
        searchKey?: string;
      };
      uuids?: string[];
    } & IPageParams
  ) => request.post<IPageData<any>>('/api/pcweb/business/price/tag/queryGoodsForAdjustPriceBill', params),
  /** 查看商品列表 */
  queryPrintForGoods: (params: Record<string, unknown> & IPageParams) =>
    request.post<IPageData<any>>('/api/pcweb/business/price/tag/queryPrintForGoods', params),
  /**
   * 确认调价单
   */
  submitAdjustPriceBill: (params: { bill: string }) =>
    request.post<IPageData<any>>('/api/pcweb/business/price/tag/submitAdjustPriceBill', params),
  /**
   * 删除调价单
   * @param params
   * @returns
   */
  removeAdjustPriceBill: (params: { bill: string }) =>
    request.post<any>('/api/pcweb/business/price/tag/removeAdjustPriceBill', params),
  tagRemoves: (params: { uuids: string[] }) => request.post<any>('/api/pcweb/business/price/tag/removes', params),
  removeStoreGoodsByGoods: (params: { uuids: string[] }) =>
    request.post<any>('/api/pcweb/business/price/tag/removeStoreGoodsByGoods', params),
  removeStoreByGoods: (params: { store: string }) =>
    request.post<any>('/api/pcweb/business/price/tag/removeStoreByGoods', params),
  removeByGoods: (params: { uuids: string[] }) =>
    request.post<any>('/api/pcweb/business/price/tag/removeByGoods', params),
  getPrintCountByGoods: (params: Record<string, unknown>) =>
    request.post<any>('/api/pcweb/business/price/tag/getPrintCountByGoods', params),
  // queryPrintForGoods: (params: Record<string, unknown>) => request.post<any>('/api/pcweb/business/price/tag/queryPrintForGoods', params),
  addByGoods: (params: { goodsList: string[] }) =>
    request.post<any>('/api/pcweb/business/price/tag/addByGoods', params),
  getAddGoodsTask: (params: { uuid: string }) =>
    request.post<any>('/api/pcweb/business/price/tag/getAddGoodsTask', params),
  /** 查看商品列表 */
  queryPrintByGoods: (
    params: {
      filter?: {
        keyword?: string;
      };
    } & IPageParams
  ) => request.post<IPageData<IPrintByGoodsQueryResult>>('/api/pcweb/business/price/tag/queryPrintByGoods', params),
  /** 查看商品列表 */
  queryStoreGoodsByGoods: (
    params: {
      filter?: {
        keyword?: string;
      };
      store: string;
    } & IPageParams
  ) =>
    request.post<IPageData<IPrintByGoodsQueryResult>>('/api/pcweb/business/price/tag/queryStoreGoodsByGoods', params),
  /** 查看商品打印门店 */
  querySkuInStoreByGoods: (
    params: {
      filter?: {
        storeInfo?: string;
      };
      goods: string;
    } & IPageParams
  ) =>
    request.post<IPageData<IPrintByGoodsQueryResult>>('/api/pcweb/business/price/tag/querySkuInStoreByGoods', params),
  /** 门店列表 */
  getPrintStoresByGoods: (params: Record<string, unknown> & IPageParams) =>
    request.post<IPageData<IQueryStoreGoodsByGoods>>('/api/pcweb/business/price/tag/queryPrintStoresByGoods', params),
  /** 查询添加商品列表 */
  queryAddByGoods: (
    params: {
      defaultSupplier: string;
      keyword: string;
      sortId: string;
      status: string;
      storeModelList: string[];
    } & IPageParams
  ) => request.post<IPageData<any>>('/api/pcweb/business/price/tag/queryAddByGoods', params),
  /* 保存价签打印列表 促销价 */
  savePromotePrice: (params: { promotionPrice: number; uuid: string }) =>
    request.post('/api/pcweb/business/price/tag/modifyPromotionPrice', params)
};
