import request from '@/utils/request';
import {
  IPaymentMethodGetAll,
  IPaymentMethodGetAllStore,
  IPaymentMethodGetAllPays,
  IPaymentMethodQueryStore,
  IPaymentsList,
  IPaymentConfig
} from '@/dataModel/retail/paymentMethod/interface';
import { PaymentSchemaEnum } from '@/dataModel/dict/enum';
const { post } = request;
interface PaymentMethodDtails {
  currencyName: string;
  isCash: boolean;
  isDefault: boolean;
  isOpenBox: boolean;
  remark: string;
  status: string;
}
const paymentMethod = {
  //获取配置方案详情
  get: (params?: { pid: string; storeId: string; uuid: string; wid: string }) =>
    post('/api/pcweb/retail/currency/schema/get', params, {}, 'pos'),
  /**
   * 获取所有配置方案
   */
  getAll: () => post<IPaymentMethodGetAll[]>('/api/pcweb/retail/currency/schema/getAll', {}, {}, 'pos'),
  /**
   * 获得所有付款方式列表
   */
  getAllPays: (params?: { schema: string }) =>
    post<IPaymentMethodGetAllPays[]>('/api/pcweb/retail/currency/schema/getAllPays', params, {}, 'pos'),

  /** 获得门店所有付款方式列表 */
  getAllPaysOfStore: (params?: { storeId: string }) =>
    post<IPaymentMethodGetAllPays[]>('/api/pcweb/retail/currency/schema/getAllPaysOfStore', params, {}, 'pos'),
  /**
   * 新增配置方案
   */
  create: (params?: { name: string; details?: Array<[]> }) =>
    post<any>('/api/pcweb/retail/currency/schema/create', params, {}, 'pos'),
  /**
   * 编辑配置方案
   */
  modify: (params?: { name: string; uuid: string; version: number }) =>
    post<any>('/api/pcweb/retail/currency/schema/modify', params, {}, 'pos'),
  /**
   * 获取所有门店
   */
  getAllStore: (params?: { schema: string }) =>
    post<IPaymentMethodGetAllStore[]>('/api/pcweb/retail/currency/schema/getAllStore', params, {}, 'pos'),
  /**
   * 删除配置方案
   */
  remove: (params?: { uuid: string }) => post<any>('/api/pcweb/retail/currency/schema/remove', params, {}, 'pos'),
  /**
   * 查询门店
   */
  queryStore: (params?: { keyword?: string; schema?: string; storeUuids?: Array<string> } & IPageParams) =>
    post<IPageData<IPaymentMethodQueryStore[]>>('/api/pcweb/retail/currency/schema/queryStore', params, {}, 'pos'),
  /**
   * 添加门店
   */
  addStore: (params?: { schema: string; storeUuids: Array<string> }) =>
    post<any>('/api/pcweb/retail/currency/schema/addStore', params, {}, 'pos'),
  /**
   *  添加付款方式
   */
  addPay: (params?: { currencyName: string; remark: string; schema: string; status: string }) =>
    post<any>('/api/pcweb/retail/currency/schema/addPay', params, {}, 'pos'),
  /**
   * 修改付款方式备注
   */
  modifyPayRemark: (params?: { remark?: string; uuid: string; version: number }) =>
    post<any>('/api/pcweb/retail/currency/schema/modifyPayRemark', params, {}, 'pos'),
  /**
   * 设置付款方式为默认
   */
  setPayDefault: (params?: { uuid: string; version: number }) =>
    post<any>('/api/pcweb/retail/currency/schema/setPayDefault', params, {}, 'pos'),
  /**
   * 修改付款方式状态
   */
  modifyPayStatus: (params?: { status: string; uuid: string; version: number }) =>
    post<any>('/api/pcweb/retail/currency/schema/modifyPayStatus', params, {}, 'pos'),
  /**
   * 编辑付款方式
   */
  modifyPayName: (params?: { name: string; uuid: string; version: number }) =>
    post<any>('/api/pcweb/retail/currency/schema/modifyPayName', params, {}, 'pos'),
  /**
   * 删除付款方式
   */
  removePay: (params?: { uuid: string }) => post<any>('/api/pcweb/retail/currency/schema/removePay', params, {}, 'pos'),
  /**
   * 获取系统初始付款方式列表
   */
  getInitPays: () => post<any>('/api/pcweb/retail/currency/schema/getInitPays', {}, {}, 'pos'),
  /**
   * 新增配置方案
   */
  createNew: (params: { name: string; schema?: string }) =>
    post<any>('/api/pcweb/retail/currency/schema/createNew', params, {}, 'pos'),
  /**
   * 保存
   */
  save: (params: { details: PaymentMethodDtails[]; uuid: string; version: number; name: string }) =>
    post<{ version: number; traceId: string }>('/api/pcweb/retail/currency/schema/save', params, {}, 'pos'),
  /**
   * 根据门店获取配置方案
   */
  getByStoreId: (params: { storeId: string }) =>
    post<IPaymentMethodGetAll>('/api/pcweb/retail/currency/schema/getByStoreId', params, {}, 'pos'),

  /** 获取鼎付通配置支付方式列表 */
  getPaymentsList: () =>
    post<IPaymentsList[]>('/api/pcweb/retail/currency/schema/getPaymentSchemaByTenantId', {}, {}, 'pos'),

  /** 保存鼎付通配置 */
  savePaymentMethods: (params: { payTypeList: PaymentSchemaEnum[] }) =>
    post('/api/pcweb/retail/currency/schema/modifyPaymentSchema', params, {}, 'pos'),
  /** 自定义付款方式新增*/
  configCCreate: (params: { name: string }) => post('/api/pcweb/retail/currency/config/create', params, {}, 'pos'),
  /**自定义付款方式获取全部 */
  configGetAll: (params?: {
    filter?: {
      searchKey?: string;
    };
  }) => post<IPaymentConfig[]>('/api/pcweb/retail/currency/config/getAll', params, {}, 'pos'),
  /**自定义付款方式修改 */
  configModify: (params: { name: string; uuid: string; version: number }) =>
    post('/api/pcweb/retail/currency/config/modify', params, {}, 'pos')
};
export default paymentMethod;
