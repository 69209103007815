import { PrePayCardStoreTypeEnum, PrePayCardTypeStatusEnum, UseCountTypeEnum } from '@/dataModel/dict/enum';
import { IPrePayCardType, IPrePayCardTypeStore, IPrepayUser } from '@/dataModel/member/prePayCardType/interface';
import request from '@/utils/request';
const { post } = request;

const pickCardType = {
  /**
   * 分页查询提货卡类型列表
   */
  queryPickUpCardType: (
    params: {
      filter?: { goods?: string; idOrName?: string; status?: string; store?: string };
    } & IPageParams
  ) => post<IPageData<IPrePayCardType>>('/api/pcweb/member/pickup/type/query', params, {}, 'card'),
  /**
   * 查询预付卡-操作员工
   */
  getPrepayUser: () => post<IPrepayUser[]>('/api/pcweb/member/prepay/getUser', {}, {}, 'card'),
  /**
   * 停用或启用礼品卡
   */
  disableOrEnable: (params: {
    remark?: string;
    status: PrePayCardTypeStatusEnum.Enabled | PrePayCardTypeStatusEnum.Disabled;
    uuid: string;
    version: number;
  }) => post<{ data: string }>('/api/pcweb/member/pickup/type/status', params, {}, 'card'),
  /**
   * 删除礼品卡类型
   */
  remove: (params: { uuid: string }) =>
    post<{ data: string }>('/api/pcweb/member/pickup/type/delete', params, {}, 'card'),
  /**
   * 获取礼品卡类型部分门店列表
   */
  getPrePayCardTypeStores: (params: { uuid: string }) =>
    post<IPrePayCardTypeStore[]>('/api/pcweb/member/pickup/type/getStores', params, {}, 'card'),
  /**
   * 获取提货卡类型详情
   */
  getPickCardTypeDetail: (params: { uuid: string }) =>
    post<IPrePayCardType>('/api/pcweb/member/pickup/type/detail', params, {}, 'card'),
  /**
   * 新增礼品卡类型
   */
  createPrePayCardType: (params: {
    id?: string;
    name: string;
    faceAmount: string;
    saleAmount: string;
    status: 'enabled' | 'disabled';
    storeScope: PrePayCardStoreTypeEnum.All | PrePayCardStoreTypeEnum.Assign;
    stores:
      | {
          storeId: string;
          storeName: string;
          storeUuid: string;
        }[]
      | [];
  }) => post<{ traceId: string; uuid: string }>('/api/pcweb/member/pickup/type/save', params, {}, 'card'),

  modifyPrePayCardType: (params: {
    id: string;
    name: string;
    goods: any[];
    faceAmount: string;
    saleAmount: string;
    storeScope: PrePayCardStoreTypeEnum.All | PrePayCardStoreTypeEnum.Assign;
    stores:
      | {
          storeId: string;
          storeName: string;
          storeUuid: string;
        }[]
      | [];
    uuid: string;
    version: number;
  }) => post<{ traceId: string }>('/api/pcweb/member/pickup/type/save', params, {}, 'card'),
  /**
   * 新增提货卡卡类型生成编码
   */
  autoCodeGenerateByPickCard: () =>
    post<{ id: string; traceId: string }>('/api/pcweb/member/pickup/type/autoCodeGenerate', {}, {}, 'card'),
  queryPickUpGoods: (
    params: {
      filter?: { searchKey?: string };
    } & IPageParams
  ) => post<IPageData<any>>('/api/pcweb/mdata/goods/pickupCard', params)
};
export default pickCardType;
