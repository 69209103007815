import {
  AllocQueryBack,
  AllocQueryOut,
  AllocQueryOutRequestFilter,
  IDistributionQuery,
  IDistributionQueryTotal
} from '@/dataModel/business/distributionQuery/interface';
import { DistTypeEnum } from '@/dataModel/dict/enum';
import request from '@/utils/request';
const { post } = request;

export default {
  /**总部人员查询门店商品配送到店的商品明细 */
  getDetailForHead: (params: AllocQueryOutRequestFilter & IPageParams) =>
    post<IPageData<AllocQueryOut>>('/api/pcweb/business/alloc/query/queryAllocOut', params),
  /**总部人员查询门店商品配送到店的商品明细合计 */
  getDetailForHeadCount: (params: { filter: AllocQueryOutRequestFilter }) =>
    post<IDistributionQueryTotal>('/api/pcweb/business/alloc/query/queryAllocOutSum', params),
  /**
   * 退货
   * /api/pcweb/business/alloc/query/queryAllocBack
   */
  queryAllocBack: (params: AllocQueryOutRequestFilter & IPageParams) =>
    post<IPageData<AllocQueryBack>>('/api/pcweb/business/alloc/query/queryAllocBack', params),
  /**
   * 汇总
   * /api/pcweb/business/alloc/query/queryAllocBackSum
   */
  queryAllocBackSum: (params: { filter: AllocQueryOutRequestFilter }) =>
    post<IDistributionQueryTotal>('/api/pcweb/business/alloc/query/queryAllocBackSum', params)
};
