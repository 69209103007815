import { KeyboardSettingsTypeEnum } from '@/dataModel/dict/enum';
import { IKeyboard } from '@/dataModel/retail/keyboardSettings/interface';
import request from '@/utils/request';
const { post } = request;

const keyboardSettings = {
  /**
   * 新增按键设置
   */
  create: (params: { functionCode: string; keyboardCode: number; type: KeyboardSettingsTypeEnum }) =>
    post('/api/pcweb/retail/keyboard/settings/create', params, {}, 'pos'),
  /**
   * 根据类型查询按键设置
   */
  getByType: (params: { type: KeyboardSettingsTypeEnum }) =>
    post<IKeyboard[]>('/api/pcweb/retail/keyboard/settings/getByType', params, {}, 'pos'),
  /**
   * 重置为默认设置
   */
  reset: (params: { type: KeyboardSettingsTypeEnum }) =>
    post('/api/pcweb/retail/keyboard/settings/reset', params, {}, 'pos'),
  /**
   * 删除单个按键设置
   */
  delete: (params: { uuid: string }) => post('/api/pcweb/retail/keyboard/settings/delete', params, {}, 'pos')
};

export default keyboardSettings;
