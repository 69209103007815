import request from '@/utils/request';
import {
  IBuyCardByBusinessTotal,
  IBuyCardByDetail,
  IBuyCardByDetailTotal,
  IBuyCardByBusiness
} from '@/dataModel/finance/buyCard';

export default {
  /** 按交易 */
  queryBuyCardByBusiness: (
    params: {
      filter?: {
        /** 开始日期 */
        startDate?: string;
        /** 结束日期 */
        endDate?: string;
        /** 购卡人id/客户id */
        buyer?: string;
        /** 售卡人id */
        saler?: string;
        /** 交易号 */
        saleOrderId?: string;
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => request.post<IPageData<IBuyCardByBusiness>>(`/api/pcweb/member/drpt/queryByBuyer`, params, {}, 'card'),

  /** 按交易总计 */
  queryBuyCardByBusinessTotal: (params: {
    filter?: {
      /** 开始日期 */
      startDate?: string;
      /** 结束日期 */
      endDate?: string;
      /** 购卡人id/客户id */
      buyer?: string;
      /** 售卡人id */
      saler?: string;
      /** 交易号 */
      saleOrderId?: string;
    };
    orderBy?: IPageParamsOrderBy;
  }) => request.post<IBuyCardByBusinessTotal>('/api/pcweb/member/drpt/queryByBuyerSum', params, {}, 'card'),

  /** 按明细 */
  queryBuyCardByDetail: (
    params: {
      filter?: {
        /** 开始日期 */
        startDate?: string;
        /** 结束日期 */
        endDate?: string;
        /** 购卡人id/客户id */
        buyer?: string;
        /** 交易号 */
        saleOrderId?: string;
        /** 卡类型 */
        cardType?: string;
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => request.post<IPageData<IBuyCardByDetail>>(`/api/pcweb/member/drpt/queryByBuyerDetail`, params, {}, 'card'),

  /** 按明细总计 */
  queryBuyCardByDetailTotal: (params: {
    filter?: {
      /** 开始日期 */
      startDate?: string;
      /** 结束日期 */
      endDate?: string;
      /** 购卡人id/客户id */
      buyer?: string;
      /** 交易号 */
      saleOrderId?: string;
      /** 卡类型 */
      cardType?: string;
    };
    orderBy?: IPageParamsOrderBy;
  }) => request.post<IBuyCardByDetailTotal>('/api/pcweb/member/drpt/queryByBuyerDetailSum', params, {}, 'card')
};
