import { IqueryStat } from '@/dataModel/retail/queryStat/interface';
import request from '@/utils/request';

export default {
  /**
   * 员工拣货查询统计
   * @param params
   * @returns
   */
  queryByGoods: (
    params: {
      filter?: {
        onlineChanel?: string;
        startDate?: string;
        endDate?: string;
        searchKey?: string;
        storeId?: string;
      };
      isStore: boolean;
    } & IPageParams
  ) => request.post<IPageData<IqueryStat>>('/api/pcweb/finance/employee/picking/queryStat', params)
};
