import { SupplierAgreementQueryBusinessEnum } from '@/dataModel/dict/enum';
import {
  IMdataSupplierAgreementGet,
  IMdataSupplierAgreementQueryItem,
  ISupplierAgreementGoods,
  ISupplierAgreementGoodsForOrder
} from '@/dataModel/mdata/supplierAgreement/interface';
import request from '@/utils/request';

const { post } = request;

const supplierAgreement = {
  /** 添加商品至采购协议 */
  addGoods: (params: {
    details: {
      /** 商品编码 */
      goodsId: string;
      /** 是否可退 */
      isReturn: 0 | 1;
      /** 最小起订量 */
      minOrdQty: number;
      /** 商品单位 */
      munit: string;
      /** 价格 */
      price: number;
      /** 包装规格 */
      qpcStr: string;
      /** 备注 */
      remark?: string;
    }[];
    /** 供应商标识 */
    supplier: string;
  }) => post('/api/pcweb/mdata/supplier/agreement/addGoods', params),
  /**
   * 分页查询供应商协议列表
   */
  query: (
    params: {
      filter?: {
        /**
         * 	商品查询关键字（名称/编号/条码）
         */
        goodsKey?: string;
        /**
         * 协议号
         */
        id?: string;
        /**
         * 协议状态
         */
        status?: string;
        /**
         * 供应商关键字（名称/编码）
         */
        supplierKey?: string;
      };
    } & IPageParams
  ) => post<IPageData<IMdataSupplierAgreementQueryItem>>('/api/pcweb/mdata/supplier/agreement/query', params),

  /**
   * 协议详情头部 部分
   * 参数二传一
   * @param params
   * @returns
   */
  get: (params: {
    /**
     * 协议uuid
     */
    bill?: string;
    /**
     * 供应商uuid
     */
    supplier?: string;
    /**
     * 协议的编码
     */
    id?: string;
  }) => post<IMdataSupplierAgreementGet>('/api/pcweb/mdata/supplier/agreement/get', params),

  /**
   *  分页查询明细
   */
  queryDetail: (
    params: {
      /** 采购协议单标识 */
      bill?: string;
      /** 采购协议编号 */
      id?: string;
      /** 目标门店id（用于门店采购及退货） */
      targetStoreId?: string;
      filter?: {
        /** 查询关键词 */
        key?: string;
        /** 分类id */
        sortId?: string;
        goodsIds?: string[];
      };
      /** 仓库标识 */
      wrh?: string;
    } & IPageParams
  ) => post<IPageData<ISupplierAgreementGoods>>('/api/pcweb/mdata/supplier/agreement/queryDetail', params),
  /**
   *  分页查询明细
   */
  queryDetailsNew: (
    params: {
      /** 采购协议单标识 */
      bill?: string;
      /** 采购协议编号 */
      id?: string;
      /** 目标门店id（用于门店采购及退货） */
      targetStoreId?: string;
      filter?: {
        /** 查询关键词 */
        keyword?: string;
        /** 需要设置信息的明细 */
        needSet?: boolean;
      };
      /** 仓库标识 */
      wrh?: string;
    } & IPageParams
  ) => post<IPageData<ISupplierAgreementGoods>>('/api/pcweb/mdata/supplier/agreement/queryDetailsNew', params),

  /**
   *  分页查询商品
   */
  queryGoods: (
    params: {
      filter?: {
        searchKey: string;
        sortId: string;
      };
      bill: string;
    } & IPageParams
  ) => post<IPageData<ISupplierAgreementGoods>>('/api/pcweb/mdata/supplier/agreement/queryGoods', params),

  /**
   *  创建协议
   */
  create: (params: {
    contactor: string;
    details: {
      goodsId: string;
      isReturn: number;
      minOrdQty: number;
      munit: string;
      price: number;
      qpcStr: string;
      // uuid: string;
    }[];
    endDate: string;
    outerBillId: string;
    remark: string;
    startDate: string;
    supplier: string;
  }) => post<any>('/api/pcweb/mdata/supplier/agreement/create', params),

  /**
   *  创建协议
   */
  modify: (params: {
    uuid?: string;
    contactor: string;
    // details: {
    //   goodsId: string;
    //   isReturn: number;
    //   minOrdQty: number;
    //   munit: string;
    //   price: number;
    //   qpcStr: string;
    //   // uuid: string;
    // }[];
    endDate: string;
    outerBillId: string;
    remark: string;
    startDate: string;
    supplier: string;
    version?: number;
  }) => post<any>('/api/pcweb/mdata/supplier/agreement/saveBill', params),

  /**
   *  获取协议明细 部分也
   */
  getAllDetails: (params: { supplier?: string; bill?: string }) =>
    post<ISupplierAgreementGoods[]>('/api/pcweb/mdata/supplier/agreement/getAllDetails', params),

  /**
   *  终止协议
   */
  terminate: (params: { bill: string; version: number }) =>
    post<any>('/api/pcweb/mdata/supplier/agreement/terminate', params),

  /**
   *分页查询明细-用于采购订单
   * @param params
   * @returns
   */
  queryDetailForOrder: (
    params: {
      supplier?: string;
      businessType?: SupplierAgreementQueryBusinessEnum;
      filter?: { key: string };
      wrh?: string;
    } & IPageParams
  ) =>
    post<IPageData<ISupplierAgreementGoodsForOrder>>('/api/pcweb/mdata/supplier/agreement/queryDetailForOrder', params),

  /**
   * 获取自采供应商商品
   */
  getDetailsForStoreIn: (params: { supplier: string }) =>
    post<{ goodsId: string }[]>('/api/pcweb/mdata/supplier/agreement/getDetailsForStoreIn', params),
  /**
   *  续签
   */
  renewBill: (params: { endDate: string; version: number; uuid: string }) =>
    post<any>('/api/pcweb/mdata/supplier/agreement/renewBill', params),
  /**
   *  单头信息
   */
  getBill: (params: { bill: string; id: string }) => post<any>('/api/pcweb/mdata/supplier/agreement/getBill', params),
  /**
   *  移除商品
   */
  removeDetail: (params: { bill: string; detailUuids: string[] }) =>
    post<any>('/api/pcweb/mdata/supplier/agreement/removeDetail', params),
  /**
   *  
编辑保存明细
   */
  saveDetail: (params: {
    isReturn: boolean;
    detailUuid: string;
    minOrdQty: number;
    price: number;
    skuUuid: string;
    version: number;
  }) => post<any>('/api/pcweb/mdata/supplier/agreement/saveDetail', params),
  /**
   *  
新添加商品
   */
  newAddGoods: (params: { bill: string; skuUuids: string[] }) =>
    post<any>('/api/pcweb/mdata/supplier/agreement/addGoodsNew', params),
  /**
   *  
查询供应商列表
   */
  getSupplierList: (params: Record<string, unknown>) =>
    post<any>('/api/pcweb/mdata/supplier/agreement/getSupplierList', params)
};
export default supplierAgreement;
