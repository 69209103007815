import { IoBatchBusinessTypeEnum, IoTaskInStatusEnum } from '@/dataModel/dict/enum';
import { IIoIn, IIoMatch } from '@/dataModel/system/ioIn/interface';
import request from '@/utils/request';

const { post } = request;

/**
 * 导入任务
 */
const ioIn = {
  /** 新建导入任务 */
  create: (params: {
    businessType: IoBatchBusinessTypeEnum;
    /** 微盟文件地址 */
    sourceUrl: string;
    /* customerId */
    customerId?: string;
  }) =>
    post<{
      uuid: IIoIn['uuid'];
      /* 导入的商品行行数 */
      detailCount: number;
    }>('/api/pcweb/system/io/in/create', params),

  /** 取消任务 */
  cancel: (params: { uuid: IIoIn['uuid'] }) =>
    post<{ version: IIoIn['version'] }>('/api/pcweb/system/io/in/cancel', params),

  /** 分页查任务 */
  query: (
    params?: {
      filter?: {
        businessType?: IoBatchBusinessTypeEnum;
        status?: IoTaskInStatusEnum;
        startDate?: string;
        endDate?: string;
      };
    } & IPageParams
  ) => post<IPageData<IIoIn>>('/api/pcweb/system/io/in/query', params),

  /** 获取任务详情 */
  get: (params: { uuid: IIoIn['uuid'] }) => post<IIoIn>('/api/pcweb/system/io/in/get', params),

  /** 确认导入任务 */
  sureImport: (params: { uuid: IIoIn['uuid'] }) => post<null>('/api/pcweb/system/io/in/sureImport', params),

  /** 获取当前业务正在执行的导入任务 */
  getRunning: (params: { businessType: IoBatchBusinessTypeEnum }) =>
    post<IIoIn>('/api/pcweb/system/io/in/getRunning', params),

  /** 获取字段匹配结果 */
  getMatchTitles: (params: { uuid: string }) => post<IIoMatch>('/api/pcweb/system/io/in/getMatchTitles', params),

  /** 开始导入 */
  startImport: (params: { taskUuid: string; headItems?: { origin: string; target: string }[] }) =>
    post<any>('/api/pcweb/system/io/in/startImport', params)
};

export default ioIn;
