// import { DistributionPlatformTypeEnum } from '@/dataModel/dict/enum';
import { IDistributionPlatform } from '@/dataModel/mdata/DistributionPlatformStore/interface';
import request from '@/utils/request';

const { post } = request;
/** 配送平台枚举 后台不提供枚举 */
export enum DistributionPlatformTypeEnum {
  /** 麦芽田配送 */
  MytSsw = 'myt_ssw',
  /* YOGO机器人 */
  YoGo = 'yogo_robot',
  /* 云迹机器人 */
  Yunji = 'yunji_robot'
}

const distributionPlatformStore = {
  /** 分页获取配送平台门店 */ // 废弃接口 CX-19538
  // query: (
  //   params: {
  //     filter?: {
  //       /**城区 */
  //       addressInfo?: string;
  //       /** 平台 */
  //       platform: DistributionPlatformTypeEnum;
  //       /** 启用状态 */
  //       status?: string;
  //       /** 门店名称/编码 */
  //       storeInfo?: string;
  //     };
  //   } & IPageParams
  // ) =>
  //   post<IPageData<IDistributionPlatform>>('/api/pcweb/mdata/distribution/platform/store/query', {
  //     ...params,
  //     filter: { ...params.filter, platform: DistributionPlatformTypeEnum.Maiyatian }
  //   }),

  /** 启用 */ // 废弃接口 CX-19538
  // enabled: (params: {
  //   /** uuid */
  //   uuidList: string[];
  //   /** 版本号 */
  //   // version: number;
  //   /** 配送平台 */
  //   platform: DistributionPlatformTypeEnum;
  // }) => post('/api/pcweb/mdata/distribution/platform/store/enable', params),

  /** 停用 */ // 废弃接口 CX-19538
  // disabled: (params: {
  //   /** uuid */
  //   uuidList: string[];
  //   /** 版本号 */
  //   // version: number;
  //   /** 配送平台 */
  //   platform: DistributionPlatformTypeEnum;
  // }) => post('/api/pcweb/mdata/distribution/platform/store/disable', params),

  /** 获取配送平台状态 */
  // 响应参数 mytSswEnabled yogoRobotEnabled yunjiRobotEnabled
  getStatus: () =>
    post<{
      createInfo?: any;
      lastModifyInfo?: any;
      maiyatian?: any;
      /* 麦芽田（松鼠屋）状态 */
      mytSswEnabled?: boolean;
      tenantId?: string;
      uuid?: string;
      version?: string;
      /* yogo状态 */
      yogoRobotEnabled?: boolean;
      /* yunji状态 */
      yunjiRobotEnabled?: boolean;
      mytSswShowing?: boolean;
      yogoRobotShowing?: boolean;
      yunjiRobotShowing?: boolean;
    }>('/api/pcweb/mdata/distribution/platform/getDistPlatform'),

  /** 保存配送平台状态 */
  saveStatus: (params: {
    /* 服务供应商 */
    serviceProvider?: string;
    /* 是否启用 */
    enabled?: boolean;
  }) => post('/api/pcweb/mdata/distribution/platform/setDistPlatform', params),

  /** 获取二维码地址 */
  getQRCodeUrl: (params: { uuid: string; platform: DistributionPlatformTypeEnum }) =>
    post<string>('/api/pcweb/mdata/distribution/platform/getStoreSetUrl', params),

  /** 修改配送平台门店名称  */
  editPlatformName: (params: { uuid: string; distPlatformStoreName: string }) =>
    post<string>('/api/pcweb/mdata/distribution/platform/modifyDistPlatformStoreName', params),

  /** 分页获取门店配送平台配置  */
  getPlatformConfig: (
    param: {
      filter?: {
        /**城区 */
        addressInfo?: string;
        /** 平台 去掉*/
        // platform?: string;
        /** 启用状态 */
        status?: string;
        /** 门店名称/编码 */
        storeInfo?: string;
      };
    } & IPageParams
  ) => post<IPageData<IDistributionPlatform>>('/api/pcweb/mdata/distribution/platform/config/query', param),

  /**门店配送平台配置 */
  platformConfig: (param: {
    // 门店uuid
    store?: string;
    // 配送配置
    deliveryConfigList?: [];
  }) => post('/api/pcweb/mdata/distribution/platform/config', param)
};

export default distributionPlatformStore;
