import {
  IHBStoreInBill,
  IHeadStoreIn,
  IHeadStoreInDetailGoods,
  IStoreIn,
  IStoreInGoods,
  IgoodsDetail
} from '@/dataModel/business/storeIn/interface';
import { StoreInSaveTargetStatusEnum, StoreInStatusEnum, StoreInTypeEnum } from '@/dataModel/dict/enum';
import { ISupplier } from '@/dataModel/mdata/supplier/interface';
import { IPurchaseOrder } from '@/dataModel/purchase/order/interface';
import request from '@/utils/request';

const { post } = request;

const storeIn = {
  /**
   * 查详情
   */
  get: (
    params: {
      billId: string;
      filter?: {
        searchKey?: string;
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) =>
    post<
      {
        details: IPageData<
          IStoreInGoods & {
            /**
             * 当前选择的收货单位
             */
            selectedUnit: {
              uuid: string;
              munit: string;
              price: number;
              qpc: number;
              qpcStr: string;
            };
          }
        >;
      } & IStoreIn
    >('/api/pcweb/business/store/in/get', params),
  /**
   * 查列表
   */
  query: (
    params: {
      filter?: {
        billId?: string;
        startTime?: string;
        endTime?: string;
        type?: StoreInTypeEnum;
        name?: string;
        supplier?: string;
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => post<IPageData<IStoreIn>>('/api/pcweb/business/store/in/query', params),

  /**
   * 获取自采商品列表
   */
  getGoods: (
    params: {
      type: StoreInTypeEnum;
      supplier: string;
      billId?: string;
      searchKey?: string;
      worksheet?: boolean;
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) =>
    post<{
      goodss: IPageData<IStoreInGoods>;
    }>('/api/pcweb/business/store/in/getGoods', params),

  /**
   * 创建
   */
  create: (params: {
    remark?: string;
    supplier?: ISupplier['id'];
    type: StoreInTypeEnum;
    goods: {
      goodsId: string;
      munitUuid: string;
      price: number;
      stockQty: number;
      qty: number | string;
      qtyStr: string;
      remark?: string;
    }[];
  }) => post<{ billId: string; version: number }>('/api/pcweb/business/store/in/create', params),

  /**
   * 修改
   */
  modify: (params: {
    billId?: string;
    remark?: string;
    version?: number;
    supplier?: ISupplier['id'];
    type: StoreInTypeEnum;
    goods: {
      goodsId: string;
      munitUuid: string;
      price: number;
      stockQty: number;
      qty: number | string;
      qtyStr: string;
      remark?: string;
    }[];
  }) => post<{ billId: string; version: number }>('/api/pcweb/business/store/in/modify', params),

  /**
   * 提交入库
   */
  saveStore: (params: { version: number; type: StoreInTypeEnum; billId: string; remark?: string }) =>
    post<{ billId: string }>('/api/pcweb/business/store/in/saveStore', params),

  /**
   * 总部分页查询自采进货
   */
  queryForHead: (
    params: {
      filter?: {
        createEndTime?: string;
        createStartTime?: string;
        goodsKeyWord?: string;
        id?: string;
        recvEndTime?: string;
        recvStartTime?: string;
        settleNo?: number | string;
        status?: StoreInStatusEnum;
        store?: string;
        supplierKeyWord?: string;
      };
    } & IPageParams
  ) => post<IPageData<IHeadStoreIn>>('/api/pcweb/business/store/in/queryForHead', params),

  /**
   * 总部分页查询自采进货详情
   */
  queryDetailForHead: (
    params: {
      bill: string;
      filter?: {
        goodsKeyWord?: string;
      };
    } & IPageParams
  ) => post<IPageData<IHeadStoreInDetailGoods>>('/api/pcweb/business/store/in/queryDetailForHead', params),

  /** 分页查询采购订单，用于进货 */
  queryPurchaseOrderForIn: (
    params: {
      filter: { id: string; /* 采购订单号 */ purchaser: string; /* 采购员标识 */ supplierId: string /* 供应商编码 */ };
    } & IPageParams
  ) => post<IPageData<IPurchaseOrder>>('/api/pcweb/business/store/in/queryPurchaseOrderForIn', params),

  /**
   * 获取单据
   */
  getForHead: (params: { uuid: string }) => post<IHeadStoreIn>('/api/pcweb/business/store/in/getForHead', params),

  /**
   * 总部作废自采进货单
   */
  invalid: (params: { uuid: string; version: number }) => post<any>('/api/pcweb/business/store/in/invalid', params),

  /**
   * 保存进货单（自采、仓配、直配、直送）
   */
  save: (params: {
    billId?: string;
    goods?: {
      goodsId: string;
      munit: string;
      qpcStr: string;
      price: number;
      qty: number;
      qtyStr: string;
      remark: string;
      stockQty: number;
    }[];
    purOrderId?: string;
    receiverId?: string;
    recvTime?: string;
    remark?: string;
    supplier?: string;
    targetStatus: StoreInSaveTargetStatusEnum;
    type?: StoreInTypeEnum;
    version?: number;
  }) => post<any>('/api/pcweb/business/store/in/save', params),

  /**
   * 分页查询（直配或直送）进货单
   */
  queryDirectAlloc: (
    params: {
      filter?: {
        createEnd?: string;
        createStart?: string;
        goodsKeyword?: string;
        idKeyword?: string;
        orderKeyword?: string;
        receiveEnd?: string;
        receiveStart?: string;
        settleNo?: number | string;
        status?: string;
        storeId?: string;
        supplierKeyword?: string;
      };
    } & IPageParams
  ) => post<IPageData<IHBStoreInBill>>('/api/pcweb/business/store/in/queryDirectAlloc', params),

  /**
   * 获取单据(直送、直配)
   */
  getDirectAlloc: (params: { uuid: string }) =>
    post<IHBStoreInBill>('/api/pcweb/business/store/in/getDirectAlloc', params),

  /**
   * 获取所有明细(直送、直配)
   */
  getDirectAllocDetails: (params: { uuid: string }) =>
    post<IHeadStoreInDetailGoods[]>('/api/pcweb/business/store/in/getDirectAllocDetails', params),

  /**
   * 根据导入任务获取商品
   */
  getGoodsByIoTask: (params: { taskUuid: string }) =>
    post<IHeadStoreInDetailGoods[]>('/api/pcweb/business/store/in/getGoodsByIoTask', params),

  /**
   * 批量删除(直送、直配)
   */
  removeDirectAllocBatch: (params: { uuids: string[] }) =>
    post<any>('/api/pcweb/business/store/in/removeDirectAllocBatch', params),

  /**
   * 删除(直送、直配)
   */
  removeDirectAlloc: (params: { uuid: string }) => post<any>('/api/pcweb/business/store/in/removeDirectAlloc', params),

  /**
   * 分页查询明细(直送、直配)
   */
  queryDirectAllocDetails: (
    params: {
      bill: string;
      filter: {
        searchKey: string;
      };
    } & IPageParams
  ) => post<IPageData<IHeadStoreInDetailGoods>>('/api/pcweb/business/store/in/queryDirectAllocDetails', params),

  /* 查询商品最新配货价 */
  getDistPriceByGoods: (params: {
    /* 商品的uuid */
    goods: string;
    price: string | number;
    /* 门店编码 */
    receiverId: string;
    /* 供应商 */
    supplier: string;
  }) => post<IgoodsDetail>('/api/pcweb/dist/schema/getDistPriceByGoods', params),
  /* 适用于总部造门店直配单，返回值为null时，前端无需修改配货价，返回值非空时，直接替换配货价 */
  calculateDistPrice: (params: { inPrice: number; priceFx: string; priceType: string }) =>
    post<IgoodsDetail>('/api/pcweb/dist/schema/calculateDistPrice', params)
};

export default storeIn;
