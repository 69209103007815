import request from '@/utils/request';
import {
  queryDeposiTchange,
  queryDeposiTchangeSummaryTotal
} from '../../../dataModel/member/headDepositChange/interface';
const { post } = request;

const storeMemberStoredSummary = {
  /**总部-门店储值汇总统计 */
  queryDepositChangeSummaryTotal: (params: {
    filter?: {
      beginDate?: string; //开始时间
      endDate?: string; //结束时间
      storeKeyWord?: string; //门店编码/名称
      consumeTimes?: number; // 消费次数
    };
  }) => post<queryDeposiTchangeSummaryTotal>('/api/pcweb/member/info/queryDepositChangesSum', params),

  /**总部-储值存款变动 */
  queryDepositChange: (
    params: {
      filter?: {
        beginDate?: string; //开始时间
        endDate?: string; //结束时间
        storeKeyWord?: string; //门店编码/名称
        consumeTimes?: number; // 消费次数
      };
    } & IPageParams
  ) => post<IPageData<queryDeposiTchange>>('/api/pcweb/member/info/queryDepositChanges', params)
};

export default storeMemberStoredSummary;
