import { IRuleBulkItem } from '@/dataModel/system/ruleBulk/interface';
import request from '@/utils/request';
const { post } = request;

const ruleBulk = {
  /**
   * 分页查询
   */
  query: (params: IPageParams) =>
    post<IPageData<IRuleBulkItem>>('/api/pcweb/system/rule/special/bulk/query', params, {}, 'pos'),
  /**
   * 修改
   */
  modify: (params: {
    amountLength: number;
    barcodeType: string;
    prefix: number;
    weightLength: number;
    memberPriceRule: string;
    retailPriceRule: string;
  }) => post<any>('/api/pcweb/system/rule/special/bulk/modify', params, {}, 'pos'),

  /**
   * 重置
   */
  reset: (params: { barcodeType: string }) => post<any>('/api/pcweb/system/rule/special/bulk/reset', params, {}, 'pos'),

  /**
   * 检测前缀是否可以使用
   */
  check: (params: { barcodeType: string; prefix: string }) =>
    post<any>('/api/pcweb/system/rule/special/bulk/check', params, {}, 'pos')
};
export default ruleBulk;
