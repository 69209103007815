import request from '@/utils/request';
import {
  IWholesaleOut,
  IWholesaleOutDetailGoods,
  IWholesaleOutGoods,
  IWholesaleOutInvoice,
  ReceiptGetResult
} from '@/dataModel/wholesale/out/interface';
import { CustomerBillCheckBillTypeEnum, WholeSaleOutStatusEnum } from '@/dataModel/dict/enum';
const { post } = request;

const wholesaleOut = {
  /** 分页查询批发出货单 */
  query: (
    params: {
      filter?: {
        createDateEnd?: string;
        createDateStart?: string;
        occurDateEnd?: string;
        occurDateStart?: string;
        customerId?: string;
        id?: string;
        salesmanId?: string;
        settleNo?: string;
        status?: WholeSaleOutStatusEnum;
        searchKey?: string;
      };
    } & IPageParams
  ) => post<IPageData<IWholesaleOut>>('/api/pcweb/wholesale/out/query', params),

  /** 分页查询商品列表 */
  queryGoods: (
    params: {
      filter?: {
        searchKey?: string;
        sortId?: string;
      };
      customerId: string;
    } & IPageParams
  ) => post<IPageData<IWholesaleOutGoods>>('/api/pcweb/wholesale/out/queryGoods', params),

  /** 创建批发出货单,保存/保存并提交/保存并发货/保存并审核 */
  create: (params: {
    locationAddress: string;
    occurDate: string;
    outDetails: {
      goodsId: string;
      invQtyPrice: number;
      munitUuid: string;
      planQty: number;
      planQtyStr: string;
      price: number;
      total: number;
    }[];
    receiverId: string;
    receiverName: string;
    remark?: string;
    salesmanId?: string;
    salesmanName?: string;
    settleType: string;
    status: string;
  }) => post<any>('/api/pcweb/wholesale/out/create', params),

  /** 查询出货单详情 */
  queryOut: (params: {
    uuid?: string;
    id?: string;
    completed?: boolean /**单据类型 */;
    billType?: CustomerBillCheckBillTypeEnum;
  }) => post<IWholesaleOut>('/api/pcweb/wholesale/out/queryOut', params),

  /** 分页查询发货单商品明细 */
  queryDetails: (
    params: {
      filter: {
        billId?: string;
        uuid?: string;
        searchKey?: string;
        bill?: string;
        billType?: CustomerBillCheckBillTypeEnum;
      };
    } & IPageParams
  ) => post<IPageData<IWholesaleOutDetailGoods>>('/api/pcweb/wholesale/out/queryDetails', params),

  /**出货单修改保存/保存并提交/保存并审核/保存并发货 */
  modify: (params: {
    id: string;
    locationAddress: string;
    occurDate: string;
    outDetails: {
      goodsId: string;
      invQtyPrice: number;
      munitUuid: string;
      planQty: number;
      planQtyStr: string;
      price: number;
      total: number;
    }[];
    receiverId: string;
    receiverName: string;
    remark?: string;
    salesmanId?: string;
    salesmanName?: string;
    settleType: string;
    status: string;
    version: number;
  }) => post<any>('/api/pcweb/wholesale/out/modify', params),

  /** 出货单提交/审核/收货/作废 */
  modifyStatus: (params: { uuid: string; status: string; version: number }) =>
    post<any>('/api/pcweb/wholesale/out/modifyStatus', params),

  /** 发货; */
  ship: (params: {
    uuid: string;
    details: { goodsId: string; qty: number; qtyStr: string; uuid: string; total: number; version: number }[];
    version: number;
  }) => post<any>('/api/pcweb/wholesale/out/ship', params),

  /** 查询发票; */
  queryInvoice: (params: { billId: string }) =>
    post<IWholesaleOutInvoice[]>('/api/pcweb/wholesale/out/queryInvoice', params),

  /** 新增发票; */
  createInvoice: (params: {
    bill: string;
    invoiceCode: string;
    invoiceNo: string;
    invoiceType: string;
    makeDate: string;
    taxRate: number;
    total: number;
  }) => post<any>('/api/pcweb/wholesale/out/createInvoice', params),

  /**删除出货单 */
  remove: (params: { uuid: string }) => post<any>('/api/pcweb/wholesale/out/remove', params),

  /**批量发货/收货/审核 */
  batchModifyStatus: (params: { status: string; filters: { uuid: string; version: number }[] }) =>
    post<any>('/api/pcweb/wholesale/out/batchModifyStatus', params),

  /**移除发票 */
  removeInvoice: (params: { uuid: string }) => post<any>('/api/pcweb/wholesale/out/removeInvoice', params),

  /**根据导入任务获取商品 */
  getGoodsByIoTask: (params: { taskUuid: string }) =>
    post<IWholesaleOutDetailGoods[]>('/api/pcweb/wholesale/out/getGoodsByIoTask', params),
  getReceipts: (params: { uuid: string }) => {
    return post<ReceiptGetResult[]>('/api/pcweb/wholesale/out/getReceipts', params);
  }
};

export default wholesaleOut;
