import { ReceiptedStatusEnum, SellbuySettleInvoiceTypeEnum, WholesaleSettleStatusEnum } from '@/dataModel/dict/enum';
import {
  IFinanceWholesaleSettle,
  IFinanceWholesaleSettleDetailWholesale,
  IFinanceWholesaleSettleInvoice,
  IFinanceWholesaleSettleReceipt
} from '@/dataModel/finance/wholesaleSettle/interface';
import request from '@/utils/request';

const { post } = request;

export default {
  /** 登记发票 */
  addInvoice: (params: {
    /** 所属结算单标识 */
    bill: string;
    /** 发票代码 */
    invoiceCode: string;
    /** 发票号码 */
    invoiceNo: string;
    /** 发票类型 */
    // TODO: 提供枚举
    invoiceType: string;
    /** 开票日期 */
    makeDate: string;
    /** 开票税率 */
    taxRate: number;
    /** 开票金额 */
    total: number;
  }) => post<{ traceId: string; uuid: string }>('/api/pcweb/finance/wholesale/settle/addInvoice', params),

  /** 修改单据状态 */
  alterStatus: (params: {
    /** 目标状态 */
    targetStatus: string;
    uuid: string;
    version: number;
  }) =>
    post<{
      traceId: string;
      version: number;
    }>('/api/pcweb/finance/wholesale/settle/alterStatus', params),

  /** 批量审核 */
  approveBatch: (params: { uuids: string[] }) =>
    post<{
      /** 失败数量 */
      failedCount: number;
      /** 成功数量 */
      successCount: number;
    }>('/api/pcweb/finance/wholesale/settle/approveBatch', params),

  /** 客户确认 */
  confirm: (params: { uuid: string; version: number }) =>
    post<{
      traceId: string;
      version: number;
    }>('/api/pcweb/finance/wholesale/settle/confirm', params),

  /** 获取结算单单头 */
  get: (params: { id?: string; uuid: string }) =>
    post<IFinanceWholesaleSettle>('/api/pcweb/finance/wholesale/settle/get', params),

  /** 获取明细信息 */
  getDetails: (params: { uuid: string }) =>
    post<{
      invoices: IFinanceWholesaleSettleInvoice[];
      receipts: IFinanceWholesaleSettleReceipt[];
      wholesales: IFinanceWholesaleSettleDetailWholesale[];
    }>('/api/pcweb/finance/wholesale/settle/getDetails', params),

  /** 获取发票列表 */
  getInvoices: (params: { uuid: string }) =>
    post<IFinanceWholesaleSettleInvoice[]>('/api/pcweb/finance/wholesale/settle/getInvoices', params),

  /** 分页查询单据 */
  query: (
    params: {
      filter?: {
        /** 截止日期 */
        createEnd?: string;
        /** 起始日期 */
        createStart?: string;
        /** 客户名称或编码关键词 */
        customerKeyword?: string;
        /** 单号类似于 */
        idLike?: string;
        /** 客户是否确认 */
        isConfirm?: boolean;
        /** 是否已经开发票 */
        isInvoice?: boolean;
        /** 收款状态 */
        receiptStatus?: ReceiptedStatusEnum;
        /** 期号 */
        settleNo?: number;
        status?: WholesaleSettleStatusEnum;
      };
    } & IPageParams
  ) => post<IPageData<IFinanceWholesaleSettle>>('/api/pcweb/finance/wholesale/settle/query', params),

  /** 删除 */
  remove: (params: { uuid: string }) => post<{ traceId: string }>('/api/pcweb/finance/wholesale/settle/remove', params),

  /** 删除发票 */
  removeInvoice: (params: { uuid: string }) =>
    post<{ traceId: string }>('/api/pcweb/finance/wholesale/settle/removeInvoice', params),

  /** 批量提交 */
  submitBatch: (params: { uuids: string[] }) =>
    post<{ failedCount: number; successCount: number }>('/api/pcweb/finance/wholesale/settle/submitBatch', params)
};
