import { IPriceTagPrint } from '@/dataModel/business/priceTag/interface';
import { IMdataTemplatePriceTag } from '@/dataModel/mdata/template/interface';
import request from '@/utils/request';

export default {
  /** 新增商品模板 */
  create: (params: { goodsIds: string[]; name: string }) =>
    request.post('/api/pcweb/mdata/template/priceTagPrint/create', params),
  /** 查看所有商品模板 */
  getAll: () => request.post<IMdataTemplatePriceTag[]>('/api/pcweb/mdata/template/priceTagPrint/getAll'),
  /** 分页查询商品列表 */
  query: (params: { billIds: string[] }) =>
    request.post<IPageData<IPriceTagPrint>>('/api/pcweb/mdata/template/priceTagPrint/query', params),
  /** 修改 */
  modify: (params: { name: string; uuid: string; version: number }) =>
    request.post('/api/pcweb/mdata/template/priceTagPrint/modify', params),
  /** 删除 */
  remove: (uuid: string) => request.post('/api/pcweb/mdata/template/priceTagPrint/remove', { uuid })
};
