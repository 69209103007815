import { IStoreGain, IStoreGainDetail, IStoreGainQueryGoods } from '@/dataModel/business/storeGain/interface';
import { ISort } from '@/dataModel/mdata/sort/interface';
import request from '@/utils/request';

const { post } = request;

export default {
  /**
   * 查报溢单详情
   */
  get: (params: { bill?: string; id?: string }) => post<IStoreGain>('/api/pcweb/business/store/gain/get', params),

  /**
   * 查询商品
   */
  queryGoods: (
    params: {
      filter?: {
        keyword?: string;
        sortId?: ISort['id'];
      };
    } & IPageParams
  ) => post<IPageData<IStoreGainQueryGoods>>('/api/pcweb/business/store/gain/queryGoods', params),

  /**
   * 创建报溢单
   */
  create: (params: {
    reason: IStoreGain['reason'];
    remark?: string;
    details: {
      goodsUuid: string;
      qty: number;
      reason?: string;
    }[];
  }) =>
    post<{
      uuid: string;
    }>('/api/pcweb/business/store/gain/create', params),

  /**
   * 修改报溢单
   */
  modify: (params: {
    version: number;
    bill: IStoreGain['id'];
    reason: IStoreGain['reason'];
    remark?: string;
    details: {
      goodsUuid: string;
      qty: number;
      reason?: string;
    }[];
  }) => post<{ version: number }>('/api/pcweb/business/store/gain/modify', params),

  /**
   * 提交报溢单
   */
  submit: (params: { bill: IStoreGain['uuid']; version: number }) =>
    post<{ traceId: string; version: number }>('/api/pcweb/business/store/gain/submit', params),

  /**
   * 删除报溢单
   */
  remove: (params: { bill: IStoreGain['uuid'] }) =>
    post<{ traceId: string }>('/api/pcweb/business/store/gain/remove', params),

  /**
   * 分页查询报溢单
   */
  queryBill: (
    params: {
      filter: {
        startDate?: string;
        endDate?: string;
        id?: IStoreGain['id'];
        status?: IStoreGain['status'];
        goodsKeyword?: string;
        reason?: string;
        storeKeyword?: string;
        goodsInfo?: string;
      };
    } & IPageParams
  ) => post<IPageData<IStoreGain>>('/api/pcweb/business/store/gain/queryBill', params),

  /**
   * 报溢单详情明细
   */
  queryDetail: (
    params: {
      bill: IStoreGain['uuid'];
      filter?: {
        keyword?: string;
        orderBy?: {
          direction?: string;
          field?: string;
          upper?: any;
          upperPurely?: any;
        } & IPageParams;
      };
    } & IPageParams
  ) => post<IPageData<IStoreGainDetail>>('/api/pcweb/business/store/gain/queryDetail', params),

  /**
   * 同意/拒绝报溢申请
   */
  examineStoreGain: (params: {
    agreeStatus: boolean;
    bill: string;
    examines: {
      ctotal: number;
      goods: string;
      goodsQpc: number;
      goodsType: {
        id: string;
        name: string;
      };
      goodsWeight: number;
      planQtyStr: number;
      price: number;
      qtyStr: string;
      retailPrice: number;
      taxRate: number;
      total: number;
      uuid: string;
      version: number;
    }[];
    version: number;
  }) => post<{ traceId: string }>('/api/pcweb/business/store/gain/examineStoreGain', params),
  /**获取导入任务明细 */
  getGoodsByIoTask: (params: { taskUuid: string }) =>
    post<IStoreGainDetail[]>('/api/pcweb/business/store/gain/getGoodsByIoTask', params),

  /**
   * 批量同意/拒绝报损单据
   */
  batchAgreeOrReject: (params: { uuids: string[]; status: boolean }) =>
    post<{ failedCount: number; successCount: number }>('/api/pcweb/business/store/gain/batchAgreeOrReject', params)
};
