import { SellbuySettlePayStatusEnum, SellbuySettleStatusEnum } from '@/dataModel/dict/enum';
import { ISellBuySettleDetail, ISellBuySettle, IAddInvoicesForm } from '@/dataModel/finance/sellBuySettle/interface';
import request from '@/utils/request';

const { post } = request;

export default {
  /**
   * 分页查询
   * @param params
   * @returns
   */
  query: (
    params: {
      filter: {
        endCreateTime?: string;
        id?: string;
        isSupplierConfirm?: boolean;
        payStatus?: SellbuySettlePayStatusEnum;
        settleNo?: string;
        startCreateTime?: string;
        status?: SellbuySettleStatusEnum;
        supplierInfo?: string;
      };
    } & IPageParams
  ) => post<IPageData<ISellBuySettle>>('/api/pcweb/finance/sellbuy/settle/query', params),

  /**
   *
   * @param params 提交
   * @returns
   */
  submit: (params: { uuid: string }) => post<any>('/api/pcweb/finance/sellbuy/settle/submit', params),

  /**
   *
   * @param params 删除
   * @returns
   */
  remove: (params: { uuid: string }) => post<any>('/api/pcweb/finance/sellbuy/settle/remove', params),

  /**
   *
   * @param params 作废
   * @returns
   */
  abort: (params: { uuid: string }) => post<any>('/api/pcweb/finance/sellbuy/settle/abort', params),

  /**
   *
   * @param params 审核
   * @returns
   */
  approve: (params: { uuid: string }) => post<any>('/api/pcweb/finance/sellbuy/settle/approve', params),

  /**
   *
   * @param params 供应商确认
   * @returns
   */
  supplierConfirm: (params: { uuid: string }) => post<any>('/api/pcweb/finance/sellbuy/settle/supplierConfirm', params),

  /**
   *
   * @param params 批量提交
   * @returns
   */
  submitBatch: (params: { uuids: string[] }) => post<any>('/api/pcweb/finance/sellbuy/settle/submitBatch', params),

  /**
   *
   * @param params 批量审核
   * @returns
   */
  approveBatch: (params: { uuids: string[] }) => post<any>('/api/pcweb/finance/sellbuy/settle/approveBatch', params),

  /**
   *
   * @param params 查询详情
   * @returns
   */
  get: (params: { uuid: string }) => post<ISellBuySettleDetail>('/api/pcweb/finance/sellbuy/settle/get', params),

  /**
   *
   * @param params 登记发票
   * @returns
   */
  addInvoice: (params: IAddInvoicesForm) => post<any>('/api/pcweb/finance/sellbuy/settle/addInvoice', params),

  /**
   *
   * @param params 删除发票
   * @returns
   */
  removeInvoice: (params: { uuid: string }) =>
    post<{ traceId: string }>('/api/pcweb/finance/sellbuy/settle/delInvoice', params)
};
