import { PrePayCardStoreTypeEnum, PrePayCardTypeStatusEnum, UseCountTypeEnum } from '@/dataModel/dict/enum';
import { IPrePayCardType, IPrePayCardTypeStore, IPrepayUser } from '@/dataModel/member/prePayCardType/interface';
import request from '@/utils/request';
const { post } = request;

const prePayCardType = {
  /**
   * 分页查询礼品卡类型列表
   */
  queryPrePayCardType: (
    params: {
      filter?: { prePayIdOrName?: string; status?: string; storeUuid?: string; useCountType?: UseCountTypeEnum };
    } & IPageParams
  ) => post<IPageData<IPrePayCardType>>('/api/pcweb/member/prepay/queryPrePayCardType', params, {}, 'card'),
  /**
   * 新增预付卡类型自动生成编码
   */
  getAutoCodeGenerate: () =>
    post<{
      batchNo: string;
      traceId: string;
    }>('/api/pcweb/member/prepay/autoBatchNoGenerate', {}, {}, 'card'),
  /**
   * 查询预付卡-操作员工
   */
  getPrepayUser: () => post<IPrepayUser[]>('/api/pcweb/member/prepay/getUser'),
  /**
   * 停用或启用礼品卡
   */
  disableOrEnable: (params: {
    remark?: string;
    status: PrePayCardTypeStatusEnum.Enabled | PrePayCardTypeStatusEnum.Disabled;
    uuid: string;
    version: number;
  }) => post<{ data: string }>('/api/pcweb/member/prepay/disableOrEnable', params, {}, 'card'),
  /**
   * 删除礼品卡类型
   */
  remove: (params: { uuid: string }) => post<{ data: string }>('/api/pcweb/member/prepay/remove', params, {}, 'card'),
  /**
   * 获取礼品卡类型部分门店列表
   */
  getPrePayCardTypeStores: (params: { uuid: string }) =>
    post<IPrePayCardTypeStore[]>('/api/pcweb/member/prepay/getPrePayCardTypeStores', params, {}, 'card'),
  /**
   * 获取礼品卡类型详情
   */
  getPrePayCardType: (params: { uuid: string }) =>
    post<IPrePayCardType>('/api/pcweb/member/prepay/getPrePayCardType', params, {}, 'card'),
  /**
   * 新增礼品卡类型生成编码
   */
  autoCodeGenerate: () =>
    post<{ id: string; traceId: string }>('/api/pcweb/member/prepay/autoCodeGenerate', {}, {}, 'card'),
  /**
   * 新增礼品卡类型
   */
  createPrePayCardType: (params: {
    id?: string;
    name: string;
    faceAmount: string;
    saleAmount: string;
    status: 'enabled' | 'disabled';
    storeScope: PrePayCardStoreTypeEnum.All | PrePayCardStoreTypeEnum.Assign;
    /** 限制一次性使用 */
    useCountType: UseCountTypeEnum;
    /**  是否IC卡 */
    mediumCard: boolean;
    /**  门店是否能写卡 */
    canStoreWrite: boolean;
    stores:
      | {
          storeId: string;
          storeName: string;
          storeUuid: string;
        }[]
      | [];
  }) => post<{ traceId: string; uuid: string }>('/api/pcweb/member/prepay/createPrePayCardType', params, {}, 'card'),

  modifyPrePayCardType: (params: {
    id: string;
    name: string;
    faceAmount: string;
    saleAmount: string;
    /** 限制一次性使用 */
    useCountType: UseCountTypeEnum;
    /**  是否IC卡 */
    mediumCard: boolean;
    /**  门店是否能写卡 */
    canStoreWrite: boolean;
    status: 'enabled' | 'disabled';
    storeScope: PrePayCardStoreTypeEnum.All | PrePayCardStoreTypeEnum.Assign;
    stores:
      | {
          storeId: string;
          storeName: string;
          storeUuid: string;
        }[]
      | [];
    uuid: string;
    version: number;
  }) => post<{ traceId: string }>('/api/pcweb/member/prepay/modifyPrePayCardType', params, {}, 'card')
};
export default prePayCardType;
