import request from '@/utils/request';
import { IMemberCard } from '@/dataModel/member/memberCard/interface';
const { post } = request;

const memberCard = {
  /**
   * 查询会员卡列表
   */
  query: (
    params: {
      filter?: {
        batchNo?: string;
        cardInfo?: string;
        salerId?: string;
        status?: string;
        storeId?: string;
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => post<IPageData<IMemberCard>>('/api/pcweb/member/card/entity/query', params),
  /**
   * 获取会员卡详情
   */
  get: (params: { id: string }) => post<IMemberCard>('/api/pcweb/member/card/entity/get', params),
  /**
   * 删除会员卡
   * @param params
   * @returns
   */
  remove: (params: { id: string }) => post<any>('/api/pcweb/member/card/entity/remove', params),
  /**
   * 批量删除卡
   */
  removeBatch: (params: { idList: string[] }) =>
    post<{ failedCount: number; failedList: string[]; successCount: number }>(
      '/api/pcweb/member/card/entity/removeBatch',
      params
    ),
  /**
   * 冻结会员卡
   */
  freeze: (params: { id: string; remark?: string; version?: number }) =>
    post<{ data: string }>('/api/pcweb/member/card/entity/freeze', params),
  /**
   * 解冻会员卡
   */
  unfreeze: (params: { id: string; remark?: string; version?: number }) =>
    post<{ data: string }>('/api/pcweb/member/card/entity/unfreeze', params),
  /**
   * 挂失会员卡
   */
  reportLoss: (params: { id: string; remark?: string; version?: number }) =>
    post<{ data: string }>('/api/pcweb/member/card/entity/reportLoss', params),
  /**
   * 补发会员卡
   */
  replace: (params: { oldId: string; newId: string; remark: string; version: number }) =>
    post<{ data: string }>('/api/pcweb/member/card/entity/replace', params),
  /**
   * 获取批次
   */
  getAllBatchNo: () => post<string[]>('/api/pcweb/member/card/entity/getAllBatchNo', {}),
  /**
   * 批量制卡
   */
  createBatch: (params: { batchNo: string; createQty: number; startCardNo: string; skipNumbers: number[] }) =>
    post<{ failedCount: number; failedList: string[]; successCount: number }>(
      '/api/pcweb/member/card/entity/createBatch',
      params
    )
};
export default memberCard;
