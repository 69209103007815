import request from '@/utils/request';
import {
  IStoreTransferItem,
  IStoreTransferDetailData,
  IStoreTransferDetailStockItem,
  IStoreTransferAddGoodsItem,
  IStoreTransferInfo
} from '@/dataModel/business/storeTransfer/interface';
const { post } = request;

const storeTransfer = {
  /**
   * 调拨记录
   */
  query: (
    params: {
      filter: {
        billClass?: string[];
        billId?: string;
        endDate?: string;
        searchKey?: string;
        startDate?: string;
        status?: string[];
      };
      orderBy?: {
        direction?: string;
        field?: string;
      };
    } & IPageParams
  ) => post<IPageData<IStoreTransferItem>>('/api/pcweb/business/store/transfer/query', params),
  /**
   * 调拨详情 目前没有使用
   */
  get: (
    params: {
      billId: string;
      filter?: {
        searchKey: string;
      };
      orderBy?: {
        direction: string;
        field: string;
      };
    } & IPageParams
  ) => post<IStoreTransferDetailData>('/api/pcweb/business/store/transfer/get', params),
  /**
   * 获取调拨单(带库存)详情 编辑跳出单的时候使用
   */
  queryDetails: (
    params: {
      billId: string;
      filter?: {
        searchKey: string;
      };
    } & IPageParams
  ) => post<IPageData<IStoreTransferDetailStockItem>>('/api/pcweb/business/store/transfer/queryDetails', params),
  /**
   * 保存调出单
   */
  modify: (params: {
    billId?: string;
    details: {
      goodsId: string;
      qty: number;
      qtyStr: string;
      reason: string;
      remark: string;
      stockQty: number;
      goodsMunit: string;
      goodsQpcStr: string;
    }[];
    reason: string;
    remark: string;
    toStoreId: string;
    version: number;
  }) => post<any>('/api/pcweb/business/store/transfer/modify', params),
  /**
   * 创建调出单
   */
  create: (params: {
    details: {
      goodsId: string;
      qty: number;
      qtyStr: string;
      reason: string;
      remark: string;
      stockQty: number;
      goodsMunit: string;
      goodsQpcStr: string;
    }[];
    reason: string;
    remark: string;
    toStoreId: string;
    version: number;
    nuid?: string;
  }) => post<any>('/api/pcweb/business/store/transfer/create', params),
  /**
   * 提交调出单
   */
  saveTransfer: (params: {
    billId?: string;
    details?: {
      goodsId: string;
      qty: number;
      qtyStr: string;
      reason: string;
      remark: string;
      stockQty: number;
    }[];
    reason?: string;
    remark?: string;
    toStoreId?: string;
    version: number;
    nuid?: string;
  }) => post<any>('/api/pcweb/business/store/transfer/saveTransfer', params),
  /**
   * 获取调拨单单头
   */
  getTransfer: (params: { billId: string }) =>
    post<IStoreTransferInfo>('/api/pcweb/business/store/transfer/getTransfer', params),
  /**
   * 确认收货保存
   */
  saveConfirmReceipt: (params: {
    billId: string;
    details: {
      goodsId: string;
      qty: number;
      qtyStr: string;
    }[];
    version: number;
  }) => post<any>('/api/pcweb/business/store/transfer/saveConfirmReceipt', params),
  /**
   * 确认收货
   */
  confirmReceipt: (params: { billId: string; version: number }) =>
    post<any>('/api/pcweb/business/store/transfer/confirmReceipt', params),
  /**
   * 删除单据
   */
  remove: (params: { id: string }) => post<any>('/api/pcweb/business/store/transfer/remove', params),
  /**
   * 作废
   */
  abolished: (params: { uuid: string; version: number }) =>
    post<any>('/api/pcweb/business/store/transfer/abolished', params),
  /**
   * 查询商品
   */
  queryGoods: (
    params: {
      filter?: {
        searchKey: string;
      };
      orderBy?: {
        direction: string;
        field: string;
      };
    } & IPageParams
  ) => post<IPageData<IStoreTransferAddGoodsItem>>('/api/pcweb/business/store/transfer/query/goods', params),

  /**
   * 根据导入任务获取商品
   */
  getGoodsByIoTask: (params: { taskUuid: string }) =>
    post<IStoreTransferDetailStockItem[]>('/api/pcweb/business/store/transfer/getGoodsByIoTask', params)
};
export default storeTransfer;
