import { CustomerRechargeStatusEnum } from '@/dataModel/dict/enum';
import { ICustomerRecharge } from '@/dataModel/finance/customerRecharge/interface';
import request from '@/utils/request';

export default {
  /** 按单据分页查询 */
  query: (params: {
    isChecked: boolean;
    filter: {
      applyEnd?: string;
      applyStart?: string;
      checkEnd?: string;
      checkStart?: string;
      contactorKeyword?: string;
      customerIdList?: string[];
      id?: string;
      salesmanIdList?: string[];
      status?: CustomerRechargeStatusEnum;
    };
  }) => request.post<IPageData<ICustomerRecharge>>(`/api/pcweb/finance/customer/recharge/query`, params),

  /**审核通过 */
  approve: (params: { uuid: ICustomerRecharge['uuid']; version: ICustomerRecharge['version'] }) =>
    request.post('/api/pcweb/finance/customer/recharge/approve', params),

  /**拒绝 */
  refuse: (params: { uuid: ICustomerRecharge['uuid']; version: ICustomerRecharge['version']; reason: string }) =>
    request.post('/api/pcweb/finance/customer/recharge/refuse', params)
};
