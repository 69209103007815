import { OMallSkuGoods } from '@/dataModel/oMall/sku/interface';
import request from '@/utils/request';
// import {  } from '@/dataModel/oMall/sku/interface';

const { post } = request;

const oMallSku = {
  /** 下架 */
  down: (params: { uuid: string }) => post('/api/pcweb/omall/sku/down', params),
  /** 批量下架 */
  downBatch: (params: { uuids: string[] }) =>
    post<{
      /** 失败数量 */
      fail: number;
      failReasons: {
        /** 商品名称 */
        key: string;
        /** 失败原因 */
        value: string;
      };
      /**  成功数量 */
      success: number;
      /** 忽略数量 */
      ignore: number;
    }>('/api/pcweb/omall/sku/down/batch', params),
  /** 批量删除*/
  removeBatch: (params: { uuids: string[] }) =>
    post<{
      /** 失败数量 */
      fail: number;
      failReasons: {
        /** 商品名称 */
        key: string;
        /** 失败原因 */
        value: string;
      };
      /**  成功数量 */
      success: number;
      /** 忽略数量 */
      ignore: number;
    }>('/api/pcweb/omall/sku/remove/batch', params),
  /** 更新最小起订量 */
  update: (params: {
    /** 最小起订量 */
    minOrderQty: number;
    /** 标识 */
    uuid: string;
  }) => post('/api/pcweb/omall/sku/minorderqty/update', params),
  /** 批量更新最小起订量 */
  updateBatch: (params: {
    /** 最小起订量 */
    minOrderQty: number;
    /** 标识 */
    uuids: string[];
  }) => post('/api/pcweb/omall/sku/minorderqty/update/batch', params),
  /** 更新最小起订量 */
  updateOrderStep: (params: {
    /** 最小起订量 */
    orderStep: number;
    /** 标识 */
    uuid: string;
  }) => post('/api/pcweb/omall/sku/orderstep/update', params),
  /** 批量更新订货倍数 */
  updateOrderStepBatch: (params: {
    /** 订货倍数 */
    orderStep: number;
    /** 标识 */
    uuids: string[];
  }) => post('/api/pcweb/omall/sku/orderstep/update/batch', params),
  /** sku查询 */
  query: (
    params: {
      filters?: {
        /** 分类编码等于 */
        categoryCodeEq?: string;
        /** 是否有图片 */
        hasImage?: boolean;
        /** 关键字 */
        keyword?: string;
        /** 状态等于 */
        statusEq?: string;
        /** 类型等于 */
        typeEq?: string;
        /** 是否上架 */
        up?: boolean;
      };
    } & IPageParams
    // TODO: 处理返回数据接口
  ) => post<IPageData<OMallSkuGoods>>('/api/pcweb/omall/sku/query', params),
  /** 上架 */
  up: (params: { uuid: string }) => post('/api/pcweb/omall/sku/up', params),
  /** 删除 */
  remove: (params: { uuid: string }) => post('api/pcweb/omall/sku/remove', params),
  /** 批量上架 */
  upBatch: (params: { uuids: string[] }) =>
    post<{
      /** 失败数量 */
      fail: number;
      failReasons: {
        /** 商品名称 */
        key: string;
        /** 失败原因 */
        value: string;
      };
      /**  成功数量 */
      success: number;
      /** 忽略数量 */
      ignore: number;
    }>('/api/pcweb/omall/sku/up/batch', params)
};

export default oMallSku;
