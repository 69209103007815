import { SupplierFeeStatusEnum } from '@/dataModel/dict/enum';
import { IFeeSupplier } from '@/dataModel/finance/feeSupplier/interface';
import { ISellBuySettleInvoices } from '@/dataModel/finance/sellBuySettle/interface';
import { IAddInvoicesForm } from '@/dataModel/finance/sellBuySettle/interface';
import request from '@/utils/request';

export default {
  /** 保存、提交 */
  create: (params: {
    info: {
      /** 费用项目标识 */
      feeItem: string;
      /** 费用主体 */
      feeSubject: string;
      /** 费用含税额 */
      feeTotal: number;
      /** 费用发生日期 */
      occurDate: number;
      remark?: string;
      /** 门店标识 */
      store?: string;
      /** 供应标识 */
      supplier: string;
    };
    /** 目标状态 */
    targetStatus: SupplierFeeStatusEnum;
    uuid?: string;
    version?: number;
  }) =>
    request.post<{ uuid: string; version: number; traceId: string }>('/api/pcweb/finance/fee/supplier/save', params),

  /** 修改状态 */
  alterStatus: (params: { targetStatus: SupplierFeeStatusEnum; uuid: string; version: number }) =>
    request.post<{ traceId: string; version: number }>('/api/pcweb/finance/fee/supplier/alterStatus', params),

  /** 删除 */
  remove: (params: { uuid: string }) =>
    request.post<{ traceId: string }>('/api/pcweb/finance/fee/supplier/remove', params),

  /** 获取日志列表(未使用) */
  getLogs: (params: { bill: string }) => request.post('/api/pcweb/finance/fee/supplier/getLogs', params),

  /** 分页查询 */
  query: (
    params: {
      filter?: {
        endDate?: string;
        feeItemId?: string;
        feeItemType?: string;
        idKeyword?: string;
        payDirection?: string;
        payType?: string;
        settleNo?: string;
        startDate?: string;
        status?: string;
        supplier?: string;
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => request.post<IPageData<IFeeSupplier>>('/api/pcweb/finance/fee/supplier/query', params),

  /** 批量作废 */
  abolish: (params: { uuids: string[] }) =>
    request.post<{ failedCount: number; successCount: number }>('/api/pcweb/finance/fee/supplier/abolishBatch', params),

  /** 批量审核 */
  approve: (params: { uuids: string[] }) =>
    request.post<IPageData<IFeeSupplier>>('/api/pcweb/finance/fee/supplier/approveBatch', params),

  /** 获取 */
  get: (params: { uuid: string; id: string }) =>
    request.post<IFeeSupplier>('/api/pcweb/finance/fee/supplier/get', params),

  /** 获取发票列表 */
  getInvoices: (params: { uuid: string; id: string }) =>
    request.post<ISellBuySettleInvoices[]>('/api/pcweb/finance/fee/supplier/getInvoices', params),

  /** 添加发票 */
  addInvoice: (params: IAddInvoicesForm) =>
    request.post<{ traceId: string; uuid: string }>('/api/pcweb/finance/fee/supplier/addInvoice', params),

  /** 删除发票 */
  removeInvoice: (params: { uuid: string }) =>
    request.post<{ traceId: string }>('/api/pcweb/finance/fee/supplier/removeInvoice', params),
  /** 查询关联单据 */
  queryBillDetails: (params: { filter: { billClass: string }; bill: string } & IPageParams) =>
    request.post<IPageData<any>>('/api/pcweb/finance/fee/supplier/queryBillDetails', params)
};
