import { IGetStoreOpenTime, IMdataOnlineStore } from '@/dataModel/mdata/onlineStore/interface';
import request from '@/utils/request';
import { IStoreSum, IOnlineStore } from '@/dataModel/mdata/onlineStore/interface';
import { IStores } from '@/dataModel/dist/pool/interface';
import { ChannelEnum } from '@/dataModel/dict/enum';
const { post } = request;

const onlineStore = {
  /**
   * 网店统计
   */
  getSum: () => post<IStoreSum>('/api/pcweb/mdata/online/store/getSum', {}),
  /**
   *
   * @param params
   * @returns
   */
  getSumAndStore: () => post<IStoreSum>('/api/pcweb/mdata/online/store/getSumAndStore', {}),
  /**
   * 网店分页查询
   */
  query: (
    params: {
      filter?: {
        banding?: boolean;
        platKey?: string;
        status?: string;
        storeKey?: string;
        channel?: string;
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => request.post<IPageData<IOnlineStore>>('/api/pcweb/mdata/online/store/query', params),

  /**
   * 门店绑定
   */
  banding: (params: { uuid: string; store: string; version: number }) =>
    request.post('/api/pcweb/mdata/online/store/banding', params),

  /** 查询网店列表 */
  getList: (params: { channel: ChannelEnum }) =>
    request.post<IMdataOnlineStore[]>('/api/pcweb/mdata/online/store/getList', params),

  /**
   * 获取营业时间
   */
  getOpen: (params: { uuid: string }) =>
    request.post<IGetStoreOpenTime>('/api/pcweb/mdata/online/store/getOpens', params),

  /**
   * 获取门店
   */
  getStore: (params: { uuid: string }) => request.post<IStores[]>('/api/pcweb/mdata/online/store/getStores', params),

  /**
   * 修改门店营业状态
   */
  openClose: (params: { uuid: string; status: string; version: number }) =>
    request.post('/api/pcweb/mdata/online/store/openClosed', params),

  /**
   * 设置营业时间
   */
  set: (params: { uuidList: string[]; timeList: string[] }) =>
    request.post('/api/pcweb/mdata/online/store/set', params),

  /**
   * 解绑
   */
  unBanding: (params: { uuid: string; version: number }) =>
    request.post('/api/pcweb/mdata/online/store/unbanding', params),

  /**
   * 门店接单设置
   */
  modifySetting: (params: { autoOrder: number; elemTake: number; jdTake: number; mtTake: number }) =>
    request.post('/api/pcweb/mdata/online/store/modifySetting', params),

  /**
   * 获取门店接单设置
   */
  getSetting: () =>
    request.post<{
      autoOrder: number;
      elemExist: number;
      elemTake: number;
      jdExist: number;
      jdTake: number;
      mtExist: number;
      mtTake: number;
    }>('/api/pcweb/mdata/online/store/getSetting'),

  /**
   *
   */
  getPlatInf: (params: null) => request.post<any[]>('/api/pcweb/system/data/transfer/getPlatInf', params),
  /**查询商品对应门店绑定的线上销售渠道 */
  getChannels: (params: { toStoreId?: string; uuids?: string[] }) =>
    request.post<any>('/api/pcweb/mdata/online/store/getChannels', params),
  /**查询快团团URL */
  getUrl: (params: { store: string }) => request.post<any>('/api/pcweb/mdata/online/store/ktt/authorizeurl/get', params)
};

export default onlineStore;
