import { IBusinessBillLog } from '@/dataModel/business/billLog/interface';
import { BillTypeEnum } from '@/dataModel/dict/enum';
import request from '@/utils/request';
const { post } = request;

/**
 * 单据操作日志
 */
const billLog = {
  /** 查单据操作日志分页 */
  query: (
    params?: {
      billType: BillTypeEnum;
      filter?: {
        billId?: IBusinessBillLog['billId'];
      };
    } & IPageParams
  ) => post<IPageData<IBusinessBillLog>>('/api/pcweb/business/bill/log/query', params),
  /** 查单据操作日志详情 */
  queryDetail: (params: { uuid: IBusinessBillLog['uuid'] }) =>
    post<IBusinessBillLog>('/api/pcweb/business/bill/log/queryDetail', params)
};

export default billLog;
