import { ChannelEnum, OnlineGoodsSearchTypeEnum } from '@/dataModel/dict/enum';
import { LevelGroup } from '@/dataModel/mdata/onlineGroup/enum';
import { IMdataAllOnlineGroup, OnlineGroupLastSyncRecord } from '@/dataModel/mdata/onlineGroup/interface';
import { IItemGroup } from '@/dataModel/mdata/onlineGroup/interface';
import request from '@/utils/request';
const { post } = request;

const onlineGroup = {
  /** 查询全部 */
  getAll: (params: {
    filter?: {
      onlineChannels?: ChannelEnum[];
      /** 所属分组 */
      onlineGroupId?: string;
      /** 搜索关键词 */
      searchKey?: string;
      /** 搜索类型 */
      searchType?: OnlineGoodsSearchTypeEnum;
      /** 门店id */
      storeIds?: string[];
    };
  }) => post<IMdataAllOnlineGroup[]>('/api/pcweb/mdata/online/group/getAll', params, {}, 'onlineGoods'),
  /** 查询全部 */
  getAllForSelect: (params: {
  }) => post<IMdataAllOnlineGroup[]>('/api/pcweb/mdata/online/group/getAllForSelect', params, {}, 'onlineGoods'),

  /**
   * 分页查询分组资料
   */
  query: (
    params?: {
      filter?: {
        id?: string;
        level?: number;
        onlineChannels?: ChannelEnum[];
        onlineGroupId?: string;
        parentId?: string;
        searchKey?: string;
        searchType?: string;
        storeIds?: number[];
        uuid?: string;
      };
      orderBy?: {
        direction?: string;
        field?: string;
      };
    } & IPageParams
  ) => post<IPageData<IItemGroup> & { sync: boolean }>('/api/pcweb/mdata/online/group/query', params, {}, 'onlineGoods'),

  /**
   * 新增
   */
  create: (params: {
    id?: string;
    level: number;
    name: string;
    parentId?: string;
    remark?: string;
    imageUrl?: string;
  }) => post<{ traceId: string; uuid: string }>('/api/pcweb/mdata/online/group/create', params, {}, 'onlineGoods'),

  /**
   * 获得
   */
  get: (params: { uuid: string }) => post<IItemGroup>('/api/pcweb/mdata/online/group/get', params, {}, 'onlineGoods'),

  /**
   * 获得所有一级分组
   */
  getGeneralGroup: () => post<IItemGroup[]>('/api/pcweb/mdata/online/group/getGeneralGroup',{}, {}, 'onlineGoods'),

  /**
   * 修改
   */
  modify: (params: { name: string; parentId?: string; uuid: string; imageUrl?: string; version: number }) =>
    post<{ traceId: string }>('/api/pcweb/mdata/online/group/modify', params, {}, 'onlineGoods'),

  /**
   * 删除
   */
  remove: (params: { id: string }) => post<{ traceId: string }>('/api/pcweb/mdata/online/group/remove', params, {}, 'onlineGoods'),

  /**
   * 查询分类
   */
  querySort: (
    params: {
      filter: {
        exist?: boolean;
        searchKey: string;
      };
      groupId: string;
      orderBy?: {
        direction: string;
        field: string;
      };
    } & IPageParams
  ) => post<IPageData<IItemGroup>>('/api/pcweb/mdata/online/group/querySort', params, {}, 'onlineGoods'),

  /**
   * 设置关联
   */
  set: (params: { sortIds: string[]; uuid: string; version: number }) =>
    post<{ traceId: string }>('/api/pcweb/mdata/online/group/set', params, {}, 'onlineGoods'),

  /**
   * 发布到平台
   */
  pushGroup: (params: {
    syncMt: boolean;
    syncEleme: boolean;
    syncJddj: boolean;
    syncWeimob: boolean;
    syncMtStoreIds: string[];
    syncElemeStoreIds: string[];
  }) => post<{ traceId: string }>('/api/pcweb/mdata/online/group/pushGroup', params, {}, 'onlineGoods'),

  /**
   *  api/pcweb/mdata/online/group/getLastSyncTime
   * @param params
   * @returns
   */
  getLastSyncTime: () => post('/api/pcweb/mdata/online/group/getLastSyncTime ',{}, {}, 'onlineGoods'),
  /**
   * 设置分组位置
   */
  moveLine: (params: {
    current: {
      id: string;
      level: LevelGroup;
      line: number;
      upper: boolean;
    };
    target: {
      id: string;
      level: LevelGroup;
      line: number;
      upper: boolean;
    };
  }) => post<{ traceId: string }>('/api/pcweb/mdata/online/group/moveLine', params, {}, 'onlineGoods'),

  /** 一键拉取平台分组 */
  pullOnlineGroup: (params: { filter: { channel: ChannelEnum; storeId: string } }) =>
    post<{ traceId: string }>('/api/pcweb/mdata/online/group/pullOnlineGroup', params, {}, 'onlineGoods'),
  /** 获取异常汇总 */
  // /api/pcweb/mdata/online/group/syncrecord/
  getErrorCount: (params: { filter: { onlineGroupUuid: string; platform: string; store: string } }) =>
    post<{
      allCount: number;
      banCreateSubGroupCount: number;
      countExceedCount: number;
      groupExistCount: number;
      sensitiveWordCount: number;
    }>('/api/pcweb/mdata/online/group/syncrecord/getErrorCount', params, {}, 'onlineGoods'),
  /** /api/pcweb/mdata/online/group/syncrecord/queryFailRecord
分页查询同步异常记录 */
  queryFailRecord: (params: { filter: { onlineGroupUuid: string; platform: string; store: string } }) =>
    post<IPageData<OnlineGroupLastSyncRecord>>('/api/pcweb/mdata/online/group/syncrecord/queryFailRecord', params, {}, 'onlineGoods')
};

export default onlineGroup;
