import request from '@/utils/request';
import {
  IPrePayUsageRecordDump,
  IPrePayUsageRecordFlow,
  IPrePayUsageRecordSale
} from '@/dataModel/member/prePayUsageRecordList/interface';
const { post } = request;

const PickCardUsageRecordList = {
  /**
   * 分页查询提货卡管理使用记录->流水
   */
  queryFlow: (
    params: {
      filter: {
        cardTypeId?: string;
        createdEndDate?: string;
        createdStartDate?: string;
        operatorId?: string;
        salespersonId?: string;
        searchKey?: any;
        posId?: string;
        storeId?: string;
      };
    } & IPageParams
  ) => post<IPageData<IPrePayUsageRecordFlow>>('/api/pickup/verificationflow/query', params, {}, 'card'),

  /**
   * 分页查询礼品卡管理使用记录->发售
   */
  querySale: (
    params: {
      filter: {
        cardTypeId?: string;
        createdEndDate?: string;
        createdStartDate?: string;
        operatorId?: string;
        salespersonId?: string;
        searchKey?: any;
      };
    } & IPageParams
  ) => post<IPageData<IPrePayUsageRecordSale>>('/api/pickup/saleflow/query', params, {}, 'card'),

  /**
   * 分页查询礼品卡管理使用记录->转储
   */
  queryDump: (
    params: {
      filter: {
        /**卡号/购卡人/交易号 */
        cardTypeId?: string;
        /**开始时间 */
        createdStartDate?: string;
        /**结束时间 */
        createdEndDate?: string;
        /**门店名称 */
        storeId?: string;
        /**操作人id */
        operatorId?: string;
        /**POS机号 */
        posId?: string;
        searchKey?: any;
        basicSkuUuid?: string;
        /**排序 */
        // orderBys?: any;
      };
    } & IPageParams
  ) => post<IPageData<IPrePayUsageRecordDump>>('/api/pickup/verificationflow/queryGoods', params, {}, 'card')
};

export default PickCardUsageRecordList;
