import {
  IOnlineOrderQueryFilter,
  OnlineOrderR,
  IOnlineOrderDetail,
  OnlineCountR
} from '@/dataModel/retail/reformManagement/interface';
import request from '@/utils/request';

export default {
  /** 分页查询 */
  query: (params: { filter: Partial<IOnlineOrderQueryFilter> }) =>
    request.post<IPageData<OnlineOrderR>>('/api/pcweb/onlineorder/r/query', params, {}, 'onlineOrder'),
  // /** 详情 - 商品信息 */
  getDetails: (params: { uuid: string }) => request.post<IOnlineOrderDetail>('/api/pcweb/onlineorder/r/get', params, {}, 'onlineOrder'),
  // /**  同意售后 */
  agree: (params: { uuid: string }) => request.post('/api/pcweb/onlineorder/r/agree', params, {}, 'onlineOrder'),
  /**取消售后单 */
  cancel: (params: { uuid: string }) => request.post('/api/pcweb/onlineorder/r/cancel', params, {}, 'onlineOrder'),
  /**确认收货 */
  receive: (params: { uuid: string }) => request.post('/api/pcweb/onlineorder/r/receive', params, {}, 'onlineOrder'),
  /** 查询单据分类数量*/
  /** 分页查询 */
  getCount: (params: { filter: Partial<IOnlineOrderQueryFilter> }) =>
    request.post<IPageData<OnlineCountR>>('/api/pcweb/onlineorder/r/getCount', params, {}, 'onlineOrder'),
  /**拒绝收货 */
  receiveRefuse: (params: { reason: string; uuid: string }) =>
    request.post('/api/pcweb/onlineorder/r/receive/refuse', params, {}, 'onlineOrder'),
  /**拒绝售后 */
  refuse: (params: { reason: string; uuid: string }) => request.post('/api/pcweb/onlineorder/r/refuse', params, {}, 'onlineOrder'),

  /**门店-全部报损 */
  allstoreloss: (params: { uuid: string }) => request.post('/api/pcweb/onlineorder/r/allstoreloss', params, {}, 'onlineOrder'),
  /**门店-商品回库 */
  invIn: (params: { uuid: string }) => request.post('/api/pcweb/onlineorder/r/inv/in', params, {}, 'onlineOrder')
};
