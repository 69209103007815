import request from '@/utils/request';
import { ISettingUserForm } from '@/dataModel/system/settingUserForm/interface';
import { UserFormTypeEnum } from '@/dataModel/dict/enum';
const { post } = request;

const settingUserForm = {
  /** 保存用户表格设置 */
  saveUserForm: (params: {
    /** 业务类型 */
    businessType: string;
    /** 表格类型(listType-列表类型,detailForm-详情页表格,queryForm-查询列表) */
    formType?: UserFormTypeEnum;
    /** 页面数据大小 */
    pageSize: number;
  }) => post('/api/pcweb/system/setting/user/saveUserForm', { ...params, formType: UserFormTypeEnum.ListType }),

  /** 查询用户表格设置 */
  getAllUserForm: () => post<ISettingUserForm[]>('/api/pcweb/system/setting/user/getAllUserForm')
};

export default settingUserForm;
