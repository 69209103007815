import request from '@/utils/request';
import {
  IAllPrePayCardList,
  IPrePayDetailBasicInfo,
  IPrePayManagement,
  IPrePayManagementDetailList
} from '@/dataModel/member/prePayManagement/interface';
import { UseCountTypeEnum } from '@/dataModel/dict/enum';
const { post } = request;

const pickCardTypeManagement = {
  /**
   * 分页查询礼品卡管理列表/未出售/使用中/已过期
   */
  query: (
    params: {
      filter: {
        batchNo?: string;
        beginConsumeTime?: string;
        beginId?: string;
        beginValidateDate?: string;
        cardType?: string;
        endConsumeTime?: string;
        endId?: string;
        endValidateDate?: string;
        idOrBuyer?: string;
        maximumSaleAmount?: string;
        minimumSaleAmount?: string;
        saleChannel?: string;
        salerId?: string;
        saleOperatorId?: string;
        status?: string;
      };
    } & IPageParams & {
        storeId?: string;
      }
  ) => post<IPageData<IPrePayManagement>>('/api/pcweb/member/pickup/entity/query', params, {}, 'card'),
  createPrePayCardBatch: (params: {
    batchNo: string;
    cardType: string;
    cardTypeId: string;
    cardTypeName: string;
    createQty: number;
    faceAmount: number;
    saleAmount: number;
    startCardNo: number;
    validateDate: string;
    version: number;
  }) =>
    post<{ errorUrl: string | null; failedCount: number; successCount: number }>(
      '/api/pcweb/member/pickup/entity/createBatch',
      params,
      {},
      'card'
    ),
  /**
   * 新增提货卡类型自动生成编码
   */
  getAutoCodeGenerate: () =>
    post<{
      batchNo: string;
      traceId: string;
    }>('/api/pcweb/member/pickup/entity/autoBatchNoGenerate', {}, {}, 'card'),
  /**
   * 获取批次
   */
  getAllBatchNo: () => post<string[]>('/api/pcweb/member/card/entity/getAllBatchNo', {}),

  /**
   * 礼品卡管理详情
   */
  get: (params: { uuid }) => post<IPrePayDetailBasicInfo>('/api/pcweb/member/pickup/entity/detail', params, {}, 'card'),

  /**
   * 礼品卡管理详情列表
   */
  getDetailList: (
    params: {
      // filter: {
      //   orderBys: any;
      // };
      uuid;
    } & IPageParams
  ) =>
    post<IPageData<IPrePayManagementDetailList>>(
      '/api/pcweb/member/pickup/entity/queryPrePayCardFlow',
      params,
      {},
      'card'
    ),

  getByCardId: (params: {
    // filter: {
    //   orderBys: any;
    // };
    cardId: string;
  }) => post<IPrePayManagementDetailList>('/api/pickup/verificationflow/getByCardId', params, {}, 'card'),
  /**
   * 删除礼品卡
   */
  batchRemovePrePayCard: (params: { uuids: string[] }) =>
    post('/api/pcweb/member/pickup/entity/deleteBatch', params, {}, 'card'),
  /**
   * 作废礼品卡
   */
  abortedPrePayCard: (params: { uuids: string[] }) =>
    post('/api/pcweb/member/pickup/entity/invalidBatch', params, {}, 'card'),

  /**
   *  查询全量发售礼品卡时带出的卡列表
   */
  selectPickupCardEntity: (params: {
    filter: {
      batchNo?: string;
      beginConsumeTime?: string;
      beginId?: string;
      beginValidateDate?: string;
      cardType?: string;
      endConsumeTime?: string;
      endId?: string;
      endValidateDate?: string;
      idOrBuyer?: string;
      maximumSaleAmount?: string;
      minimumSaleAmount?: string;
      saleChannel?: string;
      salerId?: string;
      saleOperatorId?: string;
      status?: string;
    };
  }) => post<IAllPrePayCardList>('/api/pcweb/member/pickup/entity/selectPickupCardEntity', params, {}, 'card'),
  /**
   * 批量发售
   */
  salePrePayCardBatch: (params: {
    buyerMobile: string;
    buyerName: string;
    realSaleAmount: string;
    salerId?: string;
    salerName?: string;
    sales: {
      amount: number;
      //faceAmount: number;
      ids?: string[];
      subTotal: number;
    };
    totalPrice: number;
    storeId?: string;
  }) => post('/api/pcweb/member/pickup/entity/saleBatch', params, {}, 'card'),
  /**
   * 批量制卡自动生成批次号
   */
  autoBatchNoGenerate: () =>
    post<{ batchNo: string; traceId: string }>('/api/pcweb/member/prepay/autoBatchNoGenerate', {}, {}, 'card'),

  createBatch: (params: {
    batchNo: string;
    cardType: string;
    cardTypeId: string;
    cardTypeName: string;
    createQty: number;
    faceAmount: number;
    saleAmount: number;
    startCardNo: number;
    validateDate: string;
    version: number;
  }) =>
    post<{ errorUrl: string | null; failedCount: number; successCount: number }>(
      '/api/pcweb/member/pickup/entity/createBatch',
      params,
      {},
      'card'
    )
};

export default pickCardTypeManagement;
