import { IBusinessOrderDetail } from '@/dataModel/business/orderDetail/interface';
import { IBusinessOrderGoods } from '@/dataModel/business/orderGoods/interface';
import { IBusinessOrderRecord } from '@/dataModel/business/orderRecord/interface';

import request from '@/utils/request';

const { post } = request;

const requireApi = {
  /**
   * 查询可订货列表
   * @param params
   * @returns
   */
  query: (
    params: {
      filter: {
        newGoods: boolean;
        searchKey?: string;
        sortId?: string;
        stocks?: string[];
        templateUuid?: string;
        worksheet: boolean;
        isLowSafe: boolean;
        supplierId?: string;
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) =>
    post<{
      goods: { items: IBusinessOrderGoods[]; totalCount: number };
    }>('/api/pcweb/business/require/queryGoods', params),
  /**
   * 查询订货记录
   * @param params
   * @returns
   */
  queryDetail: (
    params: {
      billId: string;
      filter?: {
        searchKey?: string;
        sortId: string;
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => post<IBusinessOrderDetail>('/api/pcweb/business/require/get', params),
  /**
   * 订货单
   * @param params
   * @returns
   */
  get: (
    params: {
      filter: {
        billId: string;
        day: string;
        maxTotal: number;
        minTotal: number;
        searchKey: string;
      };
      orderBy: IPageParamsOrderBy;
    } & IPageParams
  ) => post<{ items: IBusinessOrderRecord[]; totalCount: number }>('/api/pcweb/business/require/getOrders', params),
  /**
   * 暂存订货数量
   * @param params
   * @returns
   */
  saveWorksheet: (params: {
    details: {
      munitUuid: string;
      qty: number;
      qtyStr: string;
      remark: string;
    }[];
  }) =>
    post<{
      worksheetSort: { name: string; qty: number; total: number; num: number }[];
      total: number;
      num: number;
      qty: number;
    }>('/api/pcweb/business/require/saveWorksheet', params),
  /**
   * 提交订货
   * @param params
   * @returns
   */
  saveOrder: (params: { remark: string }) =>
    post<{ billId: string; status: boolean; traceId: string }>('/api/pcweb/business/require/saveOrder', params),
  /**
   * 清空暂存
   * @param params
   * @returns
   */
  clear: () => post<{ traceId: string }>('/api/pcweb/business/require/clear'),

  /**
   * 更新补货数
   * @returns
   */
  updateReplenishQty: () => post<{ traceId: string }>('/api/pcweb/business/require/updateReplenishQty'),

  /**
   * 获取门店暂存数据
   * @param params
   * @returns
   */
  queryWorksheet: () => post<IBusinessOrderGoods[]>('/api/pcweb/business/require/queryWorksheet')
};

export default requireApi;
