import { WarehouseStatusEnum } from '@/dataModel/dict/enum';
import {
  IWareHouse,
  IWarehouseGoods,
  IWarehouseShelf,
  IWarehouseShelfGoods,
  IPickMode,
  IGoodsList,
  IModalGoods
} from '@/dataModel/mdata/warehouse/interface';
import request from '@/utils/request';

const { post } = request;

const wareHouse = {
  /**
   *
   *  获取所有仓库列表
   */
  getAll: (
    params: {
      filter?: {
        isDu?: boolean; //是否配货
        isPu?: boolean; //是否采购
        isRu?: boolean; //是否退货
        status?: WarehouseStatusEnum;
        isHasMulti?: boolean; //是否含有多级仓
      };
    } = {}
  ) => post<IWareHouse[]>('/api/pcweb/mdata/warehouse/getAll', params),

  /**
   * 获取所有已用/可用仓库数
   * @param params
   * @returns
   */
  getAllEnabledAndUnLock: (params: {
    filter?: { isDu?: boolean; isPu?: boolean; isRu?: boolean; status?: WarehouseStatusEnum };
  }) =>
    post<{ availableCount: number; usedCount: number }>('/api/pcweb/mdata/warehouse/getAllEnabledAndUnLock', params),

  /**
   *
   *  创建
   */
  create: (params: {
    contactor?: string;
    /** 配货 */
    du?: boolean;
    hasMulti?: boolean;
    id?: string;
    locationAddress?: string;
    locationCity?: string;
    locationCityName?: string;
    locationCounty?: string;
    locationCountyName?: string;
    locationProvince?: string;
    locationProvinceName?: string;
    name: string;
    phone?: string;
    /** 采购 */
    pu?: boolean;
    remark?: string;
    /** 退货 */
    ru?: boolean;
    status?: string;
    multiList?: {
      du: boolean;
      name: string;
      pu: boolean;
      ru: boolean;
      status: string;
      uuid?: string;
    }[];
  }) => post<any>('/api/pcweb/mdata/warehouse/create', params),

  /**
   *
   *  修改仓库信息
   */
  modify: (params: {
    contactor?: string;
    /** 配货 */
    du?: boolean;
    hasMulti?: boolean;
    id?: string;
    locationAddress?: string;
    locationCity?: string;
    locationCityName?: string;
    locationCounty?: string;
    locationCountyName?: string;
    locationProvince?: string;
    locationProvinceName?: string;
    name: string;
    phone?: string;
    /** 采购 */
    pu?: boolean;
    remark?: string;
    /** 退货 */
    ru?: boolean;
    status?: string;
    multiList?: {
      du: boolean;
      name: string;
      pu: boolean;
      ru: boolean;
      status: string;
      uuid?: string;
    }[];
    version: number;
  }) => post<any>('/api/pcweb/mdata/warehouse/modify', params),

  /**
   * 获取仓库信息
   * @param params
   * @returns
   */
  get: (params: { uuid: string }) => post<IWareHouse>('/api/pcweb/mdata/warehouse/get', params),

  /**
   * 获取所有货架位
   * @returns
   */
  shelfGetAll: (params: { warehouse: string }) =>
    post<IWarehouseShelf[]>('/api/pcweb/mdata/warehouse/shelf/getAll', params),

  /**
   * 添加货架位
   * @param params
   * @returns
   */
  shelfCreate: (params: { warehouse: string; id: string }) =>
    post<any>('/api/pcweb/mdata/warehouse/shelf/create', params),

  /**
   *分页查询货位码
   * @param params
   * @returns
   */
  shelfQuery: (
    params: {
      filter?: {
        keyWord: string;
      };
      warehouse: string;
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => post<IPageData<IWarehouseShelf>>('/api/pcweb/mdata/warehouse/shelf/query', params),

  /**
   * 批量删除货架位
   * @param params
   * @returns
   */
  deleteShelfArr: (params: { uuidList: string[] }) => post<any>('/api/pcweb/mdata/warehouse/shelf/removeBatch', params),

  /**
   * 单个删除货架位
   * @param params
   * @returns
   */
  deleteShelf: (params: { uuid: string }) => post<any>('/api/pcweb/mdata/warehouse/shelf/remove', params),

  /**
    分页查询商品-用于绑定货位
   * @param params
   * @returns
   */
  queryGood: (
    params: {
      goodsList?: string[];
      shelf?: string;
      filter?: {
        include?: boolean;
        keyword?: string;
        sortId?: string;
      };
    } & IPageParams
  ) => post<IPageData<IWarehouseShelfGoods>>('/api/pcweb/mdata/warehouse/shelf/queryGoods', params),

  /**
    分页查询货架商品
   * @param params
   * @returns
   */
  queryShelfGoods: (
    params: {
      warehouse?: string;
      filter?: {
        shelfKey?: string;
        goodsKey?: string;
      };
    } & IPageParams
  ) => post<IPageData<IWarehouseGoods>>('/api/pcweb/mdata/warehouse/shelf/queryShelfGoods', params),

  /**
   批量移动货架位商品
   * @param params
   * @returns
   */
  moveShelfGoodsBatch: (params: { shelf: string; uuids: string[] }) =>
    post<any>('/api/pcweb/mdata/warehouse/shelf/moveShelfGoodsBatch', params),

  /**
获取货架位商品列表
   * @param params
   * @returns
   */
  getShelfGoods: (
    params: {
      shelf: string;
      filter?: {
        goodsKey?: string;
      };
    } & IPageParams
  ) => post<IWarehouseGoods[]>('/api/pcweb/mdata/warehouse/shelf/getShelfGoods', params),

  /**
   * 绑定货架位商品
   * @param params
   * @returns
   */
  bindShelfGoods: (params: { goodsUuids: string[]; shelf: string }) =>
    post<any>('/api/pcweb/mdata/warehouse/shelf/bindShelfGoods', params),

  /**
   * 删除货架位商品
   * @param params
   * @returns
   */
  removeShelfGoods: (params: { uuid: string }) =>
    post<any>('/api/pcweb/mdata/warehouse/shelf/removeShelfGoods', params),

  /**
   * 批量删除货架位商品
   * @param params
   * @returns
   */
  removeShelfGoodsBatch: (params: { uuids: string[] }) =>
    post<any>('/api/pcweb/mdata/warehouse/shelf/removeShelfGoodsBatch', params),

  /**
   *
   * 判断是否展示WMS
   *
   **/
  getIsShowWms: () => post<string | boolean>('/api/pcweb/mdata/warehouse/wms/get'),

  /**
   * 是否开通生鲜分拣
   *
   **/
  getShowPick: () => post<IPickMode>('/api/pcweb/tenant/rights/getTenantRightsInfo'),
  /**
   * 添加弹框商品
   *
   **/
  queryGoodsList: (
    params: {
      filter?: {
        /* 商品关键字 */
        searchKey?: string;
        /* 分类编码列表 */
        sortIds?: Array<[]>;
        /* 适用店型 */
        storeModel?: string;
      };
      /* 仓库uuid */
      wrh?: string;
    } & IPageParams
  ) => post<IPageData<IModalGoods>>('/api/pcweb/mdata/warehouse/picking/goods/queryGoods', params),

  /**
   * 添加商品
   */
  addGoods: (
    params: {
      /* 商品uuid列表 */
      goodsList?: Array<[]>;
      /* 是否全选 */
      isCheckAll?: boolean;
      searchKey?: string;
      /* 分类编码列表 */
      sortIds?: Array<[]>;
      storeModel?: string;
      /* 仓库uuid */
      wrh: string;
    } & IPageParams
  ) => post<IPageData<any>>('/api/pcweb/mdata/warehouse/picking/goods/addGoods', params),
  /**
   *
   * 查询接口
   */
  queryGoodsDetail: (
    params: {
      filter?: {
        /*拣货模式  */
        pickingType?: string;
        /* 商品关键字 */
        searchKey?: string;
        /* 货架位关键字 */
        shelfIdLike?: string;
        /* 分类编码 */
        sortId?: string;
        /* 供应商uuid */
        supplier?: string;
      };
      /* 仓库uuid */
      wrh: string;
    } & IPageParams
  ) => post<IPageData<IGoodsList>>('/api/pcweb/mdata/warehouse/picking/goods/query', params),
  /**
   *  编辑分拣商品
   *
   */

  modifyGoods: (params: { pickingType?: string; uuid?: string; shelfId?: string }) =>
    post<IGoodsList>('/api/pcweb/mdata/warehouse/picking/goods/modify', params),

  /**
   * 批量编辑商品
   *
   **/
  modifyAllGoods: (parmas: {
    /* 拣货模式 */
    pickingType?: string;
    /* 货架位 */
    shelfId?: string;
    // 批量uuids
    uuids?: Array<[]>;
  }) =>
    post<{ failedCount?: number; successCount?: number }>(
      '/api/pcweb/mdata/warehouse/picking/goods/batchModify',
      parmas
    ),

  /**
   *
   * 批量删除商品
   */
  delSelectGoods: (params: { uuids?: Array<[]> }) =>
    post<{ failedCount?: number; successCount?: number }>(
      '/api/pcweb/mdata/warehouse/picking/goods/batchRemove',
      params
    ),
  /* 删除单个商品 */
  delItemGoods: (params: { uuid?: string }) => post('/api/pcweb/mdata/warehouse/picking/goods/remove', params),
  // 是否需要切换模式
  isNeedChange: (param: { wrhUuid?: string }) => post('/api/pcweb/picking/getUncloseBillCount', param),
  // 根据仓库uuid 获得仓库信息
  getWarehouseInfo: (param: { uuid?: string }) => post<any>('/api/pcweb/mdata/warehouse/get', param)
};

export default wareHouse;
