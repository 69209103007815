import request from '@/utils/request';
import {
  ICustomerAccount,
  ICustomerBillCount,
  ICustomerDetailCount,
  ICustomerFilter,
  ICustomerAccountDetail
} from '@/dataModel/finance/customerAccount/interface';

export default {
  /** 按单据分页查询 */
  queryBill: (params: { filter: ICustomerFilter }) =>
    request.post<IPageData<ICustomerAccount>>(`/api/pcweb/finance/customer/account/queryBill`, params),

  /** 按单据分页查询 */
  queryDetail: (params: { filter: ICustomerFilter }) =>
    request.post<IPageData<ICustomerAccountDetail>>(`/api/pcweb/finance/customer/account/queryDetail`, params),

  /** 按单据统计 */
  getBillCount: (params: { filter: ICustomerFilter }) =>
    request.post<ICustomerBillCount>('/api/pcweb/finance/customer/account/getBillCount', params),

  /** 按明细统计 */
  getDetailCount: (params: { filter: ICustomerFilter }) =>
    request.post<ICustomerDetailCount>('/api/pcweb/finance/customer/account/getDetailCount', params),

  /**获取权限下的业务员列表 */
  getSalesManList: () => request.post<{ id: string; name: string }[]>('/api/pcweb/finance/customer/account/salesman')
};
