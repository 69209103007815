import request from '@/utils/request';
import { IWholesaleBack, IWholesaleBackDetail, IWholesaleBackGoods } from '@/dataModel/wholesale/back/interface';
import { CustomerBillCheckBillTypeEnum, WholeSaleBackStatusEnum } from '@/dataModel/dict/enum';
const { post } = request;

const wholesaleBack = {
  /** 分页查询批发出货单 */
  query: (
    params: {
      filter?: {
        createDateEnd: string;
        createDateStart: string;
        customerId: string;
        id: string;
        salesmanId: string;
        settleNo: string;
        status: WholeSaleBackStatusEnum;
      };
    } & IPageParams
  ) => post<IPageData<IWholesaleBack>>('/api/pcweb/wholesale/back/query', params),
  /** 分页查询退货单商品详情 */
  queryDetailsByPage: (
    params: {
      filter: {
        //门店id
        bill: string;
        /**查询关键字 */
        searchKey?: string;
        /**单据类型 */
        billType?: CustomerBillCheckBillTypeEnum;
        /**单据编号 */
        id?: string;
      };
    } & IPageParams
  ) => post<IPageData<IWholesaleBackGoods>>('/api/pcweb/wholesale/back/queryDetailsByPage', params),
  /**查询批发退货商品详情 */
  queryGoodsByPage: (
    params: {
      filter?: {
        searchKey?: string;
        // //门店id
        // storeId?: string;
      };
      customerId: string;
    } & IPageParams
  ) => post<IPageData<IWholesaleBackGoods>>('/api/pcweb/wholesale/back/queryGoodsByPage', params),
  /**新增批发退货单 */
  save: (params: {
    outBillId?: string;
    reason: string;
    remark: string;
    saleBackDetails: {
      goods: string;
      munitUuid: string;
      outBillDetail: string;
      outBillDetailLine: number;
      price: number;
      qtyStr: string;
      total: number;
    }[];
    salesmanId: string;
    senderId: string;
    settleType: string;
    type: WholeSaleBackStatusEnum;
    uuid?: IWholesaleBackDetail['uuid'];
    version?: number;
  }) => post('/api/pcweb/wholesale/back/save', params),
  /** 获取批发退货单详情 */
  queryDetail: (params: { uuid: string; billType?: CustomerBillCheckBillTypeEnum; id?: string }) =>
    post<IWholesaleBack>('/api/pcweb/wholesale/back/queryDetail', params),
  /**  变更批发退货单状态*/
  changeStatus: (params: {
    status: WholeSaleBackStatusEnum;
    saleBackList: {
      uuid: string;
      version: number;
    }[];
  }) => post<any>('/api/pcweb/wholesale/back/changeStatus', params),
  /** 删除待提交批发退货单 */
  delete: (params: { uuid: string }) => post('/api/pcweb/wholesale/back/delete', params),
  /**根据导入任务获取商品 */
  getGoodsByIoTask: (params: { taskUuid: string }) =>
    post<IWholesaleBackGoods[]>('/api/pcweb/wholesale/back/getGoodsByIoTask', params)
};

export default wholesaleBack;
