import request from '@/utils/request';
import {
  IBusinessDistributiondiff,
  IBusinessDistributiondiffDetailRes,
  IDistribututionDifference,
  IDistribututionDifferenceDetail
} from '@/dataModel/business/distributiondiff/interface';
const { post } = request;

const distributiondiff = {
  /**
   * 查询收货差异列表
   * @param params
   * @returns
   */
  query: (
    params: {
      filter?: {
        createDateBegin?: string;
        createDateEnd?: string;
        id?: string;
        goodsInfo?: string;
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => post<IPageData<IBusinessDistributiondiff>>('/api/pcweb/business/distributiondiff/query', params),

  /**
   * 查询收货差异列表
   * @param params
   * @returns
   */
  queryDetail: (
    params: {
      uuid?: string;
      id: string;
      filter?: {
        identificationName?: string;
        id?: string;
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => post<IBusinessDistributiondiffDetailRes>('/api/pcweb/business/distributiondiff/queryDetail', params),

  /**
   * 门店确认
   * @param params
   * @returns
   */
  confirm: (params: { bill: string }) => post<any>('/api/pcweb/business/distributiondiff/confirm', params),

  /**
   *
   *  获取配送差异处理 - 待处理/已处理
   */
  queryForHead: (
    params: {
      filter: {
        id: string;
        settleNo: string;
        storeKeyWord: string;
        goodsInfo?: string;
      };
      status: string;
    } & IPageParams
  ) => post<IPageData<IDistribututionDifference>>('/api/pcweb/business/distributiondiff/queryForHead', params),
  /**
   *
   *  获取差异单详情页
   */
  queryDetailForHead: (params: { uuid?: string; id?: string }) =>
    post<IDistribututionDifference>('/api/pcweb/business/distributiondiff/get', params),
  /**
   *
   *  获取差异单详情页list 待处理/已处理
   */
  queryDetailListForHead: (
    params: {
      filter: {
        goodsKeyWord: string;
      };
      bill: string;
    } & IPageParams
  ) =>
    post<IPageData<IDistribututionDifferenceDetail>>('/api/pcweb/business/distributiondiff/queryDetailForHead', params),

  /**
   *  总部保存差异
   */
  save: (params: {
    bill: string;
    examines?: {
      goodsQpc: number;
      goodsWeight: number;
      price: number;
      qty: number;
      remark: string;
      storeCommitNum: number;
      taxRate: number;
      uuid: string;
      wrhCommitNum: number;
      sendQty?: number;
    }[];
    remark: string;
    version: number;
  }) => post<{ traceId: string; version: number }>('/api/pcweb/business/distributiondiff/examineDistribute', params),
  /**
   *  总部完成处理提交
   */
  submit: (params: { bill: string; version: number }) =>
    post<{ traceId: string }>('/api/pcweb/business/distributiondiff/submit', params)
};
export default distributiondiff;
