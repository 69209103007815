import request from '@/utils/request';
import {
  IHeadMemberRegister,
  IHeadMemberFilter,
  IHeadMemberRegisterTotal,
  IHeadMemberCardBind,
  IHeadMemberCardBindFilter
} from '../../../dataModel/member/headMemberRegister/interface';
const { post } = request;

const headMemberRegister = {
  /** 总部-会员注册汇总 */
  queryHeadMemberRegister: (
    params: {
      filter?: IHeadMemberFilter;
    } & IPageParams
  ) => post<IPageData<IHeadMemberRegister>>('/api/pcweb/member/info/queryHeadMemberRegister', params),

  /** 总部-会员注册汇总统计 */
  queryHeadMemberRegisterTotal: (
    params: {
      filter?: IHeadMemberFilter;
    } & IPageParams
  ) => post<IHeadMemberRegisterTotal>('/api/pcweb/member/info/queryHeadMemberRegisterTotal', params),

  /** 总部-会员实体卡绑定 */
  queryHeadMemberCardBind: (
    params: {
      filter?: IHeadMemberCardBindFilter;
    } & IPageParams
  ) => post<IPageData<IHeadMemberCardBind>>('/api/pcweb/member/info/queryHeadMemberCardBind', params),

  /** 总部-会员实体卡绑定统计 */
  queryHeadMemberCardBindTotal: (
    params: {
      filter?: IHeadMemberCardBindFilter;
    } & IPageParams
  ) => post<IHeadMemberRegisterTotal>('/api/pcweb/member/info/queryHeadMemberCardBindTotal', params),

  /** 查询会员卡类型 */
  queryMemberCardTemplate: () =>
    post<{ templateId: string; templateName: string }[]>('/api/pcweb/member/info/queryMemberCardTemplate')
};

export default headMemberRegister;
