/**
 * 电子秤模板
 *
 * @file index.ts
 */

import { EwsTypeEnum, EwsTransferChannelEnum } from '@/dataModel/dict/enum';
import {
  IEwsTemplate,
  IEwsTemplateCreate,
  IEwsTemplateDetail,
  IEwsTemplateModified,
  IEwsTemplateQueryGoods
} from '@/dataModel/equipment/ewsTemplate/interface';
import request from '@/utils/request';

type Query = Partial<
  {
    filter: {
      ewsType: EwsTypeEnum;
      name: string;
      /**
       * 生效门店
       */
      enableStore: string;
      transferChannel?: EwsTransferChannelEnum;
    };
    orderBy: IPageParamsOrderBy;
  } & IPageParams
>;

export default {
  // 保存
  save: (params: IEwsTemplateCreate) => request.post('/api/pcweb/equipment/ewsTemplate/save', params),
  // 修改
  modified: (params: IEwsTemplateModified) => request.post('/api/pcweb/equipment/ewsTemplate/modified', params),
  // 删除
  delete: (params: { uuid: string }) => request.post('/api/pcweb/equipment/ewsTemplate/delete', params),

  //   modify: params => request.post('/api/pcweb/equipment/ewsTemplate/modify', params),
  // 分页查
  query: (params: Query) => request.post<IPageData<IEwsTemplate>>('/api/pcweb/equipment/ewsTemplate/query', params),
  // 详情
  get: (params: { uuid: string }) => request.post<IEwsTemplateDetail>('/api/pcweb/equipment/ewsTemplate/get', params),
  // 根据模板查询门店
  getTemplateStoreList: (uuid: string) =>
    request.post<{ store: string; storeId: string; storeName: string }[]>(
      '/api/pcweb/equipment/ewsTemplate/getTemplateStoreList',
      { uuid }
    ),

  // 分页查询商品资料
  queryGoods: (params: { filter: { searchKey: string } } & IPageParams) =>
    request.post<IPageData<IEwsTemplateQueryGoods>>('/api/pcweb/equipment/ewsTemplate/queryGoods', params),
  ewsSupports: () =>
    request.post<{
      android: string[];
      windows: string[];
    }>('/api/pcweb/equipment/ews/transferchannel/supports')
};
