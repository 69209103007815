import { IOMallGoodsItems, IQueryItems } from '@/dataModel/mdata/omallPriceCustome/interface';
import request from '@/utils/request';

const { post } = request;

const omallPriceCustome = {
  /**分页查询约定批发价列表 */
  query: (
    params: {
      filter: {
        customerKeyword?: string;
        goodsKeyword?: string;
        salesmanInfo?: string;
      };
    } & IPageParams
  ) => post<IPageData<IQueryItems>>('/api/pcweb/mdata/omall/price/customer/query', params),

  /** 分页查询商品-用于添加 */
  queryGoodsForAdd: (
    params: {
      filter?: {
        keyword?: string;
        sortId?: string;
      };
    } & IPageParams
  ) => post<IPageData<IOMallGoodsItems>>('/api/pcweb/mdata/omall/price/customer/queryGoodsForAdd', params),

  /** 新增约定批发价 */
  save: (params: { customerUuid: string; customerPriceGoodsList: { goodsUuid: string; price: number }[] }) =>
    post<IQueryItems>('/api/pcweb/mdata/omall/price/customer/save', params),

  /** 修改约定批发价 */
  modify: (params: { price: number; uuid: string; version: number }) =>
    post<{ traceId: string; version: number }>('/api/pcweb/mdata/omall/price/customer/modify', params),

  /** 批量删除约定批发价 */
  batchRemove: (params: { uuids: string[] }) =>
    post<{ traceId: string }>('/api/pcweb/mdata/omall/price/customer/batchRemove', params)
};

export default omallPriceCustome;
