import {
  BaseModifyRequest,
  BaseModifyResult,
  BaseSaveResult,
  Picking,
  PickingQueryFilter,
  PickingTask,
  PickingTaskQueryFilter,
  PickingWave,
  PickingWaveAllocOrder,
  PickingWaveDetail,
  PickingWaveGetDetailsRequest,
  PickingWaveGetRequest,
  PickingWaveQueryFilter,
  PickingWaveSaveRequest,
  PickingWaveStore,
  WrhAllocOrder,
  WrhAllocOrderQueryBillFilter
} from '@/dataModel/wrh/sorting/waves/interface';
import request from '@/utils/request';
const { post } = request;

const sortingWaves = {
  /**
   * /api/pcweb/picking/wave/finish 波次单确认完成
   */
  finish: (params: BaseModifyRequest) => post<BaseModifyResult>('/api/pcweb/picking/wave/finish', params),
  /**
   * /api/pcweb/picking/wave/get 查询单头
   */
  get: (params: PickingWaveGetRequest) => post<PickingWave>('/api/pcweb/picking/wave/get', params),
  // /api/pcweb/picking/wave/getDetails 查询波次单详情
  getDetails: (params: PickingWaveGetDetailsRequest) =>
    post<PickingWaveDetail>('/api/pcweb/picking/wave/getDetails', params),
  queryStores: (params: { filter: PickingWaveGetDetailsRequest } & IPageParams) =>
    post<IPageData<PickingWaveStore>>('/api/pcweb/picking/wave/queryStores', params),
  /**
   * 
   * @param params /api/pcweb/picking/wave/queryAllocOrders
配货通知单列表
   * @returns 
   */
  queryAllocOrders: (params: { filter: PickingWaveGetDetailsRequest } & IPageParams) =>
    post<IPageData<PickingWaveAllocOrder>>('/api/pcweb/picking/wave/queryAllocOrders', params),
  // /api/pcweb/picking/wave/query 列表查询
  query: (
    params: {
      filter: PickingWaveQueryFilter;
    } & IPageParams
  ) => post<IPageData<PickingWave>>('/api/pcweb/picking/wave/query', params),
  // /api/pcweb/picking/wave/remove 删除
  remove: (params: BaseModifyRequest) => post<BaseModifyResult>('/api/pcweb/picking/wave/remove', params),
  // /api/pcweb/picking/wave/rollback 回滚
  rollback: (params: BaseModifyRequest) => post<BaseModifyResult>('/api/pcweb/picking/wave/rollback', params),
  // /api/pcweb/picking/wave/save 保存
  save: (params: PickingWaveSaveRequest) => post<BaseSaveResult>('/api/pcweb/picking/wave/save', params),
  // /api/pcweb/picking/wave/start 启动波次
  start: (params: BaseModifyRequest) => post<BaseModifyResult>('/api/pcweb/picking/wave/start', params),
  /**
   * /api/pcweb/picking/wave/confirmStartFinish
波次单确认完成
   */
  confirmStartFinish: (params: BaseModifyRequest) =>
    post<BaseModifyResult>('/api/pcweb/picking/wave/confirmStartFinish', params),
  /**
   * /api/pcweb/wrh/alloc/order/queryBill
分页查询单据
   */
  queryBill: (
    params: {
      filter: WrhAllocOrderQueryBillFilter;
    } & IPageParams
  ) => post<IPageData<WrhAllocOrder>>('/api/pcweb/wrh/alloc/order/queryBill', params),
  /**
   * /api/pcweb/picking/task/query
任务列表查询
   */
  queryTask: (params: { filter: PickingTaskQueryFilter } & IPageParams) =>
    post<IPageData<PickingTask>>('/api/pcweb/picking/task/query', params),
  /**
   * /api/pcweb/picking/query
标品拣货单查询分页查询 */
  queryStandard: (params: { filter: PickingQueryFilter } & IPageParams) =>
    post<IPageData<Picking>>('/api/pcweb/picking/query', params)
};

export default sortingWaves;
