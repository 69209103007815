import {
  IBusinessReturnOrderItem,
  IBusinessReturnOrderHistory,
  IBusinessReturnOrderGoodsItem,
  IBusinessReturnOrderSupplier,
  IHeadReturnOrder,
  IReturnOrderDetailGoods,
  IReturnOrderGoods,
  IBusinessReturnOrderHistoryGoodList
} from '@/dataModel/business/returnOrder/interface';
import { ReturnOrderTypeEnum, ReturnOrderTypeStatusEnum } from '@/dataModel/dict/enum';
import request from '@/utils/request';
const { post } = request;

const returnOrder = {
  /**
   * 分页查询门店退货
   * @param params
   * @returns
   */
  query: (
    params: {
      filter: {
        id?: string;
        returnStatus?: string;
        returnType?: string;
        isFlag?: boolean; //true/待退货 false 历史退货
        goodsInfo?: string;
      };
    } & IPageParams
  ) => post<IPageData<IBusinessReturnOrderItem>>('/api/pcweb/business/returnorder/query', params),

  /**
   * 分页查询门店已经退货详情
   * @param params
   * @returns
   */
  queryReturnedDetail: (
    params: {
      filter?: {
        goodsNameOrId?: string;
      };
      returnType: string;
      uuid?: string;
      id?: string;
    } & IPageParams
  ) => post<IBusinessReturnOrderHistory>('/api/pcweb/business/returnorder/queryReturnedDetail', params),

  /**
   * 分页查询门店待退货详情
   * @param params
   * @returns
   */
  queryReturningDetail: (params: {
    filter?: {
      goodsNameOrId?: string;
    };
    returnType: string;
    uuid?: string;
    id?: string;
  }) => post<IBusinessReturnOrderHistory>('/api/pcweb/business/returnorder/queryReturningDetail', params),

  /**
   * 根据供应商和退货类型获取商品列表
   * @param params
   * @returns
   */
  getGoods: (params: { returnType?: string; supplierId?: string; supplierName?: string }) =>
    post<{ list: IBusinessReturnOrderGoodsItem[] }>('/api/pcweb/business/returnorder/getGoods', params),

  /**
   * 获取供应商
   * @param params
   * @returns
   */
  getSupplier: (params: { returnType?: string }) =>
    post<{ list: IBusinessReturnOrderSupplier[] }>('/api/pcweb/business/returnorder/getSupplier', params),
  /**
   * 提交表单
   * @param params
   * @returns
   */
  submit: (params: { id: string; returnType: string }) => post<any>('/api/pcweb/business/returnorder/submit', params),
  /**
   * 提交表单
   * @param params
   * @returns
   */
  modify: (params: {
    details: {
      goodsId: string;
      goodsMunit: string;
      price: number;
      qpcStr: string;
      qtyStr: string;
      uuid: string;
      version: number;
    }[];
    returnType: string;
    id: string;
    version: number;
  }) => post<any>('/api/pcweb/business/returnorder/modify', params),
  /**
   * 提交表单
   * @param params
   * @returns
   */
  create: (params: {
    details?: {
      goodsId: string;
      goodsMunit: string;
      goodsQpcStr: string;
      price: number;
      qty: number;
      reason: string;
      total: number;
    }[];
    reason: string;
    remark: string;
    returnType: string;
    supplier: string;
    supplierId: string;
    supplierName: string;
    total: number;
  }) => post<any>('/api/pcweb/business/returnorder/create', params),

  /**
   * 总部分页查询自采退货
   */
  queryForHead: (
    params: {
      filter?: {
        createEndTime?: string;
        createStartTime?: string;
        goodsKeyWord?: string;
        id?: string;
        recvEndTime?: string;
        recvStartTime?: string;
        settleNo?: number | string;
        status?: any;
        store?: string;
        supplierKeyWord?: string;
      };
    } & IPageParams
  ) => post<IPageData<IHeadReturnOrder>>('/api/pcweb/business/returnorder/queryForHead', params),

  /**
   * 总部分页查询自采退货详情
   */
  queryDetailForHead: (
    params: {
      bill: string;
      filter?: {
        goodsKeyWord?: string;
      };
    } & IPageParams
  ) => post<IPageData<IReturnOrderDetailGoods>>('/api/pcweb/business/returnorder/queryDetailForHead', params),

  /**
   * 总部作废自采退货单
   */
  invalid: (params: { uuid: string; version: number }) => post<any>('/api/pcweb/business/returnorder/invalid', params),

  /**
   * 获取总部自采退货单头
   */
  getStoreInBackForHead: (params: { uuid: string }) =>
    post<IHeadReturnOrder>('/api/pcweb/business/returnorder/getStoreInBackForHead', params),

  /**
   * 查询自采/直送退货商品
   */
  queryGoods: (params: { returnType: ReturnOrderTypeEnum; supplier: string }) =>
    post<IReturnOrderGoods[]>('/api/pcweb/business/returnorder/queryGoods', params),

  /**
   * 获取单头信息
   */
  get: (params: { returnType: ReturnOrderTypeStatusEnum; bill: string }) =>
    post<IBusinessReturnOrderHistory>('/api/pcweb/business/returnorder/get', params),

  /**
   * 分页查询明细
   */
  queryDetail: (
    params: {
      returnType: ReturnOrderTypeStatusEnum;
      bill: string;
      filter?: {
        keyword: string;
      };
    } & IPageParams
  ) => post<IPageData<IBusinessReturnOrderHistoryGoodList>>('/api/pcweb/business/returnorder/queryDetail', params)
};
export default returnOrder;
