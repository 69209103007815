import request from '@/utils/request';
import { ReportConfigTypeEnum } from '@/dataModel/dict/enum';
import { IReportConfigs, IReportData } from '@/dataModel/system/settingTableConfig/interface';
const { post } = request;

const settingReportConfig = {
  /** 保存用户表格配置 */
  saveReportConfig: (params: {
    /** 列表配置 */
    detailSaveList: any[];
    /**是否商户级配置 */
    isTenant?: boolean;
    /** 报表类型 */
    reportType: ReportConfigTypeEnum;
  }) => post('/api/pcweb/report/config/saveReportConfig', params),

  /** 获取用户表格配置 */
  get: (params: { reportConfigType: ReportConfigTypeEnum }) =>
    post<IReportData>('/api/pcweb/report/config/get', params),
  /**获取初始配置 */
  initReportConfig: (params: { reportConfigType: ReportConfigTypeEnum }) =>
    post<IReportConfigs[]>('/api/pcweb/report/config/initReportConfig', params)
};

export default settingReportConfig;
