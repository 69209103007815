import request from '@/utils/request';
import { WrhWholesaleBack, BaseModifyResult } from '@/dataModel/wholesale/wrhWholesaleBack/interface';
import { WholeSaleBackStatusEnum, WrhWholeSaleBackStatusEnum } from '@/dataModel/dict/enum';
import { IWholesaleOut, IWholesaleOutDetailGoods } from '@/dataModel/wholesale/warehouse/interface';
import { string } from 'prop-types';
const { post } = request;
interface BatchSubmitWrhwholesaleParam {
  id: string;
  uuid: string;
  version: number;
}
interface BatchSubmitWrhwholesaleRes {
  failedCount: number;
  successCount: number;
}

interface DelWrhwholesaleRes {
  traceId: string;
}
interface GoodsDetail {
  goods: string;
  //商品唯一标识 传goods
  goodsMunitId: string;
  //批发单位-Id
  price: number;
  //退货价
  qty: number;
  //退货数量
  total: number;
  //退货小计
}
const wrhWholesaleBack = {
  /** 分页查询仓库批发出货单 */
  wrhwholesaleQuery: (
    params: {
      filter?: {
        createDateEnd: string; //制单结束日期
        createDateStart: string; //制单开始日期
        customerId: string; //客户编码/名称
        goodsInfo: string; //商品筛选关键词(商品编码/条码/名称)
        id: string; //退货单号
        occurDateEnd?: string; //退货结束日期
        occurDateStart?: string; //退货开始日期
        salesmanId: string; //业务员编码/名称
        settleNo: string;
        statusList: WholeSaleBackStatusEnum[]; //状态
        uuid?: string; //退货单标识符
        wrhId: string; //仓库唯一标识
      };
    } & IPageParams
  ) => post<IPageData<WrhWholesaleBack>>('/api/pcweb/wholesale/wrh/back/query', params),
  /** 修改退货单状态 */
  changeWrhwholesaleStatus: (params: {
    id: string;
    targetStatus: WrhWholeSaleBackStatusEnum;
    uuid: string;
    version: number;
  }) => post<BaseModifyResult>('/api/pcweb/wholesale/wrh/back/submit', params),
  /** 批量审核退货单 */
  batchSubmitWrhwholesale: (params: { bills: BatchSubmitWrhwholesaleParam[] }) =>
    post<BatchSubmitWrhwholesaleRes>('/api/pcweb/wholesale/wrh/back/batchSubmit', params),
  /**保存 保存并提交 保存并审核总部批发退货单 */
  saveWrhwholesale: (params: {
    customerId: string; //客户编码
    details: GoodsDetail[]; //明细信息
    occurDate: string; //交货日期
    outBillId?: string; //批发出货单单号
    reason: string; //退货原因
    remark?: string; //备注
    salesmanId?: string; //业务员编码
    settleType?: string; //结算方式(byNow-现结，byPeriod-账期结算)
    targetStatus?: string; //保存目标状态，可取值：(init)待提交，(finished)已完成
    uuid?: string; //单据唯一标识
    version?: number; //
    wrhId: string; //仓库编码
  }) => post<any>('/api/pcweb/wholesale/wrh/back/save', params),
  /**退货单详情 */
  queryWrhwholesaleDetail: (params: { id: string; uuid: string }) =>
    post<any>('/api/pcweb/wholesale/wrh/back/queryBack', params),
  /**退货单商品 */
  queryWrhwholesaleGoodsList: (params: {
    filter: {
      billId: string; //单据标识
      searchKey?: string; //关键字查询
    };
    page: 0;
    pageSize: 0;
  }) => post<any>('/api/pcweb/wholesale/wrh/back/queryBackDetail', params),
  /**删除批发退货单 */
  delWrhwholesale: (params: { id: string; targetStatus: string; uuid: string; version: number }) =>
    post<DelWrhwholesaleRes>('/api/pcweb/wholesale/wrh/back/remove', params),
  /**删除批发退货单 */
  wrhwholesaleQuerySaleOut: (params: {
    filter: {
      billId: string; //单据标识
    };
    customerId: string;
    page: 0;
    pageSize: 0;
  }) => post<IWholesaleOut>('/api/pcweb/wholesale/wrh/back/querySaleOut', params),
  /**根据导入任务获取商品 */
  getGoodsByIoTask: (params: { taskUuid: string }) =>
    post<IWholesaleOutDetailGoods[]>('/api/pcweb/wholesale/wrh/goods/getGoodsByIoTask', params),
  /** 修改退货单状态 */
  abortWrhwholesale: (params: {
    id: string;
    targetStatus: WrhWholeSaleBackStatusEnum;
    uuid: string;
    version: number;
  }) => post<BaseModifyResult>('/api/pcweb/wholesale/wrh/back/abort', params)
};
export default wrhWholesaleBack;
