import request from '@/utils/request';
import { OMallGoods } from '@/dataModel/oMall/goods/interface';

const { post } = request;

const oMallGoods = {
  /** 获取首页装修配置 */
  query: (
    params: {
      filters: Partial<{
        /** 分类编码等于 */
        categoryCodeEq: string;
        /** 是否有图片 */
        hasImage: boolean;
        /** 关键字 */
        keyword: string;
        /** 状态等于 */
        statusEq: string;
        /** 类型等于 */
        typeEq: string;
        /** 是否上架 */
        up: boolean;
      }>;
    } & IPageParams
  ) => post<IPageData<OMallGoods>>('/api/pcweb/omall/goods/query', params),
  /** 商品分组列表 */
  categoryList: () =>
    post<
      {
        goodsCount: number | null;
        id: string;
        level: number;
        name: string;
        parent: { id: string; name: string };
      }[]
    >('/api/pcweb/omall/goods/category/list')
};

export default oMallGoods;
