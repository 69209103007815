import request from '@/utils/request';
import {
  IWholesaleOut,
  IWholesaleOutDetailGoods,
  IWholesaleOutGoods,
  IWholesaleOutInvoice,
  IWholesaleOutGoodsMunit
} from '@/dataModel/wholesale/warehouse/interface';
import { WholeSaleOutStatusEnum } from '@/dataModel/dict/enum';
const { post } = request;

const warehouseOut = {
  /** 分页查询仓库批发出货单 */
  query: (
    params: {
      filter?: {
        customer?: string;
        endSubmitDate?: string;
        endOccurDate?: string;
        id?: string;
        salesman?: string;
        searchKey?: string;
        settleNo?: string;
        startSubmitDate?: string;
        startOccurDate?: string;
        status?: WholeSaleOutStatusEnum;
        wrh?: string[];
      };
    } & IPageParams
  ) => post<IPageData<IWholesaleOut>>('/api/pcweb/wholesale/wrh/out/queryWrhWholeSaleOut', params),

  /** 分页查询商品列表 */
  queryGoods: (
    params: {
      filter?: {
        keyword?: string;
        sortId?: string;
      };
      wrhUuid: string;
      customerId: string;
      isBack: boolean;
    } & IPageParams
  ) => post<IPageData<IWholesaleOutGoods>>('/api/pcweb/wholesale/wrh/goods/queryGoodsForAdd', params),

  /** 创建批发出货单,保存/保存并提交/保存并发货/保存并审核 */
  create: (params: {
    customer: string;
    goodsDetails: any[];
    locationAddress?: string;
    occurDate: string;
    remark?: string;
    salesman?: string;
    settleType?: string;
    status: string;
    wrh: string;
  }) => post<any>('/api/pcweb/wholesale/wrh/out/create', params),

  /** 查询批发单单头 */
  queryOut: (params: { uuid?: string; id?: string }) =>
    post<IWholesaleOut>('/api/pcweb/wholesale/wrh/out/getByUuid', params),

  /** 分页查询发货单商品明细 */
  queryDetails: (
    params: {
      filter: {
        uuid?: string;
        searchKey?: string;
        isLoadMunits?: boolean;
      };
    } & IPageParams
  ) => post<IPageData<IWholesaleOutDetailGoods>>('/api/pcweb/wholesale/wrh/out/getDetailByUuid', params),
  /** 分页查询发货单商品明细 后台新提供的 */
  getDetailByUuidForEdit: (
    params: {
      filter: {
        uuid?: string;
        searchKey?: string;
        isLoadMunits?: boolean;
      };
    } & IPageParams
  ) => post<IPageData<IWholesaleOutDetailGoods>>('api/pcweb/wholesale/wrh/out/getDetailByUuidForEdit', params),

  /**出货单修改保存/保存并提交/保存并审核/保存并发货 */
  modify: (params: {
    uuid: string;
    locationAddress: string;
    occurDate: string;
    goodsDetails: any[];
    customer: string;
    remark?: string;
    salesman?: string;
    settleType?: string;
    status: string;
    version: number;
    wrh: string;
  }) => post<any>('/api/pcweb/wholesale/wrh/out/modify', params),

  /** 出货单提交/审核/收货/作废 */
  submits: (params: { uuids: { uuid: string; version: number }[]; status: string }) =>
    post<any>('/api/pcweb/wholesale/wrh/out/submit', params),
  /** 批发单状态修改-单个包含（提交，审核，收货，作废）*/
  submitSingle: (params: { uuid: string; version: number; status: string }) =>
    post<any>('/api/pcweb/wholesale/wrh/out/submitSingle', params),
  /**审核 废弃*/
  toApproved: (params: { uuids: { uuid: string; version: number }[] }) =>
    post<any>('/api/pcweb/wholesale/wrh/out/toApproved', params),
  /**批发单收货 废弃*/
  toReceived: (params: { uuids: { uuid: string; version: number }[] }) =>
    post<any>('/api/pcweb/wholesale/wrh/out/toReceived', params),
  /**批发单发货 废弃*/
  toSend: (params: {
    sends: {
      uuid: string;
      version: number;
      goodsDetails: {
        qty: number;
        qtyStr: string;
        total: number;
        uuid: string;
      }[];
    }[];
  }) => post<any>('/api/pcweb/wholesale/wrh/out/toSend', params),
  /**批发单发货 单个*/
  toSendSingle: (params: {
    uuid: string;
    version: number;
    goodsDetails: {
      qty: number;
      qtyStr: string;
      total: number;
      uuid: string;
    }[];
  }) => post<any>('/api/pcweb/wholesale/wrh/out/toSendSingle', params),
  /** 出货单作废 废弃*/
  aborted: (params: { uuid: string; version: number }) => post<any>('/api/pcweb/wholesale/wrh/out/aborted', params),

  /** 出货单提交/审核/收货/作废 */
  wholesaleSubmits: (params: { uuid: string; version: number }) =>
    post<any>('/api/pcweb/wholesale/wrh/out/submits', params),
  /** 发货; */
  ship: (params: {
    uuid: string;
    details: { goodsId: string; qty: number; qtyStr: string; uuid: string; total: number; version: number }[];
    version: number;
  }) => post<any>('/api/pcweb/wholesale/out/ship', params),

  /** 查询发票; */
  queryInvoice: (params: { uuid: string }) =>
    post<IWholesaleOutInvoice[]>('/api/pcweb/wholesale/wrh/out/getInvoices', params),

  /** 新增发票; */
  createInvoice: (params: {
    bill: string;
    invoiceCode: string;
    invoiceNo: string;
    invoiceType: string;
    makeDate: string;
    taxRate: number;
    total: number;
  }) => post<any>('/api/pcweb/wholesale/wrh/out/addInvoice', params),

  /**批发单删除 */
  remove: (params: { uuid: string }) => post<any>('/api/pcweb/wholesale/wrh/out/deletes', params),

  /**批量发货/收货/审核 */
  batchModifyStatus: (params: { status: string; filters: { uuid: string; version: number }[] }) =>
    post<any>('/api/pcweb/wholesale/out/batchModifyStatus', params),

  /**移除发票 */
  removeInvoice: (params: { uuid: string }) => post<any>('/api/pcweb/wholesale/wrh/out/removeInvoice', params),

  /**根据导入任务获取商品 */
  getGoodsByIoTask: (params: { taskUuid: string }) =>
    post<IWholesaleOutDetailGoods[]>('/api/pcweb/wholesale/wrh/goods/getGoodsByIoTask', params),
  /** 判断是否允许退货*/
  getCheckCanBack: (params: { uuid: string }) => post<any>('/api/pcweb/wholesale/wrh/goods/checkCanBack', params)
};

export default warehouseOut;
