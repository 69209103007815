import request from '@/utils/request';
import {
  IReportMonthStore,
  IReportMonthStoreTotal,
  IReportMonthStoreGeneralSort,
  IReportMonthStoreMediumSort,
  IReportMonthStoreSubSort,
  IReportMonthStoreGoods
} from '@/dataModel/finance/reportMonthStore/interface';
const { post } = request;

export default {
  /**查询进销存月报合计 废弃 */
  queryTotalByStore: (
    params: {
      filter?: {
        goodsId?: string; //商品编码
        storeId?: string; //门店编码
        goodsBarcode?: string; //商品条码
        goodsName?: string; //商品名称
        sortId?: string; //商品分类
        settleNo?: number; //期号
        searchKey?: string; //模糊查询
      };
    } & IPageParams
  ) => post<IReportMonthStoreTotal>('/api/pcweb/finance/report/month/store/queryTotalByStore', params),
  /** 门店/仓库维度 */
  queryByStore: (
    params: {
      filter?: {
        settleNo?: number; //期号
        storeId?: string; //门店Id
      };
    } & IPageParams
  ) => post<IPageData<IReportMonthStore>>('/api/pcweb/finance/report/month/store/queryByStore', params),
  /** 门店/仓库维度合计 */
  queryByStoreTotal: (
    params: {
      filter?: {
        settleNo?: number; //期号
        storeId?: string; //门店Id
      };
    } & IPageParams
  ) => post<IReportMonthStoreTotal>('/api/pcweb/finance/report/month/store/queryByStoreTotal', params),

  /**按商品分类大类查询 */
  queryByGeneralSort: (
    params: {
      filter?: {
        settleNo?: number; //期号
        storeId?: string; //门店编码
        sortId?: string; //商品分类
      };
    } & IPageParams
  ) =>
    post<IPageData<IReportMonthStoreGeneralSort>>('/api/pcweb/finance/report/month/store/queryByGeneralSort', params),
  /**按商品分类大类查询合计 */
  queryByGeneralSortTotal: (
    params: {
      filter?: {
        settleNo?: number; //期号
        storeId?: string; //门店编码
        sortId?: string; //商品分类
      };
    } & IPageParams
  ) => post<IReportMonthStoreTotal>('/api/pcweb/finance/report/month/store/queryByGeneralSortTotal', params),

  /**按商品分类中类查询 */
  queryByMediumSort: (
    params: {
      filter?: {
        settleNo?: number; //期号
        storeId?: string; //门店编码
        sortId?: string; //商品分类
      };
    } & IPageParams
  ) => post<IPageData<IReportMonthStoreMediumSort>>('/api/pcweb/finance/report/month/store/queryByMediumSort', params),
  /**按商品分类中类查询合计 */
  queryByMediumSortTotal: (
    params: {
      filter?: {
        settleNo?: number; //期号
        storeId?: string; //门店编码
        sortId?: string; //商品分类
      };
    } & IPageParams
  ) => post<IReportMonthStoreTotal>('/api/pcweb/finance/report/month/store/queryByMediumSortTotal', params),
  /**按商品分类小类查询 */
  queryBySubSort: (
    params: {
      filter?: {
        settleNo?: number; //期号
        storeId?: string; //门店编码
        sortId?: string; //商品分类
      };
    } & IPageParams
  ) => post<IPageData<IReportMonthStoreSubSort>>('/api/pcweb/finance/report/month/store/queryBySubSort', params),
  /**按商品分类小类查询合计 */
  queryBySubSortTotal: (
    params: {
      filter?: {
        settleNo?: number; //期号
        storeId?: string; //门店编码
        sortId?: string; //商品分类
      };
    } & IPageParams
  ) => post<IReportMonthStoreTotal>('/api/pcweb/finance/report/month/store/queryBySubSortTotal', params),
  /**按商品查询 */
  queryByGoodsId: (
    params: {
      settleNo?: number; //期号
      goodsId?: string; //商品编码
      storeId?: string; //门店编码
      goodsBarcode?: string; //商品条码
      goodsName?: string; //商品名称
      sortId?: string; //商品分类
      searchKey?: string; //模糊查询
    } & IPageParams
  ) => post<IPageData<IReportMonthStoreGoods>>('/api/pcweb/finance/report/month/store/queryByGoodsId', params),
  /**按商品查询合计 */
  queryByGoodsIdTotal: (
    params: {
      settleNo?: number; //期号
      goodsId?: string; //商品编码
      storeId?: string; //门店编码
      goodsBarcode?: string; //商品条码
      goodsName?: string; //商品名称
      sortId?: string; //商品分类
      searchKey?: string; //模糊查询
    } & IPageParams
  ) => post<IReportMonthStoreTotal>('/api/pcweb/finance/report/month/store/queryByGoodsIdTotal', params),
  /**
   * 进销存2.0 报表
   *
   * * */
  /* 按照门店维度查询 进销存2.0 */
  queryByStoreNew: (params: {
    filter?: {
      settleNo?: number;
      isStore: boolean; // 是否为门店
      wrhIdList?: Array<[]>; //门店或仓库编码
    };
  }) => post<IPageData<IReportMonthStore>>('/api/pcweb/finance/report/month/store/queryByStoreNew', params),
  /* 按照门店维度查询合计 进销存2.0 */
  getTotalByStoreNew: (params: {
    filter?: {
      settleNo?: number;
      isStore: boolean; // 是否为门店
      wrhIdList?: Array<[]>; //门店或仓库编码
    };
  }) => post<IPageData<IReportMonthStore>>('/api/pcweb/finance/report/month/store/getTotalByStore', params),
  /* 按照分类查询 */
  queryBySort: (
    params: {
      filter?: {
        isStore?: boolean;
        settleNo: number;
        sortIdList: Array<[]>; // 类别集合
        sortType: Array<[]>; // 类别维度
        wrhIdList: Array<[]>; // 门店或仓库编码
      };
    } & IPageParams
  ) => post<IPageData<IReportMonthStore>>('/api/pcweb/finance/report/month/store/queryBySort', params),
  /* 按照分类合计 */
  getTotalBySort: (params: {
    filter?: {
      isStore?: boolean;
      settleNo: number;
      sortIdList: Array<[]>; // 类别集合
      sortType: Array<[]>; // 类别维度
      wrhIdList: Array<[]>; // 门店或仓库编码
    };
  }) => post<IReportMonthStore>('/api/pcweb/finance/report/month/store/getTotalBySort', params),
  /* 按照商品维度查询 */
  queryByGoods: (params: {
    filter?: {
      isStore?: boolean; // 是否为门店
      searchKey?: string; // 商品编码/商品名称/商品条码
      settleNo?: number;
      sortIdList: Array<[]>; // 商品分类Id
      wrhIdList?: Array<[]>; // 门店或仓库编码
    };
  }) => post<IPageData<IReportMonthStore>>('/api/pcweb/finance/report/month/store/queryByGoods', params),
  /* 按照商品维度查询合计 */ // /api/pcweb/finance/report/month/store/getTotalByGoods
  getTotalByGoods: (params: {
    filter?: {
      isStore: boolean; // 是否为门店
      searchKey?: string; // 商品编码/商品名称/商品条码
      settleNo: number;
      sortIdList: Array<[]>; // 商品分类Id
      wrhIdList?: Array<[]>; // 门店或仓库编码
    };
  }) => post<IReportMonthStore>('/api/pcweb/finance/report/month/store/getTotalByGoods', params)
};
