import { WrhAllocOrderStatusEnum } from '@/dataModel/dict/enum';
import {
  IQueryBillParams,
  IQueryBill,
  IAllocOrderGoods,
  IBillDetail,
  IBIllOrderInfo
} from '@/dataModel/wrh/alloc/interface';
import request from '@/utils/request';

const alloc = {
  /** 分页查询列表 */
  queryBill: (params: { filter: IQueryBillParams } & IPageParams) =>
    request.post<IPageData<IQueryBill>>('/api/pcweb/wrh/alloc/order/queryBill', params),
  /**分页查询商品 */
  queryGoods: (
    params: {
      filter?: {
        keyword?: string;
        sortId?: string;
        pickingType?: string;
      };
      selectStoreId: string;
      wrhId: string;
    } & IPageParams
  ) => request.post<IPageData<IAllocOrderGoods>>('/api/pcweb/wrh/alloc/order/queryGoods', params),
  /** 删除 */
  remove: (params: { uuid: string }) => request.post('/api/pcweb/wrh/alloc/order/remove', params),
  /** 更新单据状态 */
  modifyStatus: (params: {
    /** 标识 */
    uuid: string;
    /** 状态 */
    targetStatus: WrhAllocOrderStatusEnum;
    /** 版本 */
    version: number;
  }) => request.post('/api/pcweb/wrh/alloc/order/modifyStatus', params),
  /** 获取详情 */
  getDetail: (
    params: {
      /** 单据标识 */
      bill: string;
      filter?: {
        /** 商品编码列表 */
        goodsId?: string[];
        /** 关键字 */
        keyword?: string;
      };
    } & IPageParams
  ) => request.post<IPageData<IBillDetail>>('/api/pcweb/wrh/alloc/order/queryDetail', params),
  /** 根据单据标识获取所有明细 */
  getDetails: (params: { uuid: string }) =>
    request.post<IAllocOrderGoods[]>('/api/pcweb/wrh/alloc/order/getDetails', params),
  /** 根据编码或标识获取单据 */
  orderGet: (params: { uuid: string }) => request.post<IBIllOrderInfo>('/api/pcweb/wrh/alloc/order/get', params),
  /** 保存订单（新增或编辑） */
  save: (params: {
    details: { goodsId: string; goodsQpcStr: string; qtyStr: string }[];
    remark?: string;
    selectStoreId: string;
    wrhId: string;
    targetStatus: WrhAllocOrderStatusEnum;
    uuid?: string;
    version?: number;
  }) =>
    request.post<{
      uuid: string;
      version: number;
    }>('/api/pcweb/wrh/alloc/order/save', params),
  /** 根据导入任务获取明细信息 */
  getDetailsByIoTask: (params: { taskUuid: string }) =>
    request.post<IAllocOrderGoods[]>('/api/pcweb/wrh/alloc/order/getDetailsByIoTask', params),
  /*配货通知单 批量提交 */
  submitAll: (params: { items: { bill: string; version: string }[] }) =>
    request.post('/api/pcweb/wrh/alloc/order/batchSubmit', params)
};
export default alloc;
