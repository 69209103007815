import { IStoreBack, IStoreBackDetailGoods, IStoreBackGoods } from '@/dataModel/business/storeBack/interface';
import { StoreBackStatusEnum } from '@/dataModel/dict/enum';
import request from '@/utils/request';
const { post } = request;

const storeBack = {
  /**分页查询单据 */
  query: (
    params: {
      filter: {
        id: string;
        status: StoreBackStatusEnum; //TODO曹海后端 枚举没确定
      };
    } & IPageParams
  ) => post<IPageData<IStoreBack>>('/api/pcweb/business/store/back/queryBill', params),

  /**分页查询商品*/
  queryGoods: (
    params: {
      filter: {
        goodsSearchKey?: string;
        sortId?: string;
      };
    } & IPageParams
  ) => post<IPageData<IStoreBackGoods>>('/api/pcweb/business/store/back/queryGoods', params),

  /**保存（创建、修改、保存并提交）*/
  save: (params: {
    uuid: string;
    details: {
      goodsId: string;
      goodsMunitUuid: string;
      price: number;
      qtyStr: string;
      reason: string;
    }[];
    info: {
      reason: string;
      remark: string;
      backTime: string;
    };
    targetStatus: StoreBackStatusEnum;
    version: number;
  }) => post<any>('/api/pcweb/business/store/back/save', params),

  /**获取单头*/
  get: (params: { id?: string; uuid?: string }) => post<IStoreBack>('/api/pcweb/business/store/back/get', params),

  /**提交单据*/
  submit: (params: { uuid: string; version: number }) => post<any>('/api/pcweb/business/store/back/submit', params),

  /**删除单据*/
  remove: (params: { uuid: string }) => post<any>('/api/pcweb/business/store/back/remove', params),

  /**分页查询单据明细 */
  queryDetail: (
    params: {
      bill: string;
      filter?: {
        goodsKeyWord?: string;
      };
    } & IPageParams
  ) => post<IPageData<IStoreBackDetailGoods>>('/api/pcweb/business/store/back/queryDetail', params),

  /**获取商品列表（新增或编辑*/
  getGoods: (params: { bill: string }) => post<IStoreBackGoods[]>('/api/pcweb/business/store/back/getGoods', params),
  /**
   * 根据导入任务获取明细信息
   * @param params
   */
  getGoodsByIoTask: (params: { taskUuid: string }) =>
    post<IStoreBackGoods[]>('/api/pcweb/business/store/back/getGoodsByIoTask', params)
};

export default storeBack;
