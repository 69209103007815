import { IContractQueryItems } from '@/dataModel/finance/contract/interface';
import { IFeeItem } from '@/dataModel/finance/feeItem/interface';
import request from '@/utils/request';

export default {
  create: (params: Partial<IFeeItem>) => request.post('/api/pcweb/finance/fee/item/create', params),

  delete: (params: { uuid: string }) => request.post('/api/pcweb/finance/fee/item/remove', params),

  modify: (params: Partial<IFeeItem>) => request.post('/api/pcweb/finance/fee/item/modify', params),

  query: (
    params: {
      filter?: {
        endDate?: string;
        feeSource?: string;
        feeType?: string;
        keyword?: string;
        payDirection?: string;
        payType?: string;
        startDate?: string;
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => request.post<IPageData<IFeeItem>>('/api/pcweb/finance/fee/item/query', params),

  /** 获取费用项目管理合约 */
  queryContractDetails: (params: { id?: string; uuid?: string } & IPageParams) =>
    request.post<IPageData<IContractQueryItems>>('/api/pcweb/finance/fee/item/queryContractDetails', params),

  get: (params: { uuid: string; id?: string }) => request.post<IFeeItem>('/api/pcweb/finance/fee/item/get', params),
  /** 获取费用项目列表 */
  getFeeItemList: (params: { feeItemType: string }) =>
    request.post<IFeeItem[]>('/api/pcweb/finance/contract/getFeeItemList', params)
};
