import { DataCheckBusinessTypeEnum } from '@/dataModel/dict/enum';
import { IDataCheckResult } from '@/dataModel/system/dataCheck/interface';
import request from '@/utils/request';

const { post } = request;

const dataCheck = {
  /**创建检查任务 */
  create: (params: {
    businessType: DataCheckBusinessTypeEnum;
    goodsRemove?: {
      id: string;
    };
    goodsRemoveBatch?: {
      ids: string[];
    };
    skuRemove?: {
      id: string;
      skuIds: string[];
    };
  }) => post<{ taskUuid: string }>('/api/pcweb/system/data/check/create', params),

  /**获取检查结果 */
  get: (params: { taskUuid: string }) => post<IDataCheckResult>('/api/pcweb/system/data/check/get', params)
};

export default dataCheck;
