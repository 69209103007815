import "./public-path";
import ReactDOM from 'react-dom';
import dva from 'dva';
import createLoading from 'dva-loading';
// import {  createHashHistory } from 'history';
import { createBrowserHistory as createHistory } from 'history';
// import saasEnv from 'saas-fe-kraken-core-react/es/utils/env';
import singleSpaDva from '@/utils/singleSpaDva';
import config from '@/config';
import router from '@/router';
import api from './services';
import { setDict } from '@/utils/dict';
import { MerchantPackTypeEnum } from './dataModel/dict/enum';
import { getMerchantPackType } from './utils/utils';
import { setSessionStorage } from '@/utils/storage';
import { setHeadingAccess } from './utils/headingAccess';
import '@/assets/styles/antd.fresh.weimob.cover.css';
import '@/assets/styles/antd.fresh.css';
import '@/assets/styles/special.fresh.css';

// 1. 创建dva实例
const app = dva({
  // history: createHashHistory(),
  history: createHistory(),
  onError() {
    // window.kraken.dispatchEvent('kraken:error');
  }
});
// 2. 装载插件
app.use(createLoading());
// 3. 注册 Model
app.model(require('./models/setting').default);
// 4. 路由配置
// app.router(router);

// app.start('#saas-app-con-pos');

if (!window.__POWERED_BY_QIANKUN__) {
  app.router(router);
  app.start('#saas-app-con-pos');
  // render({});
}
function domElementGetter() {
  let el = document.getElementById(`${config.system}-root`);
  if (!el) {
    const appCon = document.getElementById('saas-app-con-pos');
    el = document.createElement('div');
    el.className = `${config.system}`;
    el.id = `${config.system}-root`;
    appCon.appendChild(el);
  }
  return el;
}
// getAllSeting();

async function getAllSeting(){
  //
  await Promise.all([getSettingUserForm(), getDict(), getHeadingAccess(),getBillInfoSetting()]);
  await getTheme(getMerchantPackType());
}

function getTheme(merchantPackType = MerchantPackTypeEnum.Super) {
  const config = {
    [MerchantPackTypeEnum.Super]: async () => {
      await import('@/assets/styles/antd.super.weimob.cover.css');
      await import('@/assets/styles/antd.super.css');
    },
    [MerchantPackTypeEnum.Fresh]: async () => {
      await import('@/assets/styles/antd.fresh.weimob.cover.css');
      await import('@/assets/styles/antd.fresh.css');
      await import('@/assets/styles/special.fresh.css');
    }
  };

  return config[merchantPackType]();
}

// const reactLifecycles = singleSpaDva({
//   Dva: app,
//   ReactDOM,
//   domElementGetter,
//   appOptions: {}
// });

async function getDict() {
  const { data } = await api.mdata.dict.get();
  setDict(data);
  return data || {};
}

async function getHeadingAccess() {
  const { data } = await api.power.showElementList();
  const headingAccess = data || [];
  setHeadingAccess(headingAccess);
  return headingAccess;
}



async function getSettingUserForm() {
  // if (!window.kraken.getCache('userInfo')?.wid) return;
  const { data } = await api.system.settingUserForm.getAllUserForm();
  const list = data?.map(item => ({ businessType: item.businessType, pageSize: item.pageSize }));
  setSessionStorage('UserTableSetting', list);
}

async function getBillInfoSetting() {
  const { data } = await api.system.settingBill.getBillInfoSetting();
  setSessionStorage('billInfoSetting', data);
}



// export function bootstrap(props) {
//   return reactLifecycles.bootstrap(props);
// }

export async function bootstrap(props) {
  console.log('[react16] react app bootstraped=============', props);
  //return reactLifecycles.bootstrap(props);
}



export async function mount(props) {
  await props.onGlobalStateChange((state, prev) => {
    window.kraken = state?.kraken;
  });
  await Promise.all([getSettingUserForm(), getDict(), getHeadingAccess(), getBillInfoSetting()]);
  await getTheme(getMerchantPackType());
  // render(props);
  //return reactLifecycles.mount(props);
  app.router(router);
  app.start('#saas-app-con-pos');
}

export async function unmount(props) {
  const { container } = props;
  //return reactLifecycles.unmount(container ? container.querySelector('#saas-app-con-pos') : document.querySelector('#saas-app-con-pos'));
   ReactDOM.unmountComponentAtNode(container ? container.querySelector('#saas-app-con-pos') : document.querySelector('#saas-app-con-pos'));
}


