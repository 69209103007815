import { FeeItemTypeEnum } from '@/dataModel/dict/enum';
import {
  IContractQueryItems,
  IFeeItemInfoList,
  IGetAccessoryDetails,
  IGetFeeItemDetails,
  IGetFeesData
} from '@/dataModel/finance/contract/interface';
import request from '@/utils/request';
import { ISupplier } from '@/dataModel/mdata/supplier/interface';

export default {
  /** 分页查询 */
  query: (
    params: {
      filter: {
        contractEndDate: string;
        contractStartDate: string;
        endDate: string;
        idKeyword: string;
        startDate: string;
        status: string;
        supplierKeyword: string;
      };
    } & IPageParams
  ) => request.post<IPageData<IContractQueryItems>>('/api/pcweb/finance/contract/query', params),

  /** 新建/修改保存 */
  save: (params: {
    accessoryInfoList?: {
      downloadUrl: string;
      fileName: string;
      fileSize: string;
      line: number;
      remark?: string;
    }[];
    contactor: string;
    contractDate: string;
    endDate: string;
    feeItemInfoList: IFeeItemInfoList[];
    feeStatus: string;
    id?: string;
    phone: number;
    remark?: string;
    salesman: string;
    salesmanId: string;
    salesmanName: string;
    startDate: string;
    supplier: string;
    supplierContracter: string;
    uuid?: number;
    version?: number;
  }) => request.post<{ traceId: string; uuid: string }>('/api/pcweb/finance/contract/save', params),

  /** 获取供应商合约详情 */
  get: (params: { id?: string; uuid?: string }) =>
    request.post<IContractQueryItems>('/api/pcweb/finance/contract/get', params),

  /** 获取供应商合约附件 */
  getAccessoryDetails: (params: { id?: string; uuid?: string }) =>
    request.post<IGetAccessoryDetails[]>('/api/pcweb/finance/contract/getAccessoryDetails', params),

  /** 获取供应商合约费用项目明细 */
  getFeeItemDetails: (params: { id?: string; uuid?: string }) =>
    request.post<IGetFeeItemDetails[]>('/api/pcweb/finance/contract/getFeeItemDetails', params),

  /** 删除 */
  remove: (params: { uuid: string }) => request.post<{ traceId: string }>('/api/pcweb/finance/contract/remove', params),

  /** 费用预览 */
  getFees: (params: { endDate: string; feeTotal: number; monthDate: string; startDate: string } & IPageParams) =>
    request.post<IPageData<IGetFeesData>>('/api/pcweb/finance/contract/getFees', params),

  /** 检测供应商是否可以使用 */
  checkContract: (params: {
    /** 校验对象的枚举 后端: 此处值固定为supplier */
    target: string;
    /** 校验供应商的uuid */
    value: string;
  }) => request.post<any>('/api/pcweb/finance/contract/checkContract', params),
  /** 供应商列表 */
  getSupplierList: (params: Record<string, unknown>) =>
    request.post<ISupplier[]>('/api/pcweb/finance/contract/getSupplierList', params),
  /** 提成类费用预览 */
  getTiFees: (params: { cycleDay: string; cycleType: string; endDate: string; startDate: string } & IPageParams) =>
    request.post<IPageData<IGetFeesData>>('/api/pcweb/finance/contract/getFeesForPercent', params)
};
