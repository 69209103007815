import { IRulePromotionItem } from '@/dataModel/system/rulePromotion/interface';
import request from '@/utils/request';
const { post } = request;
enum barcodeTypeEnum {
  /**促销码 */
  Promotion = 'promotion',
  /**折扣码 */
  Discount = 'discount'
}
const rulePromotion = {
  /**
   * 分页查询
   */
  query: (params: { page: number; pageSize: number; barcodeType: barcodeTypeEnum }) =>
    post<IPageData<IRulePromotionItem>>('/api/pcweb/system/rule/special/promotion/query', params, {}, 'pos'),

  /**
   * 修改
   */
  modify: (params: { amountLength: number; barcodeType: string; prefix: string }) =>
    post<any>('/api/pcweb/system/rule/special/promotion/modify', params, {}, 'pos'),

  /**
   * 重置
   */
  reset: (params: { barcodeType: string }) =>
    post<any>('/api/pcweb/system/rule/special/promotion/reset', params, {}, 'pos'),

  /**
   * 检测前缀是否可以使用
   */
  check: (params: { barcodeType: string; prefix: string }) =>
    post<any>('/api/pcweb/system/rule/special/promotion/check', params, {}, 'pos')
};
export default rulePromotion;
