import {
  IGoodsInfoRule,
  INotInWhiteGoods,
  IOnlineRuleInventory,
  IQueryAddGoodsList,
  IQueryPriceGoods,
  IWhiteGoods
} from '@/dataModel/mdata/onlineRule/interface';
import { ISort } from '@/dataModel/mdata/sort/interface';
import request from '@/utils/request';
const { post } = request;

const onlineRule = {
  /**
   * 查询库存列表
   */
  queryInventory: (
    params: {
      filter: {
        searchKey: string;
      };
    } & IPageParams
  ) => post<IPageData<IOnlineRuleInventory>>('/api/pcweb/mdata/online/rule/inv/query', params, {}, 'onlineGoods'),

  /**
   * 查看库存详情
   */
  getInventory: (params: { store: string }) =>
    post<IOnlineRuleInventory>('/api/pcweb/mdata/online/rule/inv/get', params, {}, 'onlineGoods'),

  /**
   * 库存修改
   */
  modifyInventory: (params: {
    elemePercent?: number | null;
    elemeType?: string;
    jingdongPercent?: number | null;
    jingdongType?: string;
    meituanPercent?: number | null;
    meituanType?: string;
    stores: string[];
    wxappPercent?: number | null;
    wxappType?: string;
    refreshEleme?: boolean;
    refreshJd?: boolean;
    refreshMt?: boolean;
    refreshWeimob?: boolean;
  }) => post<{ traceId: string }>('/api/pcweb/mdata/online/rule/inv/modify', params, {}, 'onlineGoods'),

  /**
   * 查询-价格发布规则-门店配置
   */
  queryPriceStore: (
    params: {
      filter: {
        searchKey: string;
      };
    } & IPageParams
  ) => post<IPageData<IOnlineRuleInventory>>('/api/pcweb/mdata/online/rule/price/query', params, {}, 'onlineGoods'),

  /**
   * 查看详情-价格发布规则-门店配置
   */
  getPriceStore: (params: { store: string }) =>
    post<IOnlineRuleInventory>('/api/pcweb/mdata/online/rule/price/get', params, {}, 'onlineGoods'),

  /**
   * 库存修改-价格发布规则-门店配置
   */
  modifyPriceStore: (params: {
    elemePercent?: number | null;
    elemeType?: string;
    jingdongPercent?: number | null;
    jingdongType?: string;
    meituanPercent?: number | null;
    meituanType?: string;
    stores: string[];
    wxappPercent?: number | null;
    wxappType?: string;
    refreshEleme?: boolean;
    refreshJd?: boolean;
    refreshMt?: boolean;
    refreshWeimob?: boolean;
  }) => post<{ traceId: string }>('/api/pcweb/mdata/online/rule/price/modify', params, {}, 'onlineGoods'),

  /**
   * 查询-价格发布规则-价格敏感商品
   */
  queryPriceGoods: (
    params: {
      filter: {
        searchKey: string;
        sortId: string;
      };
      orderBy?: {
        direction: string;
        field: string;
      };
    } & IPageParams
  ) => post<IPageData<IQueryPriceGoods>>('/api/pcweb/mdata/online/rule/price/queryWhite', params, {}, 'onlineGoods'),

  /**
   * 查询-价格发布规则-价格敏感商品-添加商品列表
   */
  queryAddGoodsList: (
    params: {
      filter: {
        searchKey: string;
        sortId: string;
      };
      orderBy?: {
        direction?: string;
        field?: string;
      };
    } & IPageParams
  ) => post<IPageData<IQueryAddGoodsList>>('/api/pcweb/mdata/online/rule/price/queryGoods', params, {}, 'onlineGoods'),

  /**
   * 添加商品
   */
  addGoods: (params: { munitUuids: string[] }) =>
    post<{ traceId: string }>('/api/pcweb/mdata/online/rule/price/addGoods', params, {}, 'onlineGoods'),

  /**
   * 删除商品
   */
  remove: (params: { uuids: string[] }) =>
    post<{ traceId: string }>('/api/pcweb/mdata/online/rule/price/remove', params, {}, 'onlineGoods'),

  /**
   * 获取线上商品资料规则
   */
  getGoodsInfoRule: () => post<IGoodsInfoRule>('/api/pcweb/mdata/online/rule/goods/get',{}, {}, 'onlineGoods'),
  /**
   * 保存线上商品资料规则
   */
  saveGoodsInfoRule: (params: IGoodsInfoRule) => post('/api/pcweb/mdata/online/rule/goods/save', params, {}, 'onlineGoods'),
  /**
   * 分页查询未加入白名单的商品列表
   */
  queryNotInWhiteGoods: (
    params: {
      filter?: {
        searchKey?: string;
        sortId?: string;
      };
    } & IPageParams
  ) => post<IPageData<INotInWhiteGoods>>('/api/pcweb/mdata/online/rule/inv/queryNotInWhiteGoods', params, {}, 'onlineGoods'),
  /**
   * 分页查询加入白名单的商品
   */
  queryWhiteGoods: (
    params: {
      filter?: {
        searchKey?: string;
        sortId?: string;
      };
    } & IPageParams
  ) => post<IPageData<IWhiteGoods>>('/api/pcweb/mdata/online/rule/inv/queryWhiteGoods', params, {}, 'onlineGoods'),
  /**查询白名单列表 */
  queryWhiteSort: () =>
    post<{ sortId: string; sortName: string; uuid: string }[]>('/api/pcweb/mdata/online/rule/inv/queryWhiteSort',{}, {}, 'onlineGoods'),
  /**查询分类列表 */
  querySort: (params?: {
    /** 分类名称/编码 */
    filter: {
      searchKey: string;
    };
  }) => post<ISort[]>('/api/pcweb/mdata/online/rule/inv/querySort', params),
  /**添加商品或分类 */
  addInvWhiteData: (params: {
    invWhiteRuleAddList: {
      goodsMunit?: string; //单位
      id: string; //单位商品的munituuid或分类id
    }[];
    type: string; //商品goods，分类sort
  }) => post('/api/pcweb/mdata/online/rule/inv/addInvWhiteData', params, {}, 'onlineGoods'),
  /**删除白名单商品 */
  removeWhiteGoods: (params: { uuidList: string[] }) =>
    post('/api/pcweb/mdata/online/rule/inv/removeWhiteGoods', params, {}, 'onlineGoods')
};

export default onlineRule;
