import request from '@/utils/request';
import { IMemberStoredTrans } from '@/dataModel/member/memberStoredTrans/interface';
const { post } = request;

const memberStoredSummary = {
  /**总部-会员储值交易查询 */
  queryHeadMemberStoredTrans: (
    params: {
      filter?: {
        action?: string; //变动类型
        beginDate?: string; //开始时间
        cashierName?: string; //收银员姓名
        channel?: string; //渠道类型
        endDate?: string; //结束日期
        posId?: string; //posid
        storeSearchKey?: string; //门店
      };
    } & IPageParams
  ) => post<IPageData<IMemberStoredTrans>>('/api/pcweb/member/info/queryHeadMemberStoredTrans', params),
  /**总部-会员储值交易查询统计 */
  queryHeadMemberStoredTransTotal: (params: {
    filter?: {
      action?: string; //变动类型
      beginDate?: string; //开始时间
      cashierName?: string; //收银员姓名
      channel?: string; //渠道类型
      endDate?: string; //结束日期
      posId?: string; //posid
      storeSearchKey?: string; //门店
    };
  }) => post<any>('/api/pcweb/member/info/queryHeadMemberStoredTransTotal', params),
  /**门店-会员储值交易查询 */
  queryStoreMemberStoredTrans: (
    params: {
      filter?: any;
    } & IPageParams
  ) => post<IPageData<IMemberStoredTrans>>('/api/pcweb/member/info/queryStoreMemberStoredTrans', params),
  /**门店-会员储值交易查询统计 */
  queryStoreMemberStoredTransTotal: (params: { filter?: any }) =>
    post<any>('/api/pcweb/member/info/queryStoreMemberStoredTransTotal', params)
};

export default memberStoredSummary;
