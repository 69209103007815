import { IAppStoreGet } from '@/dataModel/system/appStore/interface';
import request from '@/utils/request';

const { post } = request;

export default {
  /**获得应用列表 */
  getAll: () => post<IAppStoreGet[]>('/api/pcweb/system/appStore/getAll'),
  /** 查询门店助手二维码 */
  getQRCode: () => post('/api/pcweb/system/appStore/getQRCode'),
  /**查询门店助手二维码 */
  getQRCodeStr: () => post('/api/pcweb/system/appStore/getQRCodeStr')
};
