import request from '@/utils/request';
import { ISettingBillInfo } from '@/dataModel/system/settingBill/interface';
const { post } = request;

const settingBill = {
  /** 查询系统所有单据信息的设置 */
  getBillInfoSetting: () => post<ISettingBillInfo>('/api/pcweb/system/business/getBillConfig')
};

export default settingBill;
