import {
  TicketBusinessTypeEnum,
  TicketTemplateDigitalNumberEnum,
  TicketTemplateDigitalNumberPositionEnum,
  TicketTemplateModeEnum
} from '@/dataModel/dict/enum';
import { ITicketTemplate, ITicketTemplateExplain } from '@/dataModel/retail/ticketTemplate/interface';
import request from '@/utils/request';

const { post } = request;

const ticketTemplate = {
  /**
   * 查询小票模板列表
   */
  query: (params?: {
    filter?: {
      businessType?: TicketBusinessTypeEnum;
    };
  }) => post<ITicketTemplate[]>('/api/pcweb/retail/ticket/template/query', params, {}, 'pos'),

  /**
   * 新增销售or退货小票模板,另存为等同新增
   */
  create: (
    params: Partial<
      Pick<
        ITicketTemplate,
        | 'headAdvertisement'
        | 'showMemberBalanceAndScore'
        | 'showMemberRegisterQrCode'
        | 'showOnlineShopQrCode'
        | 'storeName'
        | 'style'
        | 'tailAdvertisement'
        | 'tailImages'
      >
    > & {
      mode: TicketTemplateModeEnum;
      businessType: TicketBusinessTypeEnum;
      digitalBarcode: TicketTemplateDigitalNumberEnum;
      digitalBarcodePosition: TicketTemplateDigitalNumberPositionEnum;
      name: ITicketTemplate['name'];
    }
  ) => post<{ uuid: ITicketTemplate['uuid'] }>('/api/pcweb/retail/ticket/template/create', params, {}, 'pos'),

  /**
   * 新增销售or退货小票模板,另存为等同新增
   */
  modify: (
    params: {
      mode: TicketTemplateModeEnum;
      businessType: TicketBusinessTypeEnum;
      digitalBarcode: TicketTemplateDigitalNumberEnum;
      digitalBarcodePosition: TicketTemplateDigitalNumberPositionEnum;
      style?: ITicketTemplate['style'];
    } & Pick<
      ITicketTemplate,
      | 'uuid'
      | 'version'
      | 'headLogo'
      | 'headAdvertisement'
      | 'showMemberBalanceAndScore'
      | 'showMemberRegisterQrCode'
      | 'name'
      | 'showOnlineShopQrCode'
      | 'storeName'
      | 'tailAdvertisement'
      | 'tailImages'
    >
  ) => post<{ version: ITicketTemplate['version'] }>('/api/pcweb/retail/ticket/template/modify', params, {}, 'pos'),

  /**
   * 查询小票详情
   */
  get: (params: { uuid: ITicketTemplate['uuid'] }) =>
    post<ITicketTemplate>('/api/pcweb/retail/ticket/template/get', params, {}, 'pos'),

  /**
   * 删除模板(默认模板不允许删除)
   */
  remove: (params: { uuid: ITicketTemplate['uuid'] }) =>
    post<null>('/api/pcweb/retail/ticket/template/remove', params, {}, 'pos'),

  /**
   * 查询模板名称是否重复
   */
  validateName: (params: { name: ITicketTemplate['name'] }) =>
    post<{
      /**
       * true 通过，false 失败
       */
      result: boolean;
    }>('/api/pcweb/retail/ticket/template/validateName', params, {}, 'pos'),

  /**
   * 查看参数说明
   */
  getFieldExplain: (params: { businessType: TicketBusinessTypeEnum }) =>
    post<ITicketTemplateExplain>('/api/pcweb/retail/ticket/template/getFieldExplain', params, {}, 'pos'),

  /**
   * 查询小票默认样式文本
   */
  getDefaultStyle: (params: { businessType: TicketBusinessTypeEnum }) =>
    post<ITicketTemplate['style']>('/api/pcweb/retail/ticket/template/getDefaultStyle', params, {}, 'pos')
};

export default ticketTemplate;
