import request from '@/utils/request';

// 统计查询合计
interface IGetCountParams {
  filter: {
    endDate: string;
    goodsKeyword: string;
    purchaseBackId: string;
    purchaseInId: string;
    purchaseOrderId: string;
    purchaser: string;
    receiverId: string;
    sortId: string;
    startDate: string;
    status: string;
    statusList: string;
    supplier: string;
  };
  page: number;
  pageSize: number;
  pid: string;
  storeId: number;
  wid: string;
}
export const getCount = (data: IGetCountParams): Promise<Record<string, any>> => {
  return request.post('/api/pcweb/purchase/goods/getTotal', data);
};

// 按商品汇总
interface IQueryByGoodsParams {
  filter: {
    endDate: string;
    goodsKeyword: string;
    purchaseBackId: string;
    purchaseInId: string;
    purchaseOrderId: string;
    purchaser: string;
    receiverId: string;
    sortId: string;
    startDate: string;
    status: string;
    statusList: string;
    supplier: string;
  };
  page: number;
  pageSize: number;
  pid: string;
  storeId: number;
  wid: string;
}
export const queryByGoods = (data: IQueryByGoodsParams): Promise<Record<string, any>> => {
  return request.post('/api/pcweb/purchase/goods/queryByGoods', data);
};

// /api/pcweb/purchase/goods/queryBackDetail
// 退货明细
interface IQueryBackDetailParams {
  filter: {
    endDate: string;
    goodsKeyword: string;
    purchaseBackId: string;
    purchaseInId: string;
    purchaseOrderId: string;
    purchaser: string;
    receiverId: string;
    sortId: string;
    startDate: string;
    status: string;
    statusList: string;
    supplier: string;
  };
  page: number;
  pageSize: number;
  pid: string;
  storeId: number;
  wid: string;
}
export const queryBackDetail = (data: IQueryBackDetailParams): Promise<Record<string, any>> => {
  return request.post('/api/pcweb/purchase/goods/queryBackDetail', data);
};

// 供应商汇总
interface IQueryBySupplierParams {
  filter: {
    endDate: string;
    goodsKeyword: string;
    purchaseBackId: string;
    purchaseInId: string;
    purchaseOrderId: string;
    purchaser: string;
    receiverId: string;
    sortId: string;
    startDate: string;
    status: string;
    statusList: string;
    supplier: string;
  };
  page: number;
  pageSize: number;
  pid: string;
  storeId: number;
  wid: string;
}
export const queryBySupplier = (data: IQueryBySupplierParams): Promise<Record<string, any>> => {
  return request.post('/api/pcweb/purchase/goods/queryBySupplier', data);
};

// 分类汇总
interface IQueryBySortParams {
  filter: {
    endDate: string;
    goodsKeyword: string;
    purchaseBackId: string;
    purchaseInId: string;
    purchaseOrderId: string;
    purchaser: string;
    receiverId: string;
    sortId: string;
    startDate: string;
    status: string;
    statusList: string;
    supplier: string;
  };
  page: number;
  pageSize: number;
  pid: string;
  storeId: number;
  wid: string;
}
export const queryBySort = (data: IQueryBySortParams): Promise<Record<string, any>> => {
  return request.post('/api/pcweb/purchase/goods/queryBySort', data);
};

// 进货明细
interface IQueryInDetailParams {
  filter: {
    endDate: string;
    goodsKeyword: string;
    purchaseBackId: string;
    purchaseInId: string;
    purchaseOrderId: string;
    purchaser: string;
    receiverId: string;
    sortId: string;
    startDate: string;
    status: string;
    statusList: string;
    supplier: string;
  };
  page: number;
  pageSize: number;
  pid: string;
  storeId: number;
  wid: string;
}
export const queryInDetail = (data: IQueryInDetailParams): Promise<Record<string, any>> => {
  return request.post('/api/pcweb/purchase/goods/queryInDetail', data);
};

export default {
  getCount,
  queryBackDetail,
  queryByGoods,
  queryBySort,
  queryBySupplier,
  queryInDetail
};

// /api/pcweb/purchase/goods/queryByGoods
// 采购商品查询-商品汇总

// /api/pcweb/purchase/goods/queryBySort
// 采购商品查询-分类汇总

// /api/pcweb/purchase/goods/queryBySupplier
// 采购商品查询-供应商汇总

// /api/pcweb/purchase/goods/queryInDetail
// 进货明细
