import {
  SupplierSettleTypeEnum,
  SupplierStatusEnum,
  SupplierTypeEnum,
  SupplierOrderTypeEnum,
  SupplierAgreementQueryBusinessEnum,
  SupplierAuthEnumEnum
} from '@/dataModel/dict/enum';
import {
  ISupplier,
  ISupplierRemove,
  IAuthorization,
  ISupplierInfo,
  IStoreInSupplierInfo
} from '@/dataModel/mdata/supplier/interface';
import { ISupplierAgreementDetail } from '@/dataModel/purchase/order/interface';
import request from '@/utils/request';
import { number } from 'prop-types';

interface IEditSupplierParams {
  name: string;
  id: ISupplier['id'];
  bankAccountNo?: string;
  bankBranchName?: string;
  contactor?: string;
  phone?: string;
  email?: string;
  locationAddress?: string;
  locationCity?: string;
  locationCounty?: string;
  locationProvince: string;
  locationProvinceName?: string;
  locationCityName?: string;
  locationCountyName?: string;
  arriveDays: number;
  expireDays: number;
  payDate: number;
  payDays: number;
  verifyDate: number;
  verifyDays: number;
  purchaser?: string;
  remark?: string;
  supplierType: SupplierTypeEnum;
  status: SupplierStatusEnum;
  settleType: SupplierSettleTypeEnum;
  direct: boolean; // 下版本废弃
  storeIn: boolean; // 下版本废弃
  /** 是否直送到店 */
  isAlloc: boolean;
  /** 是否直配到店 */
  isDirect: boolean;
  /** 是否到仓 */
  isWrh: boolean;
  /** 允许自采 */
  isStoreIn: boolean;
}

interface ISupplierRelation {
  supplierType: any;
  /**授权失效时间*/
  authEnd: string;

  /**开始授权时间*/
  authStart: string;

  /**申请人手机号*/
  contactMobile: string;

  /**申请人姓名*/
  contactName: string;

  createInfo: any;
  lastModifyInfo: any;
  /**默认授权集合*/
  roles: string;

  status: { id: string; name: string };
  /**供应商uuid*/
  supplier: string;

  /**供应商id*/
  supplierId: string;

  /**供应商名称*/
  supplierName: string;

  /**供应商租户id（过滤我的客户）*/
  supplierTenantId: string;

  /**租户*/
  tenantId: string;

  /**租户名称（客户名称显示）*/
  tenantName: string;

  /**标识*/
  uuid: string;

  /**版本号*/
  version: number;
}
const { post } = request;

const supplier = {
  /**
   * 查询
   */
  query: (
    params: {
      filter?: {
        /**
         * 传空字符串查所有
         */
        key?: string;
        /** 供应商商品关键词 */
        searchGoods?: string;
        settleType?: SupplierSettleTypeEnum;
        status?: SupplierStatusEnum;
        type?: SupplierTypeEnum;
        //排序
        order?: SupplierOrderTypeEnum;
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => post<IPageData<ISupplier>>('/api/pcweb/mdata/supplier/query', params),
  /**
   * 查询所有无采购协议的供应商
   */
  getAllSupplierNotAgreement: (
    params: {
      filter?: {
        /**
         * 传空字符串查所有
         */
        key?: string;
        /** 供应商商品关键词 */
        searchGoods?: string;
        settleType?: SupplierSettleTypeEnum;
        status?: SupplierStatusEnum;
        type?: SupplierTypeEnum;
        //排序
        order?: SupplierOrderTypeEnum;
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => post<ISupplier[]>('/api/pcweb/mdata/supplier/getAllSupplierNotAgreement', params),
  /**
   * 查询门店下的供应商
   */
  queryStoreSupplier: (
    params: {
      filter?: {
        /**
         * 传空字符串查所有
         */
        key?: string;
        /** 供应商商品关键词 */
        searchGoods?: string;
        settleType?: SupplierSettleTypeEnum;
        status?: SupplierStatusEnum;
        type?: SupplierTypeEnum;
        //排序
        order?: SupplierOrderTypeEnum;
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => post<IPageData<ISupplier>>('/api/pcweb/mdata/supplier/queryStoreSupplier', params),
  /** 查询所有供应商 */
  queryForStore: (
    params: {
      filter?: {
        /**
         * 传空字符串查所有
         */
        key?: string;
        /** 供应商商品关键词 */
        searchGoods?: string;
        settleType?: SupplierSettleTypeEnum;
        status?: SupplierStatusEnum;
        type?: SupplierTypeEnum;
        //排序
        order?: SupplierOrderTypeEnum;
      };
      orderBy?: IPageParamsOrderBy;
    } & IPageParams
  ) => post<IPageData<ISupplier>>('/api/pcweb/mdata/supplier/queryForStore', params),

  /**
   * 查全部
   */
  getAll: (params?: { supplierType?: string; supplierStatus?: string }) =>
    post<ISupplier[]>('/api/pcweb/mdata/supplier/getAll', params),
  /**
   * 根据自采类型获取全部供应商
   */

  getByStoreDirectType: (params: { isDirect: boolean; isStoreIn?: boolean }) =>
    post<ISupplier[]>('/api/pcweb/mdata/supplier/getByStoreDirectType', params),

  /**
   * 查看供应商信息
   * @returns
   */
  get: (params: { id?: string; uuid?: string }) => post<ISupplier>('/api/pcweb/mdata/supplier/get', params),
  /**
   * 删除供应商
   * @returns
   */
  remove: (params: { uuid: string }) => post<ISupplierRemove>('/api/pcweb/mdata/supplier/remove', params),
  /**
   * 批量删除供应商
   * @returns
   */
  removeBatch: (params: { uuids: string[] }) => post<ISupplierRemove>('/api/pcweb/mdata/supplier/removeBatch', params),
  /**
   * 新增供应商
   */
  create: (params: IEditSupplierParams) => post<{ uuid: string }>('/api/pcweb/mdata/supplier/create', params),
  /**
   * 修改供应商
   */
  modify: (params: { uuid: string; version: number } & IEditSupplierParams) =>
    post<{ version: number }>('/api/pcweb/mdata/supplier/modify', params),
  /** 分页查询明细 - 用于采购订单 */
  queryDetailForOrder: (
    params: Partial<{
      businessType?: SupplierAgreementQueryBusinessEnum;
      bill: string;
      id: string;
      supplier?: string;
      targetStoreId?: string;
      wrh?: string;
      filter: { key?: string; excludeGoodsIds?: string[]; sortId?: string };
    }> &
      IPageParams
  ) =>
    request.post<IPageData<ISupplierAgreementDetail>>(
      '/api/pcweb/mdata/supplier/agreement/queryDetailForOrder',
      params
    ),
  /** 直送 true 直配 false */
  getByDirect: (direct: boolean) => request.post<ISupplier[]>('/api/pcweb/mdata/supplier/getByDirect', { direct }),
  /**
   * 查看供应商授权信息
   */
  getAuthorization: (params: { supplierId: string }) =>
    post<IAuthorization>('/api/pcweb/mdata/supplier/authorization/get', params),
  /**
   * 添加供应商授权信息
   */
  createAuthorization: (params: {
    accountName: string;
    mobile: string;
    status: SupplierAuthEnumEnum;
    supplier: string;
    supplierId: string;
    supplierName: string;
  }) => post('/api/pcweb/mdata/supplier/authorization/create', params),
  /**
   * 编辑供应商授权信息
   */
  modifyAuthorization: (params: {
    accountName: string;
    mobile: string;
    status: SupplierAuthEnumEnum;
    uuid: string;
    version: number;
  }) => post('/api/pcweb/mdata/supplier/authorization/modify', params),
  /**
   * 删除供应商授权信息
   */
  removeAuthorization: (params: { uuid: string }) => post('/api/pcweb/mdata/supplier/authorization/remove', params),
  /**
   * 获取供应商可用数和已用数
   */
  getAllEnabledAndUnLock: () => post<ISupplierInfo>('/api/pcweb/mdata/supplier/authorization/getAllEnabledAndUnLock'),

  /**
   * 根据自采类型获取
   */
  getByStoreIn: (params: { storeIn: boolean }) =>
    post<IStoreInSupplierInfo[]>('/api/pcweb/mdata/supplier/getByStoreIn', params),
  /**
   * 查询开通数量
   */
  getRelationNum: (params: Record<string, unknown>) =>
    post<{ authedCount: number; buyCount: number }>('/api/pcweb/supplier/relation/count', params),
  /**
   * 查询我的供应商列表
   */
  getRelationList: (
    params: {
      filter?: {
        status?: string;
        supplier?: string;
      };
    } & IPageParams
  ) => post<IPageData<ISupplierRelation>>('/api/pcweb/supplier/relation/query', params),
  /**
   * 生产邀请码
   */
  crateCode: (params: { mobile?: string; supplier: string }) =>
    post<{
      /**授权码*/
      authCode: string;

      /**二维码地址*/
      codeUrl: string;

      createInfo: any;
      /**到期时间*/
      expire: string;

      /**剩余过期天数*/
      expireDay: number;

      lastModifyInfo: any;
      /**手机号*/
      mobile: string;

      /**生成授权码时，是否已经注册*/
      registered: number;

      /**供应商uuid*/
      supplier: string;

      /**供应商id*/
      supplierId: string;

      /**供应商名称*/
      supplierName: string;

      /**租户*/
      tenantId: string;

      /**租户名称（客户名称显示）*/
      tenantName: string;

      /**使用状态：0：未使用(默认值)，1:已使用*/
      used: number;

      /**标识*/
      uuid: string;

      /**版本号*/
      version: number;

      wid: string;
    }>('/api/pcweb/supplier/code/crate', params),
  /**
   * 删除授权
   */
  deleteRelation: (params: { uuids: string[] }) => post('/api/pcweb/supplier/relation/delete', params),
  /**
   * 解冻
   */
  unfrozen: (params: { uuids: string[] }) => post('/api/pcweb/supplier/relation/unfrozen', params),
  /**
   * 同意授权
   */
  addAuth: (params: { uuids: string[]; roles: string[] }) => post('/api/pcweb/supplier/relation/addAuth', params),
  /**
   * 设置权限
   */
  setRoles: (params: { uuids: string[]; roles: string[] }) => post('/api/pcweb/supplier/relation/setRoles', params),
  /**
   * 供应商列表
   */
  supplierList: (params: Record<string, unknown>) => post<any[]>('/api/pcweb/supplier/relation/supplierList', params),
  /**
   * 授权码供应商列表
   */
  codeSupplierList: (params: Record<string, unknown>) => post('/api/pcweb/supplier/code/supplierList', params),
  /**
   * 授权码列表
   */
  getCodeList: (params: Record<string, unknown> & IPageParams) =>
    post<IPageData<any>>('/api/pcweb/supplier/code/query', params)
};
export default supplier;
